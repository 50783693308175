import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate, NavigateFunction } from "react-router-dom";

// own states
import { AppDispatch, State } from "../../Redux/Store";
import { selectorLanguage } from "../../Redux/Translate";

// Own components
import Text from "../../Components/Text";
import Button from "../../Components/Button";
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";

import { AlignItems, JustifyContent } from "../../Types/Column";
import { CheckInstructionsContainer } from "./Components/CheckInstructions";
import IconFont from "../../Components/IconFont";
import PlaidLink from "./Components/PlaidLink";
import usePlaidPaymentOrder from "../../Hooks/usePlaidPaymentOrder";
import {
  PaymentLinkedAccountCardLoading,
  PaymentLinkedAccountCardRadio,
} from "./Components/PaymentLinkedAccountCard";
import { PlaidAccounts } from "../../Modules/PaymentOrders/domain/Plaid";
import {
  LinkedAccountCardExtraStyled,
  CursorPointerStyled,
} from "./Payments.styled";

import { PaymentLinkedAccountSlice } from "../../Redux/PaymentLinkedAccount/PaymentLinkedAccount.slice";
import { TFunction } from "i18next";
import { CheckInstructionsCard } from "./Components/CheckInstructions/CheckInstructionsCard";
import PlaidLogoSrc from "../../Assets/Img/plaid-logo.png";

const PaymentSelectLinkedBankAccount = () => {
  const [tSendMoney] = useTranslation("sendMoney");
  const [t] = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const [showLinkAccounts, setShowLinkAxccounts] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({} as PlaidAccounts);
  const {
    accounts,
    isAccountLoading,
    isLoadingDetails,
    plaidGetAccounts,
    plaidGetAccountDetails,
  } = usePlaidPaymentOrder();
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    plaidGetAccounts();
  }, [lang]);

  const setPayment = async () => {
    const response = await plaidGetAccountDetails(selectedAccount.id);

    if (response) {
      dispatch(
        PaymentLinkedAccountSlice.actions.setLinkedAccountInformation({
          accountId: selectedAccount.id,
          accountNumber: response.account,
          routingNumber: response.routing,
          bankName: response.bankName,
        })
      );
      navigate("/review-transaction");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout
        path="/payments"
        textLink={t("buttons.back")}
        loading={isLoadingDetails}
      >
        <Container>
          <Row>
            <Column
              span={12}
              pb={8}
              px={3}
              alignItems={AlignItems.center}
              justifyContent={JustifyContent.center}
            >
              {isAccountLoading ? (
                <PaymentLinkedAccountCardLoader />
              ) : (
                <>
                  {(Object.keys(accounts).length > 0 && accounts.hasAccounts) ||
                  showLinkAccounts ? (
                    <PaymentLinkedAccountCardList
                      title={t("Payments.LinkedAccounts.selectAccount")}
                      btnText={t("Payments.LinkedAccounts.btnLinkAccount")}
                      accounts={accounts.accounts}
                      navigate={navigate}
                      getSelected={(account) => setSelectedAccount(account)}
                      t={t}
                    />
                  ) : (
                    <CheckInstructionsContainer
                      title={t("Payments.LinkedAccounts.initTitle")}
                      description={t(
                        "Payments.LinkedAccounts.initInstructions"
                      )}
                      icon={
                        <IconFont name="link" color="white" size="xxlarge" />
                      }
                    >
                      <Column mb={5}>
                        <CheckInstructionsCard
                          title={t("Payments.howItWorks")}
                          body={
                            <>
                              <Text
                                align="left"
                                size={1}
                                margin="24px 0 24px 0"
                              >
                                {t("Payments.LinkedAccounts.helpText1")}
                              </Text>
                              <Text
                                align="left"
                                size={1}
                                margin="24px 0 24px 0"
                              >
                                {t("Payments.LinkedAccounts.helpText2")}
                              </Text>
                              <Text
                                align="left"
                                size={1}
                                margin="24px 0 24px 0"
                              >
                                {t("Payments.LinkedAccounts.helpText3")}
                              </Text>
                            </>
                          }
                          imageSrcRight={PlaidLogoSrc}
                        />
                      </Column>
                      <PlaidLink
                        btnVariant="primary"
                        btnText={t("Payments.LinkedAccounts.btnStarted")}
                        btnIcon="arrowLeft"
                        onCompleted={(accountId: string) =>
                          navigate(
                            "/payment-link-external-bank-account-details",
                            {
                              state: {
                                accountId,
                                isConfirmed: true,
                                backPath: "/payment-select-linked-accounts",
                              },
                            }
                          )
                        }
                      />
                    </CheckInstructionsContainer>
                  )}
                </>
              )}
            </Column>
            {Object.keys(selectedAccount).length > 0 && (
              <Column
                span={12}
                pb={8}
                alignItems={AlignItems.center}
                justifyContent={JustifyContent.center}
              >
                <Button
                  onClick={setPayment}
                  variant={"primary"}
                  sizeButton="large"
                  sizeText="large"
                  sizeIcon="large"
                  iconButton="arrowRight"
                  text={tSendMoney("Next")}
                  colorIcon="white"
                  disabled={Object.keys(selectedAccount).length === 0}
                />
              </Column>
            )}
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

const PaymentLinkedAccountCardList = ({
  title,
  accounts,
  btnText,
  navigate,
  getSelected,
  t,
}: {
  title: string;
  accounts: Array<PlaidAccounts>;
  btnText: string;
  navigate: NavigateFunction;
  getSelected?: (account: PlaidAccounts) => void;
  t: TFunction;
}) => (
  <Row>
    <Column span={12} justifyContent={JustifyContent.center}>
      <Text
        color="black"
        size={4}
        align="left"
        weight={600}
        margin="48px 0 24px 0"
      >
        {title}
      </Text>
    </Column>
    <Column span={12} justifyContent={JustifyContent.center}>
      <PlaidLink
        btnText={btnText}
        btnVariant="outline"
        onCompleted={(accountId: string) =>
          navigate("/payment-link-external-bank-account-details", {
            state: {
              accountId,
              isConfirmed: true,
              backPath: "/payment-select-linked-accounts",
            },
          })
        }
      />
    </Column>
    <Column span={3} xs={12} />
    <Column span={6} xs={12} px={2} py={4}>
      {accounts.map((account) => (
        <div key={account.id}>
          <PaymentLinkedAccountCardRadio
            title={account.bankName}
            description={account.subtype}
            helperText={`************${account.accountLastFour}`}
            value={account.id}
            getValue={(value: ChangeEvent<HTMLInputElement>) => {
              if (value?.target?.value === account.id && getSelected) {
                getSelected(account);
              }
            }}
            deleteAccount={() =>
              navigate("/payment-link-external-bank-account-details", {
                state: {
                  accountId: account.id,
                  backPath: "/payment-select-linked-accounts",
                },
              })
            }
            extras={
              <>
                {!account.activeToken && (
                  <CursorPointerStyled>
                    <LinkedAccountCardExtraStyled
                      onClick={() =>
                        navigate(
                          "/payment-link-external-bank-account-details",
                          {
                            state: {
                              requiredUpdate: !account.activeToken,
                              accountId: account.id,
                              backPath: "/payment-select-linked-accounts",
                            },
                          }
                        )
                      }
                    >
                      <IconFont name="alert" color="error" size="small" />
                      <Text color="error" weight={600} margin="15px 0 10px 0">
                        {t("Payments.LinkedAccounts.cardNeedsUpdate")}
                      </Text>
                    </LinkedAccountCardExtraStyled>
                  </CursorPointerStyled>
                )}
                <CursorPointerStyled
                  onClick={() =>
                    navigate("/payment-link-external-bank-account-details", {
                      state: {
                        accountId: account.id,
                        backPath: "/payment-select-linked-accounts",
                      },
                    })
                  }
                >
                  <Text color="black" weight={600} margin="15px 0 15px 0">
                    {t("Payments.LinkedAccounts.cardViewDetails")}
                  </Text>
                </CursorPointerStyled>
              </>
            }
          />
        </div>
      ))}
    </Column>
    <Column span={3} xs={12} sm={3} />
  </Row>
);

const PaymentLinkedAccountCardLoader = () => (
  <Row>
    <Column span={3} xs={12} />
    <Column span={6} xs={12} p={4}>
      <PaymentLinkedAccountCardLoading />
      <PaymentLinkedAccountCardLoading />
      <PaymentLinkedAccountCardLoading />
      <PaymentLinkedAccountCardLoading />
    </Column>
    <Column span={3} xs={12} />
  </Row>
);

export default PaymentSelectLinkedBankAccount;
