import { useState } from "react";
import { SwitchWrapper, SwitchBall } from "./Switch.styled";

interface SwitchProps {
  value?: boolean;
  colorOn?: string;
  colorOff?: string;
  valueOn?: string;
  valueOff?: string;
  onChange?: (value: string) => void;
}

const Switch = ({
  value,
  colorOn = "#4caf50",
  colorOff = "#ccc",
  valueOn = "On",
  valueOff = "Off",
  onChange,
}: SwitchProps) => {
  const [isOn, setIsOn] = useState<boolean>(value || false);

  const toggleSwitch = () => {
    const newValue = !isOn ? valueOn : valueOff;
    setIsOn((prev) => !prev);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <SwitchWrapper isOn={isOn} colorOn={colorOn} colorOff={colorOff} onClick={toggleSwitch}>
      <SwitchBall isOn={isOn} />
    </SwitchWrapper>
  );
};

export default Switch;
