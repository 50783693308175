import { TOKEN_KEY } from "../Constants/Token";
import { LANG_KEY } from "../Constants/Language";
import Cookies from "universal-cookie";
import { AxiosProvider } from "./http/Axios.provider";
import { ApiRequestError } from "../Types/ApiRequestError";

const apiKey = import.meta.env.VITE_API_KEY_IAM;
const prefixUrl = import.meta.env.VITE_BASE_URL_IAM;
const HttpClient = AxiosProvider;

function getToken() {
  const cookies = new Cookies();
  return sessionStorage.getItem(TOKEN_KEY) || cookies.get(TOKEN_KEY);
}

export function createConfigApiIam() {
  const language = sessionStorage.getItem(LANG_KEY) || "en";

  const client = new HttpClient({
    config: {
      baseURL: prefixUrl,
      headers: {
        languageCode: language,
        "x-api-key": apiKey,
      },
    },
    hooks: {
      request: (config) => {
        const token = getToken();

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },
      response: {
        onRejected: (error) => {
          const apiErrorData = error.response?.data as ApiRequestError;
          const errorObj = {
            status: error.response?.status,
            statusText: error.response?.statusText,
            ...apiErrorData,
          };

          // TODO: Customize error handling allowing more types of errors and actions

          throw errorObj;
        },
      },
    },
  });

  return client;
}
