import Modal from "../Modal.component";
import IconFont from "../../IconFont";
import Text from "../../Text";
import { ModalProps } from "../../../Models/ModalInterface";
import { useTranslation } from "react-i18next";
import Button from "../../Button";
import { BanUserModalUserPhone } from "./BanUserModal.styled";
import { FlexContainer } from "../../Flex/Flex.styled";

export const BanUserModal = (props: ModalProps & { userPhone?: string }) => {
  const [tModals] = useTranslation("modals");

  return (
    <Modal {...props}>
      <FlexContainer direction="column" alignItems="center">
        <IconFont name="circle-exclamation" color="error" size="xxlarge" />
        <Text size={0} weight={800} color="error" align="center">
          {tModals("banUsers.message")}
        </Text>
        <Text size={1} weight={300} margin={0}>
          {tModals("banUsers.assistance")}
        </Text>
        <BanUserModalUserPhone href="tel:+18327302683">
          <IconFont name="phone" color="primary" size="medium" />
          <Text size={1} weight={800} margin={0}>
            +1 (832)-730-2683
          </Text>
        </BanUserModalUserPhone>
        <Button
          variant="danger"
          sizeButton="large"
          sizeText="large"
          text={tModals("close")}
          colorIcon="white"
          sizeIcon="large"
          onClick={() => props.handleClose()}
        />
      </FlexContainer>
    </Modal>
  );
};
