import { PaymentOrderRepository } from "../domain/PaymentOrderRepository";
import { VerifyPaymentOrder } from "../domain/VerifyPaymentOrder";

export const verifyPaymentOrder = (
  paymentOrderRepository: PaymentOrderRepository
) => {
  return async (payload: VerifyPaymentOrder) => {
    const response = await paymentOrderRepository.verifyPaymentOrder(payload);

    return response;
  };
};

export const termsConditionsUniteller = (
  paymentOrderRepository: PaymentOrderRepository
) => {
  return async () => {
    const response = await paymentOrderRepository.termsConditionsUniteller();

    return response;
  };
};

export const saveSenderEmail =
  (paymentOrderRepository: PaymentOrderRepository) =>
  (email: string, personId?: string) =>
    paymentOrderRepository.saveSenderEmail(email, personId);
