import { OverLay } from "./WalkthroughModal.styled";
import {
  InfoPopUp,
  WalkthroughModalProps,
} from "../../../Models/ModalInterface";
import { WalkthroughPopup } from "./WalkthroughPopup.component";
import { useEffect, useRef, useState } from "react";
import isEmpty from "lodash.isempty";

export const WalkthroughModal = ({
  id,
  show,
  popUps,
}: WalkthroughModalProps) => {
  const closedPopUps = useRef<string[]>([]);
  const [currentPopUpsQueue, setCurrentPopUpsQueue] = useState<InfoPopUp[]>([]);

  const setNextPopUpOnQueue = (popUpClosedId: string) => {
    closedPopUps.current = [...closedPopUps.current, popUpClosedId];
    const popUpsNotClosed = popUps?.filter(
      (popUp) => ![...closedPopUps.current, popUpClosedId].includes(popUp.id)
    );

    if (popUpsNotClosed && !isEmpty(popUpsNotClosed)) {
      setCurrentPopUpsQueue([popUpsNotClosed[0]]);
    }
  };

  useEffect(() => {
    if (popUps && !isEmpty(popUps)) {
      setCurrentPopUpsQueue([popUps[0]]);
    }
  }, []);

  return (
    <OverLay id={id} hidden={!show}>
      {currentPopUpsQueue?.map((popUp) => (
        <WalkthroughPopup
          key={`walkthrough-${popUp.id}`}
          popUp={popUp}
          onClose={setNextPopUpOnQueue}
        />
      ))}
    </OverLay>
  );
};
