import styled, { css, CSSProperties } from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { ifProp, prop, theme } from "styled-tools";
import {
  headerBackgroundColor,
  headerFontColor,
  headerLogo,
} from "../../Contexts/ThemeProvider/Layout";
import { FlexContainer } from "../Flex/Flex.styled";

export const HeaderStyled = styled.div`
  background: transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  position: relative;
  max-width: 1500px;
  width: 100%;
`;

export const Logo = styled.div`
  box-sizing: border-box;
  padding: 0 17px;
  display: flex;
  flex: 1 1 0px;
  align-items: center;
  justify-content: center;

  a {
    padding: 0;
    display: flex;
  }

  img {
    ${headerLogo}
  }
`;

export const LanguageSection = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  width: min-content;
  padding-left: 16px;

  p {
    display: inline-block;
    font-weight: 600;
  }

  div:first-child {
    display: inline-block;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex: 1 1 0px;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: end;
  cursor: pointer;

  ${mediaQuery[Screen.desktop]} {
    display: none;
  }
  > div:nth-child(1) {
    padding-right: 16px;
  }
`;

export const MenuContent = styled.div`
  background-color: ${headerFontColor};
  box-shadow: 0px 4px 4px 0px rgba(${(props) => props.theme.RGBA_01}, 0.25);
  display: none;
  position: absolute;
  padding: 24px 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 3;

  &.show {
    display: block;
  }

  ${mediaQuery[Screen.mobile]} {
    top: 70px;
  }

  ${mediaQuery[Screen.md]} {
    top: 70px;
  }
`;

export const MenuButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px;

  button {
    width: 100%;
  }
`;

export const Options = styled.div<{
  $show: boolean;
  ltr?: boolean;
  minWidth?: CSSProperties["minWidth"];
}>`
  background-color: ${headerFontColor};
  display: ${(props) => (props.$show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  color: ${theme("White")};
  width: fit-content;
  min-width: ${prop("minWidth", "150px")};
  position: absolute;
  z-index: 10;
  box-shadow: 4px 4px 15px 0px rgba(${(props) => props.theme.RGB_06});
  top: 65px;
  animation: slide-down ease-in 300ms forwards;
  overflow: hidden;

  ${ifProp(
    "ltr",
    css`
      border-radius: 0px 0px 0px 24px;
      justify-content: end;
      right: 0;
    `,
    css`
      border-radius: 0px 0px 24px 0px;
      justify-content: start;
    `
  )}

  @keyframes slide-down {
    0% {
      top: 40px;
      opacity: 0;
    }
    30% {
      top: 40px;
      opacity: 0.2;
    }
    40% {
      top: 40px;
      opacity: 0.4;
    }
    50% {
      top: 45px;
      opacity: 0.5;
    }
    70% {
      top: 50px;
      opacity: 0.7;
    }
    80% {
      top: 55px;
      opacity: 0.8;
    }
    90% {
      top: 60px;
      opacity: 0.9;
    }
    100% {
      top: 65px;
      opacity: 1;
    }
  }

  hr {
    background-color: ${theme("Dark_Grey")};
    width: calc(100% - 32px);
  }
`;

export const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const Element = styled(FlexContainer)`
  cursor: pointer;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  color: ${theme("White")};
  gap: 10px;
  padding: 16px;

  &:hover {
    background: ${theme("Grey")};
  }
`;

export const DesktopActionButtons = styled.div`
  display: flex;
  gap: 16px;
  flex: 1 1 0px;
  justify-content: end;

  a:last-child {
    margin-right: 16px;
  }
  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;
