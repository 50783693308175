import { AuthorizeCreateOrder } from "../domain/Authorize";
import { PaymentOrderPayload } from "../domain/PaymentOrderCreated";
import {
  PaymentOrderRepository,
  PaymentOrderWithAuthorizeRepository,
  PaymentOrderWithPayPalRepository,
  PaymentOrderWithSquareRepository,
} from "../domain/PaymentOrderRepository";

export const createPaymentOrder = (
  paymentOrderRepository: PaymentOrderRepository
) => {
  return async (payload: PaymentOrderPayload) => {
    const response = await paymentOrderRepository.createPaymentOrder(payload);

    return response;
  };
};

export const createPaymentOrderSquare = (
  paymentOrderRepository: PaymentOrderWithSquareRepository
) => {
  return async (id: string, paymentMethodFee: number) => {
    const response = await paymentOrderRepository.createPaymentLink(
      id,
      paymentMethodFee
    );

    return response;
  };
};

export const createPaymentOrderPayPal = (
  paymentOrderRepository: PaymentOrderWithPayPalRepository
) => {
  return async (amount: number, transactionId: string) => {
    const response = await paymentOrderRepository.createOrder(
      amount,
      transactionId
    );

    return response;
  };
};

export const capturePaymentPayPal = (
  paymentOrderRepository: PaymentOrderWithPayPalRepository
) => {
  return async (paypalOrderId: string) => {
    const response = await paymentOrderRepository.captureOrder(paypalOrderId);

    return response;
  };
};

export const authorizeGetCardsByPersonId = (
  paymentOrderRepository: PaymentOrderWithAuthorizeRepository
) => {
  return async (personId: string) => {
    const response = await paymentOrderRepository.getCardsByPersonId(personId);
    return response;
  };
};

export const authorizeCreateOrder = (
  paymentOrderRepository: PaymentOrderWithAuthorizeRepository
) => {
  return async (order: AuthorizeCreateOrder) => {
    const response = await paymentOrderRepository.createOrder(order);
    return response;
  };
};
