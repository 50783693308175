import { useState } from "react";
import { getSubdivisions } from "../Modules/Common/application/getSubdivisions";
import { Errors } from "../Modules/Common/domain/Errors";
import { createApiSubdivisionRepository } from "../Modules/Common/infraestructure/ApiSubdivisionRepository";
import { QuoteDeliveryMethodCode } from "../Modules/Quote/domain/QuoteDeliveryMethod";
import { sortByDisplayOrder } from "../Utilities/Array";
import { useStatus } from "./useStatus";
import { SelectOption } from "../Models/Select";

export const useSubdivision = () => {
  const [subdivisionList, setSubdivisionList] = useState<SelectOption[]>([]);
  const { status, isLoading, hasError, setStatus, setError } = useStatus();

  const getSubdivisionsByCountry = async (
    country: string,
    language: string
  ) => {
    setStatus("loading");
    try {
      const response = await getSubdivisions(createApiSubdivisionRepository())(
        country,
        language
      );
      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      setError(error as Errors);
    }
  };

  async function getSubdivisionOptions(countryCode: string, language: string) {
    const result = await getSubdivisionsByCountry(countryCode, language);

    if (result) {
      result.sort((a, b) => sortByDisplayOrder(a, b));

      const subdivisions = result.map((i) => ({
        label: i.subdivisionName,
        value: i.subdivisionCode,
      }));

      setSubdivisionList(subdivisions);
      return subdivisions;
    } else {
      setSubdivisionList([]);
    }
  }

  return {
    status,
    isLoading,
    hasError,
    subdivisionList,
    getSubdivisionsByCountry,
    getSubdivisionOptions,
    setSubdivisionList,
  };
};
