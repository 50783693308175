import { StylesConfig } from "react-select";
import { SelectOption } from "../../Models/Select";
import { selectStyles } from "../SearchSelect/SearchSelectTheme";

export const currencySelectStyles: StylesConfig<SelectOption> = {
  container: (base, props) => ({
    ...selectStyles.container?.call(this, base, props),
    display: !props.selectProps.menuIsOpen ? "flex" : "block",
    maxWidth: props.selectProps.menuIsOpen ? "294px" : "unset",
    minWidth: props.selectProps.menuIsOpen ? "250px" : "unset",
    backgroundColor: "#FFFFFF",
    flex: "1 1 auto",
    position: props.selectProps.menuIsOpen ? "absolute" : "relative",
    alignItems: "center",
  }),
  control: (base, props) => ({
    ...selectStyles.control?.call(this, base, props),
    ...(!props.selectProps.menuIsOpen
      ? { border: "none", padding: 0, width: "max-content", margin: 0 }
      : {}),
  }),
  valueContainer: (base, props) => ({
    ...selectStyles.valueContainer?.call(this, base, props),
    width: "max-content",
    fontSize: "18px",
    padding: !props.selectProps.menuIsOpen ? 0 : base.padding,
  }),
  singleValue: (base, props) => ({
    ...base,
    display: props.selectProps.menuIsOpen ? "none" : base.display,
  }),
  input: (base, props) => ({
    ...base,
    margin: 0,
    padding: 0,
    zIndex: !props.selectProps.menuIsOpen ? "-1" : base.zIndex,
    width: !props.selectProps.menuIsOpen ? 0 : base.width,
  }),
  dropdownIndicator: (base, props) => ({
    ...base,
    padding: "0px",
  }),
};

export const currencySelectRTLStyles: StylesConfig<SelectOption> = {
  ...currencySelectStyles,
  container: (base, props) => ({
    ...currencySelectStyles.container?.call(this, base, props),
    right: props.selectProps.menuIsOpen ? 0 : "unset",
  }),
};
