import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";

import PaymentSendolaPayConfirmationSrc from "../../../Assets/Img/sendola-pay-confirmation.svg";

export const HeaderIconSendola = styled.img`
  display: flex;
  height: 90px;
  width: 265px;

  ${mediaQuery[Screen.mobile]} {
    height: 60px;
    width: 215px;
  }
`;

export const ContentColumn = styled.div`
  margin-left: 0;
  margin-right: 0;

  ${mediaQuery[Screen.desktop]} {
    margin: 64px 136px;
  }
`;

export const SpacingColumn = styled.div`
  margin: 16px 0;

  ${mediaQuery[Screen.mobile]} {
    margin: 16px 16px;
  }
`;

export const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 540px;
  width: 100%;

  ${mediaQuery[Screen.mobile]} {
    max-width: 100%;
  }

  ${mediaQuery[Screen.xs]} {
    padding: 0;
    width: 100%;
  }

  div {
    padding: 0;
  }

  .PhoneInput {
    width: 100%;
  }

  .react-tel-input .form-control {
    width: 450px;
    height: 50px;
    font-size: 18px;

    ${mediaQuery[Screen.xs]} {
      width: 100%;
    }
  }
`;

export const PasswordContainer = styled.div`
  box-sizing: border-box;
  max-width: 540px;
  width: 100%;

  ${mediaQuery[Screen.mobile]} {
    max-width: 100%;

    input {
      max-width: 100%;
    }
  }
`;

export const ButtonContainer = styled.div`
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 536px;
  width: 100%;
  text-align: center;
  justify-content: center;

  span {
    padding-right: 0;

    ${mediaQuery[Screen.xs]} {
      font-size: 18px;
    }
  }

  ${mediaQuery[Screen.mobile]} {
    max-width: 100%;
  }
`;

export const CardImageContent = styled.div`
  display: flex;
  justify-content: flex-end;

  p {
    font-weight: 600;
    line-height: 32px;
  }

  ${mediaQuery[Screen.mobile]} {
    display: none;

    & input {
      max-width: 100%;
    }
  }
`;

export const SendolaPayConfimration = styled.div`
  display: flex;
  width: 225px;
  height: 235px;
  background-image: url(${PaymentSendolaPayConfirmationSrc});
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;

  div {
    width: 100px;
    height: 100px;
    margin-top: 50px;
  }
`;

export const SendolaPayChainIcon = styled.img`
  display: flex;
  height: 90px;
  width: 90px;
`;
