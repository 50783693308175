import {
  DestinationBranch,
  DestinationBranchPayload,
} from "../domain/Destination";
import { DestinationRepository } from "../domain/DestinationRepository";

export const getBranches =
  (destinationRepository: DestinationRepository) =>
  async (payload: DestinationBranchPayload): Promise<DestinationBranch[]> =>
    destinationRepository.getBranches(payload);
