import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  show: true,
};

export const ZelleModalSlice = createSlice({
  name: "zelleModal",
  initialState,
  reducers: {
    updateZelleModal: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { updateZelleModal } = ZelleModalSlice.actions;
export const ZelleModalSelector = (state: State) => state.zelleModal;

export default ZelleModalSlice.reducer;
