import styled, { css } from "styled-components";
import { ifProp, theme } from "styled-tools";
import {
  quoterCardFeeLabels,
  switchCaption,
} from "../../Contexts/ThemeProvider/Texts";
import { TransactionDetailCardColor } from "../../Contexts/ThemeProvider/Pages";

export const TabsStyled = styled.div<{ $showPopup: boolean }>`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  padding: 0 2px;
  background: ${ifProp("$showPopup", theme("White"), "none")};
  z-index: ${ifProp("$showPopup", "4", "0")};
  max-width: 100%;
  margin-bottom: 20px;
`;

export const Tab = styled.div<{ active: boolean }>`
  box-sizing: border-box;
  padding: 9px;
  position: relative;
  ${ifProp(
    "active",
    css`
      border-bottom: 2px solid ${quoterCardFeeLabels};
    `
  )};
`;

export const ContainerSwitch = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

export const SwitchBtn = styled.button<{ $active: boolean }>`
  color: ${theme("Content_UX_02")};
  background: none;
  border: none;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  max-width: 118px;
  cursor: pointer;
  span {
    color: ${theme("Primary_11")};
    ${(props) =>
      props.$active
        ? css`
            color: ${switchCaption};
          `
        : css`
            color: ${theme("Primary_11")};
          `};
  }
`;

export const TextNewContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 310px;
  width: 100%;
  align-self: center;
  justify-content: right;
  margin-bottom: -5px;
  z-index: 1;
  height: 25px;
  position: absolute;
  top: -21px;
  right: 0;

  div {
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: ${TransactionDetailCardColor};
  }
`;

export const TextNew = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-family: Poppins;
  color: ${switchCaption};
`;
