import { createSlice } from "@reduxjs/toolkit";
import { GetUserFeatureAction } from "./UserFeature.actions";
import { State } from "../Store";
import { UserFeaturesState } from "../../Models/UserFeature";

const initialState: UserFeaturesState = {
  loading: false,
  error: null,
  data: null,
};

export const UserFeatureSlice = createSlice({
  name: "UserFeature",
  initialState,
  reducers: {
    setFeatures(state, { payload }) {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUserFeatureAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetUserFeatureAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(GetUserFeatureAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default UserFeatureSlice.reducer;

export const userFeatureSelector = (state: State) => state.UserFeature;
