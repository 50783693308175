import styled, { css } from "styled-components";
import { ButtonSizesKey, handleSizeButton } from "../../Utilities/SizesButtons";

import lock from "../../Assets/Icons/lock.svg";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { ifProp, prop, theme } from "styled-tools";
import {
  buttonBackgroundColor,
  buttonBorderColor,
  buttonColor,
} from "../../Contexts/ThemeProvider/Button";
import {
  inputActiveColor,
  inputBackgroundColor,
  inputPlaceholderColor,
} from "../../Contexts/ThemeProvider/Input";
import { SizesKey } from "../../Utilities/Sizes";
import { setTextSize } from "../Text/functions/setTextSize";
import { FontColor } from "../../Utilities/Icons";
import { PropsText } from "../../Models/TextInterface";

const GeneralStyles = `
  font-size: 12px;
  font-weight: 70;
  cursor: pointer;
  border-radius: 12px;
  box-sizing: border-box;
  min-width: 110px;
  outline: none;
`;

export const ContainerInput = styled.div<{
  $size?: ButtonSizesKey;
  mb?: string;
  error?: boolean;
}>`
  position: relative;
  width: ${(props) =>
    props.$size ? `${handleSizeButton(props.$size)}px` : "100%"};
  margin-bottom: ${ifProp("mb", prop("mb"), "0")};
  text-align: left;
  flex: 1 1 auto;

  ${mediaQuery[Screen.mobile]} {
    width: 100%;
  }

  &:hover {
    div {
      color: ${ifProp("error", theme("Error"), inputActiveColor)};
    }
  }
`;

export const Placeholder = styled.div<
  {
    size?: SizesKey;
    active?: boolean;
    error?: boolean;
  } & PropsText
>`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);
  ${setTextSize()}
  font-weight: 400;
  color: ${ifProp("error", theme("Error"), inputPlaceholderColor)};
  ${ifProp(
    "active",
    css`
      color: ${ifProp("error", theme("Error"), inputActiveColor)};
      top: 0;
      left: 8px;
      font-size: 12px;
      line-height: 18px;
      background-color: ${inputBackgroundColor};
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 12px;
      z-index: 1;
    `
  )}
  transition: top ease-in-out 300ms, font-size ease-in-out 300ms, background-color ease-in-out 400ms;

  &:hover:not(&:focus, &:focus-visible, &:active) {
    color: ${ifProp("error", theme("Error"), inputActiveColor)};
  }
`;

export const InputStyled = styled.input<{
  $size?: ButtonSizesKey;
  $validate?: boolean;
  $error?: boolean;
  $full?: boolean;
  opacity?: number;
}>`
  ${GeneralStyles}
  width: ${(props) =>
    props.$full
      ? "100%"
      : props.type === "password"
      ? props.$size
        ? `calc(${handleSizeButton(props.$size)} - 28px)`
        : `180px`
      : props.$size
      ? `calc(${handleSizeButton(props.$size)} - 20px)`
      : `180px`};
  font-size: 18px;
  padding: 13px 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.$validate
      ? props.$error
        ? props.theme.Error
        : props.theme.Background_03
      : props.theme.Background_03};
  background: ${(props) =>
    props.$error ? props.theme.Content_UX_03 : props.theme.Background_01};
  background: ${(props) =>
    props.type === "password" ? `url(${lock}) no-repeat ;` : ""};
  background-size: ${(props) => (props.type === "password" ? "15px;" : "")};
  background-position: 3% 50%;
  opacity: ${prop("opacity", 1)};

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &:focus,
  &:focus-visible,
  &:active {
    border: 2px solid ${ifProp("$error", theme("Error"), inputActiveColor)};
  }

  &:hover:not(&:focus, &:focus-visible, &:active) {
    border: 1px solid ${ifProp("$error", theme("Error"), inputActiveColor)};
    color: ${ifProp("$error", theme("Error"), inputActiveColor)};
  }
`;

export const ActionButtonIcon = styled.button`
  background: ${buttonBackgroundColor};
  color: ${buttonColor};
  border: 1px solid ${buttonBorderColor};
  border-radius: 10px;
  padding: 0.3rem;
  position: absolute;
  right: 0.5rem;
  top: 1.2rem;
  display: flex;
  gap: 0.2rem;
`;

export const Text = styled.span`
  font-size: 18px;
  font-weight: 600;
  text-align: left;

  ${mediaQuery[Screen.sm]} {
    font-size: 18px;
    font-weight: 600;
    margin-left: 0px;
  }

  ${mediaQuery[Screen.xs]} {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const ContainerText = styled.div`
  font-family: Poppins;
  padding: 5px 3px;
  width: 100%;
  color: ${(props) => props.theme.Content_UX_02};
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  &.hidden {
    display: none;
  }
  * {
    color: ${(props) => props.theme.Content_UX_02};
  }
`;

export const ContainerLeft = styled.div<{ $typeInput?: string }>`
  width: ${(props) => (props.$typeInput === "password" ? "70%" : "100%")};
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-left: 2px;
`;

export const ContainerRight = styled.div<{ margin?: string }>`
  box-sizing: border-box;
  width: fit-content;
  padding: 11px 3px 0 11px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: start;
  margin: ${ifProp("margin", prop("margin"), "0px")};
  div:first-of-type {
    text-align: end;
  }
`;

export const HintTooltip = styled.div`
  position: absolute;
  top: -40px;
  right: 15px;
  width: 90%;
  font-family: Poppins;
  font-size: 14px;
  background: ${theme("Background_01")};
  box-shadow: 0px 0px 24px 0px #00000026;
  border-radius: 10px;
  padding: 10px;
  z-index: 10;
`;

export const Span = styled.span<{ $error?: boolean }>`
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: ${(props) =>
    props.$error ? props.theme.Error : props.theme.Green_05};
  width: 70%;
  flex-grow: 1;
  text-align: left;
`;
Span.displayName = "InputSpan";
