import styled from "styled-components";
import { ButtonSizesKey, handleSizeButton } from "../../Utilities/SizesButtons";
import { theme } from "styled-tools";
import { handleSizeSelect } from "../../Utilities/SizeSelect";

export const SelectStyled = styled("div")<{ $size?: ButtonSizesKey }>`
  width: ${(props) =>
    props.$size ? `${handleSizeButton(props.$size)}px` : "100%"};
  margin: 0;
  box-sizing: border-box;
`;

export const SelectHeader = styled("div")<{ $error: boolean }>`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  padding: 15px;
  font-weight: 500;
  border-radius: 12px;
  border: 1px solid
    ${(props) =>
      props.$error ? props.theme.Red_08 : props.theme.Background_03};
  box-sizing: border-box;
  background: ${theme("Background_01")};
  font-size: 11px;
  color: ${theme("Content_UX_02")};
  &:focus {
    border: 2px solid ${theme("Content_State_UX_Active")};
  }

  &:focus-visible {
    outline: 2px solid
      ${(props) => (props.$error ? props.theme.Red_08 : props.theme.Primary_01)};
  }

  & .containerSelected {
    & :focus {
      border: 2px solid ${(props) => props.theme.Background_02};
    }
  }

  & .searchInput {
    padding: 0;
  }

  input {
    padding: 0;
    border: none;
    font-size: 18px;
    outline: none;

    :focus-visible {
      border: 0;
    }
  }
`;

export const SectionText = styled.div`
  display: flex;
  width: 80%;
  justify-content: flex-start;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 700;
`;

export const SectionIcon = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;
  padding: 3px 0;
`;

export const SectionSelectList = styled("div")`
  position: relative;
`;

export const SelectList = styled("ul")<{ $size?: string }>`
  background: ${(props) => props.theme.Background_01};
  border: 2px ${(props) => props.theme.Background_01};
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(${(props) => props.theme.RGBA_01}, 0.25);
  color: ${(props) => props.theme.Content_UX_02};
  box-sizing: border-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  padding: 8px 16px;
  margin: 0;
  text-align: left;
  width: ${(props) =>
    props.$size ? `${handleSizeSelect(props.$size)}px` : "100%"};
  z-index: 2;
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
`;

export const ListItem = styled("li")`
  list-style: none;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;

  span {
    width: 100%;
    font-size: 18px;
  }

  &:hover {
    background: ${(props) => props.theme.Primary_04_highlight};
    color: ${(props) => props.theme.Background_02};
  }

  &.selected {
    background: ${(props) => props.theme.Primary_04_highlight};
    color: ${(props) => props.theme.Background_02};
  }
`;

export const Label = styled.label`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.Content_UX_02};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  font-family: Poppins;
  line-height: normal;
  padding: 5px;
  text-align: left;
`;

export const ErrorContainer = styled.div`
  color: ${(props) => props.theme.Red_08};
  display: flex;
  align-items: end;
  padding: 0 8px;
`;

export const ErrorMessage = styled.div`
  font-size: 16px;
  margin-left: 8px;
`;

export const Search = styled.input``;
