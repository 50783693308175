import { useTranslation } from "react-i18next";
import { InfoPopUp } from "../../../Models/ModalInterface";
import Icon from "../../Icon";
import Text from "../../Text";
import {
  HeadModal,
  IconContainer,
  ModalContainer,
  ModalWrap,
  TextNew,
  TextNewContainer,
  TextSection,
} from "./WalkthroughModal.styled";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../Redux/Translate";

interface AbsoluteProps {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}
interface WalkthroughPopupProps {
  popUp: InfoPopUp;
  onClose?: (popUpId: string) => void;
}

export const WalkthroughPopup = ({ popUp, onClose }: WalkthroughPopupProps) => {
  const { t } = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const [position, setPosition] = useState<AbsoluteProps>({ top: 0, left: 0 });
  const [arrowPosition, setArrowPosition] = useState<AbsoluteProps>({
    bottom: 27,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const arrowIconContainerRef = useRef<HTMLDivElement>(null);
  const mobileViewport = 462;

  const updatePosition = useCallback(() => {
    if (popUp.element) {
      const rect = popUp.element.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const scrollTop = window.scrollY;
      const arrowIcon = arrowIconContainerRef?.current;

      const popUpTopPosition =
        rect.top -
        (Number(containerRef.current?.offsetHeight || "0") + 31) -
        (scrollTop || 0);
      const popUpLeftPosition =
        windowWidth > mobileViewport
          ? (rect.left + rect.right) / 2 -
            Number(containerRef.current?.offsetWidth || "0") / 2
          : Math.abs(
              Number(containerRef.current?.parentElement?.offsetWidth || "0") -
                Number(containerRef.current?.offsetWidth || "0")
            ) / 2;

      setPosition({
        top: popUpTopPosition,
        left: popUpLeftPosition,
      });

      if (windowWidth <= mobileViewport) {
        setArrowPosition((current) => ({
          ...current,
          left:
            rect.right -
              Number(popUpLeftPosition || "0") -
              Number(popUp.element?.offsetWidth || "0") / 2 -
              Number(arrowIcon?.offsetWidth || "0") +
              window.scrollX || 0,
        }));
      } else {
        setArrowPosition((current) => ({
          ...current,
          left: undefined,
        }));
      }
    }
  }, [popUp.element, containerRef, window.scrollY, window.scrollX]);

  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  return (
    <ModalContainer
      ref={containerRef}
      id={`walkthrough-${popUp.id}`}
      top={`${position.top}px`}
      left={`${position.left}px`}
    >
      <ModalWrap>
        <TextNewContainer>
          <TextSection>
            <TextNew>{t("Dashboard.TabNew")}</TextNew>
          </TextSection>
          <HeadModal
            onClick={() => {
              popUp.onClose && popUp.onClose(popUp.id);
              onClose && onClose(popUp.id);
            }}
          >
            <Icon icon="iconX" color="solid_light" />
          </HeadModal>
        </TextNewContainer>
        <Text color="black" size={"default"} weight={500} align="left">
          {language === "en" ? popUp.messageEn : popUp.messageEs}
        </Text>
        <IconContainer
          ref={arrowIconContainerRef}
          left={arrowPosition.left ? arrowPosition.left + "px" : undefined}
        >
          <Icon icon="downArrow" size="large" color="white" />
        </IconContainer>
      </ModalWrap>
    </ModalContainer>
  );
};
