import styled, { css, keyframes } from "styled-components";
import { theme } from "styled-tools";

const CentredContent = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TransferLoaderTitle = styled.h1`
  max-width: 671px;
  color: ${theme("Context_UX_01")};
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  overflow-wrap: break-word;
  margin-top: 0;
`;

export const TransferLoader = styled.div`
  ${CentredContent}
  padding-bottom: 40px;
`;

export const TransferLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 210px;
  margin: 30px 20px 0;
`;

export const TransferLoaderCardImage = styled.div`
  ${CentredContent}
  justify-content: end;
  background-position: center top;
  width: 332px;
  height: 367px;
  mask-size: 325px 360px;
`;

export const TransferLoaderText = styled.div`
  color: ${theme("Background_01")};
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 40px;
`;

export const TransferLoaderDescription = styled.p`
  color: ${theme("Content_UX_02")};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 30px 20px 0;
`;

const SpinnerKeyframes = keyframes`
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
`;

export const TransferLoaderSpinnerContent = styled.div`
  width: 85px;
  height: 85px;
  position: relative;
  margin-bottom: 100px;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 1));
    z-index: 2;
  }
`;

export const TransferLoaderSpinner = styled.div`
  width: 75px;
  height: 75px;
  border: 5px solid rgba(${theme("RGB_Background_01")}, 0.3);
  border-left: 5px solid ${theme("Content_UX_03")};
  border-radius: 50%;
  animation: ${SpinnerKeyframes} 0.5s linear 0s infinite;
`;
