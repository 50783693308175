import styled from "styled-components";
import { theme } from "styled-tools";

export const CardDetails = styled.div`
  background-color: ${theme("Background_01")};
  border-bottom: 1px solid ${theme("Background_09")};
  width: 100%;
  max-width: 395px;
  margin: 12px 0px;
`;

export const ContainerCard = styled.div`
  display: flex;
  justify-content: center;
  gap: auto;
  width: 100%;
  padding: 12px 0;
  margin: 0;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: left;
  gap: 8px;
  width: auto;
  align-items: center;
`;

export const IconCountry = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactDetails = styled.div`
  font-family: Poppins;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`;
export const StatusText = styled.p<{ status: string }>`
  font-size: 12px;
  font-weight: 400;
  margin: "0 0 4px 0";
  text-align: left;
  color: ${theme("Content_UX_04")};
  span {
    font-weight: 600;
    color: ${(props) =>
      props.status.toLowerCase() === "paid" ||
      props.status.toLowerCase() === "declined"
        ? theme("Primary_12")
        : props.status.toLowerCase() === "rejected" ||
          props.status.toLowerCase() === "canceled"
        ? theme("Primary_20")
        : props.status.toLowerCase() === "pending" ||
          props.status.toLowerCase() === "verified" ||
          props.status.toLowerCase() === "accepted" ||
          props.status.toLowerCase() === "created" ||
          props.status.toLowerCase() === "funds" ||
          props.status.toLowerCase() === "reviewing" ||
          props.status.toLowerCase() === "available for payment"
        ? theme("Primary_21")
        : theme("Content_UX_04")};
  }
`;
