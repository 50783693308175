import { useTranslation } from "react-i18next";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { Slider } from "../../Slider/Slider.component";
import Button from "../../Button";
import { useEffect, useRef, useState } from "react";
import { useBeneficiary } from "../../../Hooks/useBeneficiary";
import { Account, BeneficiaryRecipient } from "../../../Modules/Beneficiary/domain/Beneficiary";
import { DashboardSlice } from "../../../Redux/Dashboard/Dashboard.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Redux/Store";
import { RecipientCard } from "./RecipientCard.component";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { BeneficiarySlice } from "../../../Redux/Beneficiary";
import { BeneficiarySelector } from "../../../Redux/Beneficiary/Beneficiary.slice";
import { useModal } from "../../../Hooks/useModal";
import isEmpty from "lodash.isempty";
import { useNavigate } from "react-router-dom";
import Skeleton from "../../Skeleton";
import { AddRecipientCardButton } from "./AddRecipientCardButton.component";
import { QuoterProps } from "../../../Modules/Quote/domain/QuoteSelected";

interface RecipientSelectProps {
  country?: string;
  deliveryMethod?: "D2B" | "CPU";
  sendTo: string | null;
  sendToOptions?: QuoterProps["sendToOptionsSelect"];
  onChange?: (recipient?: BeneficiaryRecipient | null) => void;
}

export const RecipientsSelect = ({
  country,
  deliveryMethod,
  sendTo,
  sendToOptions,
  onChange,
}: RecipientSelectProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [recipients, setRecipients] = useState<BeneficiaryRecipient[]>([]);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const {
    data: { beneficiarySelected },
  } = useSelector(BeneficiarySelector);
  const { currentDeliveryMethodQuote } = useSelector(QuotationSelector).data;
  const currentQuoteDestinations = currentDeliveryMethodQuote?.paymentDestinations;
  const isCashPickup = deliveryMethod === "CPU";

  const { isLoading: beneficiariesIsLoading, getBeneficiariesPaginated } = useBeneficiary();
  const { modal, showModal } = useModal();

  const fetchBeneficiaries = async () => {
    const paginationResponse = await getBeneficiariesPaginated({
      country: country,
      page: currentPage,
      recent: true,
      perPage: 1000,
    });
    if (paginationResponse) {
      const recipients = paginationResponse.recipients || [];

      setPageCount(Math.ceil(paginationResponse.pagination.total / 20));
      setRecipients(recipients);
      if (!beneficiarySelected && isCashPickup) {
        selectBeneficiary(recipients[0]);
      } else if (!beneficiarySelected) {
        const recipientOfDestinationQuoted = recipients.find((recipient) =>
          recipient.account.some((acc) => acc.destinationId === sendTo)
        );

        recipientOfDestinationQuoted
          ? selectBeneficiary(
              {
                ...recipientOfDestinationQuoted,
              },
              recipientOfDestinationQuoted.account.find((acc) => acc.destinationId === sendTo)
            )
          : !isEmpty(recipients) && selectBeneficiary({ ...recipients[0] }, recipients[0].account[0]);
      }

      return recipients;
    }
    dispatch(DashboardSlice.actions.setLoading(false));
  };

  const selectBeneficiary = async (beneficiary?: BeneficiaryRecipient, account?: Account, triggerOnChange = true) => {
    const selected = beneficiary
      ? {
          ...beneficiary,
          accountSelected: account && {
            ...account,
            destinationId:
              sendToOptions?.find(
                (destination) => destination.value === account?.destinationId || destination.label === account.bankName
              )?.value || account?.destinationId,
            rail: sendToOptions?.find(
              (destination) => destination.value === account?.destinationId || destination.label === account.bankName
            )?.rail,
          },
        }
      : undefined;
    if (selected && triggerOnChange) {
      onChange && onChange(selected as BeneficiaryRecipient);
    } else if (selected) {
      dispatch(BeneficiarySlice.actions.setBeneficiarySelected(selected));
    }
  };

  const onClickBeneficiary = (recipient?: BeneficiaryRecipient) => {
    if (recipient && isEmpty(recipient.account) && deliveryMethod === "D2B") {
      showModal({
        modalType: "addBeneficiaryAccountModal",
        recipient,
        country: country,
        handleConfirm: async (account) => {
          await fetchBeneficiaries();
          const recipientSelected = recipients.find((r) => r.beneficiaryId === recipient.beneficiaryId);
          const accountSelected = recipientSelected?.account?.find((acc) => acc.id === (account as Account).id);
          selectBeneficiary(recipient, accountSelected as Account);
        },
      });
    } else if (recipient && recipient.account.length > 1 && deliveryMethod === "D2B") {
      showModal({
        modalType: "selectBeneficiaryAccountModal",
        recipient,
        handleConfirm: async (account) => {
          const recipients = await fetchBeneficiaries();
          if (recipients) {
            const recipientSelected = recipients.find((r) => r.beneficiaryId === recipient.beneficiaryId);
            const accountSelected = recipientSelected?.account?.find((acc) => acc.id === (account as Account).id);
            selectBeneficiary(recipient, accountSelected as Account);
          }
        },
      });
    } else {
      selectBeneficiary(recipient, deliveryMethod !== "CPU" ? recipient?.account[0] : undefined);
    }
  };

  useEffect(() => {
    fetchBeneficiaries();
  }, [country, isCashPickup]);

  useEffect(() => {
    if (beneficiarySelected && beneficiarySelected.accountSelected && !beneficiarySelected.accountSelected.rail) {
      selectBeneficiary(beneficiarySelected as BeneficiaryRecipient, beneficiarySelected.accountSelected);
    }
  }, [currentQuoteDestinations]);

  useEffect(() => {
    if (!isEmpty(recipients) && beneficiarySelected?.beneficiaryId && !beneficiariesIsLoading) {
      const target = document.getElementById(`recipient-${beneficiarySelected.beneficiaryId}`);
      if (target) {
        target.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [recipients, beneficiarySelected, beneficiariesIsLoading, sliderRef]);

  useEffect(() => {
    if (beneficiarySelected && !beneficiarySelected.accountSelected?.id && deliveryMethod === "D2B") {
      showModal({
        modalType: "selectBeneficiaryAccountModal",
        recipient: beneficiarySelected as BeneficiaryRecipient,
        handleConfirm: async (account) => {
          const recipients = await fetchBeneficiaries();
          if (recipients) {
            const recipientSelected = recipients.find((r) => r.beneficiaryId === beneficiarySelected.beneficiaryId);
            const accountSelected = recipientSelected?.account?.find((acc) => acc.id === (account as Account).id);
            selectBeneficiary(beneficiarySelected as BeneficiaryRecipient, accountSelected as Account);
          }
        },
        handleClose: (confirm?: boolean) => {
          if (!confirm && onChange) {
            onChange(null);
          }
        },
      });
    } else if (deliveryMethod === "CPU" && beneficiarySelected) {
      onChange &&
        onChange({
          ...(beneficiarySelected as BeneficiaryRecipient),
          accountSelected: undefined,
        });
    }
  }, [deliveryMethod, country]);

  return (
    <FlexContainer direction="column" h="5.375rem" style={{ position: "relative" }}>
      <FlexContainer justify="space-between" alignItems="center" m="0 0 8px 0">
        <Text size={1} weight={500} align="left" margin={0}>
          {tSendMoney("RecipientTitle")}
        </Text>
        <Button
          variant="transparent"
          padding="0px"
          onClick={() => {
            dispatch(BeneficiarySlice.actions.setNewBeneficiary(""));
            dispatch(DashboardSlice.actions.setPreviousView("dashboard"));
            dispatch(BeneficiarySlice.actions.setCountrySelected(country));
            navigate("/beneficiaries/create");
          }}
        >
          <Text color="solid_light" size={0.5} lineHeight="1.3125rem" weight={500} margin={0}>
            {`+ ${t("Beneficiaries.AddNewRecipient")}`}
          </Text>
        </Button>
      </FlexContainer>
      <Slider containerRef={sliderRef} gap="14px" overflowSize="16px">
        {beneficiariesIsLoading &&
          Array.from({ length: 2 }, (_, index) => <Skeleton key={index} width={254} height={53} />)}
        {!beneficiariesIsLoading && isEmpty(recipients) && (
          <AddRecipientCardButton
            onClick={() => {
              dispatch(DashboardSlice.actions.setPreviousView("dashboard"));
              dispatch(BeneficiarySlice.actions.setCountrySelected(country));
              navigate("/beneficiaries/create");
            }}
          />
        )}
        {!beneficiariesIsLoading &&
          recipients.map((recipient) => (
            <RecipientCard
              id={`recipient-${recipient.beneficiaryId}`}
              key={recipient.beneficiaryId}
              {...recipient}
              active={beneficiarySelected?.beneficiaryId === recipient.beneficiaryId}
              onClick={(recipient) => onClickBeneficiary(recipient)}
              onEditClick={() => {
                dispatch(DashboardSlice.actions.setPreviousView("dashboard"));
                dispatch(BeneficiarySlice.actions.setBeneficiaryEdit(recipient));
                navigate("/beneficiaries/edit");
              }}
            />
          ))}
      </Slider>
      {modal}
    </FlexContainer>
  );
};
