import styled from "styled-components";
import { theme } from "styled-tools";

export const List = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;

  a {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export const Item = styled.li`
  border-bottom: 1px solid ${theme("Background_02")};
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  margin: 20px 0;
  width: 100%;
`;
