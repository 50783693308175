import { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash.isempty";

// Own states
import { AppDispatch, State } from "../../Redux/Store";

// Own Components
import { Column, Container, Row } from "../../Components/Grid";
import Button from "../../Components/Button";
import Icon from "../../Components/Icon";
import Layout from "../../Components/Layout";
import Loader from "../../Components/Loader";
import Modal from "../../Components/Modal";
import Text from "../../Components/Text";
import Title from "../../Components/Title";
import TransactionLoader from "../../Components/TransactionLoader";

import CheckoutForm from "./Components/CheckoutForm";
import CheckoutSelectCard from "./Components/CheckoutSelectCard";

// Own States
import { getCustomerCard } from "../../Redux/PaymentCheckout";
import {
  transferOrder,
  verifyTransferOrder,
  addTransferOrderStepsAction,
} from "../../Redux/TransferOrders";

// Own Styles
import { TransactionCardsList, TransactionCardImg } from "./Payments.styled";

import { AlignItems, JustifyContent } from "../../Types/Column";
import type {
  MakePayment,
  PaymentCheckoutCard,
  PaymentCheckoutCustomerCard,
} from "../../Types/Payments";
import type { ApiRequestError } from "../../Types/ApiRequestError";
import {
  TransferOrders,
  VerifyTransferOrderSuccess,
  VerifyTransferOrders,
} from "../../Types/TransferOrders";

import SendolaCard from "../../Assets/Img/Sendola-card.png";

const requestPayment: VerifyTransferOrders = {
  rail: "string",
  deliveryMethod: "string",
  paymentNetwork: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  transferReason: "string",
  orderedAt: "2023-11-21T19:17:17.218Z",
  instruction: {
    origination: {
      currency: "string",
      amount: 0,
      country: "string",
      countrySubdivision: "string",
      latitude: "string",
      longitude: "string",
    },
    destination: {
      currency: "string",
      amount: 0,
      country: "string",
      countrySubdivision: "string",
      latitude: "string",
      longitude: "string",
      bankInstitution: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      accountNumber: "string",
      paymentNetworkId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      pickupPointId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    },
    quote: {
      exchangeRate: 0,
      marketExchangeRate: 0,
      fee: 0,
      currencyFee: "string",
      fxSpread: 0,
      quoteUpdatedAt: "2023-11-21T19:17:17.218Z",
    },
  },
  sender: {
    userId: "string",
    firstName: "string",
    middleName: "string",
    lastName: "string",
    dateOfBirth: "2023-11-21T19:17:17.218Z",
    ssn: "string",
    homeAddress: {
      addressLine1: "string",
      addressLine2: "string",
      locality: "string",
      countrySubdivision: "string",
      country: "string",
      zipCode: "string",
      latitude: "string",
      longitude: "string",
    },
    contact: {
      phoneNumber: "string",
      emailAddress: "string",
    },
    identifications: [
      {
        issuerCountry: "string",
        typeOfId: "string",
        idNumber: "string",
        expirationDate: "2023-11-21T19:17:17.218Z",
      },
    ],
  },
  receiver: {
    contactId: "string",
    firstName: "string",
    middleName: "string",
    lastName: "string",
    dateOfBirth: "2023-11-21T19:17:17.218Z",
    homeAddress: {
      addressLine1: "string",
      addressLine2: "string",
      locality: "string",
      countrySubdivision: "string",
      country: "string",
      zipCode: "string",
      latitude: "string",
      longitude: "string",
    },
    contact: {
      phoneNumber: "string",
      emailAddress: "string",
    },
    identifications: [
      {
        issuerCountry: "string",
        typeOfId: "string",
        idNumber: "string",
        expirationDate: "2023-11-21T19:17:17.218Z",
      },
    ],
    additionalInformation: {
      rfc: "string",
      curp: "string",
    },
  },
  payment: {
    amount: 5,
    paymentSource: "string",
  },
};

const PaymentCard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [initializedCards, setInitializedCards] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCardsLoading, setCardsIsLoading] = useState(false);
  const [cards, setCards] = useState([] as PaymentCheckoutCard[]);
  const [isAddNewCard, setIsAddNewCard] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch<AppDispatch>();

  const cardType = searchParams.get("type");
  const cardTypeTitle =
    cardType === "debit"
      ? t("CheckoutPayment.debitCardtitlePage")
      : t("CheckoutPayment.creditCardtitlePage");

  const getCards = async () => {
    setCardsIsLoading(true);
    const customerEncoded = encodeURIComponent("ramiro.pacheco@sendola.io");
    const response = await dispatch(getCustomerCard(customerEncoded));
    const { cardResponseSources } =
      response?.payload as PaymentCheckoutCustomerCard;

    setCards(cardResponseSources);
    setInitializedCards(true);
    setCardsIsLoading(false);
  };

  const makePayment = async ({ token, id }: MakePayment) => {
    let checkoutRequest = {} as VerifyTransferOrders;

    if (isEmpty(token) && isEmpty(id)) return;

    setIsLoading(true);

    checkoutRequest = {
      ...requestPayment,
      payment: {
        ...requestPayment.payment,
        paymentSource: token || id || "",
      },
    };

    const verifyResponse = await dispatch(verifyTransferOrder(checkoutRequest));

    const { isSuccess } = verifyResponse.payload as VerifyTransferOrderSuccess;

    if (isSuccess) {
      const transferResponse = await dispatch(transferOrder(checkoutRequest));
      const { status: error, message } =
        transferResponse.payload as TransferOrders & ApiRequestError;

      if (error) {
        setShowModalError(true);
        setMessageError(message);
      } else {
        dispatch(
          addTransferOrderStepsAction(transferResponse.payload as object)
        );
        navigate("/transaction-completed");
      }
    }

    setIsLoading(false);
  };

  const hideModalError = () => setShowModalError(false);

  useEffect(() => {
    getCards();
  }, []);

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <Layout>
          <TransactionLoader
            title={t("ReviewTransaction.LoaderTitle")}
            text=""
            description={t("ReviewTransaction.LoaderDescription")}
            image="transferLoader"
          />
        </Layout>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/payments" textLink={t("buttons.back")}>
        <Container>
          <Row>
            <Column span={1} xs={12} />
            <Column span={6} xs={12} sm={12} md={12} p={2}>
              <Title
                size={5}
                tagName="h2"
                color="black"
                text={cardTypeTitle}
                align="left"
              />
              <Text color="grey" size={2} align="left" weight={400}>
                {t("CheckoutPayment.description")}
              </Text>
              {!initializedCards || isCardsLoading ? (
                <Column span={12} justifyContent={JustifyContent.center}>
                  <Loader />
                </Column>
              ) : (
                <TransactionCardsList>
                  {!cards?.length || cards?.length === 0 || isAddNewCard ? (
                    <>
                      <CheckoutForm makePayment={makePayment} />
                      {cards?.length > 0 && (
                        <Button
                          text={t("CheckoutPayment.buttonCancelText")}
                          onClick={() => setIsAddNewCard(false)}
                          sizeText="medium"
                          colorIcon="white"
                          variant="default"
                          sizeIcon="medium"
                          sizeButton="xxlarge"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <CheckoutSelectCard
                        data={cards || []}
                        makePayment={makePayment}
                      />
                      <Button
                        text={t("CheckoutPayment.buttonText")}
                        onClick={() => setIsAddNewCard(true)}
                        sizeText="medium"
                        colorIcon="white"
                        variant="primary"
                        sizeIcon="medium"
                        sizeButton="xxlarge"
                      />
                    </>
                  )}
                </TransactionCardsList>
              )}
            </Column>
            <Column
              span={5}
              sm={12}
              md={12}
              alignItems={AlignItems.center}
              justifyContent={JustifyContent.center}
            >
              <TransactionCardImg src={SendolaCard} />
            </Column>
            <Column span={1} xs={12} />
          </Row>
        </Container>
        <Modal show={showModalError} handleClose={hideModalError}>
          <Icon icon="alertTriangle" color="error" size="large" />
          <Title
            tagName="h1"
            size={5}
            text={t("CheckoutPayment.modalErrorTitle")}
            color="black"
          />
          <Text size={3}>{messageError}</Text>
          <Button
            text={t("buttons.close")}
            onClick={hideModalError}
            sizeText="medium"
            iconButtonLeft="circleX"
            colorIcon="white"
            variant="primary"
            sizeIcon="medium"
          />
        </Modal>
      </Layout>
    </ThemeProvider>
  );
};

export default PaymentCard;
