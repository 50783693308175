import { createAsyncThunk } from "@reduxjs/toolkit";
import { createClient } from "../../Services/ApiClient";

import { transferOrdersAPIUrl } from "../../Constants/ConfigureApi";

import type { ApiRequestError } from "../../Types/ApiRequestError";
import type {
  TransferOrders,
  VerifyTransferOrderSuccess,
  VerifyTransferOrders,
} from "../../Types/TransferOrders";

import { AppDispatch } from "../../Redux/Store";

import { addDataToStore } from "./TransferOrders.slice";

const client = createClient();

export const addTransferOrderStepsAction =
  (data: object) => async (dispatch: AppDispatch) => {
    dispatch(addDataToStore(data));
  };

export const verifyTransferOrder = createAsyncThunk<
  VerifyTransferOrderSuccess,
  VerifyTransferOrders,
  { rejectValue: ApiRequestError }
>(
  "TransferOrders/verifyTransferOrder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await client.post<VerifyTransferOrderSuccess>(
        `${transferOrdersAPIUrl}/verify-transfer-orders`,
        payload
      );
      return response.data;
      // eslint-disable-next-line
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const transferOrder = createAsyncThunk<
  TransferOrders,
  VerifyTransferOrders,
  { rejectValue: ApiRequestError }
>("TransferOrders/TransferOrder", async (payload, { rejectWithValue }) => {
  try {
    const response = await client.post<TransferOrders>(
      "transfer-orders",
      payload
    );
    return response.data;
    // eslint-disable-next-line
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
