import { useTranslation } from "react-i18next";
import { SelectOption } from "../Models/Select";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useEffect, useState } from "react";
import { AdditionalField } from "../Modules/Common/domain/AdditionalFields";
import { useSelector } from "react-redux";
import { QuotationSelector } from "../Redux/Quotation/Quotation.slice";
import { Destination } from "../Modules/Common/domain/Destination";
import { useDestination } from "./useDestination";
import { useDebounce } from "./useDebounce";
import isEmpty from "lodash.isempty";
import { QuoteDeliveryMethodCode } from "../Modules/Quote/domain/QuoteDeliveryMethod";
import { selectorLanguage } from "../Redux/Translate";
import { useCity } from "./useCity";
import { countrySelector } from "../Redux/Country/Country.slice";
import { ExchangeRate } from "../Modules/Common/domain/ExchangeRates";

interface HookOptions {
  country: string;
  destinationList?: (SelectOption & Destination)[];
  form?: UseFormReturn<FieldValues>;
  fieldsPrefix?: string;
  countrySubdivisions?: SelectOption[];
  transferType?: QuoteDeliveryMethodCode;
  destinationSelected?: Destination;
  includeBranchFields?: boolean;
  includeBeneficiaryFields?: boolean;
  onAdditionalFields?: (additionalFields: AdditionalField[]) => void;
}

export const useBeneficiaryAdditionalFields = ({
  country,
  destinationList,
  form,
  fieldsPrefix,
  countrySubdivisions,
  transferType = "D2B",
  destinationSelected,
  includeBranchFields = true,
  includeBeneficiaryFields = true,
  onAdditionalFields,
}: HookOptions) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const countries = useSelector(countrySelector).countries as ExchangeRate[];

  const [additionalFields, setAdditionalFields] = useState<AdditionalField[]>(
    []
  );
  const [additionalFieldsAppriza, setAdditionalFieldsAppriza] = useState<
    AdditionalField[]
  >([]);
  const { isLoading: cityIsLoading, getSubdivisionCitiesOptions } = useCity();
  const {
    isLoading: destinationIsLoading,
    getDestinationBranches,
    getDestinationAdditionalFields,
    getAdditionalFieldsAppriza,
  } = useDestination();
  const isLoading = cityIsLoading || destinationIsLoading;

  const lang = useSelector(selectorLanguage);

  const { currentQuotation } = useSelector(QuotationSelector).data;
  const incomingTransferType = transferType || "D2B";

  const fieldNamePrefix = fieldsPrefix ? `${fieldsPrefix}.` : "";

  const fetchBankBranchesOptions = async ({
    destinationRailCode,
    state,
    city,
    currencyCode,
    country,
  }: {
    destinationRailCode?: string;
    state?: string;
    city?: string;
    country?: string;
    currencyCode?: string;
  }) => {
    const currency =
      currencyCode ||
      countries.find((ex) => ex.countryCode === country)?.currency ||
      currentQuotation?.destinationCurrency;

    if (currency && destinationRailCode && state && country && city) {
      const bankBranches = await getDestinationBranches({
        countryISOCode: country,
        currencyISOCode: currency,
        payerSpecificCode: destinationRailCode,
        stateISOCode: state,
        city: city,
        receptionMethodName: incomingTransferType || "D2B",
      });

      if (bankBranches) {
        return bankBranches.map((branch) => ({
          key: branch.branchName,
          label: branch.branchName,
          value: branch.branchCode,
        }));
      } else {
        return [];
      }
    }
  };

  const fetchAdditionalFields = async ({
    destinationId,
    destinationSelected,
    country,
    transferType,
  }: {
    destinationId?: string;
    destinationSelected?: Destination;
    country?: string;
    transferType?: QuoteDeliveryMethodCode;
  }) => {
    const destination =
      destinationSelected ||
      destinationList?.find((destination) => destination.id === destinationId);

    const currencyISOCode =
      countries.find((ex) => ex.countryCode === country)?.currency ||
      currentQuotation?.destinationCurrency;
    const payerSpecificCode = destination?.payerSpecificCode;
    const receptionMethodName = transferType;
    let destinationAdditionalFields =
      currencyISOCode &&
      payerSpecificCode &&
      receptionMethodName &&
      includeBeneficiaryFields &&
      destination.rail === "UNT"
        ? (await getDestinationAdditionalFields({
            currencyISOCode,
            payerSpecificCode,
            destinationId: destination.id,
            receptionMethodName,
            language: lang.language,
          })) || []
        : [];

    onAdditionalFields && onAdditionalFields(destinationAdditionalFields);

    if (includeBeneficiaryFields) {
      destinationAdditionalFields = [
        ...destinationAdditionalFields.map((field) => ({
          ...field,
          fieldName: `${fieldNamePrefix}${field.fieldName}`,
        })),
      ];
    }

    if (
      destination &&
      destination.isPayerBranchRequired &&
      includeBranchFields
    ) {
      destinationAdditionalFields = [
        ...destinationAdditionalFields,
        {
          fieldBelongsTo: "Beneficiary",
          fieldLabel: t("Beneficiaries.AddAccount.additionalFields.State"),
          fieldName: `${fieldNamePrefix}accountStateISOCode`,
          fieldType: "dropdown",
          fieldOptions: [],
          fieldGroup: "branch",
        },
        {
          fieldBelongsTo: "Beneficiary",
          fieldLabel: t("Beneficiaries.CreateBeneficiary.Form.City"),
          fieldName: `${fieldNamePrefix}accountCity`,
          fieldType: "dropdown",
          fieldOptions: [],
          fieldGroup: "branch",
        },
        {
          fieldBelongsTo: "Beneficiary",
          fieldLabel: t("Beneficiaries.AddAccount.additionalFields.Branch"),
          fieldName: `${fieldNamePrefix}branch`,
          fieldType: "dropdown",
          fieldOptions: [],
          fieldGroup: "branch",
        },
      ];
    }

    destinationAdditionalFields = await Promise.all(
      destinationAdditionalFields.map(async (field) => ({
        ...field,
        fieldOptions:
          field.fieldName === `${fieldNamePrefix}accountStateISOCode`
            ? countrySubdivisions?.map((opt) => ({
                ...opt,
                key: opt.label,
              }))
            : field.fieldOptions?.map((opt) => ({
                ...opt,
                value: opt.key,
                label: tSendMoney(`additionalFieldsOptions.${opt.value}`, {
                  defaultValue: opt.value,
                }),
              })),
      }))
    );
    setAdditionalFields(destinationAdditionalFields);

    return destinationAdditionalFields;
  };

  const cleanAdditionalFields = () => {
    if (form) {
      for (const field of additionalFields) {
        form.setValue(field.fieldName, "");
      }
      form.setValue(`${fieldNamePrefix}accountStateISOCode`, "");
      form.setValue(`${fieldNamePrefix}accountCity`, "");
      form.setValue(`${fieldNamePrefix}branch`, "");
    }
  };

  const setBranchOptions = useDebounce(
    async ({
      destinationSelected,
      destinationId,
      currencyCode,
      state,
      city,
    }: {
      destinationSelected?: Destination;
      destinationId?: string;
      currencyCode?: string;
      country?: string;
      state: string;
      city: string;
    }) => {
      const result = [...additionalFields];
      const idx = result.findIndex(
        (field) => field.fieldName === `${fieldNamePrefix}branch`
      );
      const destination =
        destinationSelected ||
        destinationList?.find(
          (destination) => destination.value === destinationId //getValues(`${fieldNamePrefix}destination`)
        );

      if (idx !== -1 && destination?.payerSpecificCode) {
        result[idx].fieldOptions = await fetchBankBranchesOptions({
          destinationRailCode: destination.payerSpecificCode,
          currencyCode,
          country,
          state,
          city,
        });
      }

      if (!isEmpty(result)) {
        setAdditionalFields(result);
      }
    },
    900
  );

  const setCitiesOptions = async (subdivisionCode: string) => {
    const cities = await getSubdivisionCitiesOptions(subdivisionCode);
    const result = [...additionalFields];
    const idx = result.findIndex(
      (field) => field.fieldName === `${fieldNamePrefix}accountCity`
    );

    if (idx !== -1 && cities) {
      result[idx].fieldOptions = cities;
    }
  };

  const fetchAdditionalFieldsAppriza = async (countryCode: string) => {
    let AdditionalFieldsAppriza = await getAdditionalFieldsAppriza(
      countryCode,
      lang.language
    );
    if (AdditionalFieldsAppriza) {
      AdditionalFieldsAppriza = [
        ...AdditionalFieldsAppriza.map((field: AdditionalField) => ({
          ...field,
          fieldName: `${fieldNamePrefix}${field.fieldName}`,
          fieldOptions: field.fieldOptions?.map((opt) => ({
            ...opt,
            value: opt.key,
            label: tSendMoney(`additionalFieldsOptions.${opt.value}`, {
              defaultValue: opt.value,
            }),
          })),
        })),
      ];
    } else {
      AdditionalFieldsAppriza = [];
    }

    setAdditionalFieldsAppriza(AdditionalFieldsAppriza);

    return AdditionalFieldsAppriza;
  };

  useEffect(() => {
    if (form) {
      const subscription = form.watch(async (_value, { name, type }) => {
        const accountStateISOCode = form.getValues(
          `${fieldNamePrefix}accountStateISOCode`
        );
        const accountCity = form.getValues(`${fieldNamePrefix}accountCity`);
        const destination =
          form.getValues(`${fieldNamePrefix}destination`) ||
          form.getValues(`destination`);

        if (name?.includes("destination") && (!type || type === "change")) {
          cleanAdditionalFields();
          if (destination) {
            fetchAdditionalFields({
              destinationId: destination.toString(),
              transferType,
              country,
            });
          } else {
            setAdditionalFields([]);
          }
        } else if (
          name &&
          [
            `${fieldNamePrefix}accountStateISOCode`,
            `${fieldNamePrefix}accountCity`,
          ].includes(name) &&
          type === "change"
        ) {
          if (
            name === `${fieldNamePrefix}accountStateISOCode` &&
            accountStateISOCode
          ) {
            form.setValue(`${fieldNamePrefix}accountCity`, "");
            setCitiesOptions(accountStateISOCode);
          }

          if (accountStateISOCode && accountCity) {
            form?.trigger(`${fieldNamePrefix}accountCity`, {
              shouldFocus: true,
            });
            form.setValue(`${fieldNamePrefix}branch`, null, {
              shouldValidate: true,
              shouldTouch: true,
            });
            setBranchOptions({
              destinationSelected,
              destinationId: destinationSelected?.id || destination.toString(),
              country,
              state: form.getValues(`${fieldNamePrefix}accountStateISOCode`),
              city: form.getValues(`${fieldNamePrefix}accountCity`),
            });
          }
        }
      });

      return () => subscription.unsubscribe();
    }
  }, [form, additionalFields, destinationList, countrySubdivisions]);

  useEffect(() => {
    if (lang) {
      if (destinationSelected && !isEmpty(countrySubdivisions)) {
        fetchAdditionalFields({
          destinationSelected,
          transferType,
          country,
        });
      }
    }
  }, [destinationSelected, countrySubdivisions, lang]);

  useEffect(() => {
    (async () => {
      if (!isEmpty(countrySubdivisions) && !isEmpty(additionalFields)) {
        const destinationAdditionalFields: AdditionalField[] =
          await Promise.all(
            additionalFields.map(async (field) => ({
              ...field,
              fieldOptions:
                field.fieldName === `${fieldNamePrefix}accountStateISOCode`
                  ? countrySubdivisions?.map((opt) => ({
                      ...opt,
                      key: opt.label,
                    }))
                  : field.fieldOptions?.map((opt) => ({
                      ...opt,
                      label: opt.key,
                    })),
            }))
          );

        setAdditionalFields(destinationAdditionalFields);
      }
    })();
  }, [countrySubdivisions]);

  return {
    additionalFields,
    isLoading,
    setAdditionalFields,
    fetchAdditionalFields,
    fetchBankBranchesOptions,
    additionalFieldsAppriza,
    setAdditionalFieldsAppriza,
    fetchAdditionalFieldsAppriza,
  };
};
