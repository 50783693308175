import { PaymentOrderWithSendolaPayRepository } from "../domain/PaymentOrderRepository";
import {
  PrivacyPolicyStatus,
  SenderPrivacyPolicyStatus,
  SendolaCredentials,
  SendolaPayTransaction,
  SendolaPayTransactionDetail,
  ShowSendolaPay,
} from "../domain/SendolaPay";

export const getShowSendolaPay =
  (SendolaPayRepository: PaymentOrderWithSendolaPayRepository) =>
  async (): Promise<ShowSendolaPay> =>
    SendolaPayRepository.getShowSendolaPay();

export const getPrivacypolicyStatus =
  (SendolaPayRepository: PaymentOrderWithSendolaPayRepository) =>
  async (personId: string): Promise<PrivacyPolicyStatus> =>
    SendolaPayRepository.getPrivacypolicyStatus();

export const senderPrivacypolicyStatus =
  (SendolaPayRepository: PaymentOrderWithSendolaPayRepository) =>
  async (): Promise<SenderPrivacyPolicyStatus> =>
    SendolaPayRepository.senderPrivacypolicyStatus();

export const senderCredentialsSendolaOne =
  (SendolaPayRepository: PaymentOrderWithSendolaPayRepository) =>
  async (
    personId: string,
    body: SendolaCredentials
  ): Promise<SenderPrivacyPolicyStatus> =>
    SendolaPayRepository.senderCredentialsSendolaOne(personId, body);

export const sendolaPayTransactionDetail =
  (SendolaPayRepository: PaymentOrderWithSendolaPayRepository) =>
  async (
    personId: string,
    body: SendolaPayTransaction
  ): Promise<SendolaPayTransactionDetail> =>
    SendolaPayRepository.sendolaPayTransactionDetail(personId, body);
