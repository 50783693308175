import { defaultImages, martoriImages } from "../ThemeProvider/Images";

type ThemeAction =
  | { type: "set_default_theme" }
  | { type: "set_martori_theme" };

export interface ThemeState {
  currentTheme: "default" | "martori";
  images: {
    [key: string]: string;
  };
}

export const themeReducer = (
  state: ThemeState,
  action: ThemeAction
): ThemeState => {
  switch (action.type) {
    case "set_default_theme":
      return { currentTheme: "default", images: { ...defaultImages } };

    case "set_martori_theme":
      return { currentTheme: "martori", images: { ...martoriImages } };

    default:
      return state;
  }
};
