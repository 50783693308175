export const LANG_KEY = "lang";

export const GET_LANGUAGE_SETTINGS = "getLanguageSettings";
export const GET_LANGUAGE_SETTINGS_PENDING = `${GET_LANGUAGE_SETTINGS}/pending`;
export const GET_LANGUAGE_SETTINGS_REJECTED = `${GET_LANGUAGE_SETTINGS}/rejected`;
export const GET_LANGUAGE_SETTINGS_FULFILLED = `${GET_LANGUAGE_SETTINGS}/fulfilled`;

export const PATCH_LANGUAGE_SETTINGS = "patchLanguageSettings";
export const PATCH_LANGUAGE_SETTINGS_PENDING = `${PATCH_LANGUAGE_SETTINGS}/pending`;
export const PATCH_LANGUAGE_SETTINGS_REJECTED = `${PATCH_LANGUAGE_SETTINGS}/rejected`;
export const PATCH_LANGUAGE_SETTINGS_FULFILLED = `${PATCH_LANGUAGE_SETTINGS}/fulfilled`;
