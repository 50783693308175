import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectorLanguage } from "../../Redux/Translate";
import { LEGAL_FILES } from "../../Constants/LegalFiles";

const Legal = () => {
  const location = useLocation();
  const { pathname } = location;
  const lang = useSelector(selectorLanguage);
  const LegalFilesUrl = LEGAL_FILES.filter((item) => item.path === pathname);
  useEffect(() => {
    if (lang) {
      if (lang.language === "es") {
        window.location.href = LegalFilesUrl[0].urlEs || "";
      }
      if (lang.language === "en") {
        window.location.href = LegalFilesUrl[0].urlEn || "";
      }
    }
  }, [lang]);
  return null;
};
export default Legal;
