import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useModal } from "../../../../Hooks/useModal";

import Button from "../../../../Components/Button";
import { Column, Container, Row } from "../../../../Components/Grid";
import IconShape from "../../../../Components/IconShape";
import Title from "../../../../Components/Title";
import Text from "../../../../Components/Text";
import TableInfo from "../../../../Components/TableInfo";

import { AlignItems, JustifyContent } from "../../../../Types/Column";

import { RowBold, tTableInfoData } from "../../../../Models/TableInfo";

import {
  ReceiptRowStyled,
  ReceiptInfoRowStyled,
} from "./PaymentLinkedAccountsStatus.styled";
import { PlaidAccountsDetail } from "../../../../Modules/PaymentOrders/domain/Plaid";
import usePlaidPaymentOrder from "../../../../Hooks/usePlaidPaymentOrder";
import PlaidLink from "../PlaidLink";

type Props = {
  hasBackPath?: string;
  isConfirmed?: boolean;
  requiredUpdate?: boolean;
  data: PlaidAccountsDetail;
  accountId: string;
};

const PaymentReceiptStatus = ({
  isConfirmed,
  requiredUpdate,
  data,
  accountId,
  hasBackPath,
}: Props) => {
  const { modal, setOpenModal, showModal } = useModal();
  const { errorModal, plaidDeleteAccount } = usePlaidPaymentOrder();
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  let accountDetails: Array<tTableInfoData> = [];

  if (Object.keys(data).length > 0) {
    accountDetails = [
      {
        id: "bank",
        label: t("Payments.LinkedAccounts.details.bankName"),
        value: data.bankName,
        bold: RowBold.LEFT,
        padding: "12px 0",
      },
      {
        id: "accountType",
        label: t("Payments.LinkedAccounts.details.accountType"),
        value: data.plaidAccountName,
        bold: RowBold.LEFT,
        padding: "12px 0",
      },
      {
        id: "accountNumber",
        label: t("Payments.LinkedAccounts.details.accountNumber"),
        value: `************${data.accountLastFour}`,
        bold: RowBold.LEFT,
        padding: "12px 0",
      },
      {
        id: "routingNumber",
        label: t("Payments.LinkedAccounts.details.routingNumber"),
        value: data?.routingLastFour ? `******${data.routingLastFour}` : "",
        bold: RowBold.LEFT,
        padding: "12px 0",
      },
      {
        id: "status",
        label: t("Payments.LinkedAccounts.details.status"),
        value: !requiredUpdate
          ? data.status
          : t("Payments.LinkedAccounts.cardNeedsUpdate"),
        bold: RowBold.LEFT,
        padding: "12px 0",
      },
    ];
  }

  const showUnlinkAlertModal = (data: PlaidAccountsDetail) => {
    showModal({
      modalType: "unlinkAccountModalAlert",
      title: t("Payments.LinkedAccounts.details.btnUnlink"),
      content: (
        <Text size={1} color="grey">
          {`${data.bankName} ************${data.accountLastFour}`}
        </Text>
      ),
      message: t("Payments.LinkedAccounts.modalUnlink.subtitle"),
      handleConfirm: async () => {
        const response = await plaidDeleteAccount(accountId);

        if (response) {
          setOpenModal(false);
          showUnlinkSuccessModal();
        }
      },
    });
  };

  const showUnlinkSuccessModal = () => {
    showModal({
      modalType: "unlinkAccountSuccessModal",
      title: t("Payments.LinkedAccounts.details.btnUnlink"),
      content: (
        <Text size={1} color="grey">
          {`${data.bankName} ************${data.accountLastFour}`}
        </Text>
      ),
      message: t("Payments.LinkedAccounts.modalUnlink.title"),
      handleConfirm: () => {
        navigate(hasBackPath || "/payment-link-external-bank-account");
      },
    });
  };

  return (
    <Container>
      <Row as={ReceiptRowStyled}>
        {isConfirmed && (
          <Column
            span={12}
            py={2}
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.flexStart}
          >
            <IconShape
              border="rounded"
              icon="circle-check"
              iconColor="white"
              iconSize="medium"
              bgColor="primary"
              width={50}
              height={50}
            />
          </Column>
        )}
      </Row>
      <Row as={ReceiptRowStyled}>
        <Column span={12} py={2}>
          <Title
            color=""
            tagName="h3"
            size={3}
            weight={600}
            text={
              isConfirmed
                ? t("Payments.LinkedAccounts.detailsTitle")
                : t("Payments.LinkedAccounts.details.title")
            }
            align="center"
          />
        </Column>
      </Row>
      <Row as={ReceiptInfoRowStyled}>
        <Column
          span={12}
          p={2}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.flexStart}
        >
          {accountDetails.length > 0 && (
            <TableInfo data={accountDetails}></TableInfo>
          )}
        </Column>
      </Row>
      <Row>
        <Column
          span={12}
          py={2}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.flexStart}
        >
          <>
            {requiredUpdate && !isConfirmed && (
              <PlaidLink
                requiredUpdate={requiredUpdate}
                updateData={{
                  redirectUri: "https://prueba.example.com",
                  accountId,
                }}
                btnVariant="primary"
                btnText={t("Payments.LinkedAccounts.details.btnRelink")}
                btnIcon="arrowLeft"
                onCompleted={(accountId) =>
                  navigate(hasBackPath || "/payment-link-external-bank-account")
                }
              />
            )}

            {isConfirmed && (
              <Button
                disabled={accountDetails.length === 0}
                type="button"
                variant="outline"
                sizeButton="large"
                sizeText="medium"
                text={t("Payments.LinkedAccounts.details.btnNext")}
                onClick={() =>
                  navigate(hasBackPath || "/payment-link-external-bank-account")
                }
              />
            )}

            {!requiredUpdate && !isConfirmed && (
              <Button
                disabled={accountDetails.length === 0}
                type="button"
                variant="outline"
                sizeButton="large"
                sizeText="medium"
                text={t("Payments.LinkedAccounts.details.btnUnlink")}
                onClick={() => showUnlinkAlertModal(data)}
              />
            )}
          </>
        </Column>
      </Row>
      {modal}
      {errorModal}
    </Container>
  );
};

export default PaymentReceiptStatus;
