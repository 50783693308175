import styled from "styled-components";

export const ContainerLink = styled.div`
  display: flex;
  align-items: center;
  margin-block: 10px;
  padding-block: 5px;

  div: first-child {
    margin-right: 5px;
  }

  a {
    font-family: "Poppins";
  }
`;
