import theme from "styled-theming";

import { theme as config } from "./Theme";

export const inputActiveColor = theme("currentTheme", {
  default: config.Primary_01,
  martori: config.MartoriPrimary_01,
});

export const inputBackgroundColor = theme("currentTheme", {
  default: config.White,
  martori: config.White,
});

export const inputPlaceholderColor = theme("currentTheme", {
  default: config.Silver,
  martori: config.Silver,
});
