import { StylesConfig } from "react-select";
import { SelectOption } from "../../Models/Select";
import { selectStyles } from "../SearchSelect/SearchSelectTheme";

export const quoterSelectStyles: StylesConfig<SelectOption> = {
  control: (base, props) => ({
    ...selectStyles.control?.call(this, base, props),
    borderWidth: "2px",
  }),
};
