import logoDefault from "../../Assets/logo-sendola.png";
import logoMartori from "../../Assets/Img/Martori/martori_logo.png";
import bgHome from "../../Assets/Img/bgHomePage.jpg";
import bgHomeMartori from "../../Assets/Img/Martori/bgHomePage.jpg";
import bgHomeMobile from "../../Assets/Img/bgHomePageResponsive.jpg";
import bgOpenAccount from "../../Assets/Img/OpenAccount.png";
import bgOpenAccountMartori from "../../Assets/Img/Martori/OpenAccount.png";
import bgTransactionLoader from "../../Assets/Img/bgTransferLoader.png";
import bgTransactionLoaderMartori from "../../Assets/Img/Martori/bgTransferLoader.png";

export const defaultImages = {
  logo: logoDefault,
  bgHome: bgHome,
  bgHomeMobile: bgHomeMobile,
  bgOpenAccount: bgOpenAccount,
  bgTransactionLoader: bgTransactionLoader,
};

export const martoriImages = {
  logo: logoMartori,
  bgHome: bgHomeMartori,
  bgHomeMobile: "",
  bgOpenAccount: bgOpenAccountMartori,
  bgTransactionLoader: bgTransactionLoaderMartori,
};
