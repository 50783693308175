import { createSlice } from "@reduxjs/toolkit";
import { getAchAccount } from "./PaymentACH.actions";
import { PaymentACHState } from "../../Models/Payments";
import { State } from "../Store";

const initialState: PaymentACHState = {
  loading: false,
  error: null,
  data: {
    achInformation: undefined,
  },
};

export const PaymentAchSlice = createSlice({
  name: "paymentAch",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
      return state;
    },
    setAchInformation(state, action) {
      state.data.achInformation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAchAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // eslint-disable-next-line
      .addCase(getAchAccount.fulfilled, (state: any, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAchAccount.rejected, (state, action) => {
        state.loading = false;
        state.data = {};
        state.error = action.error;
      });
  },
});

export default PaymentAchSlice.reducer;

export const PaymentAchSelector = (state: State) => state.paymentAch;
