import { useMemo } from "react";

import Icon from "../Icon";

import { FloatingButton } from "./FloatingZelleButton.styled";
import { 
  PendingTransactionsModalSelector,
  updatePendingTransactionsModal
} from "../../Redux/PendingTransactionsModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Redux/Store";

const FloatingZelleButton = () => {
  const dispatch = useDispatch<AppDispatch>();
  const showPendeingTransactionsModalSelector = useSelector(PendingTransactionsModalSelector);

  const showTransactionsModal = useMemo(() => 
    showPendeingTransactionsModalSelector?.show, 
    [showPendeingTransactionsModalSelector]
  );

  const openModal = async () => {
    await dispatch(updatePendingTransactionsModal(!showTransactionsModal));
  };

  return (
    <div>
      <FloatingButton onClick={openModal} id="floatingButton">
        <Icon icon="currencyDollarExchange" color="white" fillIcon={false} size="medium" />
      </FloatingButton>
    </div>
  );
};

export default FloatingZelleButton;
