import { createAsyncThunk } from "@reduxjs/toolkit";
import { createConfigApiIam } from "../../Services/ApiIam";
import { TOKEN_VALIDATION } from "../../Constants/Token";

const client = createConfigApiIam();

export const TokenValidationAction = createAsyncThunk(
  TOKEN_VALIDATION,
  async (_, thunkAPI) => {
    try {
      const response = await client.get("/api/tokenvalidation");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
