import { MutableRefObject, PropsWithChildren, useEffect, useRef } from "react";
import { SliderWrapper } from "./Slider.styled";
import { CSSProperties } from "styled-components";

interface SliderProps extends PropsWithChildren {
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  gap?: CSSProperties["gap"];
  overflowSize?: string;
  top?: CSSProperties["top"];
}

export const Slider = ({
  containerRef,
  gap,
  overflowSize,
  top,
  children,
}: SliderProps) => {
  const sliderRef = containerRef || useRef<HTMLDivElement | null>(null);

  const invertWheel = (evt: WheelEvent) => {
    if (sliderRef.current && evt.deltaX === 0 && evt.deltaY !== 0) {
      evt.preventDefault();
      sliderRef.current.scrollLeft +=
        sliderRef.current.children[0].children[0].clientWidth *
        Math.sign(evt.deltaY);
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.addEventListener("wheel", invertWheel);

      return () => {
        sliderRef.current?.removeEventListener("wheel", invertWheel);
      };
    }
  }, [sliderRef.current]);

  return (
    <SliderWrapper
      ref={sliderRef}
      gap={gap}
      overflowSize={overflowSize}
      top={top}
    >
      <div>{children}</div>
    </SliderWrapper>
  );
};
