import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Button from "../../Components/Button";
import { DynamicInput } from "../../Components/DynamicInput";
import { Column, Container, Row } from "../../Components/Grid";
import Layout from "../../Components/Layout";
import Text from "../../Components/Text";
import Title from "../../Components/Title";
import { useBeneficiaryAdditionalFields } from "../../Hooks/useBeneficiaryAdditionalFields";
import { useSubdivision } from "../../Hooks/useSubdivision";
import {
  BeneficiarySelector,
  BeneficiarySlice,
} from "../../Redux/Beneficiary/Beneficiary.slice";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import { AppDispatch } from "../../Redux/Store";
import { ThemeSelector } from "../../Redux/Theme";
import { ButtonSection } from "../AddBeneficiary/AddBeneficiary.styled";
import { AlignItems, JustifyContent } from "../../Types/Column";
import { selectorLanguage } from "../../Redux/Translate";

export const SelectDestinationBranch = () => {
  const [t] = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const [tSendMoney] = useTranslation("sendMoney");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const theme = useSelector(ThemeSelector);
  const { currentPaymentDestination } = useSelector(QuotationSelector).data;
  const { beneficiaryToSave } = useSelector(BeneficiarySelector).data;
  const form = useForm({ mode: "all" });
  const {
    formState: { errors, isValid },
    handleSubmit,
  } = form;

  const {
    subdivisionList,
    isLoading: subdivisionsLoading,
    getSubdivisionOptions,
  } = useSubdivision();

  const {
    additionalFields: beneficiaryAdditionalFields,
    isLoading: additionalFieldsLoading,
  } = useBeneficiaryAdditionalFields({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    country: beneficiaryToSave!.recipient.country,
    countrySubdivisions: subdivisionList,
    destinationSelected: currentPaymentDestination,
    transferType: "CPU",
    includeBeneficiaryFields: false,
    form,
  });

  const isLoading = subdivisionsLoading || additionalFieldsLoading;

  const processSubmit = handleSubmit((data) => {
    if (data.branch) {
      dispatch(
        BeneficiarySlice.actions.setBeneficiaryToSave({
          ...beneficiaryToSave,
          recipient: {
            ...beneficiaryToSave?.recipient,
            additionalField: {
              branch: data.branch,
              additionalFieldInfo: [],
            },
          },
        })
      );

      navigate("/recipient-confirmation");
    }
  });

  useEffect(() => {
    if (beneficiaryToSave?.recipient?.country) {
      getSubdivisionOptions(beneficiaryToSave.recipient.country, language);
    }
  }, [beneficiaryToSave]);

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} loading={isLoading}>
        <FormProvider {...form}>
          <form onSubmit={processSubmit}>
            <Container>
              <Row>
                <Column span={12}>
                  <Title
                    tagName="h1"
                    text={tSendMoney("branchSelection.title")}
                    color="black"
                    size={6}
                    align="left"
                  />

                  <Text color="black" size={1} align="left">
                    {tSendMoney("branchSelection.subtitle")}
                  </Text>
                </Column>
              </Row>

              {beneficiaryAdditionalFields &&
                beneficiaryAdditionalFields.map((field) => (
                  <Row key={field.fieldName}>
                    <Column span={12} px={2}>
                      <DynamicInput {...field} />
                    </Column>
                  </Row>
                ))}

              <Row>
                <Column
                  span={12}
                  alignItems={AlignItems.center}
                  justifyContent={JustifyContent.center}
                >
                  <Button
                    text={t("Beneficiaries.CreateBeneficiary.Form.Submit")}
                    variant={isValid ? "primary" : "default"}
                    sizeText="large"
                    sizeButton="medium"
                    disabled={!isValid}
                  />
                </Column>
              </Row>
            </Container>
          </form>
        </FormProvider>
      </Layout>
    </ThemeProvider>
  );
};
