import { useEffect, useState } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useNavigate, useLocation, NavigateFunction } from "react-router-dom";

// own states
import { State } from "../../Redux/Store";
import { selectorLanguage } from "../../Redux/Translate";

// Own components
import Text from "../../Components/Text";
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";

import { AlignItems, JustifyContent } from "../../Types/Column";
import { CheckInstructionsContainer } from "./Components/CheckInstructions";
import IconFont from "../../Components/IconFont";
import PlaidLink from "./Components/PlaidLink";
import usePlaidPaymentOrder from "../../Hooks/usePlaidPaymentOrder";
import {
  PaymentLinkedAccountCard,
  PaymentLinkedAccountCardLoading,
} from "./Components/PaymentLinkedAccountCard";
import { PlaidAccounts } from "../../Modules/PaymentOrders/domain/Plaid";
import {
  LinkExternalCardDescription,
  LinkedAccountCardExtraStyled,
} from "./Payments.styled";

const PaymentLinkExternalBankAccount = () => {
  const [t] = useTranslation("global");
  const [showLinkAccounts, setShowLinkAxccounts] = useState(false);
  const { accounts, isAccountLoading, plaidGetAccounts } =
    usePlaidPaymentOrder();
  const theme = useSelector((state: State) => state.theme);
  const lang = useSelector(selectorLanguage);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    plaidGetAccounts();
  }, [lang]);

  return (
    <ThemeProvider theme={theme}>
      <Layout
        path={`/${location?.state?.paymentsPath || "dashboard"}`}
        textLink={t("buttons.back")}
      >
        <Container>
          <Row>
            <Column
              span={12}
              py={8}
              alignItems={AlignItems.center}
              justifyContent={JustifyContent.center}
            >
              {isAccountLoading ? (
                <PaymentLinkedAccountCardLoader />
              ) : (
                <>
                  {(Object.keys(accounts).length > 0 && accounts.hasAccounts) ||
                  showLinkAccounts ? (
                    <PaymentLinkedAccountCardList
                      title={t("Payments.LinkedAccounts.title")}
                      btnText={t("Payments.LinkedAccounts.btnLinkAccount")}
                      accounts={accounts.accounts}
                      navigate={navigate}
                      t={t}
                    />
                  ) : (
                    <CheckInstructionsContainer
                      title={t("Payments.LinkedAccounts.initTitle")}
                      description={t(
                        "Payments.LinkedAccounts.initInstructions"
                      )}
                      icon={
                        <IconFont name="link" color="white" size="xxxlarge" />
                      }
                    >
                      <PlaidLink
                        btnVariant="primary"
                        btnText={t("Payments.LinkedAccounts.btnStarted")}
                        btnIcon="arrowLeft"
                        onCompleted={(accountId: string) => {
                          navigate(
                            "/payment-link-external-bank-account-details",
                            {
                              state: { accountId, isConfirmed: true },
                            }
                          );
                        }}
                      />
                    </CheckInstructionsContainer>
                  )}
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

const PaymentLinkedAccountCardList = ({
  title,
  accounts,
  btnText,
  navigate,
  t,
}: {
  title: string;
  accounts: Array<PlaidAccounts>;
  btnText: string;
  navigate: NavigateFunction;
  t: TFunction;
}) => (
  <Row>
    <Column span={12} justifyContent={JustifyContent.center}>
      <Text
        color="black"
        size={4}
        align="left"
        weight={600}
        margin="48px 0 24px 0"
      >
        {title}
      </Text>
    </Column>
    <Column span={12} py={3} justifyContent={JustifyContent.center}>
      <IconFont name="external-link" size="large" color="primary" />
    </Column>
    <Column span={12} justifyContent={JustifyContent.center}>
      <PlaidLink
        btnText={btnText}
        btnVariant="outline"
        onCompleted={(accountId: string) =>
          navigate("/payment-link-external-bank-account-details", {
            state: { accountId, isConfirmed: true },
          })
        }
      />
    </Column>
    <Column span={3} xs={12} />
    <Column span={6} xs={12} px={2} py={4}>
      {accounts.map((account) => (
        <div
          key={account.id}
          onClick={() =>
            navigate("/payment-link-external-bank-account-details", {
              state: {
                requiredUpdate: !account.activeToken,
                accountId: account.id,
              },
            })
          }
        >
          <PaymentLinkedAccountCard
            title={account.bankName}
            subtitle={
              <LinkExternalCardDescription>
                <span>{account.accountName}</span>
                <span>************{account.accountLastFour}</span>
              </LinkExternalCardDescription>
            }
            extra={
              <>
                {!account.activeToken && (
                  <LinkedAccountCardExtraStyled>
                    <IconFont name="alert" color="error" size="small" />
                    <Text color="error" weight={600} margin="5px 0 5px 0">
                      {t("Payments.LinkedAccounts.cardNeedsUpdate")}
                    </Text>
                  </LinkedAccountCardExtraStyled>
                )}
                <Text color="black" weight={600} margin="15px 0 15px 0">
                  {t("Payments.LinkedAccounts.cardViewDetails")}
                </Text>
              </>
            }
          />
        </div>
      ))}
    </Column>
    <Column span={3} xs={12} sm={3} />
  </Row>
);

const PaymentLinkedAccountCardLoader = () => (
  <Row>
    <Column span={3} xs={12} />
    <Column span={6} xs={12} p={4}>
      <PaymentLinkedAccountCardLoading />
      <PaymentLinkedAccountCardLoading />
      <PaymentLinkedAccountCardLoading />
      <PaymentLinkedAccountCardLoading />
    </Column>
    <Column span={3} xs={12} />
  </Row>
);

export default PaymentLinkExternalBankAccount;
