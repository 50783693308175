import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useLocation } from "react-router-dom";

// own states
import { State } from "../../Redux/Store";

// Own components
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";

import { AlignItems, JustifyContent } from "../../Types/Column";
import usePlaidPaymentOrder from "../../Hooks/usePlaidPaymentOrder";
import PaymentReceiptStatus from "./Components/PaymentLinkedAccountsStatus";

const PaymentLinkExternalBankAccountDetails = () => {
  const [t] = useTranslation("global");
  const { isAccountLoading, accountDetails, plaidGetAccountDetails } =
    usePlaidPaymentOrder();
  const theme = useSelector((state: State) => state.theme);
  const { state } = useLocation();

  useEffect(() => {
    if (state?.accountId) {
      getDetails(state?.accountId);
    }
  }, [state]);

  const getDetails = (accountId: string) => {
    plaidGetAccountDetails(accountId);
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout
        loading={isAccountLoading}
        path={
          state?.backPath
            ? `${state?.backPath}`
            : "/payment-link-external-bank-account"
        }
        textLink={t("buttons.back")}
      >
        <Container>
          <Row>
            <Column span={3} xs={12} />
            <Column
              span={6}
              xs={12}
              py={8}
              alignItems={AlignItems.center}
              justifyContent={JustifyContent.center}
            >
              <PaymentReceiptStatus
                requiredUpdate={state?.requiredUpdate}
                hasBackPath={state?.backPath}
                isConfirmed={state?.isConfirmed}
                data={accountDetails}
                accountId={state?.accountId}
              />
            </Column>
            <Column span={3} xs={12} />
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default PaymentLinkExternalBankAccountDetails;
