import { FlagIconPath } from "../../Utilities/FlagIcon";
import { DestinationFlag, FlagDestinationContainer, OriginFlag } from "./FlagDestination.styled";

export interface FlagDestinationProps {
  origin: string;
  destination: string;
}

const FlagDestination = (props: FlagDestinationProps) => {
  const { origin, destination } = props;

  return (
    <FlagDestinationContainer>
      <OriginFlag>
        <img src={FlagIconPath(origin)} alt="originFlag" height={31} width={31} />
      </OriginFlag>
      <DestinationFlag>
        <img src={FlagIconPath(destination)} alt="destinationFlag" height={24} width={24} />
      </DestinationFlag>
    </FlagDestinationContainer>
  );
};

export default FlagDestination;
