import { createSlice } from "@reduxjs/toolkit";
import { getCustomerCard } from "./PaymentCheckout.actions";

export const PaymentCheckoutSlice = createSlice({
  name: "PaymentCheckout",
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line
      .addCase(getCustomerCard.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      // eslint-disable-next-line
      .addCase(getCustomerCard.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.data = action.payload;
      })
      // eslint-disable-next-line
      .addCase(getCustomerCard.rejected, (state: any, action: any) => {
        state.loading = false;
        state.data = {};
        state.error = action.error;
      });
  },
});

export default PaymentCheckoutSlice.reducer;

// eslint-disable-next-line
export const PaymentCheckoutSelector = (state: any) => state.PaymentCheckout;
