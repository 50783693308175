import styled from "styled-components";
import { theme } from "styled-tools";

export const FloatingButton = styled.button`
  background: ${theme("Gradient_08")};
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0px 4px 24px 0px ${theme("Shadow_01")};
  position: fixed;
  border: none;
  cursor: pointer;
  z-index: 5;
  bottom: 150px;
  right: 20px;
`;
