import { BeneficiaryAccountCreated } from "../domain/BeneficiaryAccountCreated";
import { BeneficiaryAccountForm } from "../domain/BeneficiaryAccountForm";
import { BeneficiaryRepository } from "../domain/BeneficiaryRepository";

export const addBeneficiaryAccount =
  (beneficiaryRepository: BeneficiaryRepository) =>
  async (
    accountData: BeneficiaryAccountForm
  ): Promise<BeneficiaryAccountCreated> =>
    beneficiaryRepository.addBeneficiaryAccount(accountData);
