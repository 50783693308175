import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { isNil, isEmpty } from "lodash";

// States
import { State } from "../../../Redux/Store";
import { PersonsKYCSelector } from "../../../Redux/PersonsKYC";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { PaymentOrderPayload } from "../../../Modules/PaymentOrders/domain/PaymentOrderCreated";

// Components
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import { JustifyContent } from "../../../Types/Column";
import Title from "../../../Components/Title";
import Text from "../../../Components/Text";
import { FlexContainer } from "../../../Components/Flex/Flex.styled";
import AuthorizeForm from "../../../Components/AuthorizeForm";
import TransactionLoader from "../../../Components/TransactionLoader";

// Styles
import { PaymentAuthorizeContent } from "../Payments.styled";
import { TransactionLoaderContainer } from "./PaymentAuthorize.styled";

// Assets
import VisaSrc from "../../../Assets/Img/visa.png";
import MasterCardSrc from "../../../Assets/Img/mastercard.png";
import AmexSrc from "../../../Assets/Img/amex-minified.png";

// Hooks
import { useAuthorizePaymentOrder } from "../../../Hooks/userAuthorizePaymentOrder";
import { usePaymentOrderPayload } from "../../../Hooks/usePaymentOrderPayload";
import { usePaymentOrder } from "../../../Hooks/usePaymentOrders";
import { useModal } from "../../../Hooks/useModal";

// Modules
import {
  AuthorizeAccounts,
  AuthorizeAccountsSelect,
  AuthorizeCreateOrder,
  AuthorizeFormData,
} from "../../../Modules/PaymentOrders/domain/Authorize";

// Context
import { ThemeContext } from "../../../Contexts/ThemeContext/ThemeContext";


const PaymentAuthorize = () => {
  const { t } = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const { theme: { images }} = useContext(ThemeContext);
  const navigate = useNavigate();
  const theme = useSelector((state: State) => state.theme);
  const [accountsCards, setAccountsCards] = useState<AuthorizeAccountsSelect[]>(
    []
  );
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const { data: personKYCData } = useSelector(PersonsKYCSelector);
  const { currentPaymentMethod } = useSelector(QuotationSelector).data;
  const {
    isLoading,
    isOrderLoading,
    error,
    getAuthorizeCardsPayment,
    setAuthorizeCreateOrder,
  } = useAuthorizePaymentOrder();
  const { paymentOrderPayload } = usePaymentOrderPayload();
  const {
    isLoading: loadingPaymentOrder,
    error: paymentOrderError,
    createPayment,
  } = usePaymentOrder();
  const { 
    modal: paymentOrderModal,
    showModal: showPaymentOrderModal 
  } = useModal();

  const getAuthorizeCards = async () => {
    const personId = personKYCData?.personVeriff?.personId;

    const response = await getAuthorizeCardsPayment(personId || "");

    if (response?.data && response?.data.length > 0) {
      setAccountsCards(accountsSelectFormat(response?.data));
    }
  };

  const accountsSelectFormat = (accounts: AuthorizeAccounts[]) => {
    const formattedAccounts: AuthorizeAccountsSelect[] = [];

    if (accounts.length > 0) {
      accounts.forEach((account) => {
        formattedAccounts.push({
          value: account.cardId,
          label: account.cardholder,
          cardNumber: account.cardNumber,
          expDate: account.expirationDate,
          accountType: getAccountTypeLogo(account?.accountType || ""),
        });
      });
    }

    return formattedAccounts;
  };

  const preparePayment = async (orderPayload: AuthorizeFormData) => {
    if (currentPaymentMethod) {
      const request = paymentOrderPayload as PaymentOrderPayload;
      const response = await createPayment(request);

      if (response && response?.payment?.amount && response?.id) {
        const personId = personKYCData?.personVeriff?.personId;
        const order: AuthorizeCreateOrder = {
          transactionId: request.id,
          amount: response.payment.amount,
          personId: personId || "",
          creditCard: {
            cardholder: orderPayload.cardHolder,
            cardNumber: orderPayload.cardNumber,
            expirationDate: orderPayload.expDate,
            cardCode: orderPayload.cardCode,
            saveCard: orderPayload.saveCard,
          },
        };
        const orderResponse = await setAuthorizeCreateOrder(order);
        setIsPaymentCompleted(true);
        navigate("/transaction-completed");
      }
    }
  };

  const getAccountTypeLogo = (type: string) => {
    const accountTypeFomat = !isEmpty(type) ? type.toLocaleLowerCase() : "";

    switch (accountTypeFomat) {
      case "visa":
        return VisaSrc;
      case "mastercard":
        return MasterCardSrc;
      case "americanexpress":
        return AmexSrc;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (
      isNil(currentPaymentMethod) ||
      currentPaymentMethod?.paymentOrigin !== 'authorize.net'
    ) {
      return navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    getAuthorizeCards();
  }, []);

  useEffect(() => {
    if (paymentOrderError) {
      showPaymentOrderModal({
        modalType: "error",
        errorMessage: tModals("paymentOrder.errorMessage"),
      });
    }
  }, [paymentOrderError]);

  return (
    <ThemeProvider theme={theme}>
      {loadingPaymentOrder || isOrderLoading && (
        <TransactionLoaderContainer>
          <TransactionLoader
            title={t("ReviewTransaction.LoaderTitle")}
            text={t("ReviewTransaction.LoaderText")}
            description={t("ReviewTransaction.LoaderDescription")}
            image="transferLoader"
            bgImage={images["bgTransactionLoader"]}
          />
        </TransactionLoaderContainer>
      )}
      <Layout
        loading={isLoading || loadingPaymentOrder}
        path="/"
        textLink={t("buttons.back")}
        roundedContent
      >
        <Container as={PaymentAuthorizeContent}>
          <Row>
            <Column span={12} mt={1} justifyContent={JustifyContent.center}>
              <FlexContainer
                direction="column"
                gap="8px"
                mw="26.5625rem"
                p="5px 16px 24px 16px"
              >
                <Title
                  weight={600}
                  size={2}
                  tagName="h2"
                  color="black"
                  text={t("Authorize.titlePage")}
                  align="left"
                />
                <Text color="grey" size={0.5} weight={500} align="left">
                  {t("Authorize.descriptionPage")}
                </Text>
              </FlexContainer>
            </Column>
            <Column span={12} justifyContent={JustifyContent.center}>
              <FlexContainer
                direction="column"
                gap="8px"
                mw="26.5625rem"
                p="0 24px 24px"
              >
                <AuthorizeForm
                  isPaymentCompleted={isPaymentCompleted}
                  makePayment={(orderPayload: AuthorizeFormData) =>
                    preparePayment(orderPayload)
                  }
                  data={accountsCards}
                />
              </FlexContainer>
            </Column>
          </Row>
        </Container>
        {paymentOrderModal}
      </Layout>
    </ThemeProvider>
  );
};

export default PaymentAuthorize;
