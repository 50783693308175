import styled from "styled-components";

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  min-width: 100%;
`;

type StyledParams = Parameters<typeof StyledRow>;
type StyledProps = StyledParams[0];

export function Row(props: StyledProps) {
  return <StyledRow {...props} />;
}
