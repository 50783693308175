import styled from "styled-components";
import { theme } from "styled-tools";
import searchIcon from "../../Assets/Icons/search.svg";
import { inputActiveColor } from "../../Contexts/ThemeProvider/Input";

export const Search = styled.label`
  text-align: left;
  color: ${theme("Background_05")};
  width: 100%;

  .search-input {
    font-family: Poppins;
    background-image: url("${searchIcon}");
    background-color: ${theme("Background_06")};
    background-size: 24px 24px;
    background-position: right 16px center;
    background-repeat: no-repeat;
    padding: 10.5px 16px;
    border: 1px solid ${theme("Background_05")};
    color: ${theme("Background_05")};
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 10px;
    width: 100%;
    outline: none;

    &:focus,
    &:focus-visible,
    &:active {
      background-image: none;
      border: 2px solid ${inputActiveColor};
    }

    &:hover {
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
    }
  }
`;
