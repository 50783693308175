import { HeadModal, ModalWrap, OverLay } from "./Modal.styled";
import { ModalProps } from "../../Models/ModalInterface";
import IconFont from "../IconFont";
import Button from "../Button";

const Modal = (props: ModalProps) => {
  const { id, show, children, handleClose, hiddenCloseIcon = true, as } = props;

  return (
    <OverLay
      id={id}
      hidden={!show}
      zIndex={props.zIndex}
      onClick={(evt) => {
        evt.stopPropagation();
        if (!hiddenCloseIcon) {
          handleClose();
        }
      }}
    >
      <ModalWrap onClick={(event: React.MouseEvent) => event.stopPropagation()} as={as || ModalWrap}>
        {!hiddenCloseIcon && (
          <HeadModal>
            <Button variant="transparent" sizeButton="fit" padding="0px" onClick={() => handleClose()}>
              <IconFont name="cancel" color="black" size="medium" />
            </Button>
          </HeadModal>
        )}
        {children}
      </ModalWrap>
    </OverLay>
  );
};

export default Modal;
