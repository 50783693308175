import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { ModalWrap } from "../Modal.styled";

export const PopupCard = styled(ModalWrap)`
  width: 350px;
`;

export const IconContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;

  & svg {
    width: 45px;
    height: 45px;
  }
`;

export const TextContainer = styled.div`
  width: 100%;

  h1 {
    font-family: Poppins;
    text-align: center;
  }

  h5 {
    padding: 0 50px;
  }
`;

export const ButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  button {
    box-sizing: border-box;

    span {
      font-size: 20px;
    }
  }
`;
