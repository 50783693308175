import { QuoteDeliveryMethodCode } from "../../Quote/domain/QuoteDeliveryMethod";
import { Destination } from "../domain/Destination";
import { DestinationRepository } from "../domain/DestinationRepository";

export const getDestinations =
  (destinationRepository: DestinationRepository) =>
  async (
    deliveryMethod: QuoteDeliveryMethodCode,
    country: string,
    sponsorBank?: string
  ): Promise<Destination[]> =>
    destinationRepository.getDestinations(deliveryMethod, country, sponsorBank);
