import styled from "styled-components";
import { headerBackgroundColor } from "../../Contexts/ThemeProvider/Layout";
import { theme } from "styled-tools";
import { quoterCardFeeLabels } from "../../Contexts/ThemeProvider/Texts";

export const PaginationSection = styled.div`
  .page-item a.page-link:hover {
    background-color: ${headerBackgroundColor};
    color: ${theme("Background_01")};
  }

  .page-item.active .page-link {
    font-weight: 700;
    color: ${theme("Background_01")};
    background-color: ${quoterCardFeeLabels};
    border: ${quoterCardFeeLabels};
  }

  .page-item.disabled .page-link {
    color: ${theme("Background_04")};
    pointer-events: none;
    cursor: auto;
  }

  .page-item .page-link {
    color: ${headerBackgroundColor};
  }
`;
