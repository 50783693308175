import { PropsLink } from "../../Models/LinkInterface";
import { LinkStyled } from "./Link.styled";

const Link = ({ href, variant, size, children, weight, ...props }: PropsLink) => {
  return (
    <LinkStyled to={href} variant={variant || "normal"} size={size || 1} weight={weight || 400} {...props}>
      {children}
    </LinkStyled>
  );
};

export default Link;
