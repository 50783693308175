import styled from "styled-components";
import { theme } from "styled-tools";
import { ModalWrap } from "../Modal.styled";

export const StepLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 7px 9px;
  background-color: ${theme("Grey_Light")};
`;

export const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  color: ${theme("White")};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  border-radius: 50%;
  background: ${theme("Gradient_Primary")};
`;

export const ZelleConfirmationWrap = styled(ModalWrap)`
  border-radius: 16px;
  border: 2px solid ${theme("Zelle")};
`;
