import { ReactNode } from "react";
import SendolaLogoSrc from "../../../../../Assets/Img/sendola-icon.png";
import {
  FlexContainer,
  FlexItem,
} from "../../../../../Components/Flex/Flex.styled";
import { Column, Row } from "../../../../../Components/Grid";
import IconFont from "../../../../../Components/IconFont";
import IconShape from "../../../../../Components/IconShape";
import Text from "../../../../../Components/Text";
import { JustifyContent } from "../../../../../Types/Column";
import {
  Image,
  InstructionCard,
  InstructionCardBodyContainer,
} from "./CheckInstructionsCard.styled";

type CheckInstructionsCardProps = {
  title: string;
  body: ReactNode;
  iconLeft?: ReactNode;
  imageSrcLeft?: string;
  iconRight?: ReactNode;
  imageSrcRight?: string;
};

export const CheckInstructionsCard = ({
  title,
  body,
  iconLeft,
  imageSrcLeft,
  iconRight,
  imageSrcRight,
}: CheckInstructionsCardProps) => {
  return (
    <InstructionCard>
      <Row>
        <Column span={12} justifyContent={JustifyContent.center}>
          <Text weight={800} size={1}>
            {title}
          </Text>
        </Column>
      </Row>
      <FlexContainer
        justify="center"
        alignItems="center"
        gap="10px"
        m="20px 0 40px 0"
      >
        <FlexItem w="33.33%" justify="end" alignSelf="center">
          {iconLeft ||
            (imageSrcLeft ? (
              <Image src={imageSrcLeft} />
            ) : (
              <Image src={SendolaLogoSrc} />
            ))}
        </FlexItem>
        <FlexItem w="80px" justify="center" alignSelf="center">
          <IconShape
            icon={"arrows-flat"}
            bgColor="transparent"
            iconColor="black"
            iconSize="medium"
          />
        </FlexItem>
        <FlexItem w="50px" justify="center" alignSelf="center">
          {iconRight ||
            (imageSrcRight ? (
              <Image src={imageSrcRight} />
            ) : (
              <IconFont name="user" color="primary" size="xlarge" />
            ))}
        </FlexItem>
      </FlexContainer>

      <InstructionCardBodyContainer>{body}</InstructionCardBodyContainer>
    </InstructionCard>
  );
};
