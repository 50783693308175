import { useDispatch, useSelector } from "react-redux";
import { Errors } from "../Modules/Common/domain/Errors";
import { getCoupons } from "../Modules/Rewards/application/getCoupons";
import { createApiCouponRepository } from "../Modules/Rewards/infrastructure/ApiCouponRepository";
import { useStatus } from "./useStatus";
import { CouponsSelector, CouponsSlice } from "../Redux/Coupons/Coupons.slice";
import { AppDispatch } from "../Redux/Store";
import { QuotationSlice } from "../Redux/Quotation";
import { PersonsKYCSelector } from "../Redux/PersonsKYC";
import { userIdSelector } from "../Redux/User/User.slice";
import { verifyCoupon } from "../Modules/Rewards/application/verifyCoupon";
import { checkIfCouponApplied, VerifyCouponPayloadShort } from "../Modules/Rewards/domain/Coupon";

export const useCoupons = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, startRequest, endRequest } = useStatus();
  const { couponsGrouped } = useSelector(CouponsSelector).data;
  const userId = useSelector(userIdSelector);
  const personData = useSelector(PersonsKYCSelector).data;
  const person = personData?.personVeriff;

  const getUserCoupons = async () => {
    startRequest();
    dispatch(QuotationSlice.actions.setCurrentQuoteDiscounted(undefined));
    try {
      const couponsRes = await getCoupons(createApiCouponRepository())();

      dispatch(CouponsSlice.actions.setCoupons(couponsRes));
      if (couponsRes.couponsGrouped?.length > 0) {
        dispatch(QuotationSlice.actions.setCouponToApply(couponsRes.couponsGrouped[0]));
      } else {
        dispatch(QuotationSlice.actions.setCouponToApply(undefined));
      }

      endRequest();
      return couponsRes;
    } catch (error) {
      dispatch(CouponsSlice.actions.setCoupons([]));
      dispatch(QuotationSlice.actions.setCouponToApply(undefined));
      endRequest({ error: error as Errors });
    }
  };

  const verifyQuoteCoupon = async (payload: VerifyCouponPayloadShort) => {
    startRequest();
    try {
      const quoteDiscounted = await verifyCoupon(createApiCouponRepository())({
        ...payload,
        isFirstTransaction: person?.applyFeePromotion || false,
        userId,
      });

      const couponApplied = checkIfCouponApplied(payload, quoteDiscounted.data);

      dispatch(CouponsSlice.actions.setErrorsCoupons(null));

      endRequest();
      return { couponApplied, quoteDiscounted: quoteDiscounted.data };
    } catch (error) {
      dispatch(CouponsSlice.actions.setErrorsCoupons(error));
      endRequest({ error: error as Errors });
    }
  };

  return {
    isLoading,
    couponsGrouped,
    getUserCoupons,
    verifyQuoteCoupon,
  };
};
