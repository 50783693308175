import styled from "styled-components";
import { mediaQueryMax, Screen } from "../../Utilities/MediaQuery";

export const ContentColumn = styled.div`
  margin: 64px 136px;

  @media screen and (${mediaQueryMax[Screen.md]}) {
    margin: 0 30px;
  }
`;
