import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { CouponState } from "../../Modules/Rewards/domain/Coupon";

const initialState: CouponState = {
  loading: false,
  error: null,
  data: {
    coupons: [],
    couponsGrouped: [],
  },
};

export const CouponsSlice = createSlice({
  name: "Coupons",
  initialState,
  reducers: {
    setCoupons(state, action) {
      state.data.coupons = action.payload.coupons;
      state.data.couponsGrouped = action.payload.couponsGrouped;
    },
    setErrorsCoupons(state, action) {
      state.error = action.payload?.errors;
    },
  },
});

export default CouponsSlice.reducer;

export const CouponsSelector = (state: State) => state.Coupons;
