const sizesButton = {
  small: "125px",
  medium: "160px",
  large: "240px",
  xlarge: "330px",
  xxlarge: "420px",
  xxxlarge: "520px",
  default: "200px",
  full: "100%",
  fit: "fit-content",
};

export type ButtonSizesKey = keyof typeof sizesButton;

const sizesText = {
  small: 12,
  medium: 16,
  mLarge: 18,
  large: 20,
  default: 14,
};

export type TextSizesKey = keyof typeof sizesText;

const sizesButtonLineHeight = {
  small: 18,
  medium: 16,
  mLarge: 18,
  large: 20,
  default: 16,
};

export const handleSizeButton = (size?: ButtonSizesKey) => {
  return size ? sizesButton[size] : sizesButton["default"];
};

export const handleText = (size?: TextSizesKey) => {
  return size ? sizesText[size] : sizesText["default"];
};

export const handleButtonLineHeight = (size?: TextSizesKey) => {
  return size ? sizesButtonLineHeight[size] : sizesButtonLineHeight["default"];
};
