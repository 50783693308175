import { createSlice } from "@reduxjs/toolkit";
import { DashboardState } from "../../Modules/Common/domain/Transactions";
import { State } from "../Store";

const initialState: DashboardState = {
  currentTab: "dashboard",
  loading: false,
};

export const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    setTabDashboard(state, { payload }) {
      state.currentTab = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setPreviousView(state, { payload }) {
      state.previousView = payload;
    },
  },
});

export const DashboardSelector = (state: State) => state.Dashboard;
