import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

import { useIsSessionAlive } from "../../Services/SessionTimeout";
import { TOKEN_KEY, TOKEN_VALIDATION_FULFILLED } from "../../Constants/Token";
import { doLogout, storeLogin } from "../../Services/Session";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Redux/Store";
import { fromAuthApp } from "../../Utilities/RedirectFromOrigin";
import isEmpty from "lodash.isempty";
import { TokenValidationAction } from "../../Redux/TokenValidation/TokenValidation.actions";
import { jwtDecode } from "jwt-decode";
import { useModal } from "../../Hooks/useModal";
import { PersonsKYCSelector } from "../../Redux/PersonsKYC";
import { DashboardSlice } from "../../Redux/Dashboard/Dashboard.slice";
import { PlatformAppURL } from "../../Constants/ExternalServices";
import { REFERRAL_COOKIE_NAME } from "../../Constants/Referral";
import { DOMAIN } from "../../Constants/Auth";
import { FeatureContext } from "../../Contexts/Feature/FeatureContext";

// List of paths which require the user to be logged in
const authRequired = [
  "/beneficiaries",
  "/beneficiary-selection",
  "/recipient-confirmation",
  "/contacts",
  "/dashboard",
  "/pick-up-location",
  "/review-transaction",
  "/dashboard",
  "/edit-beneficiary",
  "/transaction-completed",
  "/transaction-details",
  "/transaction-history",
  "/transaction-history-details",
  "/try-quote",
  "/payments",
  "/payment-ach",
  "/payment-card",
  "/payment-sendola-pay",
  "/payment-sendola-pay-balance",
  "/payment-sendola-pay-agreement",
  "/payment-sendola-pay-confirmation",
  "/payment-link-external-bank-account",
  "/payment-select-linked-accounts",
  "/payment-link-external-bank-account-details",
];

// List of paths which require the user to _not_ be logged in
// For example, a login page
const authForbidden = ["/"];

// If logged in and the path forbids it, redirect here
const authForbiddenRedirect = "/dashboard";

export default function AuthGuard() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isSessionAlive = useIsSessionAlive();
  const [timer, setTimer] = useState(0);
  const logoutUrl = fromAuthApp("logout");
  const cookies = new Cookies();
  const authCookie = cookies.get(TOKEN_KEY);
  const { modal: banUserModal, showModal } = useModal();
  const { modal: referralModal, showModal: showReferralModal } = useModal();
  const { data } = useSelector(PersonsKYCSelector);
  const personData = useSelector(PersonsKYCSelector).data;
  const person = personData?.personVeriff;

  const featureContext = useContext(FeatureContext);

  const tokenInvalidate = () => {
    doLogout(dispatch);
    window.location.href = logoutUrl;
  };

  const tokenValidate = async () => {
    const response = await dispatch(TokenValidationAction());

    if (response?.type === TOKEN_VALIDATION_FULFILLED) {
      const decode = JSON.stringify(jwtDecode(authCookie));
      const userId = JSON.parse(decode)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
      storeLogin(dispatch, authCookie, userId);
    } else {
      tokenInvalidate();
    }
  };

  // Whenever we navigate, and whenever the internal timer changes, do a check
  useEffect(() => {
    // Redirect if auth required
    if (authRequired.includes(pathname) && !isSessionAlive) {
      tokenInvalidate();
    }
    // Redirect if must not be authenticated
    if (authForbidden.includes(pathname) && isSessionAlive) {
      dispatch(DashboardSlice.actions.setTabDashboard("dashboard"));
      navigate(authForbiddenRedirect, { state: { preserveQuote: true } });
    }
  }, [pathname, timer]);

  const observeBanUserRemove = () => {
    const targetNode = document;

    const config = { attributes: true, childList: true, subtree: true };

    const callback: MutationCallback = function (mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.removedNodes.length > 0) {
          for (const removedNode of mutation.removedNodes) {
            if ((removedNode as Element).id === "ban-user") {
              window.location.href = PlatformAppURL;
            }
          }
        }
      }
    };

    const observer = new MutationObserver(callback);

    if (targetNode) {
      observer.observe(targetNode, config);
    }
  };

  const checkIfUserIsBanned = () => {
    if (data?.isLocked || data?.personVeriff?.isLocked) {
      showModal({
        modalType: "banUser",
        userPhone: "99999",
        id: "ban-user",
        handleClose: () => {
          window.location.href = PlatformAppURL;
        },
      });

      observeBanUserRemove();
    }
  };

  const setHideModal = () => {
    const date = new Date();
    const nextDate = new Date(date.setDate(date.getDate() + 1));

    cookies.set(REFERRAL_COOKIE_NAME, nextDate.getTime(), {
      expires: nextDate,
      domain: DOMAIN,
    });
  };

  const getMustShowReferralModal = (): boolean => {
    const expires = cookies.get(REFERRAL_COOKIE_NAME);

    if (!expires) return true;

    try {
      if (expires) {
        const date = new Date();
        if (new Date(expires).getTime() > date.getTime()) return false;
      }
    } catch (error) {
      cookies.remove(REFERRAL_COOKIE_NAME);
      return true;
    }

    return true;
  };

  // Update the internal timer mentioned above, every second
  useEffect(() => {
    if (!isEmpty(authCookie)) {
      tokenValidate();
    }

    const n = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);

    return () => clearInterval(n);
  }, []);

  useEffect(() => {
    if (featureContext?.features?.rewardsPopupFeature && !person?.applyFeePromotion) {
      const mustShow = getMustShowReferralModal();

      if (mustShow) {
        showReferralModal({
          modalType: "referralModal",
          handleClose: setHideModal,
        });
      }
    }
  }, [featureContext?.features?.rewardsPopupFeature]);

  useEffect(() => {
    checkIfUserIsBanned();
  }, [data?.isLocked]);

  return (
    <>
      {banUserModal}
      {referralModal}
    </>
  );
}
