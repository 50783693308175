import { ReactNode } from "react";

import { Column, Row } from "../../../../Components/Grid";
import Text from "../../../../Components/Text";
import IconFont from "../../../../Components/IconFont";
import RadioCustom from "../../../../Components/RadioCustom";

import { JustifyContent } from "../../../../Types/Column";

import {
  LinkedAccountCardAmount,
  LinkedAccountCardRadioStyled,
  LinkedAccountRadioCardStyled,
  LinkedAccountContent,
} from "./PaymentLinkedAccountCard.styled";
import { CursorPointerStyled } from "../../Payments.styled";

type Props = {
  title: string;
  description: string;
  helperText: string;
  extras?: ReactNode;
  value: string;
  radioRef?: React.Ref<HTMLInputElement> | null;
  getValue: (value: React.ChangeEvent<HTMLInputElement>) => void;
  deleteAccount?: () => void;
};

const PaymentLinkedAccountCardRadio = ({
  title,
  description,
  helperText,
  value,
  radioRef,
  getValue,
  deleteAccount,
  extras,
}: Props) => (
  <Column span={12} as={LinkedAccountRadioCardStyled}>
    <Row>
      <Column span={1} p={2} pr={1}>
        <LinkedAccountCardRadioStyled>
          <RadioCustom
            forwardRef={radioRef}
            name="paymentType"
            value={value}
            onChange={getValue}
          />
        </LinkedAccountCardRadioStyled>
      </Column>
      <Column span={10} pt={2} pb={2}>
        <LinkedAccountContent>
          <div>
            <Text
              color="black"
              size={1}
              align="left"
              weight={600}
              margin="5px 0"
            >
              {title}
            </Text>
            <Text
              color="black"
              size={1}
              align="left"
              weight={400}
              margin="5px 0"
            >
              {description}
            </Text>
            <LinkedAccountCardAmount>
              <Text
                color="grey"
                size={1}
                align="left"
                weight={400}
                margin="5px 0"
              >
                {helperText}
              </Text>
            </LinkedAccountCardAmount>
            {extras}
          </div>
        </LinkedAccountContent>
      </Column>
      {deleteAccount && (
        <Column span={1} p={2} justifyContent={JustifyContent.flexEnd}>
          <CursorPointerStyled onClick={deleteAccount}>
            <IconFont name="trash" size="medium" color="gray" />
          </CursorPointerStyled>
        </Column>
      )}
    </Row>
  </Column>
);

export default PaymentLinkedAccountCardRadio;
