import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { PlaidState } from "../../Modules/PaymentOrders/domain/Plaid";

const initialState: PlaidState = {
  loading: false,
  error: null,
  data: {
    linkedAccountInformation: undefined,
  },
};

export const PaymentLinkedAccountSlice = createSlice({
  name: "paymentLinkedAccount",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
      return state;
    },
    setLinkedAccountInformation(state, action) {
      state.data.linkedAccountInformation = action.payload;
    },
  },
});

export default PaymentLinkedAccountSlice.reducer;

export const PaymentLinkedAccountSelector = (state: State) =>
  state.paymentLinkedAccount;
