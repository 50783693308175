import { useSelector } from "react-redux";
import { ModalVariant } from "../Models/ModalInterface";
import { Codes } from "../Modules/PaymentOrders/domain/VelocityLimit";
import { useModal } from "./useModal";
import { QuotationSelector } from "../Redux/Quotation/Quotation.slice";
import { BeneficiarySelector } from "../Redux/Beneficiary/Beneficiary.slice";

export const useVelocityLimits = () => {
  const { modal, showModal } = useModal();
  const { currentQuotation } = useSelector(QuotationSelector).data;
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;

  const showVelocityModal = ({
    codesMessages,
    modalTypeVelocity = "velocityLimit",
    pageName,
    onClose,
  }: {
    codesMessages: Codes[];
    modalTypeVelocity: ModalVariant;
    pageName?: string;
    onClose?: () => void;
  }) => {
    showModal({
      modalType: modalTypeVelocity,
      codesMessages,
      pageName,
      amount: currentQuotation?.amount,
      beneficiaryName: beneficiarySelected?.fullName,
      handleClose: onClose,
    });
  };

  return { velocityModal: modal, showVelocityModal };
};
