import { useTranslation } from "react-i18next";
import {
  CountrieWhithoutCP,
  CountrieWhithoutD2B,
  ExchangeRate,
  SenderExchangeRates,
} from "../../Modules/Common/domain/ExchangeRates";
import {
  calculateTotalBeforeDiscount,
  calculateTotalToShow,
  QuoteChannel,
  QuoteOption,
  QuoterProps,
  QuoteSelectedValue,
} from "../../Modules/Quote/domain/QuoteSelected";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectorLanguage } from "../../Redux/Translate";
import { useCountry } from "../../Hooks/useCountry";
import { AppDispatch, State } from "../../Redux/Store";
import { allowedCountries } from "../../Modules/Common/domain/Countries";
import isEmpty from "lodash.isempty";
import { CodeKeys } from "../../Models/ModalInterface";
import { SelectOption } from "../../Models/Select";
import { useDebounce } from "../../Hooks/useDebounce";
import { FlexContainer } from "../Flex/Flex.styled";
import { PaymentMethodSelect } from "./PaymentMethodSelect/PaymentMethodSelect.component";
import { ExchangeRateContainer, QuoterCardWrapper } from "./QuoterCardAdvanced.styled";
import { RecipientsSelect } from "./RecipientsSelect/RecipientsSelect.component";
import { formatNumber } from "../../Utilities/NumberUtils";
import { MoneyInputLight } from "../MoneyInput/MoneyInputLight.component";
import { ErrorVelocityLimitsStyled, IconVelocityLimits, TextVelocityLimits } from "../QuoterCard/QuoterCard.styled";
import Icon from "../Icon";
import { DeliveryMethodSelect } from "./DeliveryMethodSelect/DeliveryMethodSelect.component";
import Text from "../Text";
import Button from "../Button";
import { BeneficiarySlice } from "../../Redux/Beneficiary";
import { BeneficiarySelector } from "../../Redux/Beneficiary/Beneficiary.slice";
import { SearchSelect } from "../SearchSelect/SearchSelect.component";
import { quoterSelectStyles } from "../QuoterCard/QuoterSelectTheme";
import { Currency } from "../../Modules/Common/domain/Currency";
import { BeneficiaryRecipient } from "../../Modules/Beneficiary/domain/Beneficiary";
import { PersonsKYCSelector } from "../../Redux/PersonsKYC";
import { KYC_APPROVED } from "../../Constants/KYCStatus";
import { useLocation } from "react-router-dom";
import { PaymentMethod, PlaidAccount } from "../../Modules/Common/domain/PaymentMethods";
import { PlatformAppURL } from "../../Constants/ExternalServices";
import IconFont from "../IconFont";
import { Superscript } from "../Text/Superscript.component";
import Link from "../Link";
import { useModal } from "../../Hooks/useModal";
import { getUrl } from "../../Utilities/Redirect";
import { CouponBar } from "../CouponBar/CouponBar.component";
import { CouponInput } from "../CouponInput/CouponInput.component";
import { CouponsSelector } from "../../Redux/Coupons/Coupons.slice";
import { useFeature } from "../../Hooks/useFeature";
import { ChannelSwitch } from "./ModelSwitch/ModelSwitch.component";
import { bestFXLessFeePayers } from "../../Constants/Regexp";

export const QuoterCardAdvanced = ({
  deliveryMethodsSelect = [],
  sendToOptionsSelect = [],
  value,
  maxAmount,
  minAmount,
  showVelocityLimit,
  codesMessages = [],
  proofOfLifeNeeded,
  couponToApply,
  handleSubmit,
  verifyQuoteCoupon,
  onChange,
}: QuoterProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [tSendMoney] = useTranslation("sendMoney");
  const [t] = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const lang = useSelector(selectorLanguage);
  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    countryDestination,
    currentPaymentDestination,
    currentPaymentMethod,
    paymentMethodSelected,
    currentQuoteDiscounted,
    quotesPerChannel,
  } = useSelector((state: State) => state.quotation.data);
  const {
    data: { beneficiarySelected },
  } = useSelector(BeneficiarySelector);
  const personData = useSelector(PersonsKYCSelector).data;
  const { countries } = useCountry();
  const location = useLocation();
  const { modal: legalNotesModal, showModal: showLegalNotesModal } = useModal();
  const { manualCouponFeature, bestFxLessFeeFeature } = useFeature();

  const [amount, setAmount] = useState<number | string>(value?.amount || 500);
  const [amountInputValue, setAmountInputValue] = useState<number | string>(amount);
  const [currency, setCurrency] = useState<string | undefined>(
    value?.currency || (countries.find((c) => c.countryCode === "MEX") ? Currency.MXN : countries[0]?.defaultCurrency)
  );
  const [country, setCountry] = useState<string | undefined>(
    value?.country || (countries.find((c) => c.countryCode === "MEX") ? "MEX" : countries[0]?.countryCode)
  );
  const [deliveryMethodsList, setDeliveryMethodsList] = useState<QuoteOption[]>([]);
  const [deliveryMethod, setDeliveryMethod] = useState<QuoteSelectedValue["deliveryMethod"]>(
    value?.deliveryMethod || "D2B"
  );
  const [sendTo, setSendTo] = useState<string | null>(value?.sendTo || null);
  const [channel, setChannel] = useState<QuoteChannel | null>(null);
  const [balanceNotValid, setBalanceNotValid] = useState(false);
  const [showCouponInputManual, setShowCouponInputManual] = useState<boolean>(true);
  const [couponManualValue, setCouponManualValue] = useState<string>("");
  const couponsSelector = useSelector(CouponsSelector);

  const person = personData?.personVeriff;
  const kycLevel2 = person?.kycLevelStatus?.find((kyc) => kyc.level === 2);

  const velocityErrorsToShow = useMemo(
    () => codesMessages.filter((item) => !["VL005", "VL008"].includes(item.code)),
    [codesMessages]
  );
  const amountPerTransactionLimit = useMemo(
    () => codesMessages.find((item) => item.code === "VL005" || item.code === "VL008"),
    [codesMessages]
  );
  const amountLimit = useMemo(
    () => (amountPerTransactionLimit ? Number(amountPerTransactionLimit.value) : null),
    [amountPerTransactionLimit]
  );
  const maxExceeded = Boolean(
    (maxAmount && Number(amount) > maxAmount) || (amountLimit && Number(amount) > amountLimit)
  );
  const minNotReached = Boolean(minAmount && Number(amount) < minAmount);
  const amountNotValid = Boolean(value?.amount) && (maxExceeded || minNotReached);

  const proofOfLifeLimitReached = useMemo(
    () =>
      (Number(amount) > 999 || codesMessages.some((item) => item.code === "VL018")) &&
      (!kycLevel2?.status || kycLevel2.status !== KYC_APPROVED),
    [codesMessages, kycLevel2?.status, amount]
  );

  const showChannelSwitch = useMemo(
    () =>
      bestFxLessFeeFeature &&
      currentDeliveryMethodQuote &&
      currency &&
      Number(amount || "0") >= 400 &&
      currentPaymentDestination?.payerQuote &&
      bestFXLessFeePayers.test(currentPaymentDestination.payerQuote),
    [
      currentDeliveryMethodQuote,
      currency,
      amount,
      currentPaymentDestination?.payerQuote,
      currentPaymentMethod,
      bestFxLessFeeFeature,
    ]
  );

  const cantSubmit =
    !currentQuotation ||
    !currentDeliveryMethodQuote ||
    !countryDestination ||
    !currentPaymentDestination ||
    !allowedCountries.includes(countryDestination) ||
    amountNotValid ||
    (!isEmpty(codesMessages) && showVelocityLimit) ||
    !paymentMethodSelected ||
    !currentPaymentMethod ||
    !beneficiarySelected ||
    (Number(amount) > 999 && personData?.personVeriff?.status !== "approved");

  const messagesVelocity: CodeKeys = useMemo(
    () => ({
      VL001: tModals("velocityLimits.messagetxnDaily"),
      VL002: tModals("velocityLimits.messageTxnMonthly"),
      VL003: tModals("velocityLimits.messageAmountDaily"),
      VL004: tModals("velocityLimits.messageAmountMonthly"),
      VL005: tModals("velocityLimits.messageAmount", {
        amount: formatNumber(Number(codesMessages?.find((item) => item.code === "VL005")?.value || "0")),
      }),
      VL007: tModals("velocityLimits.messageMinAmountDestination"),
      VL008: tModals("velocityLimits.messageAmount", {
        amount: formatNumber(Number(codesMessages?.find((item) => item.code === "VL008")?.value || "0")),
      }),
      VL009: tModals("velocityLimits.messageAmountDestinationDaily", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL009")?.value || "0")),
      }),
      VL010: tModals("velocityLimits.messageAmountDestinationMonthly"),
      VL013: tModals("velocityLimits.messageCountrySubdivisionDaily", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL013")?.value || "0")),
      }),
      VL014: tModals("velocityLimits.messageCountrySubdivisionMonthly", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL014")?.value || "0")),
      }),
      VL015: tModals("velocityLimits.messageCountrySubdivisionDaily", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL015")?.value || "0")),
      }),
      VL016: tModals("velocityLimits.messageCountrySubdivisionMonthly", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL016")?.value || "0")),
      }),
      VL017: tModals("velocityLimits.messageBasicKYCLimitDaily", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL017")?.value || "0")),
      }),
      VL018: tModals("velocityLimits.messageBasicKYCLimitMonthly", {
        limit: formatNumber(Number(codesMessages?.find((item) => item.code === "VL018")?.value || "0")),
      }),
      VL019: tModals("velocityLimits.messageBasicKYCDeclined"),
      VB001: tModals("velocityLimits.messageNegativeBalanceQuoter"),
    }),
    [codesMessages, lang]
  );

  const couponDiscountIsApplied = couponToApply || currentQuoteDiscounted;

  const totalCost = useMemo(
    () =>
      calculateTotalToShow({
        currentTotalCost:
          couponDiscountIsApplied && currentPaymentMethod?.discount
            ? Number(currentPaymentMethod.totalCost) + Number(currentPaymentMethod.discount)
            : currentPaymentMethod?.totalCost,
        currentFee: value?.currentQuote?.fee,
        currentQuoteDiscounted,
        discount: currentQuoteDiscounted?.discount,
        quoteDiscount: !couponDiscountIsApplied ? currentPaymentMethod?.discount : null,
      }),
    [
      currentPaymentMethod?.totalCost,
      currentPaymentMethod?.discount,
      value,
      value?.currentQuote?.fee,
      currentQuoteDiscounted,
    ]
  );
  const { totalBeforePaymentMethodDiscount, totalDiscounts } = useMemo(
    () => calculateTotalBeforeDiscount(totalCost, currentPaymentMethod?.totalCost, currentPaymentMethod?.discount),
    [totalCost, currentPaymentMethod?.discount, currentPaymentMethod?.totalCost]
  );

  async function handleDeliveryMethod(optionSelected: SelectOption) {
    if (optionSelected?.value !== deliveryMethod) {
      setSendTo(null);
      setChannel(null);
      setDeliveryMethod(optionSelected.value as QuoteSelectedValue["deliveryMethod"]);
    }
  }

  async function handleCurrencyChange(exchangeRate: ExchangeRate) {
    setSendTo(null);
    dispatch(BeneficiarySlice.actions.setBeneficiarySelected(undefined));
    setCurrency(exchangeRate?.currency);
    setCountry(exchangeRate?.countryCode);
  }

  function handleBankInstitution(optionSelected: SelectOption) {
    setSendTo(optionSelected.value);
  }

  function handleRecipientChange(recipient?: BeneficiaryRecipient | null) {
    if (deliveryMethod === "D2B" && recipient?.accountSelected?.destinationId) {
      dispatch(BeneficiarySlice.actions.setBeneficiarySelected(recipient));
      setSendTo(recipient.accountSelected.destinationId);
    } else if (deliveryMethod === "CPU") {
      dispatch(BeneficiarySlice.actions.setBeneficiarySelected(recipient));
    } else if (!recipient) {
      dispatch(BeneficiarySlice.actions.resetState());
      setSendTo(null);
    }
  }

  const setValues = () => {
    if (value?.amount) {
      setAmount(value.amount);
      setAmountInputValue(value.amount);
    }
    value?.currency && setCurrency(value.currency);
    value?.country && setCountry(value.country);
    getDeliveryMethod();
    setSendTo(value?.sendTo || null);
  };

  const resetValues = () => {
    setAmount(500);
    setCurrency(countries[1]?.currency);
    setCountry(countries[1]?.countryCode);
    setDeliveryMethod("D2B");
    getDeliveryMethod();
    setSendTo(null);
  };

  const triggerOnChange = useDebounce(() => {
    const newValue = {
      amount: Number(amount || "0"),
      currency,
      country,
      deliveryMethod,
      sendTo,
      paymentMethodSource: paymentMethodSelected?.paymentSource,
      channel,
    };
    onChange && onChange(newValue, cantSubmit);
  }, 500);

  useEffect(() => {
    if (
      paymentMethodSelected &&
      sendTo &&
      !sendToOptionsSelect.find((sendToOption) => sendToOption.value === sendTo) &&
      location.state?.previous !== "createRecipient"
    ) {
      dispatch(BeneficiarySlice.actions.resetState());
      setSendTo(null);
    }
  }, [paymentMethodSelected, sendToOptionsSelect]);

  useEffect(() => {
    triggerOnChange();
  }, [
    amount,
    currency,
    country,
    deliveryMethod,
    sendTo,
    paymentMethodSelected?.id,
    paymentMethodSelected?.paymentSource,
    channel,
  ]);

  useEffect(() => {
    if (
      !isEmpty(value) &&
      (value?.amount?.toString() !== amount?.toString() ||
        value?.currency !== currency ||
        value?.sendTo !== sendTo ||
        value?.deliveryMethod !== deliveryMethod ||
        value?.country !== country)
    ) {
      setValues();
    }
  }, [value?.amount, value?.currency, value?.country, value?.sendTo, value?.deliveryMethod]);

  useEffect(() => {
    if (lang) {
      getDeliveryMethod();
    }
  }, [CountrieWhithoutD2B, CountrieWhithoutCP, country, lang]);

  useEffect(() => {
    if (!isEmpty(countries) && (!country || !countries.find((c) => c.countryCode === country))) {
      setCurrency(countries[0]?.currency);
      setCountry(countries[0]?.countryCode);
    }
  }, [countries]);

  useEffect(() => {
    try {
      const paymentMethod = paymentMethodSelected;
      const paymentMethodIsPlaidAccount = paymentMethod?.paymentSource === "Sendola Plaid";
      const paymentMethodIsSendolaPay = paymentMethod?.paymentSource === "Sendola Card";
      if (
        paymentMethod &&
        currentPaymentMethod &&
        (paymentMethodIsPlaidAccount || paymentMethodIsSendolaPay) &&
        !amountNotValid
      ) {
        const plaidBalanceIsLessThanTotal =
          paymentMethodIsPlaidAccount && (paymentMethod as PlaidAccount).available < totalCost;

        const sendolaBalanceIsLessThanTotal =
          paymentMethodIsSendolaPay && (paymentMethod as PaymentMethod).availableBalance < totalCost;

        if (plaidBalanceIsLessThanTotal || sendolaBalanceIsLessThanTotal) {
          if (!codesMessages.find((error) => error.code === "VB001")) {
            codesMessages?.push({
              code: "VB001",
              value: (
                (paymentMethod as PlaidAccount).available || (paymentMethod as PaymentMethod).availableBalance
              ).toString(),
              msg: tModals("velocityLimits.messageNegativeBalance"),
            });
            setBalanceNotValid(true);
          }
        } else {
          setBalanceNotValid(false);
          const idx = codesMessages.findIndex((error) => error.code === "VB001");
          if (idx !== -1) {
            codesMessages?.splice(idx, 1);
          }
        }
      } else {
        setBalanceNotValid(false);
        const idx = codesMessages.findIndex((error) => error.code === "VB001");
        if (idx !== -1) {
          codesMessages?.splice(idx, 1);
        }
      }
    } catch (error) {
      setBalanceNotValid(false);
      const idx = codesMessages.findIndex((error) => error.code === "VB001");
      if (idx !== -1) {
        codesMessages?.splice(idx, 1);
      }
    }
  }, [paymentMethodSelected, currentPaymentMethod, amountNotValid]);

  const getDeliveryMethod = () => {
    if (country) {
      if (CountrieWhithoutCP.includes(country as string) || personData?.personVeriff?.countrySubdivision === "PR") {
        const delivery = [deliveryMethodsSelect[0]];
        setDeliveryMethodsList(delivery);
        setDeliveryMethod("D2B");
      } else if (CountrieWhithoutD2B.includes(country as string)) {
        const delivery = [deliveryMethodsSelect[1]];
        setDeliveryMethodsList(delivery);
        setDeliveryMethod("CPU");
      } else {
        setDeliveryMethodsList(deliveryMethodsSelect);
      }
    }
  };

  useEffect(() => {
    if (!showChannelSwitch) {
      setChannel(null);
    }
  }, [showChannelSwitch]);

  return (
    <QuoterCardWrapper>
      {showVelocityLimit && codesMessages && !isEmpty(velocityErrorsToShow) && (
        <FlexContainer direction="column" gap="16px" mw="26.5625rem" flex="1 1 auto" p="5px 16px">
          <ErrorVelocityLimitsStyled>
            <IconVelocityLimits>
              <Icon icon="alertTriangle" color="error" size="large" />
            </IconVelocityLimits>
            <TextVelocityLimits>
              {velocityErrorsToShow[0] && (
                <label>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: messagesVelocity[velocityErrorsToShow[0]?.code] || t("global.unknownError"),
                    }}
                  />
                </label>
              )}
            </TextVelocityLimits>
          </ErrorVelocityLimitsStyled>
        </FlexContainer>
      )}
      <FlexContainer direction="column" gap="16px" mw="26.5625rem" flex="1 1 auto" p="5px 16px 24px 16px">
        <RecipientsSelect
          country={country}
          deliveryMethod={deliveryMethod}
          sendTo={sendTo}
          sendToOptions={sendToOptionsSelect}
          onChange={handleRecipientChange}
        />
        <FlexContainer alignItems="center">
          <MoneyInputLight
            label={tSendMoney("Send")}
            labelSuperScript="1"
            labelInfo={tSendMoney("KnowSendingLimits")}
            amount={amountInputValue.toString()}
            data={SenderExchangeRates}
            errorLabel={
              maxExceeded
                ? tSendMoney("errors.maxAmount", {
                    maxAmount: formatNumber(maxAmount || 0),
                  })
                : minNotReached
                ? tSendMoney("errors.minAmount", {
                    minAmount: formatNumber(minAmount || 0),
                  })
                : undefined
            }
            onLabelInfoClick={() => {
              showLegalNotesModal({
                modalType: "legalInfoModal",
              });
            }}
            handleInputChange={(e) => {
              setAmountInputValue(e.target.value);
            }}
            handleInputBlur={() => {
              setAmount(amountInputValue);
            }}
            maxLength={6}
          />
        </FlexContainer>
        {amountNotValid && !proofOfLifeLimitReached && (
          <ErrorVelocityLimitsStyled>
            <IconVelocityLimits>
              <Icon icon="alertTriangle" color="error" size="large" />
            </IconVelocityLimits>
            <TextVelocityLimits>
              {maxExceeded ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: tSendMoney("errors.maxAmount", {
                      maxAmount: formatNumber(amountLimit ? amountLimit : maxAmount || 0),
                    }),
                  }}
                />
              ) : minNotReached ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: tSendMoney("errors.minAmount", {
                      minAmount: formatNumber(minAmount || 0),
                    }),
                  }}
                />
              ) : undefined}
            </TextVelocityLimits>
          </ErrorVelocityLimitsStyled>
        )}
        <hr />
        <FlexContainer>
          <MoneyInputLight
            label={tSendMoney("Recipient")}
            currency={currency}
            country={country}
            amount={value?.currentQuote?.amountToReceive?.toString() || "0"}
            data={countries}
            handleInputChange={() => false}
            handleCurrencyChange={handleCurrencyChange}
            disabled
            isRecipientInput
          ></MoneyInputLight>
        </FlexContainer>
        {proofOfLifeLimitReached && (
          <ErrorVelocityLimitsStyled>
            <IconVelocityLimits>
              <Icon icon="alertTriangle" color="error" size="large" />
            </IconVelocityLimits>
            <TextVelocityLimits>
              {proofOfLifeNeeded ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: tModals("velocityLimits.messageKYC1Resubmission", {
                      href: getUrl(PlatformAppURL, { proofOfLife: true }),
                    }),
                  }}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: tModals("velocityLimits.messageKYC1Limit"),
                  }}
                />
              )}
            </TextVelocityLimits>
          </ErrorVelocityLimitsStyled>
        )}
        {value?.currentQuote?.fx && (
          <FlexContainer direction="column">
            <FlexContainer alignItems="baseline" justify="space-between" p="2px 8px 4px 8px" as={ExchangeRateContainer}>
              <Text size={0.5} lineHeight="21px" align="left" weight={600} margin={0}>
                {tSendMoney("ExchangeRate")}
                <Superscript note="4" />:
              </Text>
              <Text size={0.5} lineHeight="21px" align="right" weight={600} margin={0}>
                $1.00 USD = ${formatNumber(value.currentQuote.fx, { minimumFractionDigits: 2 })} {currency}
              </Text>
            </FlexContainer>
            <Link
              href="#"
              size={0.1}
              align="left"
              color="grey"
              underline
              onClick={() => {
                showLegalNotesModal({
                  modalType: "legalInfoModal",
                });
              }}
            >
              {tSendMoney("ExchangeRateDisclosure")}
            </Link>
          </FlexContainer>
        )}
        <DeliveryMethodSelect options={deliveryMethodsList} value={deliveryMethod} onChange={handleDeliveryMethod} />
        {deliveryMethod === "CPU" && (
          <SearchSelect
            labelProps={{ color: "solid_light", size: 0 }}
            label={tSendMoney("PickupLocation")}
            options={sendToOptionsSelect}
            name="country"
            onChange={handleBankInstitution}
            value={sendTo ? sendToOptionsSelect.find((c) => c.value === sendTo) : null}
            customStyles={quoterSelectStyles}
            showAvatar
          />
        )}
        <PaymentMethodSelect discount={!couponDiscountIsApplied ? currentPaymentMethod?.discount : null} />
        {showChannelSwitch && currency && (
          <ChannelSwitch
            lessFeesData={{
              fx: quotesPerChannel?.[QuoteChannel.lessFee]?.currentDeliveryMethodQuote?.fx || 0,
              fee: quotesPerChannel?.[QuoteChannel.lessFee]?.currentDeliveryMethodQuote?.fee || 0,
              currency,
            }}
            bestFXData={{
              fx: quotesPerChannel?.[QuoteChannel.bestFx]?.currentDeliveryMethodQuote?.fx || 0,
              fee: quotesPerChannel?.[QuoteChannel.bestFx]?.currentDeliveryMethodQuote?.fee || 0,
              currency,
            }}
            onChange={setChannel}
          />
        )}
        {couponToApply && currentQuoteDiscounted && (
          <CouponBar
            couponCode={couponToApply.code || "REF-FRIENDS"}
            showCouponInputManual={setShowCouponInputManual}
            showRemoveCoupon={manualCouponFeature}
          />
        )}
        {manualCouponFeature && showCouponInputManual && (
          <CouponInput
            hasError={couponsSelector.error?.[0]}
            msgError={
              lang.language === "es"
                ? couponsSelector.error?.[1]
                : lang.language === "en"
                ? couponsSelector.error?.[2]
                : undefined
            }
            errorLabel={tSendMoney("Coupons.errorCoupon")}
            warningLabel={tSendMoney("Coupons.warningCoupon")}
            handleInputValue={setCouponManualValue}
            handleSubmit={() => {
              verifyQuoteCoupon && verifyQuoteCoupon(couponManualValue);
            }}
          />
        )}
        <FlexContainer direction="column">
          <FlexContainer alignItems="baseline" justify="space-between">
            <Text size={0.5} lineHeight="21px" weight={500} color="grey" margin={0}>
              {tSendMoney("TransferFees")}
              <Superscript note="5" />:
            </Text>
            {currentQuoteDiscounted && currentQuoteDiscounted.fee !== value?.currentQuote?.fee ? (
              <FlexContainer w="max-content" alignItems="center" gap="6px">
                <Text size={0.5} lineHeight="21px" weight={500} color="solid_2" textDecoration="line-through">
                  $
                  {formatNumber(value?.currentQuote?.fee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
                <Text size={0.5} lineHeight="21px" weight={500}>
                  $
                  {formatNumber(currentQuoteDiscounted?.fee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
              </FlexContainer>
            ) : (
              <Text size={0.5} lineHeight="21px" weight={500}>
                $
                {formatNumber(value?.currentQuote?.fee || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            )}
          </FlexContainer>
          {currentPaymentMethod && (
            <FlexContainer alignItems="baseline" justify="space-between">
              <Text size={0.5} lineHeight="21px" weight={500} color="grey" margin={0}>
                {tSendMoney("PaymentMethodFee")}
                <Superscript note="6" />:
              </Text>
              {currentQuoteDiscounted &&
              currentQuoteDiscounted.paymentMethodFee !== currentPaymentMethod.paymentMethodFee ? (
                <FlexContainer w="max-content" alignItems="center" gap="6px">
                  <Text size={0.5} lineHeight="21px" weight={500} color="solid_2" textDecoration="line-through">
                    $
                    {formatNumber(currentPaymentMethod.paymentMethodFee || 0, {
                      minimumFractionDigits: 2,
                    })}{" "}
                    USD
                  </Text>
                  <Text size={0.5} lineHeight="21px" weight={500}>
                    $
                    {formatNumber(currentQuoteDiscounted.paymentMethodFee || 0, {
                      minimumFractionDigits: 2,
                    })}{" "}
                    USD
                  </Text>
                </FlexContainer>
              ) : (
                <Text size={0.5} lineHeight="21px" weight={500} margin={0}>
                  $
                  {formatNumber(currentPaymentMethod.paymentMethodFee || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
              )}
            </FlexContainer>
          )}
          {!couponDiscountIsApplied && currentPaymentMethod?.discount && (
            <FlexContainer alignItems="baseline" justify="space-between">
              <Text size={0.5} lineHeight="21px" weight={500} color="grey" margin={0} gradient>
                {tSendMoney("ZelleDiscount")}:
              </Text>
              <Text size={0.5} lineHeight="21px" weight={500} margin={0} gradient>
                - $
                {formatNumber(currentPaymentMethod.discount || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                USD
              </Text>
            </FlexContainer>
          )}
        </FlexContainer>
        <hr style={{ margin: "-7px 0" }} />
        <FlexContainer justify="space-between">
          <Text size={0.5} lineHeight="21px" weight={700} color="grey" margin={0}>
            {tSendMoney("TotalPayment")}:
          </Text>
          {totalCost > 0 && currentQuoteDiscounted?.discount && totalCost !== currentPaymentMethod?.totalCost ? (
            <FlexContainer w="auto" direction="column" alignItems="end">
              <FlexContainer w="max-content" alignItems="center" gap="6px">
                <Text size={0.5} lineHeight="21px" weight={500} color="neutral600" textDecoration="line-through">
                  $
                  {formatNumber(currentPaymentMethod?.totalCost || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                </Text>
                <Text size={0.5} lineHeight="21px" weight={500} gradient>
                  $
                  {formatNumber(totalCost || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
              </FlexContainer>
              <Text as="span" size={0.5} weight={600} lineHeight="21px">
                {tSendMoney("Coupons.save", {
                  amount: formatNumber(currentQuoteDiscounted?.discount || 0, {
                    minimumFractionDigits: 2,
                  }),
                })}
              </Text>
            </FlexContainer>
          ) : totalCost > 0 &&
            !couponDiscountIsApplied &&
            currentPaymentMethod?.discount &&
            totalBeforePaymentMethodDiscount !== totalCost ? (
            <FlexContainer w="auto" direction="column" alignItems="end">
              <FlexContainer w="max-content" alignItems="center" gap="6px">
                <Text size={0.5} lineHeight="21px" weight={500} color="neutral600" textDecoration="line-through">
                  $
                  {formatNumber(totalBeforePaymentMethodDiscount || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                </Text>
                <Text size={0.5} lineHeight="21px" weight={500} gradient>
                  $
                  {formatNumber(totalCost || 0, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </Text>
              </FlexContainer>
              <Text as="span" size={0.5} weight={600} lineHeight="21px">
                {tSendMoney("Coupons.save", {
                  amount: formatNumber(totalDiscounts || 0, {
                    minimumFractionDigits: 2,
                  }),
                })}
              </Text>
            </FlexContainer>
          ) : totalCost > 0 ? (
            <Text size={0.5} lineHeight="21px" weight={500} color="magenta" margin={0}>
              $
              {formatNumber(totalCost, {
                minimumFractionDigits: 2,
              })}{" "}
              USD
            </Text>
          ) : (
            <Text size={0.5} lineHeight="21px" weight={500} color="magenta" margin={0}>
              --
            </Text>
          )}
        </FlexContainer>
        {currentPaymentMethod?.paymentOrigin === "PayPal" && (
          <Text color="success">
            <span
              dangerouslySetInnerHTML={{
                __html: tSendMoney("paymentMethods.paypal.disclaimer"),
              }}
            ></span>
          </Text>
        )}
        {balanceNotValid && (
          <FlexContainer gap="5px" flex="1 1 auto" alignItems="center">
            <IconVelocityLimits>
              <Icon icon="alertTriangle" color="error" size="medium" />
            </IconVelocityLimits>
            <Text size={0.5} color="error" align="left" weight={500} margin={0}>
              {messagesVelocity["VB001"]}
            </Text>
          </FlexContainer>
        )}
        <FlexContainer gap="4px">
          <IconFont name="info" color="gray" />
          <Text align="left" color="grey">
            {tSendMoney("QuoteDisclosure")}&nbsp;
            <Link
              href="#"
              align="left"
              color="grey"
              size="default"
              underline
              onClick={() => {
                showLegalNotesModal({
                  modalType: "legalInfoModal",
                });
              }}
            >
              {tSendMoney("ViewLegalNotes")}
            </Link>
          </Text>
        </FlexContainer>
        <FlexContainer>
          <Button
            variant="primary"
            sizeButton="full"
            text={tSendMoney("Next")}
            sizeText="medium"
            iconButton="arrowRight"
            colorIcon="white"
            sizeIcon="xlarge"
            padding="8px"
            disabled={cantSubmit}
            onClick={handleSubmit}
          />
        </FlexContainer>
      </FlexContainer>
      {legalNotesModal}
    </QuoterCardWrapper>
  );
};
