import { useTranslation } from "react-i18next";
import Text from "../../../../Components/Text";
import { FormInput } from "../../../../Components/Input";
import { allowedCompoundNames, allowedOneWord } from "../../../../Constants/Regexp";
import { DateInput } from "../../../../Components/DateInput/DateInput.component";
import { Controller, useFormContext } from "react-hook-form";

export const ProfileForm = () => {
  const { t } = useTranslation("global");
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Text size={2} lineHeight="24px" weight={600} align="left" margin="0 0 25px 2px">
        {t("Beneficiaries.ProfileTitle")}
      </Text>
      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.FirstName")}
        label="firstName"
        rules={{
          required: t("Forms.required"),
          pattern: {
            value: allowedOneWord,
            message: t("Forms.oneWordAlphabet"),
          },
          validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
        }}
        mb="25px"
        isError={Boolean(errors?.["firstName"])}
      />
      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.MiddleName")}
        label="middleName"
        rules={{
          pattern: {
            value: allowedOneWord,
            message: t("Forms.oneWordAlphabet"),
          },
        }}
        mb="25px"
      />
      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.LastName")}
        label="lastName"
        rules={{
          required: t("Forms.required"),
          pattern: {
            value: allowedCompoundNames,
            message: t("Forms.inputAlphabets"),
          },
          validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
        }}
        mb="25px"
        isError={Boolean(errors?.["lastName"])}
      />
      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.Alias")}
        label="alias"
        rules={{}}
        infoText={t("Beneficiaries.CreateBeneficiary.Form.AliasHint")}
        mb="25px"
      />

      <Controller
        control={control}
        name="dateOfBirth"
        rules={{ required: t("Forms.required") }}
        render={({ field: { value, onChange } }) => (
          <DateInput
            label={t("Beneficiaries.CreateBeneficiary.Form.DateOfBirth")}
            name="dateOfBirth"
            value={value}
            errors={errors}
            onChange={onChange}
          />
        )}
      />
    </>
  );
};
