import styled, { css } from "styled-components";
import { ButtonSizesKey, handleButtonLineHeight, handleSizeButton, handleText } from "../../Utilities/SizesButtons";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { ifProp, prop } from "styled-tools";
import { ButtonProps } from "../../Models/buttonInterface";
import {
  buttonBackgroundColor,
  buttonBorderColor,
  buttonColor,
  buttonDisabledBackground,
  buttonDisabledColor,
  buttonDisabledBorderColor,
  buttonOutlineDisabledColor,
  buttonOutlineDisabledBackgroundColor,
} from "../../Contexts/ThemeProvider/Button";

const GeneralStyles = `
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50px;
  &:hover {
    opacity: 0.9;
  }
`;

export const ButtonDefault = styled.button<
  {
    $type?: string;
    $sizeButton?: ButtonSizesKey;
  } & Partial<ButtonProps>
>`
  ${GeneralStyles}
  background: ${buttonBackgroundColor};
  color: ${buttonColor};
  ${(props) =>
    !["gradient", "transparent"].includes(props.variant || "")
      ? css`
          border: 1px solid ${buttonBorderColor};
        `
      : css`
          border: 0px;
        `};
  font-family: "Poppins";
  width: ${(props) => (props.$sizeButton ? `${handleSizeButton(props.$sizeButton)}` : `auto`)};
  font-size: ${(props) => (props.sizeText ? `${handleText(props.sizeText)}px` : "20px")};
  font-weight: ${prop("weight", "700")};
  line-height: ${(props) =>
    props.lineHeight || (props.sizeText ? `${handleButtonLineHeight(props.sizeText)}px` : "20px")};
  padding: ${(props) =>
    props.padding || (!props.$sizeButton || props.$sizeButton === "small" ? "8px 32px" : "16px 32px")};
  ${ifProp(
    "withShadow",
    css`
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    `
  )}

  &:disabled {
    background: ${ifProp(
      {
        variant: "outline",
      },
      buttonOutlineDisabledBackgroundColor,
      buttonDisabledBackground
    )};
    color: ${ifProp(
      {
        variant: "outline",
      },
      buttonOutlineDisabledColor,
      buttonDisabledColor
    )};
    border: 1px solid ${buttonDisabledBorderColor};
  }

  ${mediaQuery[Screen.xs]} {
    text-align: center;
  }
`;

export const TextContainer = styled.div<{
  $iconButton?: string;
  $iconButtonLeft?: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    width: ${(props) => (!props.$iconButton && !props.$iconButtonLeft ? "100%" : "")};
    text-align: ${(props) => (!props.$iconButton ? "center" : "right")};
  }
`;
