import { QuoteRepository } from "../domain/QuoteRepository";
import { QuoteChannel } from "../domain/QuoteSelected";

export const getQuoteWithFees =
  (quoteRepository: QuoteRepository) =>
  async (
    countryCode: string,
    currencyCode: string,
    amount: number,
    payerQuote?: string,
    channel?: QuoteChannel | null
  ) =>
    quoteRepository.getQuoteWithFees(countryCode, currencyCode, amount, payerQuote, channel);

export const getQuoteWithFeesAndTiers =
  (quoteRepository: QuoteRepository) =>
  async (
    countryCode: string,
    currencyCode: string,
    amount: number,
    payerQuote?: string,
    channel?: QuoteChannel | null
  ) =>
    quoteRepository.getQuoteWithFeesAndTiers(countryCode, currencyCode, amount, payerQuote, channel);
