import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";
import { textComponentSolidlight } from "../../Contexts/ThemeProvider/Texts";

export const BeneficiariesCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: left;
  align-items: baseline;
  gap: 16px;

  ${mediaQuery[Screen.lg]} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaQuery[Screen.mobile]} {
    display: flex;
    margin: 0;
    flex-direction: column;
  }
`;

export const CountryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 2px ${theme("Primary_18")} solid;
`;

export const ContainerTitleAccount = styled.div`
  width: 100%;
  border-left: 8px solid ${textComponentSolidlight};
`;
