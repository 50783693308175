import styled from "styled-components";
import { theme } from "styled-tools";

export const ToastContainer = styled.div<{
  $status: string;
  hidden: boolean;
}>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: row;
  height: 70px;
  width: 100%;
  background-color: ${theme("White")};
  top: 10px;
  z-index: 6;
  border-left: 4px solid;
  border-color: ${(props) =>
    props.$status === "success"
      ? theme("Primary_14")
      : props.$status === "warning"
      ? theme("Primary_15")
      : theme("Primary_16")};
  position: absolute;
  align-items: center;
  max-width: 450px;
  opacity: ${(props) => (props.hidden ? "0" : "1")};
  transition: opacity 0.2s, display 1s allow-discrete;

  h1 {
    margin: 0 0 0 12px;
    width: 80%;
  }

  div:first-of-type {
    margin: 0 0 0 20px;
  }

  div:last-of-type {
    margin: 0 30px;
  }
`;
