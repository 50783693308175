export enum Currency {
  MXN = "MXN",
  GTQ = "GTQ",
  USD = "USD",
  JMD = "JMD",
  INR = "INR",
  VND = "VND",
  PHP = "PHP",
  BRL = "BRL",
  ARS = "ARS",
  BOB = "BOB",
  CLP = "CLP",
  COP = "COP",
  PEN = "PEN",
  UYU = "UYU",
  SLV = "SLV",
  HNL = "HNL",
  ECU = "ECU",
  CAD = "CAD",
  NIO = "NIO",
}
