import styled from "styled-components";

export const SwitchWrapper = styled.div<{ isOn: boolean; colorOn: string; colorOff: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 44px;
  height: 24px;
  border-radius: 15px;
  background-color: ${({ isOn, colorOff, colorOn }) => (isOn ? colorOn : colorOff)};
  transition: background-color 0.3s ease;
  padding: 2px;
  cursor: pointer;
`;

export const SwitchBall = styled.div<{ isOn: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  transform: ${({ isOn }) => (isOn ? "translateX(calc(44px - 20px - 4px))" : "translateX(0)")};
  transition: transform 0.3s ease;
`;
