import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

import mediaQuery, { Screen } from "../../../../Utilities/MediaQuery";

export const PDFViewerModalStyled = styled.div<{
  isOpen?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  margin-top: 20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100vh;
  overflow-width: 20px;
  overflow-y: scroll;
  touch-action: pan-y;
  -ms-touch-action: pan-y;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  outline: 0;
  display: ${ifProp("isOpen", "block", "none")};

  ${mediaQuery[Screen.mobile]} {
    margin-top: 0px;
  }
`;

export const PDFViewerModalOverlay = styled.div<{
  isOpenModal?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: ${theme("Background_02")};
  opacity: 0.8;
  display: ${ifProp("isOpenModal", "block", "none")};
  transition: opacity 0.15s linear;
`;

export const PDFViewerHeader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(${theme("RGBA_04")}, 0.8);
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: calc(100% - 48px);
  z-index: 1060;

  .close {
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }

  ${mediaQuery[Screen.mobile]} {
    width: calc(100% - 28px);
  }
`;

export const PdfHeaderRight = styled.div`
  margin-left: auto;
  display: flex;
`;

export const PdfHeaderDownload = styled.div`
  button {
    margin: 0px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const PdfHeaderClose = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const PDFViewerFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(${theme("RGBA_04")}, 0.8);
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: calc(100% - 48px);
  z-index: 9;
`;

export const PDFViewerModalContent = styled.div`
  position: relative;
  margin-left: auto;
  margin-rigth: auto;
  padding-top: 3rem;

  ${mediaQuery[Screen.mobile]} {
    padding-top: 2rem;
  }
`;
