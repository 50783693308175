import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";

interface TimerProps {
  target?: Date;
}

export const useTimer = (props?: TimerProps) => {
  const [targetDate, setTargetDate] = useState<Date | undefined>(props?.target);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (targetDate) {
      const calculateTimeLeft = () => {
        const now = new Date();
        const thirtyMinutesLater = new Date(targetDate.getTime() + 30 * 60 * 1000);
        const diffInSeconds = differenceInSeconds(thirtyMinutesLater, now);
        setTimeLeft(diffInSeconds);
      };

      calculateTimeLeft();

      const timer = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [targetDate]);

  const minutes = Math.floor(timeLeft / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (timeLeft % 60).toString().padStart(2, "0");

  return { minutes, seconds, setTargetDate };
};
