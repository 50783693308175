import styled from "styled-components";
import { BottomModalWrapper } from "../Modal/Modal.styled";
import { theme } from "styled-tools";

export const CouponsModalWrapper = styled(BottomModalWrapper)`
  background: ${theme("Background_14")};
  width: 100%;
  padding: 0;
  min-height: 160px;
  height: fit-content;
  max-height: 80%;
  z-index: 1001;
`;

export const BorderGradient = styled.div`
  box-sizing: border-box;
  background: ${theme("Gradient_Rewards")};
  width: 100%;
  height: 100%;
  border-radius: 24px 24px 0 0;
  display: flex;
  justify-content: center;
  padding: 1.5px 0 0 0;

  > div:nth-child(1) {
    box-sizing: border-box;
    padding: 16px 24px;
    background: ${theme("Background_14")};
    width: 100%;
    height: 100%;
    border-radius: 24px 24px 0 0;
  }
`;

export const CouponQty = styled.div`
  box-sizing: border-box;
  background: ${theme("Gradient_Rewards")};
  border-radius: 50%;
  width: fit-content;
  min-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: ${theme("Background_01")};
  text-align: center;
  padding: 4px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
