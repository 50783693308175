import { createAsyncThunk } from "@reduxjs/toolkit";
import { createConfigApiApp } from "../../Services/ApiApp";
import { GET_LANGUAGE_SETTINGS } from "../../Constants/Language";
import { ApiRequestError } from "../../Types/ApiRequestError";
import { LanguageResponse } from "../../Models/Language";

const client = createConfigApiApp();

export const GetLanguageSettingAction = createAsyncThunk(
  GET_LANGUAGE_SETTINGS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get<LanguageResponse>(`/users/language`);
      if (!response.data.data.language) {
        response.data.data.language = "en";
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error as ApiRequestError);
    }
  }
);
