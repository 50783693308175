import styled from "styled-components";
import { paymentMethodInstructionCard } from "../../../../../Contexts/ThemeProvider/Pages";

export const InstructionCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 382px;
  padding: 1.5rem;
  border-radius: 1.33rem;
  background-color: ${paymentMethodInstructionCard};
`;

export const InstructionCardBodyContainer = styled.div`
  text-wrap: balanced;
  box-sizing: border-box;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;
