import { useContext, useEffect, useRef } from "react";
import { FlexContainer } from "../../Components/Flex/Flex.styled";
import { Container } from "../../Components/Grid";
import Text from "../../Components/Text";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { ThemeContext as theme, ThemeContext } from "../../Contexts/ThemeContext/ThemeContext";
import Layout from "../../Components/Layout";
import { useDashboard } from "../../Hooks/useDashboard";
import { useSelector } from "react-redux";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import IconFont from "../../Components/IconFont";
import { QuoteSummary } from "../../Components/QuoterCardAdvanced/QuoteSummary/QuoteSummary.component";
import Link from "../../Components/Link";
import { imgTypes } from "../../Utilities/Icons";
import Button from "../../Components/Button";
import { scrollTopWindow } from "../../Utilities/ScrollTopWindow";
import { usePaymentOrder } from "../../Hooks/usePaymentOrders";
import { usePaymentOrderPayload } from "../../Hooks/usePaymentOrderPayload";
import { PaymentOrderVerifySelector } from "../../Redux/PaymentOrder/PaymentOrderVerify.slice";
import { useSquarePaymentOrder } from "../../Hooks/useSquarePaymentOrder";
import { usePayPalPaymentOrder } from "../../Hooks/usePayPalPaymentOrder";
import { useModal } from "../../Hooks/useModal";
import { PaymentOrderCreated, PaymentOrderPayload } from "../../Modules/PaymentOrders/domain/PaymentOrderCreated";
import { useBlocker, useNavigate } from "react-router-dom";
import { PaypalButtonContainer, TransactionLoaderContainer } from "./ReviewTransaction.styled";
import TransactionLoader from "../../Components/TransactionLoader";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENTID } from "../../Constants/Payments";
import { selectorLanguage } from "../../Redux/Translate";

export const ReviewTransactionPage = () => {
  const navigate = useNavigate();

  const [t] = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const unitellerUrl = import.meta.env.VITE_UNITELLER_URL;
  const {
    theme: { images },
  } = useContext(ThemeContext);
  const { newQuoterFeature } = useDashboard();
  const { isLoading: loadingPaymentOrder, error: paymentOrderError, createPayment } = usePaymentOrder();
  const { paymentOrderPayload } = usePaymentOrderPayload();
  const { modal: paymentOrderModal, showModal: showPaymentOrderModal } = useModal();
  const { modal: zelleConfirmationModal, showModal: showZelleConfirmationModal } = useModal();
  const { modal: venmoConfirmationModal, showModal: showVenmoConfirmationModal } = useModal();

  const lang = useSelector(selectorLanguage);
  const { currentVerifyResponse } = useSelector(PaymentOrderVerifySelector).data;
  const { createSquarePayment, isLoading: LoadingSquare } = useSquarePaymentOrder();
  const {
    createPayPalPayment,
    capturePayPalPayment,
    error: paypalError,
    isLoading: loadingPayPal,
  } = usePayPalPaymentOrder();

  const {
    countryDestination,
    currentQuotation,
    currentDeliveryMethodQuote,
    currentPaymentMethod,
    currentPaymentDestination,
    paymentMethodSelected,
  } = useSelector(QuotationSelector).data;

  const paypalOrderIdRef = useRef<string>("");
  const paymentOrderIdRef = useRef<string>("");

  const loading = loadingPaymentOrder || LoadingSquare || loadingPayPal;

  const onApprove = async (paypalOrderId: string) => {
    await capturePayPalPayment(paypalOrderId);
    paypalOrderIdRef.current = "";
    navigate("/transaction-completed");
  };

  const createPaypalOrder = async (): Promise<string> => {
    if (currentPaymentMethod) {
      scrollTopWindow();

      if (paypalOrderIdRef?.current) return paypalOrderIdRef.current;

      const request = paymentOrderPayload as PaymentOrderPayload;

      let response: PaymentOrderCreated | undefined;
      if (!paymentOrderIdRef?.current) {
        response = await createPayment(request);
      }

      if (response && response?.payment?.amount && response?.id) {
        paymentOrderIdRef.current = response.id;

        const order = await createPayPalPayment(request.payment.amount, response?.id || paymentOrderIdRef.current);

        if (order?.data) {
          const splittedData = order.data.split("token=");
          const orderId = splittedData?.[1] || "";

          paypalOrderIdRef.current = orderId;
          return orderId;
        }
      }
    }

    return "";
  };

  const preparePayment = async () => {
    if (currentPaymentMethod) {
      scrollTopWindow();

      if (currentPaymentMethod?.paymentOrigin === "authorize.net") {
        return navigate('/payment-authorize-add-card');
      }

      const request = paymentOrderPayload as PaymentOrderPayload;
      const response = await createPayment(request);

      if (response) {
        if (currentVerifyResponse && currentPaymentMethod?.paymentOrigin === "Square") {
          const responseSquare = await createSquarePayment(
            currentVerifyResponse.id,
            currentPaymentMethod?.paymentMethodFee || 0
          );

          if (responseSquare) {
            window.location.href = responseSquare.payment_link.url;
          }
        }
        navigate("/transaction-completed");
      }
    }
  };

  useEffect(() => {
    paypalOrderIdRef.current = "";
    paymentOrderIdRef.current = "";
  }, [paymentOrderPayload]);

  useEffect(() => {
    const hasCouponError = paymentOrderError?.errors
      ?.filter((err) => !!err)
      ?.some((err) => {
        if (typeof err === "string" || err instanceof String) {
          return (err as string).toLowerCase().includes("the coupon is not valid");
        }
        return false;
      });

    if (hasCouponError) {
      showPaymentOrderModal({
        modalType: "error",
        errorMessage: tModals("paymentOrder.errorCoupon"),
        handleClose: () => {
          navigate("/dashboard");
        },
      });
    } else if (paymentOrderError) {
      showPaymentOrderModal({
        modalType: "error",
        errorMessage: tModals("paymentOrder.errorMessage"),
      });
    }
  }, [paymentOrderError]);

  useEffect(() => {
    if (paypalError) {
      showPaymentOrderModal({
        modalType: "error",
        errorMessage: tModals("paypal.errorMessage"),
      });
    }
  }, [paypalError]);

  useEffect(() => {
    if (!currentQuotation || !currentDeliveryMethodQuote) {
      navigate("/dashboard");
    }
  }, []);

  useBlocker(
    ({ nextLocation, currentLocation }) =>
      loading &&
      currentLocation.pathname === "/review-transaction" &&
      ["/dashboard", "/beneficiary-selection"].includes(nextLocation.pathname)
  );

  return (
    <ThemeProvider theme={theme}>
      {loading && (
        <TransactionLoaderContainer>
          <TransactionLoader
            title={t("ReviewTransaction.LoaderTitle")}
            text={t("ReviewTransaction.LoaderText")}
            description={t("ReviewTransaction.LoaderDescription")}
            image="transferLoader"
            bgImage={images["bgTransactionLoader"]}
          />
        </TransactionLoaderContainer>
      )}
      <Layout path={newQuoterFeature ? "/dashboard" : "/payments"} textLink={t("buttons.back")} loading={loading}>
        <Container maxWidth="442px">
          <FlexContainer direction="column" justify="center" p="13px 16px" gap="24px">
            <FlexContainer direction="column">
              <Text size={2} align="left" weight={600}>
                {t("ReviewTransaction.SectionTitle")}
              </Text>
              <Text size={0.5} align="left" weight={600} color="grey">
                {t("ReviewTransaction.SectionDescription")}
              </Text>
            </FlexContainer>
            <QuoteSummary />
            <FlexContainer>
              { currentPaymentMethod?.paymentOrigin === "PayPal" ? (
                <PayPalScriptProvider
                  options={{
                    clientId: PAYPAL_CLIENTID,
                    currency: "USD",
                    locale: "en_US",
                    intent: "capture",
                  }}
                >
                  <PaypalButtonContainer>
                    <PayPalButtons
                      style={{
                        disableMaxWidth: true,
                        shape: "pill",
                        tagline: false,
                      }}
                      forceReRender={[
                        lang,
                        currentQuotation,
                        currentDeliveryMethodQuote,
                        countryDestination,
                        currentPaymentMethod,
                        currentPaymentDestination,
                        paymentMethodSelected,
                      ]}
                      fundingSource="paypal"
                      createOrder={createPaypalOrder}
                      onApprove={(data) => onApprove(data.orderID)}
                    />
                  </PaypalButtonContainer>
                </PayPalScriptProvider>
              ) : (
                <Button
                  sizeButton="full"
                  variant="primary"
                  onClick={() => {
                    if (currentPaymentMethod?.paymentOrigin === "Zelle") {
                      showZelleConfirmationModal({
                        modalType: "zelleConfirmationModal",
                        handleConfirm: () => preparePayment(),
                        handleClose: () => navigate(-1),
                      });
                    } else if (currentPaymentMethod?.paymentOrigin === "Venmo") {
                      showVenmoConfirmationModal({
                        modalType: "venmoConfirmationModal",
                        handleConfirm: () => preparePayment(),
                        handleClose: () => navigate(-1),
                      });
                    } else {
                      preparePayment();
                    }
                  }}
                >
                  <FlexContainer justify="center" gap="20px">
                    <Text color="white" size={1} weight={600}>
                      {
                        ( currentPaymentMethod?.paymentOrigin === "Zelle"
                          || currentPaymentMethod?.paymentOrigin === "Venmo" ) 
                          ? t("ReviewTransaction.ActionButtonCreateOrder")
                          : t("ReviewTransaction.ActionButton")
                      }
                    </Text>
                    <IconFont name="arrow-right" color="white" size="medium" />
                  </FlexContainer>
                </Button>
              )}
            </FlexContainer>
            {currentPaymentDestination?.rail === "UNT" && (
              <FlexContainer direction="column" alignItems="center" justify="center" gap="24px">
                <img src={imgTypes["logoUniteller"]} width={83} alt="logo-header" />
                <FlexContainer justify="center" alignItems="center" gap="4px">
                  <IconFont name="circle-exclamation" size="default" color="error" />
                  <Text size="default">
                    <Link
                      href={`${unitellerUrl}`}
                      target={"_blank"}
                      rel="noopener noreferrer"
                      color="error"
                      size="default"
                      underline
                    >
                      {t("ReviewTransaction.disclaimerUniteller")}
                    </Link>
                    {t("ReviewTransaction.disclaimerUniteller2")}
                  </Text>
                </FlexContainer>
              </FlexContainer>
            )}
          </FlexContainer>
          {paymentOrderModal}
          {zelleConfirmationModal}
          {venmoConfirmationModal}
        </Container>
      </Layout>
    </ThemeProvider>
  );
};
