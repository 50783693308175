import { components, OptionProps } from "react-select";
import {
  SectionOption,
  TextOption,
  TextOptionImageAvatar,
  TextOptionNew,
} from "./SearchSelect.styled";
import { useTranslation } from "react-i18next";
import { SelectOption } from "../../Models/Select";
import { LetterAvatar } from "../Avatar/LetterAvatar.component";

export const CustomOption = ({
  children,
  ...props
}: OptionProps<SelectOption>) => {
  const [t] = useTranslation("global");

  return (
    <components.Option {...props}>
      <SectionOption>
        {props.data.showAvatar && (
          <>
            {props.data.image ? (
              <TextOptionImageAvatar src={props.data.image} />
            ) : (
              <LetterAvatar label={props.data.label} />
            )}
          </>
        )}
        <TextOption>{children}</TextOption>
        {props.data.isNew && <TextOptionNew>{t("Quote.News")}</TextOptionNew>}
      </SectionOption>
    </components.Option>
  );
};
