import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

// Own states
import { State } from "../../Redux/Store";

// Own components
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Title from "../../Components/Title";

// Own styles
import { ContentColumn } from "./HelpAndSupport.styled";
import LinksList from "../../Components/LinksList";
import { IconTypeKey } from "../../Models/IconInterface";

const HelpAndSupport = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");

  const LegalLinks: Array<{ icon: IconTypeKey; name: string; url: string }> = [
    {
      icon: "circleUser",
      name: t("helpAndSupport.links.custumer"),
      url: "/",
    },
    {
      icon: "headset",
      name: t("helpAndSupport.links.faqs"),
      url: "/",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/" textLink={t("buttons.back")}>
        <Container>
          <Row>
            <Column as={ContentColumn} span={12}>
              <Row>
                <Column span={12}>
                  <Title
                    size={5}
                    text={t("helpAndSupport.title")}
                    color="black"
                    align="left"
                    tagName="h1"
                  />
                </Column>
              </Row>
              <Row>
                <Column span={4} xs={12} md={12}>
                  <LinksList data={LegalLinks} />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default HelpAndSupport;
