import styled from "styled-components";

export const CardsListContainer = styled.div`
  margin-top: 60px;
`;

export const CardsListSelect = styled.div`
  margin-top: 16px;
  margin-bottom: 80px;
`;
