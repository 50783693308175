import styled from "styled-components";
import { theme } from "styled-tools";

export const TransactionLoaderContainer = styled.div`
  position: fixed;
  overflow: hidden;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
`;