import { PaymentOrderWithPlaidRepository } from "../domain/PaymentOrderRepository";
import {
  PlaidAccountsDelete,
  PlaidAccountsDetail,
  PlaidAccountsResponse,
  PlaidAccountsUpdateRequest,
  PlaidCreateToken,
  PlaidCreateTokenStatus,
  PlaidTokenExchangeRequest,
  PlaidTokenExchangeResponse,
} from "../domain/Plaid";

export const getAccounts =
  (repository: PaymentOrderWithPlaidRepository) =>
  async (languageCode: string): Promise<PlaidAccountsResponse> =>
    repository.getAccounts(languageCode);

export const getAccountDetails =
  (repository: PaymentOrderWithPlaidRepository) =>
  async (
    accountId: string,
    languageCode: string
  ): Promise<PlaidAccountsDetail> =>
    repository.getAccountDetails(accountId, languageCode);

export const deleteAccount =
  (repository: PaymentOrderWithPlaidRepository) =>
  async (
    accountId: string,
    languageCode: string
  ): Promise<PlaidAccountsDelete> =>
    repository.deleteAccount(accountId, languageCode);

export const createToken =
  (repository: PaymentOrderWithPlaidRepository) =>
  async (
    languageCode: string,
    body?: PlaidCreateToken
  ): Promise<PlaidCreateTokenStatus> =>
    repository.createToken(languageCode, body);

export const exchangeToken =
  (repository: PaymentOrderWithPlaidRepository) =>
  async (
    body: PlaidTokenExchangeRequest,
    languageCode: string
  ): Promise<PlaidTokenExchangeResponse> =>
    repository.exchangeToken(body, languageCode);

export const updateAccount =
  (repository: PaymentOrderWithPlaidRepository) =>
  async (
    body: PlaidAccountsUpdateRequest,
    languageCode: string
  ): Promise<PlaidCreateTokenStatus> =>
    repository.updateAccount(body, languageCode);
