import { createSlice } from "@reduxjs/toolkit";
import { PatchLanguageSettingAction } from "./PatchLanguageSettings.actions";
import { State } from "../Store";
import { PatchLanguageState } from "../../Models/Language";

const initialState: PatchLanguageState = {
  loading: false,
  error: null,
  data: null,
};

export const PatchLanguageSettingSlice = createSlice({
  name: "patchLanguageSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PatchLanguageSettingAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(PatchLanguageSettingAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(PatchLanguageSettingAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export default PatchLanguageSettingSlice.reducer;

export const PatchLanguageSettingSelector = (state: State) =>
  state.patchLanguageSettings;
