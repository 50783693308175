import styled from "styled-components";
import { theme } from "styled-tools";

import mediaQuery, { Screen } from "../../../../../Utilities/MediaQuery";

export const CheckInstructionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 520px;

  ${mediaQuery[Screen.mobile]} {
    width: 100%;
    max-width: 390px;
  }
`;
