import { createClient } from "../../../Services/ApiClient";
import { Country, CountryRepository } from "../domain/Countries";
import { Currency } from "../domain/Currency";
import {
  RecipientExchangeRates,
  SenderExchangeRates,
} from "../domain/ExchangeRates";

const client = createClient();
const prefixService = "gpcatalogs/country";
const allCountriesService = "catalogs/country";

export const createApiCountryRepository = (): CountryRepository => {
  return { getCountries, getAllCountries };
};

const getCountries: CountryRepository["getCountries"] = async (
  lang: string,
  sponsorBank?: string
) => {
  const res = await client.get<Country[]>(`${prefixService}`, {
    headers: {
      languageCode: lang,
      sponsorBank,
    },
  });

  let countries = RecipientExchangeRates.filter((countryData) =>
    res.data
      .map((country) => country.countryCode)
      .includes(countryData.countryCode)
  ).map((countryData) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const country = res.data.find(
      (country) => country.countryCode === countryData.countryCode
    )!;
    return {
      ...countryData,
      ...country,
      currency: country.defaultCurrency as Currency,
    };
  });

  countries = countries.sort((a, b) =>
    a.countryName.localeCompare(b.countryName)
  );

  return countries;
};

const getAllCountries: CountryRepository["getAllCountries"] = async (lang) => {
  const res = await client.get<Country[]>(`${allCountriesService}`, {
    headers: {
      languageCode: lang,
    },
  });

  let countries = [...SenderExchangeRates, ...RecipientExchangeRates]
    .filter((countryData) =>
      res.data
        .map((country) => country.countryCode)
        .includes(countryData.countryCode)
    )
    .map((countryData) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const country = res.data.find(
        (country) => country.countryCode === countryData.countryCode
      )!;
      return {
        ...countryData,
        ...country,
        currency: country.defaultCurrency as Currency,
      };
    });

  countries = countries.sort((a, b) =>
    a.countryName.localeCompare(b.countryName)
  );

  return countries;
};
