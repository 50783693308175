export const handleSizeSelect = (size: string) => {
  switch (size) {
    case "small":
      return 120;
    case "medium":
      return 150;
    case "large":
      return 200;
    case "xlarge":
      return 300;
    default:
      return 180;
  }
};
