import styled from "styled-components";
import { prop, theme } from "styled-tools";
import { TransactionDetailCardColor } from "../../../Contexts/ThemeProvider/Pages";
import { switchCaption } from "../../../Contexts/ThemeProvider/Texts";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";

export const OverLay = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  overflow-y: auto;
  z-index: 3;
`;

export const IconContainer = styled.div<{
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}>`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: none;
  align-items: end;
  bottom: ${prop("bottom", "-27px")};
  left: ${prop("left", "45%")};
`;

export const ModalContainer = styled.div<{
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}>`
  width: 310px;
  max-width: 100%;
  position: absolute;
  top: ${prop("top")};
  bottom: ${prop("bottom")};
  right: ${prop("right")};
  left: ${prop("left")};
  transition: all ease-in-out 300ms;
`;

export const ModalWrap = styled.div`
  position: relative;
  box-sizing: border-box;
  background: ${theme("Background_01")};
  width: 100%;
  max-width: 350px;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  flex-direction: column;
  display: flex;
  padding: 2px 25px;
`;

export const HeadModal = styled.div`
  width: 100%;
  text-align: right;
  justify-content: end;
  display: flex;
`;

export const TextNewContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: center;
  margin-top: 10px;
`;

export const TextSection = styled.div`
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: ${TransactionDetailCardColor};
  top: 2px;
`;

export const TextNew = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-family: Poppins;
  color: ${switchCaption};
`;
