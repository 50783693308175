import { useTranslation } from "react-i18next";
import { Column, Row } from "../../../../Components/Grid";
import { Account } from "../../../../Modules/Beneficiary/domain/Beneficiary";
import Icon from "../../../../Components/Icon";
import Input from "../../../../Components/Input";
import { Controller, useFormContext } from "react-hook-form";
import { useBeneficiaryAdditionalFields } from "../../../../Hooks/useBeneficiaryAdditionalFields";
import { AccountTypes, Destination } from "../../../../Modules/Common/domain/Destination";
import { CustomSelectInstance, SelectOption } from "../../../../Models/Select";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DynamicInput } from "../../../../Components/DynamicInput";
import Loader from "../../../../Components/Loader";
import { AdditionalField } from "../../../../Modules/Common/domain/AdditionalFields";
import { AdditionalFieldInfo } from "../../../../Modules/Beneficiary/domain/BeneficiaryForm";
import isEmpty from "lodash.isempty";
import { useBeneficiaryValidations } from "../../../../Hooks/useBeneficiaryValidations";
import { SearchSelect } from "../../../../Components/SearchSelect/SearchSelect.component";
import { allowedAlphanumeric } from "../../../../Constants/Regexp";
import { selectorLanguage } from "../../../../Redux/Translate";
import { useSelector } from "react-redux";
import { ContainerTitleAccount } from "./BeneficiaryAccountCard.styled";
import Text from "../../../../Components/Text";
import { bankCard, FormSectionBank, TitleSectionBank } from "./BeneficiaryAccountCard.styled";

interface BeneficiaryAccountCardProps {
  index: number;
  account: Account;
  bankList: (SelectOption & Destination)[];
  subdivisionList: SelectOption[];
  name?: string;
  showAsCard?: boolean;
  showDestinationNameInput?: boolean;
  showDelete?: boolean;
  bankSelected?: string;
  onConfirmationModal: (account: Account) => void;
  onAdditionalFields: (additionalFields: AdditionalField[]) => void;
  onBankAccountChange?: (evt: React.ChangeEvent<HTMLInputElement>, index: number, country: string) => void;
}

export const BeneficiaryAccountCard = ({
  index,
  account,
  bankList,
  subdivisionList,
  name,
  showAsCard = true,
  showDestinationNameInput = true,
  onConfirmationModal,
  onBankAccountChange,
  onAdditionalFields,
  showDelete,
  bankSelected,
}: BeneficiaryAccountCardProps) => {
  const [t] = useTranslation("global");
  const form = useFormContext();
  const {
    formState: { errors },
    control,
    getValues,
    register,
    trigger,
    watch,
    setValue,
  } = form;
  const fieldsPrefix = `accounts.${index}`;
  const [destinationSelected, setDestinationSelected] = useState<Destination | undefined>(undefined);

  const accountTypeId = useRef<CustomSelectInstance>(null);
  const [AccountTypeList, setAccountTypeList] = useState<AccountTypes[]>([]);
  const { validateBankAccountEditBeneficiary } = useBeneficiaryValidations();
  const { language } = useSelector(selectorLanguage);

  const { additionalFields, isLoading: additionalFieldsIsLoading } = useBeneficiaryAdditionalFields({
    country: account.country,
    countrySubdivisions: subdivisionList,
    destinationSelected,
    form,
    fieldsPrefix: `${fieldsPrefix}`,
    onAdditionalFields,
  });

  const accountAdditionalFields = useMemo(
    () => additionalFields.filter((field) => field.fieldGroup !== "branch" && field.fieldBelongsTo !== "Transaction"),
    [additionalFields]
  );

  const branchAdditionalFields = useMemo(
    () => additionalFields.filter((field) => field.fieldGroup === "branch" && field.fieldBelongsTo !== "Transaction"),
    [additionalFields]
  );

  const setDestinationId = useCallback(() => {
    const destination = bankList.find((destination) => destination.id === account.destination);

    setDestinationSelected(destination);
    setAccountType();
  }, [account, bankList]);

  const setAccountType = () => {
    const accountSelect = account.destinationList;
    if (accountSelect && destinationSelected) {
      const destination = accountSelect.find((destination) => destination.id === destinationSelected.id);
      const AccountTypeList: AccountTypes[] = [];
      destination?.destinationExpressions?.map((item) => {
        AccountTypeList.push({
          label: language === "en" ? item.labelEN : item.labelSP,
          value: item.accountType,
          key: item.accountType,
        });
      });
      setAccountTypeList(AccountTypeList);
    }
  };

  const getAdditionalFieldDefault = (field: AdditionalField) => {
    return account.additionalFieldInfo?.find((additionalField: AdditionalFieldInfo) =>
      additionalField.fieldName?.includes(field.fieldName.split(".").reverse()[0])
    )?.fieldValue;
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === `${fieldsPrefix}.bankName` || name === `destination`) {
        account.destination = value[name];
        setDestinationId();
        setAccountType();
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, bankList]);

  useEffect(() => {
    setValue("country", account.country);
    setDestinationId();
  }, [bankList]);

  useEffect(() => {
    if (destinationSelected) {
      setAccountType();
    }
  }, [destinationSelected, language]);

  return (
    <Row>
      <Column span={12} position="relative" {...(showAsCard && { as: bankCard })}>
        {additionalFieldsIsLoading && <Loader />}
        <>
          <Column span={12} px={1} pb={2} as={TitleSectionBank}>
            <ContainerTitleAccount>
              <Text size={1} weight={500} color="black" margin="0 8px" align="left">
                {name || `${t("Beneficiaries.EditBeneficiary.account")} ${index + 1}`}
              </Text>
              {showDelete && (
                <Icon icon={"trash"} size="medium" color="black" onClick={() => onConfirmationModal(account)} />
              )}
            </ContainerTitleAccount>
          </Column>
          {showDestinationNameInput && (
            <Column span={12} as={FormSectionBank}>
              <Input
                typeInput="text"
                placeholderInput={t("Beneficiaries.EditBeneficiary.titleAccount")}
                label={`${fieldsPrefix}.bankName`}
                register={register}
                disabledInput
              />
            </Column>
          )}
          <Column span={12} as={FormSectionBank}>
            <Controller
              control={control}
              name={`${fieldsPrefix}.accountTypeId`}
              rules={{
                required: t("Forms.required"),
              }}
              render={({ field: { onBlur, onChange, value } }) => (
                <SearchSelect
                  labelProps={{ color: "grey", size: 0 }}
                  selectRef={accountTypeId}
                  errors={errors}
                  placeholderInput={t("Beneficiaries.AddAccount.additionalFields.AccountType")}
                  options={AccountTypeList || []}
                  name={`${fieldsPrefix}.accountTypeId`}
                  placeholder=""
                  onBlur={onBlur}
                  onChange={(sel) => {
                    onChange(sel?.value || "");
                    trigger("accountTypeId");
                  }}
                  value={AccountTypeList?.find((b) => b.value === value)}
                />
              )}
            />
          </Column>
          <Column span={12} as={FormSectionBank}>
            <Input
              typeInput="text"
              placeholderInput={t(
                (AccountTypeList &&
                  AccountTypeList.find((b) => b.value === getValues(`${fieldsPrefix}.accountTypeId`))?.label) ||
                  "Beneficiaries.AddAccount.additionalFields.AccountType"
              )}
              label={`${fieldsPrefix}.accountNumber`}
              register={register}
              rules={{
                required: t("Forms.required"),
                validate: {
                  valid: (value: string, formValues) =>
                    validateBankAccountEditBeneficiary(
                      value,
                      {
                        ...formValues,
                        bankList,
                      },
                      fieldsPrefix,
                      bankSelected
                    ),
                },
              }}
              errors={errors}
              onInputChange={(e) => onBankAccountChange && onBankAccountChange(e, index, account.country)}
            />
          </Column>
          {destinationSelected?.isBeneIfscCodeRequired && (
            <Column span={12} as={FormSectionBank}>
              <Input
                typeInput="text"
                placeholderInput={t("Beneficiaries.AddAccount.additionalFields.IfscCode")}
                label={`${fieldsPrefix}.ifscCode`}
                maxLength={11}
                register={register}
                errors={errors}
                rules={{
                  required: t("Forms.required"),
                  pattern: {
                    value: allowedAlphanumeric,
                    message: t("Forms.minLength", { min: 11 }),
                  },
                }}
              />
            </Column>
          )}
          {additionalFields && !isEmpty(accountAdditionalFields) && (
            <Column span={12} as={FormSectionBank}>
              {accountAdditionalFields.map((field) => (
                <Row key={field.fieldName}>
                  <Column span={12}>
                    <DynamicInput {...field} defaultValue={getAdditionalFieldDefault(field)} />
                  </Column>
                </Row>
              ))}
            </Column>
          )}
          {!isEmpty(branchAdditionalFields) && (
            <Column span={12} as={FormSectionBank}>
              {additionalFields &&
                branchAdditionalFields.map((field) => (
                  <Row key={field.fieldName}>
                    <Column span={12}>
                      <DynamicInput {...field} />
                    </Column>
                  </Row>
                ))}
            </Column>
          )}
        </>
      </Column>
    </Row>
  );
};
