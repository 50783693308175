import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "../Modal.component";
import { UnitellerDisclaimerModalProps } from "../../../Models/ModalInterface";
import Text from "../../Text";
import Icon from "../../Icon";
import Button from "../../Button";
import { useTranslation } from "react-i18next";
import CheckboxInput from "../../Checkbox";
import PDFViewer from "../../PDFViewer";
import { UNITELLER_LEGAL_FILES } from "../../../Constants/LegalFiles";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../Redux/Translate";
import { Column, Row } from "../../Grid";
import { FormInput } from "../../Input";
import { allowedEmail } from "../../../Constants/Regexp";
import { UnitellerDisclaimerModalWrapper } from "./UnitellerDisclaimerModal.styled";
import { FlexContainer } from "../../Flex/Flex.styled";

export const UnitellerDisclaimerModal = (
  props: UnitellerDisclaimerModalProps
) => {
  const { t } = useTranslation("global");
  const [tModals] = useTranslation("modals");
  const lang = useSelector(selectorLanguage);
  const form = useForm({ mode: "all" });
  const {
    formState: { errors, isValid: isFormValid },
    register,
    handleSubmit,
    setFocus,
  } = form;

  const submit = handleSubmit((data) => {
    if (isFormValid) {
      props.handleConfirm && props.handleConfirm(data);
    } else {
      focusErrored();
    }
  });

  const focusErrored = () => {
    const firstError = Object.keys(errors).reduce((field: string | null, a) => {
      return field && errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  };

  useEffect(() => {
    focusErrored();
  }, [errors, setFocus]);

  return (
    <Modal {...props} as={UnitellerDisclaimerModalWrapper}>
      <FormProvider {...form}>
        <Icon icon="alertTriangle" color="warning" size="xlarge" />
        <Text size={4} weight={700} align="center">
          Uniteller
        </Text>
        <Text size={1} weight={400} align="center" margin="16px 0">
          {tModals("uniteller.message")}
        </Text>
        <form onSubmit={submit}>
          <Row>
            <Column span={12} mb={3}>
              <CheckboxInput
                id="check-legal-terms"
                variant="checkbox"
                label="checkLegalTerms"
                register={register}
                errors={errors}
                rules={{
                  required: t("Forms.required"),
                }}
              >
                <span>
                  {tModals("uniteller.text")}
                  <PDFViewer
                    url={
                      lang.language === "en"
                        ? UNITELLER_LEGAL_FILES[0].urlEn
                        : UNITELLER_LEGAL_FILES[0].urlEs
                    }
                    text={tModals("uniteller.userAgreement")}
                    variant="black"
                    size={1}
                  />
                  {tModals("uniteller.text2")}
                  <PDFViewer
                    url={
                      lang.language === "en"
                        ? UNITELLER_LEGAL_FILES[1].urlEn
                        : UNITELLER_LEGAL_FILES[1].urlEs
                    }
                    text={tModals("uniteller.privacyPolicy")}
                    variant="black"
                    size={1}
                  />
                </span>
              </CheckboxInput>
            </Column>
          </Row>
          {props.showEmailInput && (
            <Row>
              <Column span={12} mb={3}>
                <FormInput
                  textLabel={tModals("uniteller.email")}
                  labelSize={1}
                  infoText={tModals("uniteller.emailInfo")}
                  typeInput="email"
                  label="email"
                  // defaultValueInput={props.emailDefaultValue}
                  rules={{
                    required: t("Forms.required"),
                    pattern: {
                      value: allowedEmail,
                      message: t("Forms.inputEmail"),
                    },
                    minLength: {
                      value: 5,
                      message: t("Forms.minLength", { min: 5 }),
                    },
                    maxLength: {
                      value: 50,
                      message: t("Forms.maxLength", { max: 50 }),
                    },
                    validate: (value) =>
                      value.trim() === "" ? t("Forms.required") : true,
                  }}
                />
              </Column>
            </Row>
          )}
          <FlexContainer
            alignItems="center"
            justify="center"
            direction="column"
            gap="1rem"
          >
            <Button
              type="submit"
              variant={isFormValid ? "primary" : "default"}
              sizeButton="large"
              sizeText="large"
              text={tModals("uniteller.buttonAcept")}
              colorIcon="white"
              sizeIcon="large"
            />
            <Button
              type="button"
              variant={"outline"}
              sizeButton="large"
              sizeText="large"
              text={t("global.cancel")}
              colorIcon="white"
              sizeIcon="large"
              onClick={() => props.handleClose()}
              color={"Primary_01"}
            />
          </FlexContainer>
        </form>
      </FormProvider>
    </Modal>
  );
};
