import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Own redux and models
import { pendingTransactionsModalProps } from "../../../Models/ModalInterface";
import { selectorLanguage } from "../../../Redux/Translate";

// Own components
import { Column, Row } from "../../Grid";
import Text from "../../Text";
import Link from "../../Link";
import Modal from "../Modal.component";
import { VenmoTransaction } from "../../VenmoTransaction";
import ZelleTransaction from "../../ZelleTransaction";

// Own styles
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";

// Own Assets
import CurrencyDollarExchange from "../../../Assets/Img/currency-dollar-exchange.png";

// Own Contants
import { waLinkEn, waLinkEs } from "../../../Constants/Zelle";
import { JustifyContent } from "../../../Types/Column";
import { useState } from "react";


export const PendingTransactionsModal = (props: pendingTransactionsModalProps) => {
  const [t] = useTranslation("global");
  const lang = useSelector(selectorLanguage);
  const [showTranslationInstructions, setShowTranlationsInstructions] = useState<boolean>(false);
  const [showVenmoTranslationInstructions, setShowVenmoTranlationsInstructions] = useState<boolean>(false);
  const WhatsAppLink = lang.language === "en" ? waLinkEn : waLinkEs;

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      { (!showTranslationInstructions && !showVenmoTranslationInstructions) && (
        <Row>
          <Column span={2}>
            <img src={CurrencyDollarExchange} alt="icon-currency-zelle" width={62} height={62} />
          </Column>
          <Column span={10} mb={1}>
            <Text size={2} color="Primary01" align="left" weight="600" margin="0 12px">
              {t("zelleTransactionsModal.title")}
            </Text>
            <Text color="grey" align="left" weight="500" margin="0 12px">
              {t("zelleTransactionsModal.subTitle")}
            </Text>
          </Column>
        </Row>
      )}

      { !showVenmoTranslationInstructions && (
        <ZelleTransaction
          showTransactionInstructions={setShowTranlationsInstructions}
          transactionList={props?.transactionList} 
        />
      )}

      { !showTranslationInstructions && (
        <VenmoTransaction
          showTransactionInstructions={setShowVenmoTranlationsInstructions}
          transactionList={props?.transactionList}
        />
      )}
      <Row>
        <Column span={12} justifyContent={JustifyContent.center} mt={2}>
          <Text size={1} color="black" align="left" weight="400" margin="0 12px">
            {t("zelleTransactionsModal.needHelp")}
            <Link href={WhatsAppLink} weight={600} underline>
              {t("zelleTransactionsModal.contact")}
            </Link>
          </Text>
        </Column>
      </Row>
    </Modal>
  );
};