import isEmpty from "lodash.isempty";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const useFirstErrorAutofocus = () => {
  const {
    formState: { errors },
    setFocus,
  } = useFormContext();

  useEffect(() => {
    if (!isEmpty(errors)) {
      const firstError = Object.keys(errors).reduce((field: string | null, a) => {
        return field && !!errors[field] ? field : a;
      }, null);

      if (firstError) {
        setFocus(firstError);
      }
    }
  }, [errors, setFocus]);

  return {};
};
