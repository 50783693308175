import { createSlice } from "@reduxjs/toolkit";
import theme from "../../Contexts/ThemeProvider";
import { State } from "../Store";

const initialState = theme;

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {},
});

export const ThemeSelector = (state: State) => state.theme;

export default themeSlice.reducer;
