import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequestError } from "../../Types/ApiRequestError";
import { createClient } from "../../Services/ApiClient";
import { tByUserId } from "../../Types/PersonKyc";

const client = createClient();

export const getStatusPersonKYC = createAsyncThunk<
  tByUserId,
  void,
  { rejectValue: ApiRequestError }
>("getStatusPersonKYC", async (_, { rejectWithValue }) => {
  try {
    const response = await client.get<tByUserId>("persons/ByUserId");
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
