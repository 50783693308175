import { PropsWithChildren, createContext, useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { isNil } from "lodash";

import { defaultImages, martoriImages } from "../ThemeProvider/Images";
import { globalConfigSelector } from "../../Redux/GlobalConfig/GlobalConfig.slice";

import theme from "../ThemeProvider";

import { ThemeState, themeReducer } from "./ThemeReducer";

interface ThemeContextProps {
  theme: ThemeState;
  setDefaultTheme: () => void;
  setMartoriTheme: () => void;
}

const martoriKey = import.meta.env.VITE_MARTORI_KEY;

export const ThemeContext = createContext({} as ThemeContextProps);

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [theming, dispatch] = useReducer(themeReducer, {
    currentTheme: "default",
    images: defaultImages,
  });
  const stateConfig = useSelector(globalConfigSelector);

  useEffect(() => {
    if (
      !isNil(stateConfig?.customerId) &&
      stateConfig?.customerId === martoriKey
    ) {
      setMartoriTheme();
    }
  }, [stateConfig]);

  const setDefaultTheme = () => {
    dispatch({ type: "set_default_theme" });
  };

  const setMartoriTheme = () => {
    dispatch({ type: "set_martori_theme" });
  };

  return (
    <ThemeContext.Provider
      value={{
        theme: theming,
        setDefaultTheme,
        setMartoriTheme,
      }}
    >
      <StyledThemeProvider
        theme={{
          currentTheme: theming.currentTheme,
          images: theming.images,
          ...theme,
        }}
      >
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
