import { useCallback, useEffect, useRef, useState } from "react";
import { StateStatus } from "../Modules/Common/domain/StateStatus";
import { Errors } from "../Modules/Common/domain/Errors";

export const useStatus = () => {
  const [status, setStatus] = useState<StateStatus>("idle");
  const [error, setError] = useState<Errors | null>(null);
  const activeRequests = useRef(0);

  const isLoading = status === "loading";
  const hasError = status === "error";

  const startRequest = useCallback(() => {
    activeRequests.current += 1;
    setStatus("loading");
  }, []);

  const endRequest = useCallback(
    (opts?: { error?: Errors; hasError?: boolean }) => {
      activeRequests.current -= 1;
      if (activeRequests.current === 0) {
        if (opts?.error || opts?.hasError) {
          setStatus("error");
          setError(opts.error ?? null);
        } else {
          setStatus("idle");
        }
      }
    },
    []
  );

  useEffect(() => {
    if (status !== "error") {
      setError(null);
    }
  }, [status]);

  return {
    status,
    isLoading,
    hasError,
    error,
    setStatus,
    setError,
    startRequest,
    endRequest,
  };
};
