import { useTranslation } from "react-i18next";
import { Column, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import { ButtonSection } from "../../AddBeneficiary/AddBeneficiary.styled";
import { FlexContainer, FlexItem } from "../../../Components/Flex/Flex.styled";
import { CountryIcon } from "../../../Components/MoneyInput/MoneyInput.styled";
import { CustomSelectInstance, SelectOption } from "../../../Models/Select";
import { useDispatch, useSelector } from "react-redux";
import { SearchSelect } from "../../../Components/SearchSelect/SearchSelect.component";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useRef } from "react";
import { FormInput } from "../../../Components/Input/FormInput.component";
import Button from "../../../Components/Button";
import { useDestination } from "../../../Hooks/useDestination";
import { QuotationSelector, QuotationSlice } from "../../../Redux/Quotation/Quotation.slice";
import { AdditionalField } from "../../../Modules/Common/domain/AdditionalFields";
import { allowedAlphanumeric } from "../../../Constants/Regexp";
import { AccountTypes, Destination } from "../../../Modules/Common/domain/Destination";
import { AppDispatch } from "../../../Redux/Store";
import { useBeneficiaryAdditionalFields } from "../../../Hooks/useBeneficiaryAdditionalFields";
import { DynamicInput } from "../../../Components/DynamicInput";
import { useBeneficiaryValidations } from "../../../Hooks/useBeneficiaryValidations";
import { allowedCountries } from "../../../Modules/Common/domain/Countries";
import { countrySelector } from "../../../Redux/Country/Country.slice";
import { useCountry } from "../../../Hooks/useCountry";
import { ContainerTitleAccount, CountryContainer } from "../Beneficiaries.styled";
import { LoaderStyled } from "../../../Components/Loader/Loader.styled";

export interface BeneficiaryAccountProps {
  country: string;
  countrySubdivisions?: SelectOption[];
  showOnNextButton?: boolean;
  AccountTypeList?: AccountTypes[];
  showCountry?: boolean;
  showAccountHeading?: boolean;
  showDestinationSelector?: boolean;
  loading?: boolean;
  onAdditionalFields?: (additionalFields: AdditionalField[]) => void;
  onBankList?: (bankList?: (SelectOption & Destination)[]) => void;
  onNext?: (
    bankList?: (SelectOption & {
      code?: string | undefined;
    })[]
  ) => void;
  onLoading?: (loading?: boolean) => void;
}

export const BeneficiaryAccount = ({
  country,
  countrySubdivisions,
  AccountTypeList,
  showOnNextButton,
  showCountry,
  showAccountHeading,
  showDestinationSelector,
  loading,
  onAdditionalFields,
  onBankList,
  onNext,
  onLoading,
}: BeneficiaryAccountProps) => {
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const accountTypeId = useRef<CustomSelectInstance>(null);
  const { countries } = useCountry();

  const form = useFormContext();
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = form;

  const { bankList, isLoading: destinationIsLoading, getDestinationsByCountry } = useDestination();
  const { currentPaymentDestination, currentDeliveryMethodQuote } = useSelector(QuotationSelector).data;
  const { additionalFields, isLoading: additionalFieldsIsLoading } = useBeneficiaryAdditionalFields({
    country,
    destinationSelected: currentPaymentDestination,
    destinationList: bankList,
    form,
    countrySubdivisions,
    onAdditionalFields,
  });

  const { validateBankAccount } = useBeneficiaryValidations();

  const countryIcon = country && countries.find((exchange) => exchange.countryCode === country)?.icon;

  const countriesList: (SelectOption & { shortCode: string })[] = useSelector(countrySelector)
    .countries.filter((country) => country.countryCode !== "USA")
    .map((c) => ({
      label: c.countryName,
      value: c.countryCode,
      shortCode: c.alpha2Code, // For the phone number input
    }));

  const isLoading = destinationIsLoading || additionalFieldsIsLoading;

  async function fetchBanks(countryCode: string) {
    await getDestinationsByCountry("D2B", countryCode);

    setValue("destination", currentPaymentDestination?.id);
    trigger("destination");
  }

  useEffect(() => {
    if (allowedCountries.includes(country)) {
      fetchBanks(country);
    }
  }, [country, allowedCountries]);

  useEffect(() => {
    if (bankList) {
      onBankList && onBankList(bankList);
      dispatch(
        QuotationSlice.actions.setCurrentDeliveryMethodQuote({
          ...currentDeliveryMethodQuote,
          paymentDestinations: bankList,
        })
      );
    }
  }, [bankList]);

  useEffect(() => {
    onLoading && onLoading(isLoading);
  }, [onLoading, isLoading]);

  return (
    <FlexContainer direction="column" gap="16px">
      {showCountry && country ? (
        <FlexContainer p="0px">
          <CountryContainer>
            <Text size={0} weight={500} color="black" margin="8px 0" align="left">
              {t("Beneficiaries.Account.Country")}
            </Text>
            <FlexItem w="auto">
              {countryIcon && <CountryIcon src={countryIcon} />}
              <Text size={0} weight={500} color="black" margin="8px 0" align="left">
                {countriesList.find((c) => c.value === country)?.value}
              </Text>
            </FlexItem>
          </CountryContainer>
        </FlexContainer>
      ) : (
        showCountry && (
          <Row>
            <Column span={12}>
              <Controller
                control={control}
                name="country"
                rules={{ required: t("Forms.required") }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <SearchSelect
                    labelProps={{ color: "grey", size: 0 }}
                    errors={errors}
                    placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.Country")}
                    options={countriesList}
                    name="country"
                    placeholder=""
                    onBlur={onBlur}
                    onChange={(sel) => onChange((sel as SelectOption)?.value || "")}
                    value={countriesList.find((c) => c.value === value)}
                  />
                )}
              />
            </Column>
          </Row>
        )
      )}
      {
        <Row>
          {showAccountHeading && (
            <Column span={12}>
              <Text size={0} weight={500} color="black" margin="16px 0" align="left">
                {t("Beneficiaries.AddAccount.details")}
              </Text>
            </Column>
          )}
          <Column span={12}>
            {showDestinationSelector ? (
              <Controller
                control={control}
                name={"destination"}
                rules={{
                  required: t("Forms.required"),
                }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <SearchSelect
                    labelProps={{ color: "grey", size: 0 }}
                    errors={errors}
                    options={bankList || []}
                    name={"destination"}
                    placeholder=""
                    onBlur={onBlur}
                    onChange={(sel) => {
                      onChange(sel?.value || "");
                    }}
                    value={bankList?.find((b) => b.value === value)}
                    placeholderInput={t("Beneficiaries.AddAccount.bankName")}
                  />
                )}
              />
            ) : (
              <FormInput
                label="bankName"
                defaultValueInput={currentPaymentDestination?.destination}
                disabledInput
                placeholderInput={t("Beneficiaries.AddAccount.bankName")}
              />
            )}
          </Column>
        </Row>
      }
      <Row>
        {showAccountHeading && (
          <Column span={12} pb={2}>
            <ContainerTitleAccount>
              <Text size={1} weight={500} color="black" margin="0 8px" align="left">
                {t("Beneficiaries.AddAccount.account")}
              </Text>
            </ContainerTitleAccount>
          </Column>
        )}
        <Column span={12}>
          <Controller
            control={control}
            name={"accountTypeId"}
            rules={{
              required: t("Forms.required"),
            }}
            render={({ field: { onBlur, onChange, value } }) => (
              <SearchSelect
                labelProps={{ color: "grey", size: 0 }}
                selectRef={accountTypeId}
                errors={errors}
                options={AccountTypeList || []}
                name={"accountTypeId"}
                placeholder=""
                onBlur={onBlur}
                onChange={(sel) => {
                  onChange(sel?.value || "");
                }}
                value={AccountTypeList?.find((b) => b.value === value)}
                placeholderInput={t("Beneficiaries.AddAccount.additionalFields.AccountType")}
              />
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <FormInput
            label="bankAccount"
            rules={{
              required: t("Forms.required"),
              validate: {
                valid: (value, formValues) => validateBankAccount(value, { ...formValues, bankList }),
              },
            }}
            feedbackText={undefined}
            placeholderInput={t(
              (AccountTypeList && AccountTypeList.find((b) => b.value === getValues(`accountTypeId`))?.label) ||
                "Beneficiaries.AddAccount.additionalFields.AccountType"
            )}
          />
        </Column>
      </Row>

      {additionalFields &&
        additionalFields.map((field) => (
          <Row key={field.fieldName}>
            <Column span={12}>
              <DynamicInput {...field} />
            </Column>
          </Row>
        ))}

      {bankList.find((bank) => bank.id === getValues("destination"))?.isBeneIfscCodeRequired && (
        <Row>
          <Column span={12}>
            <FormInput
              label="beneIfscCode"
              maxLength={11}
              rules={{
                required: t("Forms.required"),
                pattern: {
                  value: allowedAlphanumeric,
                  message: t("Forms.minLength", { min: 11 }),
                },
              }}
              feedbackText={undefined}
              placeholderInput={t("Beneficiaries.AddAccount.additionalFields.IfscCode")}
            />
          </Column>
        </Row>
      )}

      {showOnNextButton && (
        <Row>
          <Column span={12}>
            <ButtonSection>
              <Button
                type="button"
                variant="primary"
                sizeButton="full"
                disabled={loading}
                onClick={() => {
                  trigger(), onNext && onNext();
                }}
              >
                <FlexContainer justify="center" gap="8px">
                  {loading && <LoaderStyled color="white" width="20px" height="20px" border="3px" />}
                  <Text size={2} align="left" color="white" weight={600}>
                    {t("Beneficiaries.CreateBeneficiary.Form.VerifyBankInfo")}
                  </Text>
                </FlexContainer>
              </Button>
            </ButtonSection>
          </Column>
        </Row>
      )}
    </FlexContainer>
  );
};
