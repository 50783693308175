import { PropsWithChildren } from "react";
import { useCountry } from "../../Hooks/useCountry";
import isEmpty from "lodash.isempty";
import Loader from "../Loader";

export const CountryGuard = ({ children }: PropsWithChildren) => {
  const { countries } = useCountry();
  if (isEmpty(countries)) return <Loader full />;
  return <>{children}</>;
};
