import { createSlice } from "@reduxjs/toolkit";
import { verifyTransferOrder } from "./TransferOrders.actions";
import { State } from "../Store";
import { TransferOrderState } from "../../Modules/PaymentOrders/domain/PaymentOrderState";

const initialState: TransferOrderState = {
  loading: false,
  error: null,
  data: {
    transactionDetails: undefined,
    transactionCard: undefined,
    transactionTracking: undefined,
  },
};

export const TransferOrdersSlice = createSlice({
  name: "TransferOrders",
  initialState,
  reducers: {
    // eslint-disable-next-line
    addDataToStore(state: any, action) {
      state.data = action.payload;
    },
    setTransactionDetail(state, action) {
      state.data.transactionDetails = action.payload;
    },
    setTransactionCard(state, action) {
      state.data.transactionCard = action.payload;
    },
    setTransactionTracking(state, action) {
      state.data.transactionTracking = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line
      .addCase(verifyTransferOrder.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      // eslint-disable-next-line
      .addCase(verifyTransferOrder.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.data = action.payload;
      })
      // eslint-disable-next-line
      .addCase(verifyTransferOrder.rejected, (state: any, action: any) => {
        state.loading = false;
        state.data = {};
        state.error = action.error;
      });
  },
});

export const { addDataToStore } = TransferOrdersSlice.actions;

export default TransferOrdersSlice.reducer;

// eslint-disable-next-line
export const TransferOrdersSelector = (state: State) => state.TransferOrders;
