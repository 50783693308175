import styled from "styled-components";
import { ifProp, theme } from "styled-tools";

export const FormContainer = styled.div`
  margin-top: 60px;

  .card-number-frame,
  .expiry-date-frame,
  .cvv-frame {
    border-radius: 12px;
    height: 30px;
    padding: 8px 10px;
    border-width: 2px;
    border-style: solid;
    border-color: ${theme("Background_05")};
    background-color: ${theme("Background_06")};

    &.frame--activated.frame--focus {
      border: 2px ${theme("Primary_01")} solid;
    }
    &.frame--activated.frame--invalid {
      color: ${theme("Red_08")};
      border: 2px ${theme("Red_08")} solid;
    }
  }
`;

export const FormLabel = styled.div`
  p {
    color: ${theme("Label")};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
  }
`;

export const FormInput = styled.div<{
  isError?: boolean;
}>`
  & > div {
    display: none;
  }

  input {
    font-family: "Poppins";
    color: ${theme("Label")};
    background-color: ${theme("Background_06")};
    box-sizing: border-box;
    border-radius: 12px;
    border-width: 2px;
    border-style: solid;
    border-color: ${ifProp("isError", theme("Red_08"), theme("Background_05"))};
    font-weight: 500;
    margin-top: 0;
    padding: 12px 14px;
    width: 100%;

    &:focus-visible {
      outline: none;
    }

    &:focus,
    &:active,
    &:focus-visible {
      border-width: 2px;
      border-color: ${theme("Primary_01")};
    }
  }
`;
