import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

// Own states
import { State } from "../../Redux/Store";
import { AlignItems, JustifyContent } from "../../Types/Column";

// Own components
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Title from "../../Components/Title";

// Own styles
import { ContentColumn } from "./Settings.styled";
import LinksList from "../../Components/LinksList";

// Own assets
import SettingsImage from "../../Assets/Img/settings-image.jpg";
import { IconTypeKey } from "../../Models/IconInterface";

const Settings = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");

  const LegalLinks: Array<{ icon: IconTypeKey; name: string; url: string }> = [
    {
      icon: "circleUser",
      name: t("Settings.links.AccountDetails"),
      url: "/",
    },
    {
      icon: "headset",
      name: t("Settings.links.HelpAndSupport"),
      url: "/",
    },
    {
      icon: "bag",
      name: t("Settings.links.Rewards"),
      url: "/",
    },
    {
      icon: "message",
      name: t("Settings.links.Disclosures"),
      url: "/",
    },
    {
      icon: "circleUser",
      name: t("Settings.links.CloseAccount"),
      url: "/",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/" textLink={t("buttons.back")}>
        <Container>
          <Row>
            <Column as={ContentColumn} span={12}>
              <Row>
                <Column span={12}>
                  <Title
                    size={5}
                    text={t("Settings.title")}
                    color="black"
                    align="left"
                    tagName="h1"
                  />
                </Column>
              </Row>
              <Row>
                <Column span={4} xs={12} md={12}>
                  <LinksList data={LegalLinks} />
                </Column>
                <Column
                  span={8}
                  justifyContent={JustifyContent.flexEnd}
                  alignItems={AlignItems.center}
                  hiddenMobile
                >
                  <img src={SettingsImage} alt="SettingsImage" />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default Settings;
