import { createClient } from "../../../Services/ApiClient";
import { AuthorizeCreateOrder, AuthorizeResponse } from "../domain/Authorize";
import { PaymentOrderWithAuthorizeRepository } from "../domain/PaymentOrderRepository";

const client = createClient();
const AuthorizePayPrefixService = "Authorize";

export const AuthorizeApiPaymentOrderRepository =
  (): PaymentOrderWithAuthorizeRepository => {
    return {
      getCardsByPersonId,
      createOrder
    };
  };

const getCardsByPersonId = async (personId: string) => {
  const response = await client.get<AuthorizeResponse>(
    `${AuthorizePayPrefixService}/cards-by-person/${personId}`
  );
  return response.data;
};

const createOrder= async (data: AuthorizeCreateOrder) => {
    const response = await client.post<any>(
      `${AuthorizePayPrefixService}/create-order`,
      data
    );
    return response.data;
  };