import { createSlice } from "@reduxjs/toolkit";
import { FeatureState } from "../../Modules/Common/domain/Feature";
import { State } from "../Store";

const initialState: FeatureState = {
  appFeatures: [],
};

export const FeaturesSlice = createSlice({
  name: "Features",
  initialState,
  reducers: {
    setAppFeatures: (state, { payload }) => {
      state.appFeatures = payload;
    },
  },
});

export const FeaturesSelector = (state: State) => state.Features;
