import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

// Own Components
import { Column, Row } from "../../../../Components/Grid";
import Button from "../../../../Components/Button";
import Select from "../../../../Components/Select";
import Title from "../../../../Components/Title";

// Own Styles
import {
  CardsListContainer,
  CardsListSelect,
} from "./CheckoutSelectCar.styled";

import type {
  PaymentCardList as CardListProps,
  PaymentCheckoutCard,
} from "../../../../Types/Payments";
// Type for the options
type SelectOptions = {
  label: string;
  value: string;
};

const CheckoutSelectCard = ({ data = [], makePayment }: CardListProps) => {
  const [t] = useTranslation("global");
  const [isValid, setIsValid] = useState(false);
  const [cardId, setCardId] = useState("");

  const onChange = (value: string) => {
    if (!isEmpty(value)) {
      setIsValid(true);
      setCardId(value);
    } else {
      setIsValid(false);
    }
  };

  const setCardsOption = (data: PaymentCheckoutCard[]) => {
    const options: SelectOptions[] = [];

    data.map((option: PaymentCheckoutCard) => {
      options.push({
        label: `${option?.scheme} * ${option?.last4} `,
        value: option.cardResponseSourceCheckoutId,
      });
    });

    return options;
  };

  return (
    <Row>
      <Column span={12}>
        <CardsListContainer>
          <Title
            size={4}
            tagName="h4"
            color="black"
            text={t("CheckoutPayment.titleCardsList")}
            align="left"
          />
          <CardsListSelect>
            <Select
              getOptionSelected={onChange}
              options={setCardsOption(data)}
            />
          </CardsListSelect>
        </CardsListContainer>
        <Button
          variant={!isValid ? "default" : "primary"}
          onClick={() => makePayment({ id: cardId })}
          text={t("CheckoutPayment.checkoutFormButton")}
          disabled={!isValid}
          sizeText="medium"
          sizeButton="xxlarge"
        />
      </Column>
    </Row>
  );
};

export default CheckoutSelectCard;
