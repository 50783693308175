import { useContext } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../../Components/Layout";
import { Column, Row } from "../../Components/Grid";
import Title from "../../Components/Title";
import { useTranslation } from "react-i18next";
import { AppDispatch, State } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../Components/Icon";
import Text from "../../Components/Text";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { BeneficiaryName } from "../Beneficiaries/Components/BeneficiaryCard/BeneficiaryCard.styled";
import Button from "../../Components/Button";
import {
  ConfirmationContainer,
  ConfirmationHeaderContainer,
  ConfirmationHeaderIcon,
  ConfirmationHeaderTitle,
  RecipientInfoCard,
} from "./RecipientConfirmation.styled";
import { FlexItem } from "../../Components/Flex/Flex.styled";
import { useBeneficiary } from "../../Hooks/useBeneficiary";
import { usePaymentOrder } from "../../Hooks/usePaymentOrders";
import { useModal } from "../../Hooks/useModal";
import { BeneficiarySelector } from "../../Redux/Beneficiary/Beneficiary.slice";
import { BeneficiarySlice } from "../../Redux/Beneficiary";
import { GeoLocationContext } from "../../Contexts/GeoLocation";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import isEmpty from "lodash.isempty";
import { BeneficiaryCreated } from "../../Modules/Beneficiary/domain/BeneficiaryCreated";
import { useCountry } from "../../Hooks/useCountry";

export const RecipientConfirmation = () => {
  const [tSendMoney] = useTranslation("sendMoney");
  const [tModals] = useTranslation("modals");
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useSelector((state: State) => state.theme);
  const beneficiaryToSave = useSelector(
    (state: State) => state.beneficiary.data.beneficiaryToSave
  );
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;
  const { currentDeliveryMethodQuote } = useSelector(QuotationSelector).data;
  const deliveryMethod = currentDeliveryMethodQuote?.deliveryMethodCode;
  const { countries } = useCountry();

  const currentQuoteDestinations =
    currentDeliveryMethodQuote?.paymentDestinations;
  const {
    modal: beneficiaryModal,
    showModal: showBeneficiaryModal,
    setOpenModal: setUnitellerDisclaimerModalOpen,
  } = useModal();
  const {
    isLoading: beneficiaryLoading,
    error: beneficiaryError,
    createRecipient,
  } = useBeneficiary();
  const {
    isLoading: verifyLoading,
    verifyErrorModal,
    velocityModal,
    verifyCurrentSendMoneySelection,
    verificationUniteller,
    saveSenderEmailUniteller,
  } = usePaymentOrder();
  const isLoading = beneficiaryLoading || verifyLoading;
  const location = useContext(GeoLocationContext);

  const saveBeneficiary = async () => {
    await handleSaveBeneficiary();
  };

  const handleSaveBeneficiary = async () => {
    if (beneficiaryToSave) {
      const additionalAccount =
        beneficiaryToSave.account?.[0]?.additionalFieldInfo?.filter(
          (item) => item.fieldBelongsTo !== "Transaction"
        );

      const beneficiary = {
        ...beneficiaryToSave,
        account: [
          {
            ...beneficiaryToSave.account?.[0],
            additionalFieldInfo: additionalAccount,
          },
        ],
      };

      // create and select beneficiary
      const response = await createRecipient(beneficiary);
      if (response?.recipient) {
        showBeneficiaryModal({
          modalType: "success",
          message: tModals("beneficiaries.messageSuccess"),
          handleClose: async () => {
            if (beneficiaryToSave?.rail === "UNT") {
              await getStatusUnitellerVerification(response);
            } else {
              dispatch(
                BeneficiarySlice.actions.setBeneficiarySelected({
                  ...response.recipient,
                  accountSelected: {
                    ...response.account,
                    additionalFieldInfo:
                      beneficiaryToSave.account?.[0]?.additionalFieldInfo,
                    rail: currentQuoteDestinations?.find(
                      (destination) =>
                        destination.id ===
                        beneficiaryToSave.account?.[0]?.destinationId
                    )?.rail,
                  },
                })
              );
            }
          },
        });
      }
    }
  };

  const getStatusUnitellerVerification = async (
    beneficiaryCreated: BeneficiaryCreated
  ) => {
    const response = await verificationUniteller();
    if (beneficiaryToSave) {
      if (response?.showTermsAndConditions || isEmpty(response?.senderEmail)) {
        showBeneficiaryModal({
          modalType: "unitellerDisclaimer",
          showEmailInput: true,
          emailDefaultValue: response?.senderEmail || undefined,
          handleConfirm: async (data) => {
            const { email } = data as {
              checkLegalTerms: boolean;
              email: string;
            };
            if (email) {
              await saveSenderEmailUniteller(email);
            }
            setUnitellerDisclaimerModalOpen(false);
            dispatch(
              BeneficiarySlice.actions.setBeneficiarySelected({
                ...beneficiaryCreated.recipient,
                accountSelected: {
                  ...beneficiaryCreated.account,
                  additionalFieldInfo:
                    beneficiaryToSave.account?.[0]?.additionalFieldInfo,
                  rail: currentQuoteDestinations?.find(
                    (destination) =>
                      destination.id ===
                      beneficiaryToSave?.account?.[0]?.destinationId
                  )?.rail,
                },
              })
            );
          },
        });
      } else {
        dispatch(
          BeneficiarySlice.actions.setBeneficiarySelected({
            ...beneficiaryCreated.recipient,
            accountSelected: {
              ...beneficiaryCreated.account,
              additionalFieldInfo:
                beneficiaryToSave.account?.[0]?.additionalFieldInfo,
              rail: currentQuoteDestinations?.find(
                (destination) =>
                  destination.id ===
                  beneficiaryToSave?.account?.[0]?.destinationId
              )?.rail,
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    if (beneficiaryError) {
      let errorMessage = tModals("beneficiaries.messageSaveError");

      if (
        beneficiaryError.errors?.some(
          (error) =>
            (error as string)?.includes("same bank account") ||
            (error as string)?.includes("already exists")
        )
      ) {
        errorMessage = tModals("beneficiaries.messageDuplicated");
      }

      if (beneficiaryError.error?.msg) {
        errorMessage = beneficiaryError.error?.msg;
      }

      showBeneficiaryModal({
        modalType: "error",
        errorMessage,
        handleClose: () => {
          navigate(-1);
        },
      });
    }
  }, [beneficiaryError]);

  useEffect(() => {
    if (beneficiarySelected) {
      verifyCurrentSendMoneySelection();
    }
  }, [beneficiarySelected]);

  useEffect(() => {
    if (!beneficiaryToSave) {
      navigate(-1);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} loading={isLoading}>
        <ConfirmationHeaderContainer>
          <ConfirmationHeaderIcon>
            <Icon icon="recipientSuccess" size="xxxlarge" />
          </ConfirmationHeaderIcon>
          <ConfirmationHeaderTitle>
            <Title
              tagName="h1"
              text={t("RecipientConfirmation.title")}
              color="black"
              size={6}
              align="center"
            />
          </ConfirmationHeaderTitle>
        </ConfirmationHeaderContainer>
        <ConfirmationContainer>
          <FlexItem>
            <Text size={0} align="left" margin="2rem 0">
              {t("RecipientConfirmation.verify")}
            </Text>
          </FlexItem>
          <Row>
            <Column
              span={12}
              as={RecipientInfoCard}
              py={3}
              px={4}
              padding="20px 32px"
              mb={10}
            >
              <Text
                size={3}
                align="left"
                weight={700}
                margin="11px 0 0 0"
              >{`${beneficiaryToSave?.recipient.firstName} ${beneficiaryToSave?.recipient.lastName}`}</Text>
              <BeneficiaryName>
                <img
                  src={
                    countries.find(
                      (exchange) =>
                        exchange.countryCode ===
                        beneficiaryToSave?.recipient.country
                    )?.icon
                  }
                />
                <Text
                  size={1}
                  weight={400}
                  color="grey"
                  margin="0"
                  align="left"
                >
                  {beneficiaryToSave?.recipient.country}
                </Text>
              </BeneficiaryName>
              <Text size={0} align="left" weight={600} margin="38px 0 0px 0">
                {t("RecipientConfirmation.personalInformation")}
              </Text>
              <Text size={0} align="left" weight={100} margin={0}>
                {t("RecipientConfirmation.phoneNumber")}:{" "}
                {beneficiaryToSave?.recipient.phoneNumber}
              </Text>
              <Text size={0} align="left" weight={100} margin={0}>
                {t("RecipientConfirmation.email")}:{" "}
                {beneficiaryToSave?.recipient.email}
              </Text>
              {deliveryMethod === "D2B" && (
                <>
                  <Text size={0} align="left" weight={600} margin="5px 0 0 0">
                    {t("RecipientConfirmation.bankInformation")}
                  </Text>
                  <Text size={0} align="left" weight={100} margin={0}>
                    {t("RecipientConfirmation.bankName")}:{" "}
                    {beneficiaryToSave?.bankName}
                  </Text>
                  <Text
                    size={0}
                    align="left"
                    weight={100}
                    margin="0px 0 20px 0"
                  >
                    {t("RecipientConfirmation.accountNumber")}:{" "}
                    {beneficiaryToSave?.account?.[0]?.accountNumber}
                  </Text>
                </>
              )}
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <Text size={0} align="left" weight={400} margin="0 0 40px 0">
                {t("RecipientConfirmation.continueDisclosure")}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <Button
                type="submit"
                variant={"primary"}
                sizeButton="large"
                sizeText="large"
                text={tSendMoney("Next")}
                iconButton="chevronRight"
                colorIcon="white"
                sizeIcon="large"
                onClick={() => saveBeneficiary()}
              />
            </Column>
          </Row>
        </ConfirmationContainer>
        {beneficiaryModal}
        {verifyErrorModal}
        {velocityModal}
      </Layout>
    </ThemeProvider>
  );
};
