import { RefObject, useCallback, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { Page, PageProps } from "react-pdf";

const observerConfig = {
  root: null,
  rootMargin: "0px",
  threshold: isMobile ? [1.0] : [0.2],
};

type Props = {
  pageNumber: number;
  isOpenedModal: boolean;
  totalPages: number;
  setPageVisibility: (pageNumber: number, atBottom: boolean) => void;
} & PageProps;

const PDFViewerPage = ({
  pageNumber,
  isOpenedModal,
  setPageVisibility,
  ...rest
}: Props) => {
  const mainDivRef = useRef<HTMLDivElement>(null);
  const bottomDivRef = useRef<HTMLDivElement>(null);

  const onIntersectionPageChange = useCallback<IntersectionObserverCallback>(
    ([entry]) => {
      if (entry.isIntersecting) {
        setPageVisibility(pageNumber, false);
      }
    },
    [pageNumber, isOpenedModal, setPageVisibility]
  );

  const onIntersectionBottomPageChange =
    useCallback<IntersectionObserverCallback>(
      ([entry]) => {
        if (entry.isIntersecting) {
          setPageVisibility(pageNumber, true);
        }
      },
      [pageNumber, isOpenedModal, setPageVisibility]
    );

  useEffect(() => {
    observeIntersection(onIntersectionPageChange, mainDivRef);
    observeIntersection(onIntersectionBottomPageChange, bottomDivRef);
  }, [
    mainDivRef,
    isOpenedModal,
    observerConfig,
    onIntersectionPageChange,
    onIntersectionBottomPageChange,
  ]);

  const observeIntersection = (
    callback: IntersectionObserverCallback,
    ref: RefObject<HTMLDivElement>
  ) => {
    if (!ref?.current || !("IntersectionObserver" in window)) {
      return undefined;
    }

    const observer = new IntersectionObserver(callback, observerConfig);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  };

  return (
    <div ref={mainDivRef}>
      <Page pageNumber={pageNumber} {...rest} />
      <div ref={bottomDivRef}></div>
    </div>
  );
};

export default PDFViewerPage;
