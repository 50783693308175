import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  PlaidAccountsDetail,
  PlaidAccountsResponse,
  PlaidAccountsUpdateRequest,
  PlaidTokenExchangeRequest,
} from "../Modules/PaymentOrders/domain/Plaid";
import {
  createToken,
  exchangeToken,
  getAccounts,
  getAccountDetails,
  deleteAccount,
  updateAccount,
} from "../Modules/PaymentOrders/application/plaidPaymentOrder";
import { PlaidPaymentOrderRepository } from "../Modules/PaymentOrders/infrastructure/ApiPaymentWithPlaidRepository";

import { useModal } from "./useModal";
import { Errors } from "../Modules/Common/domain/Errors";
import { useStatus } from "./useStatus";

const usePlaidPaymentOrder = () => {
  const [t, i18n] = useTranslation("global");
  const [isAccountLoading, setIsAccountLoading] = useState(true);
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const { status, hasError, isLoading, error, setStatus, setError } =
    useStatus();
  const [accounts, setAccounts] = useState({} as PlaidAccountsResponse);
  const [accountDetails, setAccountDetails] = useState(
    {} as PlaidAccountsDetail
  );
  const { modal: errorModal, showModal: showErrorModal } = useModal();

  const plaidCreateToken = async () => {
    setStatus("loading");

    try {
      const response = await createToken(PlaidPaymentOrderRepository())(
        i18n.language
      );
      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");

      if (error) {
        setError(error as Errors);
      }
    }
  };

  const plaidExchangeToken = async (body: PlaidTokenExchangeRequest) => {
    setStatus("loading");

    try {
      const response = await exchangeToken(PlaidPaymentOrderRepository())(
        body,
        i18n.language
      );
      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      plaidErrorModal();

      if (error) {
        setError(error as Errors);
      }
    }
  };

  const plaidGetAccounts = async () => {
    setStatus("loading");
    setIsAccountLoading(true);

    try {
      const response = await getAccounts(PlaidPaymentOrderRepository())(
        i18n.language
      );
      setStatus("idle");
      setAccounts(response);
    } catch (error) {
      setStatus("error");

      if (error) {
        setError(error as Errors);
      }
    } finally {
      setIsAccountLoading(false);
    }
  };

  const plaidGetAccountDetails = async (accountId: string) => {
    setIsLoadingDetails(true);
    setStatus("loading");

    try {
      const response = await getAccountDetails(PlaidPaymentOrderRepository())(
        accountId,
        i18n.language
      );
      setStatus("idle");
      setAccountDetails(response);
      return response;
    } catch (error) {
      setStatus("error");

      if (error) {
        setError(error as Errors);
      }
    } finally {
      setIsLoadingDetails(false);
      setIsAccountLoading(false);
    }
  };

  const plaidDeleteAccount = async (accountId: string) => {
    setIsAccountLoading(true);
    setStatus("loading");

    try {
      const response = await deleteAccount(PlaidPaymentOrderRepository())(
        accountId,
        i18n.language
      );
      setStatus("idle");

      return response;
    } catch (error) {
      setStatus("error");

      if (error) {
        setError(error as Errors);
        plaidErrorModal();
      }
    } finally {
      setIsAccountLoading(false);
    }
  };

  const plaidUpdateAccount = async (body: PlaidAccountsUpdateRequest) => {
    setIsAccountLoading(true);
    setStatus("loading");

    try {
      const response = await updateAccount(PlaidPaymentOrderRepository())(
        body,
        i18n.language
      );
      setStatus("idle");

      return response;
    } catch (error) {
      setStatus("error");
      plaidErrorModal();

      if (error) {
        setError(error as Errors);
      }
    } finally {
      setIsAccountLoading(false);
    }
  };

  const plaidErrorModal = () => {
    showErrorModal({
      modalType: "error",
      title: t("Payments.LinkedAccounts.modalErrorTitle"),
      errorMessage: t("Payments.LinkedAccounts.modalErrorMessage"),
    });
  };

  return {
    isAccountLoading,
    isLoading,
    isLoadingDetails,
    accounts,
    accountDetails,
    error,
    hasError,
    errorModal,
    showErrorModal,
    plaidGetAccounts,
    plaidCreateToken,
    plaidExchangeToken,
    plaidGetAccountDetails,
    plaidDeleteAccount,
    plaidUpdateAccount,
  };
};

export default usePlaidPaymentOrder;
