import { ReactNode, Ref } from "react";
import { isMobile, isDesktop } from "react-device-detect";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchContentRef,
} from "react-zoom-pan-pinch";

import {
  PDFViewerZoomStyled,
  PDFViewerZooomButtons,
} from "./PDFViewerZoom.styled";

import Button from "../../../Button";

const PDFViewerZoom = ({
  children,
  transformComponentRef,
  zoomIn,
  zoomOut,
}: {
  children: ReactNode;
  transformComponentRef?: Ref<ReactZoomPanPinchContentRef>;
  zoomIn?: () => void;
  zoomOut?: () => void;
}) => {
  return (
    <TransformWrapper
      ref={transformComponentRef}
      initialScale={1}
      smooth={true}
      limitToBounds={true}
      doubleClick={{ disabled: true }}
      disablePadding={true}
      panning={{
        allowLeftClickPan: true,
        allowMiddleClickPan: true,
        allowRightClickPan: true,
        wheelPanning: true,
      }}
      wheel={{
        wheelDisabled: true,
      }}
      maxScale={isMobile ? 3 : 8}
    >
      <>
        {isDesktop && zoomIn && zoomOut && (
          <PDFViewerZooomButtons>
            <Button
              variant={"primary"}
              sizeButton="small"
              sizeText={"small"}
              text={"-"}
              onClick={zoomOut}
            />
            <Button
              variant={"primary"}
              sizeButton="small"
              sizeText={"small"}
              text={"+"}
              onClick={zoomIn}
            />
          </PDFViewerZooomButtons>
        )}
        <TransformComponent>
          <PDFViewerZoomStyled>{children}</PDFViewerZoomStyled>
        </TransformComponent>
      </>
    </TransformWrapper>
  );
};

export default PDFViewerZoom;
