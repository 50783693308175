// An object with a displayOrder property
type WithDisplayOrder<T> = T & {
  displayOrder: string | number;
};

/**
 * Helper sorting function that compares each object's `displayOrder` property.
 * Will convert strings to numbers. If the string is not convertible, it will treat it as
 * an infinitely large number, thus sorting the object after
 * @param a First object
 * @param b Second object
 */
export function sortByDisplayOrder<T>(
  a: WithDisplayOrder<T>,
  b: WithDisplayOrder<T>
) {
  const orderA = Number(a.displayOrder) || Number.MAX_VALUE;
  const orderB = Number(b.displayOrder) || Number.MAX_VALUE;

  return orderA - orderB;
}
