import styled, { css } from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import HomePageCards from "../../Assets/Img/HomePageCards.png";
import { theme, prop, ifProp } from "styled-tools";
import {
  cardInformationBackgroundColor,
  cardInformationButtonBackgroundColor,
  cardInformationButtonIconBackgroundColor,
  cardInformationFontColor,
  cardInformationItemBorderBgColor,
  cardInformationItemBorderColor,
  headerGradientBase,
  iconInfoBorderColor,
  iconInfoColor,
} from "../../Contexts/ThemeProvider/Pages";
import { footerTitle } from "../../Contexts/ThemeProvider/Layout";

export const HeadHome = styled.div<{
  bgImage: string;
  bgImageResponsive?: string;
  isDefaultTheme?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${ifProp(
    {
      isDefaultTheme: false,
    },
    css`
      background-image: linear-gradient(
          -30deg,
          transparent 50%,
          ${headerGradientBase} 76%
        ),
        url(${prop("bgImage")});
    `,
    css`
      background: url(${prop("bgImage")});
    `
  )}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: 2rem;

  ${mediaQuery[Screen.mobile]} {
    ${ifProp(
      {
        isDefaultTheme: false,
      },
      css`
        background-image: linear-gradient(
            -360deg,
            transparent 50%,
            ${headerGradientBase} 65%
          ),
          url(${prop("bgImage")});
      `,
      css`
        background: url(${prop("bgImageResponsive")});
      `
    )}
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0 0 50px;

  h1 {
    font-size: 48px;
    width: 910px;
  }
  p {
    font-size: 18px;
    font-weight: 600;
    margin-right: 275px;
  }

  ${mediaQuery[Screen.mobile]} {
    padding: 40px 0 0 0;

    h1 {
      font-size: 32px;
      width: 100%;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      margin-right: 0;
    }
  }
`;

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  align-self: center;
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 30px 0;

  span {
    font-size: 32px;
  }

  button {
    border-radius: 35px;
  }

  ${mediaQuery[Screen.mobile]} {
    margin: 0;
    span {
      font-size: 20px;
      font-weight: 600;
    }

    button {
      width: 100%;
    }
  }
`;

export const CurrencySection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 680px;
  width: 100%;
  margin: 0 60px;

  ${mediaQuery[Screen.mobile]} {
    max-width: 380px;
    justify-self: center;
    margin: 40px 0 0 0;
  }
`;

export const IconsInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 830px;
  width: 100%;
  margin: 24px 0;

  p {
    text-align: center;
    width: 100%;
  }

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
    width: 100%;
    margin: 0;

    p {
      font-size: 18px;
      width: 60%;
    }
  }
`;

export const IconInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 3%;
  margin: 60px 0;

  ${mediaQuery[Screen.mobile]} {
    margin: 0;
    padding: 30px 0;
  }

  &:not(:last-child) {
    border-width: 0px;
    border-right-width: 2px;
    border-style: solid;
    border-image: ${iconInfoBorderColor};

    ${mediaQuery[Screen.mobile]} {
      border-bottom: 1px ${theme("Primary_02")} solid;
      border-width: 0px;
      border-image: none;
      border-bottom-width: 1px;
    }
  }
`;

export const IconInfoColor = styled.span`
  color: ${iconInfoColor};
`;

export const IconCustom = styled.img`
  width: 80px;
  height: 80px;

  ${mediaQuery[Screen.mobile]} {
    width: 50px;
    height: 50px;
  }
`;

export const CardInformationSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1360px;
  width: 100%;
  height: 592px;
  background-color: ${cardInformationBackgroundColor};
  background-image: url(${HomePageCards});
  background-size: 530px 660px;
  background-repeat: no-repeat;
  background-position-x: 103%;
  background-position-y: 50px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.25);
  margin: 40px 30px;
  padding-left: 30px;

  ${mediaQuery[Screen.lg]} {
    background-image: none;
  }

  ${mediaQuery[Screen.mobile]} {
    background-image: none;
    max-width: 429px;
    width: 100%;
    height: 1384px;
    margin: 40px 0px;
    padding: 0 20px;
  }
`;

export const IconCardInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 3%;
  margin: 50px 0;

  ${mediaQuery[Screen.mobile]} {
    margin: 0;
    width: 260px;
    padding: 10px 0;
  }

  &:not(:last-child) {
    border-width: 0px;
    border-right-width: 2px;
    border-style: solid;
    border-right: 1px ${theme("Primary_02")} solid;

    ${mediaQuery[Screen.mobile]} {
      border-bottom-width: 0px;
      border-right: 0px;
    }
  }
`;

export const ButtonLink = styled.div`
  background: ${cardInformationButtonBackgroundColor};
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px;
  margin: 10px 0;
  margin-bottom: 40px;
  height: 60px;
  width: 360px;

  img {
    width: 50px;
    height: 50px;
  }

  a {
    display: contents;
    font-size: 24px;
    font-weight: 600;
    color: ${cardInformationFontColor};
    text-align: left;
  }

  ${mediaQuery[Screen.mobile]} {
    font-size: 24px;
    width: 300px;
    height: 40px;

    img {
      width: 35px;
      height: 35px;
    }
  }
`;

export const ButtonLinkIcon = styled.div`
  background-color: ${cardInformationButtonIconBackgroundColor};
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ButtonCardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: left;

  ${mediaQuery[Screen.lg]} {
    p {
      display: none;
    }
  }

  ${mediaQuery[Screen.mobile]} {
    align-self: center;
    justify-self: center;
    margin: 30px 0 0 0;

    p {
      display: none;
      width: 100%;
    }

    button {
      width: 100%;
    }
  }

  p {
    width: 260px;
    text-align: left;
  }
`;

export const TitleFooter = styled.p`
  color: ${footerTitle};
  font-weight: 500;
  font-size: 40px;
  justify-content: center;
  margin: 60px 10px;
  font-family: Poppins;

  ${mediaQuery[Screen.mobile]} {
    margin: 30px 10px;
  }
`;

export const ImgCard = styled.img`
  width: 100%;
  max-width: 357px;
  height: auto;
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 30px 0;

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
  }
`;

export const ItemSection = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid ${cardInformationItemBorderColor};
  background: ${cardInformationItemBorderBgColor};
  align-items: center;
  padding: 16px 24px;
  margin: 50px 30px;
  max-width: 290px;
  width: 100%;

  ${mediaQuery[Screen.mobile]} {
    width: 100%;
    align-self: center;
    margin: 16px 30px;
  }
`;

export const LinkFooter = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
