import { IconsFontType } from "../../../Models/IconInterface";
import { Destination } from "../../Common/domain/Destination";
import { PaymentMethods } from "../../Common/domain/PaymentMethods";
import { Codes } from "../../PaymentOrders/domain/VelocityLimit";
import { CouponGroup, VerifyCouponResponse } from "../../Rewards/domain/Coupon";

import { Quote } from "./Quote";
import { QuotePaymentMethodDestination } from "./QuoteDeliveryMethod";

export enum QuoteChannel {
  lessFee = "Cheaper Fee",
  bestFx = "Best FX",
}

export type QuoterProps = {
  deliveryMethodCurrency?: "D2B" | "CPU" | undefined;
  deliveryMethodsSelect?: QuoteOption[];
  sendToOptionsSelect?: (QuoteOption & Destination)[];
  full?: boolean;
  title?: string;
  textButton: string;
  value?: QuoteSelectedValue;
  maxAmount?: number;
  minAmount?: number;
  statusButton?: string;
  isDisabledButton?: boolean;
  showVelocityLimit?: boolean;
  codesMessages?: Codes[];
  paymentMethodOptions?: QuoteOption[];
  proofOfLifeNeeded?: boolean;
  couponToApply?: CouponGroup;
  handleSubmit: () => void;
  verifyQuoteCoupon?: (value: string) => void;
  onChange?: (tQuoteValue: QuoteSelectedValue, cantSubmit?: boolean) => void;
};

export type QuoteSelectedValue = {
  amount?: number;
  currency?: string | null;
  country?: string | null;
  deliveryMethod?: "D2B" | "CPU";
  sendTo?: string | null;
  currentQuote?: Quote["deliveryMethods"][number];
  withTiers?: boolean;
  paymentMethodSource?: PaymentMethods;
  channel?: QuoteChannel | null;
};

export type QuoteOption = {
  label: string;
  value: string;
  iconName?: IconsFontType;
};

export const getQuoteMethodDestination = (
  currentDeliveryMethodDestinations: QuotePaymentMethodDestination[],
  idSendTo: string
) => {
  return currentDeliveryMethodDestinations.find((deliveryMethod) => deliveryMethod.id === idSendTo);
};

export const calculateTotalToShow = ({
  currentTotalCost,
  currentFee,
  currentQuoteDiscounted,
  discount,
  quoteDiscount,
  applyFeePromotion,
}: {
  currentTotalCost?: number;
  currentFee?: number;
  currentQuoteDiscounted?: VerifyCouponResponse["data"];
  discount?: number;
  quoteDiscount?: number | null;
  applyFeePromotion?: boolean;
}) => {
  if (applyFeePromotion) {
    return Number(currentTotalCost || "0") - Number(currentFee || "0");
  } else if (currentQuoteDiscounted) {
    return (
      Number(currentQuoteDiscounted?.amount || "0") +
      Number(currentQuoteDiscounted?.fee || "0") +
      Number(currentQuoteDiscounted?.paymentMethodFee || "0") -
      Number(discount || "0") -
      Number(quoteDiscount || "0")
    );
  }

  return Number(currentTotalCost || "0");
};

export const calculateTotalBeforeDiscount = (
  totalAfterDiscounts: number,
  totalBeforeDiscounts?: number,
  discount?: number | null
) => {
  const totalWithQuoteDiscount = Number(totalBeforeDiscounts || "0") + Number(discount || "0");
  const totalDiscounts = totalWithQuoteDiscount - Number(totalAfterDiscounts || "0");
  return {
    totalBeforePaymentMethodDiscount: Number(totalWithQuoteDiscount || "0"),
    totalDiscounts,
  };
};
