import { bestFXLessFeePayers } from "../../../Constants/Regexp";
import { Currency } from "../../Common/domain/Currency";
import { Destination } from "../../Common/domain/Destination";

import { QuoteDeliveryMethod } from "./QuoteDeliveryMethod";

export interface Quote {
  originCurrency: Currency;
  destinationCurrency: string;
  amount: number;
  rail: string;
  deliveryMethods: QuoteDeliveryMethod[];
}

export const checkDestinationAmountRange = (amount: number, destination?: Destination) => {
  const amountIsUnderMaxAllowed =
    (destination && !destination.maximumSendAmount) ||
    (destination?.maximumSendAmount && amount <= destination?.maximumSendAmount);
  const amountIsOverMinAllowed =
    (destination && !destination.minimumSendAmount) ||
    (destination?.minimumSendAmount && amount >= destination?.minimumSendAmount);
  return amountIsUnderMaxAllowed && amountIsOverMinAllowed;
};

export const mustQuoteCheaperFeeAndBestFx = (amount: number, destination?: Destination) => {
  if (!destination?.payerQuote) return false;
  return bestFXLessFeePayers.test(destination.payerQuote) && amount >= 400;
};
