// Bank account
export const bankAccountUrl = import.meta.env.VITE_BANK_ACCOUNT_URL || "";
export const apiKeyBankAccount =
  import.meta.env.VITE_BANK_ACCOUNT_API_KEY || "";

// Authentication
export const authenticationUrl = import.meta.env.VITE_AUTHENTICATION_URL || "";
export const authenticationApiKey =
  import.meta.env.VITE_AUTHENTICATION_API_KEY || "";
export const authenticationPlatformId =
  import.meta.env.VITE_AUTHENTICATION_PLATFORM_ID || "";

// Transfer
export const transferManagerAPIUrl =
  import.meta.env.VITE_GLOBAL_TRANSFER_URL || "";

// Ledger
export const ledgerAPIUrl = import.meta.env.VITE_LEDGER_URL || "";
export const ledgerApiKey = import.meta.env.VITE_LEDGER_API_KEY || "";

// Contacts
export const contactsAPIUrl = import.meta.env.VITE_CONTACTS_URL || "";

// Contact-us
export const contactusAPIUrl = import.meta.env.VITE_CONTACTUS_URL || "";
export const contactUsApiKey = import.meta.env.VITE_CONTACTUS_API_KEY || "";

// BBF
export const bffApiUrl = import.meta.env.VITE_GLOBAL_TRANSFER_URL || "";
export const bffApiKey = import.meta.env.VITE_API_KEY || "";

// Payment Checkout
export const checkoutPublicKey =
  import.meta.env.VITE_PAYMENT_CHECKOUT_PUBLIC_KEY || "";
export const checkoutApiKey =
  import.meta.env.VITE_PAYMENT_CHECKOUT_API_KEY || "";
export const checkoutAPIUrl = import.meta.env.VITE_PAYMENT_CHECKOUT_URL || "";

// Transfers orders
export const transferOrdersAPIUrl =
  import.meta.env.VITE_TRANSFERS_ORDERS_URL || "";
export const transferOrdersApiKey =
  import.meta.env.VITE_PAYMENT_CHECKOUT_API_KEY || "";

// Person Card
export const personCardAPIUrl = import.meta.env.VITE_PERSON_CARD_URL || "";
export const personCardApiKey =
  import.meta.env.VITE_PAYMENT_CHECKOUT_API_KEY || "";

export const buildDate = import.meta.env.VITE_BUILD_DATE;
