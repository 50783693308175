import { buildDate } from "../../../Constants/ConfigureApi";
import { Feature, FeatureType } from "../domain/Feature";
import { FeatureRepository } from "../domain/FeatureRepository";

export const createApiFeatureRepository = (): FeatureRepository => {
  return { getFeatures };
};

const getFeatures = async () => {
  const features: Feature[] = [
    {
      value: "transactions",
      label: "Dashboard.TabTransaction",
      labelEn: "Transactions",
      labelEs: "Transacciones",
      descriptionEn:
        "Now you can check your transaction history in this new space.",
      descriptionEs:
        "Ahora puedes consultar tu historial de transacciones en este nuevo espacio.",
      active: true,
      releaseDate: "2024-06-26T00:00:00.000Z",
      type: "dashboardTab",
      version: "2.1.12",
    },
    {
      value: "recipients",
      label: "Dashboard.TabRecipients",
      labelEn: "Recipients",
      labelEs: "Destinatarios",
      descriptionEn:
        "We have added new navigation. You can now view and add recipients by clicking the tab below.",
      descriptionEs:
        "Hemos añadido una nueva navegación. Ahora puedes ver y añadir destinatarios haciendo clic en la pestaña de abajo.",
      active: true,
      releaseDate: "2024-07-11T00:00:00.000Z",
      type: "dashboardTab",
      version: "2.1.12",
    },
  ];

  return features;
};
