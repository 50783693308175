import { AppDispatch } from "../Redux/Store";
import { tokenSlice } from "../Redux/Token/Token.slice";
import { userIdSlice } from "../Redux/User/User.slice";
import { globalConfigSlice } from "../Redux/GlobalConfig/GlobalConfig.slice";

import { PersonsKYCSlice } from "../Redux/PersonsKYC";
import { BeneficiarySlice } from "../Redux/Beneficiary";
import { GeolocationSlice } from "../Redux/Geolocation/Geolocation.slice";

export function storeLogin(dispatch: AppDispatch, token: string, id: string) {
  dispatch(tokenSlice.actions.setToken(token));
  dispatch(tokenSlice.actions.initTimestamp());
  dispatch(userIdSlice.actions.setUserId(id));
}

export function clearPersistedStores(dispatch: AppDispatch) {
  dispatch(PersonsKYCSlice.actions.resetState());
  dispatch(BeneficiarySlice.actions.resetState());
  dispatch(GeolocationSlice.actions.resetState());
}

export function doLogout(dispatch: AppDispatch) {
  localStorage.clear();
  sessionStorage.clear();
  dispatch(tokenSlice.actions.clearToken());
  dispatch(userIdSlice.actions.clearUserId());
  dispatch(globalConfigSlice.actions.clearConfig());
  clearPersistedStores(dispatch);
}
