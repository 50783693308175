import { ModalProps } from "../../../Models/ModalInterface";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import Modal from "../Modal.component";
import { BottomModalWrapper } from "./BottomModal.styled";
import popUpRecipientInfo from "../../../Assets/Img/popup-recipient-info.png";
import Button from "../../Button";
import { useTranslation } from "react-i18next";

export const BottomModal = (props: ModalProps) => {
  const { t } = useTranslation("global");
  return (
    <Modal {...props} as={BottomModalWrapper}>
      <FlexContainer justify="center" m="0">
        <Text size={1} weight={500} margin={0}>
          {props.title}
        </Text>
      </FlexContainer>
      <FlexContainer justify="center" m="0 0 12px 0">
        <img src={popUpRecipientInfo} width="210px" />
      </FlexContainer>
      <FlexContainer m="0 0 31px">
        <Text size={0.5} weight={500} margin={0}>
          {props.message}
        </Text>
      </FlexContainer>
      <FlexContainer justify="center" m="0 0 8px 0">
        <Button
          variant="primary"
          sizeButton="full"
          sizeText="mLarge"
          text={t("global.continue")}
          padding="8px"
          lineHeight="27px"
          onClick={() => props.handleClose()}
        />
      </FlexContainer>
    </Modal>
  );
};
