import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createConfigApiApp } from "../../Services/ApiApp";
import { PATCH_LANGUAGE_SETTINGS } from "../../Constants/Language";
import { ApiRequestError } from "../../Types/ApiRequestError";
import { LanguageResponse } from "../../Models/Language";

const client = createConfigApiApp();

export const PatchLanguageSettingAction = createAsyncThunk(
  PATCH_LANGUAGE_SETTINGS,
  async (payload: string, { rejectWithValue }) => {
    try {
      const data = {
        language: payload,
      };
      const response = await client.patch<LanguageResponse>(
        `/users/language`,
        data
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error as ApiRequestError);
    }
  }
);
