import { createClient } from "../../../Services/ApiClient";
import { IPLocationRepository } from "../domain/IPLocationRepository";
import { IPLocationPosition } from "../domain/LocationPosition";

const client = createClient({
  baseURL: "https://ipapi.co",
});

export const createIPLocationRepository = (): IPLocationRepository => {
  return { getIPLocation };
};

const getIPLocation = async () => {
  const response = await client.get<IPLocationPosition>(`/json`);
  return response.data;
};
