import { createClient } from "../../../Services/ApiClient";
import { SubdivisionRepository } from "../domain/SubdivisionRepository";
import { SubnationalDivision } from "../domain/Subnational";

const client = createClient();
const subdivisionPrefixService = "country-subdivision/";

export const createApiSubdivisionRepository = (): SubdivisionRepository => {
  return { getSubdivisionsByCountry };
};

const getSubdivisionsByCountry = async (country: string, language: string) => {
  const response = await client.get<SubnationalDivision[]>(
    `${subdivisionPrefixService}/byCountry/${country}`,
    {
      headers: {
        languageCode: language,
      },
    }
  );
  return response.data;
};
