import { createSlice } from "@reduxjs/toolkit";
import { getPersonCard } from "./PersonsCard.actions";
import { State } from "../Store";

export const PersonsCardSlice = createSlice({
  name: "PersonsCard",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line
      .addCase(getPersonCard.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      // eslint-disable-next-line
      .addCase(getPersonCard.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.data = action.payload;
      })
      // eslint-disable-next-line
      .addCase(getPersonCard.rejected, (state: any, action: any) => {
        state.loading = false;
        state.data = {};
        state.error = action.payload;
      });
  },
});

export default PersonsCardSlice.reducer;

export const PersonsCardSelector = (state: State) => state.PersonsCard;
