import styled from "styled-components";
import { theme } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const TransactionCardContainer = styled.div`
  background: ${theme("Background_01")};
  border: 1px solid ${theme("Primary_18")};
  border-radius: 20px;
  box-sizing: border-box;
  height: 76px;
  padding: 8px;
  margin: 12px 0;
  width: 100%;
`;

export const TruncateText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 190px;
    white-space: nowrap;
  }
`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const RowIntructions = styled.div`
  background-color: ${theme("Background_01")};
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${theme("Primary_18")};
  padding: 12px 16px;
  margin: 24px 0;

  .text-strong {
    color: ${theme("VenmoColor")};
  }
`;

export const RowSteps = styled.div`
  margin: 16px 0;
`;

export const TextCopy = styled.div`
  display: flex;

  p {
    border-bottom: 1px solid ${theme("Primary_600")};

    &:hover {
      color: ${theme("VenmoColor")};
      border-bottom: none;
    }
  }
`;

export const ArrowBack = styled.div`
  position: absolute;
  top: 24px;
  z-index: 5;
`;
