import { useDispatch } from "react-redux";
import { AppDispatch } from "../Redux/Store";
import { useStatus } from "./useStatus";
import { sendolaApiPaymentOrderRepository } from "../Modules/PaymentOrders/infrastructure/ApiPaymentWithSendolaPayRepository";
import {
  getPrivacypolicyStatus,
  getShowSendolaPay,
  senderCredentialsSendolaOne,
  senderPrivacypolicyStatus,
  sendolaPayTransactionDetail,
} from "../Modules/PaymentOrders/application/sendolaPayPaymentOrder";
import { Errors } from "../Modules/Common/domain/Errors";
import { SendolaPayOrderSlice } from "../Redux/PaymentOrder/SendolaPayOrder.slice";
import { getStatusPersonKYC } from "../Redux/PersonsKYC";
import { tByUserId } from "../Types/PersonKyc";
import { useEffect, useState } from "react";
import {
  SendolaCredentials,
  SendolaPayTransaction,
} from "../Modules/PaymentOrders/domain/SendolaPay";

export const useSendolaPayPaymentOrder = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [person, setPerson] = useState(false);
  const [personId, setPersonId] = useState("");
  const [statusPrivacypolicy, setStatusPrivacypolicy] = useState(false);

  const { status, isLoading, hasError, error, setStatus, setError } =
    useStatus();

  const getShowSendolaPayStatus = async () => {
    setStatus("loading");
    try {
      const response = await getShowSendolaPay(
        sendolaApiPaymentOrderRepository()
      )();
      dispatch(SendolaPayOrderSlice.actions.setStatusSendolaPay(response));
      setStatus("idle");
    } catch (error) {
      setStatus("error");
      if (error && (error as Errors).errors) {
        setError(error as Errors);
      }
    }
  };

  const getPrivacypolicyStatusStatus = async (personId: string) => {
    setStatus("loading");
    try {
      const response = await getPrivacypolicyStatus(
        sendolaApiPaymentOrderRepository()
      )(personId);
      dispatch(SendolaPayOrderSlice.actions.setPrivacypolicyStatus(response));
      setStatusPrivacypolicy(response.senderPrivacyPolicyStatus);
      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      if (error && (error as Errors).errors) {
        setError(error as Errors);
      }
    }
  };

  const senderPrivacypolicy = async () => {
    setStatus("loading");
    try {
      const response = await senderPrivacypolicyStatus(
        sendolaApiPaymentOrderRepository()
      )();
      dispatch(
        SendolaPayOrderSlice.actions.setPrivacypolicyStatusSendola(response)
      );
      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      if (error && (error as Errors).errors) {
        setError(error as Errors);
      }
    }
  };

  const senderCredentialsSendola = async (
    personId: string,
    credentiales: SendolaCredentials
  ) => {
    setStatus("loading");
    try {
      const response = await senderCredentialsSendolaOne(
        sendolaApiPaymentOrderRepository()
      )(personId, credentiales);
      dispatch(
        SendolaPayOrderSlice.actions.setCredentialsSendolaResponse(response)
      );
      setStatus("idle");
      return response;
    } catch (error: any) {
      setStatus("error");
      return error;
    }
  };

  const getTransactionDetail = async (
    personId: string,
    amount: SendolaPayTransaction
  ) => {
    setStatus("loading");
    try {
      const response = await sendolaPayTransactionDetail(
        sendolaApiPaymentOrderRepository()
      )(personId, amount);
      dispatch(SendolaPayOrderSlice.actions.setTransactionDetail(response));
      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      if (error && (error as Errors).errors) {
        setError(error as Errors);
      }
    }
  };

  useEffect(() => {
    if (!person) {
      getPersonId();
    }
  }, [person]);

  const getPersonId = async () => {
    setPerson(true);
    const userAuthPerson = await dispatch(getStatusPersonKYC());
    const { personId } = userAuthPerson.payload as tByUserId;
    setPersonId(personId);
  };

  return {
    statusPrivacypolicy,
    senderPrivacypolicy,
    getTransactionDetail,
    getShowSendolaPayStatus,
    senderCredentialsSendola,
    getPrivacypolicyStatusStatus,
    status,
    isLoading,
    hasError,
    error,
    personId,
  };
};
