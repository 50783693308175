import { useFormContext } from "react-hook-form";
import Input from ".";

// Same props <Input> takes, minus register function and errors object
type FormInputProps = Omit<Parameters<typeof Input>[0], "register" | "errors">;

export const FormInput = (props: FormInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Input
      register={register}
      errors={errors}
      {...props}
      typeInput={props.typeInput || "text"}
      full
    />
  );
};
