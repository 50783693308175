import styled from "styled-components";
import { theme } from "styled-tools";

import mediaQuery, { mediaQueryMin, Screen } from "../../Utilities/MediaQuery";

export const PaymentMethodsContainer = styled.div`
  box-sizing: border-box;
  margin: auto;
  ${mediaQuery[Screen.desktop]} {
    padding: 0 5rem;
  }
`;

export const PaymentDescription = styled.p`
  color: ${theme("Content_UX_02")};
  font-size: 48px;
`;

export const PaymentSendolaCardLogo = styled.img`
  align-self: start;
  display: flex;
  height: auto;
  margin-top: -4px;
  width: 92px;
`;

export const PaymentSendolaPayLogo = styled.img`
  display: flex;
  height: 32px;
  width: 41px;
`;

export const PaymentSendolaCardLink = styled.a`
  color: ${theme("Primary_01")};
  font-weight: 600;
`;

export const PaymentInputContent = styled.div`
  input {
    width: 100%;
  }
`;

export const PaymentModalContent = styled.div`
  padding: 15px 20px;
  margin-bottom: 20px;

  p {
    font-size: inherit;
    width: 100%;
  }

  span,
  a {
    font-size: 12px;
    font-weight: 400;
  }

  a {
    font-weight: 600;
  }
`;

export const PaymentSendolaPayIcon = styled.img`
  width: 68px;
  height: 52px;
  margin-bottom: 32px;
  margin-top: 32px;
`;

export const PaymentAuthorizeContent = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  max-width: 1468px;
  padding-bottom: 10rem;
`;


export const PaymentAuthorizeInner = styled.div`
  box-sizing: border-box;
  padding: 2rem 3rem;

  ${mediaQuery[Screen.mobile]} {
    padding: 0;
  }
`;

export const TransactionCardsList = styled.div`
  margin-bottom: 100px;
`;

export const TransactionCardImg = styled.img`
  display: none;

  @media screen and (${mediaQueryMin[Screen.lg]}) {
    display: block;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: 520px;

  ${mediaQuery[Screen.mobile]} {
    width: 100%;
    max-width: 390px;
  }
`;

export const IconSendolaPay = styled.div`
  span {
    font-size: 7em;
  }
`;

export const disclaimerUniteller = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 0;

  a {
    font-weight: 800;
    color: ${theme("Red_08")};
    text-decoration: underline;
    text-decoration-color: ${theme("Red_08")};
  }
`;

export const LinkExternalCardDescription = styled.span`
  span {
    font-weight: 400;

    &:first-child {
      margin-right: 1.5em;
    }
  }
`;

export const LinkedAccountCardExtraStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  span {
    margin-right: 10px;
  }
`;

export const CursorPointerStyled = styled.div`
  cursor: pointer;
`;
