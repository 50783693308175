import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useSelector } from "react-redux";

// Own states
import { Column, Container, Row } from "../../Components/Grid";
import { TransactionsCard } from "./Components";
import getDateFnsLocale from "../../Utilities/dateFnsLocale";
import Text from "../../Components/Text";
import {
  ItemsContainer,
  ItemsContainerPaginator,
  PaginationSection,
  RecentTransactions,
  SearchContainer,
  TransactionContainer,
  TransactionsContainer,
  TransferMonthTitle,
} from "./Transactions.styled";
import {
  PaymentTransactionHistory,
  TransactionList,
} from "../../Modules/PaymentOrders/domain/PaymentOrder";
import { AlignItems } from "../../Types/Column";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

import { CustomFormatDate } from "../../Utilities/String";
import { selectorLanguage } from "../../Redux/Translate";
import Icon from "../../Components/Icon";
import IconFont from "../../Components/IconFont";
import { SearchInput } from "../../Components/Input/SearchInput.component";

export interface TransactionsProps {
  transactionList: PaymentTransactionHistory | null;
  transactionDetail: (transaction: TransactionList) => void;
}

const Transactions = ({
  transactionList,
  transactionDetail,
}: TransactionsProps) => {
  const lang = useSelector(selectorLanguage);
  const { t, i18n } = useTranslation("global");
  const [searchValue, setSearchValue] = useState("");
  const [dateList, setDateList] = useState<string[]>([]);
  const [dateListPagination, setDateListPagination] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const currentTransaction = React.useMemo(() => {
    const originalList = transactionList;
    return originalList?.results.filter(
      (transaction) =>
        transaction.contactName
          .replaceAll(" ", "")
          .toUpperCase()
          .includes(searchValue.replaceAll(" ", "").toUpperCase()) ||
        String(transaction.amountFound).includes(searchValue) ||
        transaction.deliveryMethod
          .replaceAll(" ", "")
          .toUpperCase()
          .includes(searchValue.replaceAll(" ", "").toUpperCase()) ||
        transaction.status
          .replaceAll(" ", "")
          .toUpperCase()
          .includes(searchValue.replaceAll(" ", "").toUpperCase())
    );
  }, [searchValue, transactionList]);

  const currentPageTransaction = React.useMemo(() => {
    const listaCurrentTransaction = currentTransaction?.slice(
      (currentPage - 1) * 10,
      currentPage * 10
    );
    if (listaCurrentTransaction?.length === 0) {
      setCurrentPage(1);
    }
    return listaCurrentTransaction;
  }, [currentTransaction, currentPage]);

  useEffect(() => {
    if (currentTransaction) {
      setPageCount(Math.ceil(currentTransaction.length / 10));
    }
  }, [currentTransaction]);

  function handlePageChange(page: number) {
    setCurrentPage(page);
  }

  useEffect(() => {
    if (currentPageTransaction) {
      setDateListPagination(getMonthsTransactions(currentPageTransaction));
    }

    if (currentTransaction) {
      setDateList(getMonthsTransactions(currentTransaction));
    }
  }, [currentPageTransaction, currentTransaction]);

  const getMonthsTransactions = (transactionList: TransactionList[]) => {
    const date: string[] = [];
    transactionList.map((item) => {
      if (item && item.month) {
        date.push(item.month);
      }
    });

    const result = date.filter((item: string, index: number) => {
      return date.indexOf(item) === index;
    });

    return result;
  };

  return (
    <Container maxWidth="442px" margin="24px auto" as={TransactionsContainer}>
      <Row>
        <Column span={12}>
          {transactionList && transactionList.results.length > 0 && (
            <Row>
              <Column
                span={12}
                xs={12}
                py={3}
                alignItems={AlignItems.flexStart}
                as={SearchContainer}
              >
                <SearchInput
                  searchValue={searchValue}
                  onChange={(value) => setSearchValue(value)}
                />
              </Column>
            </Row>
          )}
          <Row>
            <Column span={12} xs={12} md={12} as={ItemsContainerPaginator}>
              {dateListPagination && dateListPagination.length > 0 ? (
                dateListPagination.map((item, index) => {
                  return (
                    <TransactionContainer key={index}>
                      <Text
                        key={index}
                        size={0.5}
                        color="black"
                        weight={600}
                        as={TransferMonthTitle}
                      >
                        {CustomFormatDate(item, lang.language)}
                      </Text>
                      {currentPageTransaction &&
                        currentPageTransaction.length > 0 &&
                        currentPageTransaction.map(
                          (transaction: TransactionList, index: number) => {
                            return (
                              item === transaction.month && (
                                <TransactionsCard
                                  key={transaction.transactionId + index}
                                  data={transaction}
                                  date={format(
                                    new Date(transaction.processedAt),
                                    "MMM dd, yyyy",
                                    {
                                      locale: getDateFnsLocale(i18n.language),
                                    }
                                  )}
                                  getDetails={() =>
                                    transactionDetail(transaction)
                                  }
                                />
                              )
                            );
                          }
                        )}
                    </TransactionContainer>
                  );
                })
              ) : (
                <RecentTransactions>
                  <Text
                    size={2}
                    align="center"
                    color="black"
                    weight={600}
                    margin="20px 0 4px 20px"
                  >
                    {transactionList && transactionList.results.length > 0
                      ? t("Dashboard.NoTransactionSearch")
                      : t("Dashboard.NotTransactions")}
                  </Text>
                  <Text
                    size={2}
                    align="center"
                    color="black"
                    weight={600}
                    margin="20px 0 4px 20px"
                  >
                    {transactionList && transactionList.results.length > 0
                      ? t("Dashboard.NoTransactionSearchText2")
                      : ""}
                  </Text>
                </RecentTransactions>
              )}
            </Column>
            <Column span={12} xs={12} md={12} as={ItemsContainer}>
              {dateList && dateList.length > 0 ? (
                dateList.map((item, index) => {
                  return (
                    <TransactionContainer key={index}>
                      <Text
                        key={index}
                        size={1}
                        color="black"
                        weight={600}
                        as={TransferMonthTitle}
                      >
                        {CustomFormatDate(item, lang.language)}
                      </Text>
                      {currentTransaction &&
                        currentTransaction.length > 0 &&
                        currentTransaction.map(
                          (transaction: TransactionList, index: number) => {
                            return (
                              item === transaction.month && (
                                <TransactionsCard
                                  key={transaction.transactionId + index}
                                  data={transaction}
                                  date={format(
                                    new Date(transaction.processedAt),
                                    "MMM dd, yyyy",
                                    {
                                      locale: getDateFnsLocale(i18n.language),
                                    }
                                  )}
                                  getDetails={() =>
                                    transactionDetail(transaction)
                                  }
                                />
                              )
                            );
                          }
                        )}
                    </TransactionContainer>
                  );
                })
              ) : (
                <RecentTransactions>
                  {transactionList && transactionList.results.length > 0 ? (
                    <>
                      <IconFont name="search" size={"medium"} color="black" />
                      <Text
                        size={"default"}
                        align="center"
                        color="black"
                        weight={600}
                        margin="20px 0 0 0"
                      >
                        {t("Dashboard.NoTransactionSearch")}
                      </Text>
                      <Text
                        size={"default"}
                        align="center"
                        color="black"
                        weight={600}
                        margin="20px 0 0 0"
                      >
                        {t("Dashboard.NoTransactionSearchText2")}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Icon
                        icon={"cashCancel"}
                        color="disabled"
                        size="xlarge"
                        fillIcon={false}
                      />
                      <Text
                        size={"default"}
                        align="center"
                        color="disabled"
                        weight={400}
                        margin="20px 0 4px 0"
                      >
                        {t("Dashboard.NotTransactions")}
                      </Text>
                    </>
                  )}
                </RecentTransactions>
              )}
            </Column>
          </Row>
          <Row>
            <Column span={12} as={PaginationSection} py={4}>
              <ResponsivePagination
                current={currentPage}
                total={pageCount}
                onPageChange={(page) => handlePageChange(page)}
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  );
};

export default Transactions;
