export const DifferenceTime = (date: string) => {
  const today = new Date();
  const processedAt = new Date(date);
  const diffMs = Number(today) - Number(processedAt);
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
  const diffDays = Math.floor(diffMs / 86400000);

  return diffMins < 30 && diffHrs <= 0 && diffDays <= 0 ? true : false;
};
