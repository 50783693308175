import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { ThemeProvider } from "styled-components";

import IconFont from "../../Components/IconFont";
import Button from "../../Components/Button";
import { Column, Container, Row } from "../../Components/Grid";
import Layout from "../../Components/Layout";
import Text from "../../Components/Text";
import { CountryCode } from "libphonenumber-js/core";
import { allowedCompoundNames, allowedDate, allowedEmail } from "../../Constants/Regexp";
import InputDateMask from "../../Components/InputDateMask";

import type { AppDispatch, State } from "../../Redux/Store";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import { BeneficiarySlice } from "../../Redux/Beneficiary";
import { getStatusPersonKYC } from "../../Redux/PersonsKYC/PersonsKYC.actions";
import { GET_PERSON_ID_FULFILLED } from "../../Constants/Person";
import { tByUserId } from "../../Types/PersonKyc";
import { FormInput } from "../../Components/Input/FormInput.component";
import { useNavigate } from "react-router";
import { SearchSelect } from "../../Components/SearchSelect/SearchSelect.component";
import { ButtonSection, Header, SectionStep } from "./AddBeneficiary.styled";
import { BeneficiaryForm } from "../../Modules/Beneficiary/domain/BeneficiaryForm";
import { FlexContainer, FlexItem } from "../../Components/Flex/Flex.styled";
import { CountryIcon } from "../../Components/MoneyInput/MoneyInput.styled";
import { formatDOBString } from "../../Modules/Beneficiary/domain/Beneficiary";
import { CustomSelectInstance } from "../../Models/Select";
import { BeneficiaryAccount } from "../Beneficiaries";
import { AccountTypes } from "../../Modules/Common/domain/Destination";
import Title from "../../Components/Title";
import { useBankAccount } from "../../Hooks/useBankAccount";
import { useBeneficiary } from "../../Hooks/useBeneficiary";
import { useBeneficiaryAdditionalFields } from "../../Hooks/useBeneficiaryAdditionalFields";
import { DynamicInput } from "../../Components/DynamicInput";
import { CountryCodeList } from "../../Modules/Common/domain/PhoneCountryCode";
import isEmpty from "lodash.isempty";
import { selectorLanguage } from "../../Redux/Translate";
import { allowedCountries } from "../../Modules/Common/domain/Countries";
import { useCountry } from "../../Hooks/useCountry";
import FlagPhoneNumberInput from "../../Components/FlagPhoneNumberInput";

export default function AddBeneficiary() {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const { language } = useSelector(selectorLanguage);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { countries, countriesWithoutUSAOptions } = useCountry();

  const form = useForm({ mode: "all" });
  const {
    control,
    formState: { errors, isValid: isFormValid },
    getValues,
    getFieldState,
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = form;

  const incomingCountry = useSelector(QuotationSelector).data.countryDestination as string;
  const incomingCountryIcon =
    incomingCountry && countries.find((exchange) => exchange.countryCode === incomingCountry)?.icon;
  const incomingTransferType = useSelector(QuotationSelector).data.currentDeliveryMethodQuote?.deliveryMethodCode;
  const { currentPaymentDestination } = useSelector(QuotationSelector).data;

  const [selectedCountry, setSelectedCountry] = useState(incomingCountry);
  const lang = useSelector(selectorLanguage);
  const isBankTransfer = incomingTransferType === "D2B";
  const isCashPickup = incomingTransferType === "CPU";
  const [fullForm, setFullForm] = useState(!isBankTransfer);
  const subdivRef = useRef<CustomSelectInstance | null>(null);
  const destinationRef = useRef<CustomSelectInstance | null>(null);
  const cityRef = useRef<CustomSelectInstance | null>(null);
  const [phoneCountryCodeSelect, setPhoneCountryCodeSelect] = useState<string>();
  const [AccountType, setAccountTypeList] = useState<AccountTypes[]>([]);

  const { handleDobChange } = useBeneficiary({ form });
  const {
    additionalFields,
    bankList,
    isLoading: bankAccountLoading,
    subdivisionList,
    citiesList,
    setAdditionalFields,
    setBankList,
    setStatus: setBankAccountStatus,
    setSubdivisionList,
    getSubdivisionOptions,
    getSubdivisionCitiesOptions,
  } = useBankAccount();

  const { additionalFields: beneficiaryAdditionalFields } =
    incomingTransferType === "CPU"
      ? useBeneficiaryAdditionalFields({
          country: incomingCountry,
          destinationSelected: currentPaymentDestination,
          transferType: "CPU",
          includeBranchFields: false,
          countrySubdivisions: subdivisionList,
          form,
        })
      : { additionalFields: [] };

  const {
    additionalFieldsAppriza,
    fetchAdditionalFieldsAppriza,
    isLoading: loadingAdditional,
  } = useBeneficiaryAdditionalFields({
    country: "",
  });
  const isLoading = useSelector((state: State) => state.beneficiary.loading) || bankAccountLoading;

  const loading = loadingAdditional || isLoading;

  async function showFullForm() {
    await trigger(["country", "destination", "bankAccount"]);

    const beneficiaryCountry = getFieldState("country");
    const beneficiaryBankName = getFieldState("destination");
    const beneficiaryBankAccount = getFieldState("bankAccount");

    const show = !(beneficiaryCountry.invalid || beneficiaryBankName.invalid || beneficiaryBankAccount.invalid);

    setFullForm(show);
    if (show) {
      document?.getElementById("general-data-form")?.scrollIntoView({ behavior: "smooth" });
    }
  }

  const processSubmit = handleSubmit(async (data) => {
    const userAuthPerson = await dispatch(getStatusPersonKYC());
    if (userAuthPerson.type === GET_PERSON_ID_FULFILLED) {
      const { personId } = userAuthPerson.payload as tByUserId;
      const date = formatDOBString(data.dateOfBirth).split("/");
      const destinationSelected =
        currentPaymentDestination || bankList.find((destination) => destination.destination === data.destination);

      const formData: BeneficiaryForm = {
        personId,
        recipient: {
          firstName: data.firstName?.trim(),
          middleName: data.middleName?.trim(),
          lastName: data.lastNames?.trim(),
          alias: data.alias?.trim(),
          dateOfBirth: `${date[2]}-${date[0]}-${date[1]}`,
          phoneNumber: `${data.phoneCountryCode}${data.PhoneNumber?.trim()}`,
          email: data.email?.trim(),
          addressType: "Ho",
          address: data.address1?.trim(),
          addressExtra: data.address2,
          city: data.city,
          subnational: `${data.subdivision}`,
          country: data.country,
          countryOfBirth: data.country,
          nationality: data.country,
          postalCode: data.postalCode?.trim(),
          gender: "M",
        },
      };

      if (isBankTransfer && destinationSelected) {
        formData.account = [
          {
            destinationId: destinationSelected.id,
            accountTypeId: data.accountTypeId,
            accountNumber: data.bankAccount,
            additionalFieldInfo: additionalFields.map((field) => ({
              fieldBelongsTo: field.fieldBelongsTo,
              fieldName: field.fieldName,
              fieldValue: getValues(field.fieldName),
            })),
            branch: getValues("branch"),
          },
        ];

        if (data.beneIfscCode) {
          formData.account[0].ifscCode = data.beneIfscCode;
        }

        formData.recipient.additionalField = {
          additionalFieldInfo: additionalFieldsAppriza.map((field) => ({
            fieldBelongsTo: "",
            fieldName: field.fieldName,
            fieldValue: getValues(field.fieldName),
          })),
        };
      } else if (isCashPickup && currentPaymentDestination) {
        formData.account = [
          {
            destinationId: currentPaymentDestination.id,
            accountTypeId: data.accountTypeId,
          },
        ];
        formData.recipient.additionalField = {
          additionalFieldInfo: beneficiaryAdditionalFields.map((field) => ({
            fieldBelongsTo: field.fieldBelongsTo,
            fieldName: field.fieldName,
            fieldValue: getValues(field.fieldName),
          })),
          branch: getValues("branch"),
        };
      }

      dispatch(
        BeneficiarySlice.actions.setBeneficiaryToSave({
          ...formData,
          bankName: destinationSelected?.destination,
          rail: destinationSelected?.rail,
        })
      );

      if (isCashPickup && currentPaymentDestination?.isPayerBranchRequired) {
        navigate("/select-recipient-branch");
      } else {
        navigate("/recipient-confirmation");
      }
    }
  });

  useEffect(() => {
    if (fullForm) {
      document?.getElementById("general-data-form")?.scrollIntoView({ behavior: "smooth" });
    }
  }, [fullForm]);

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      if (name === "subdivision" && type === "change") {
        setValue("city", "");
        cityRef.current?.clearValue();
        await getSubdivisionCitiesOptions(value.subdivision);
      }

      if (name === "country" && type === "change") {
        const { country } = value;
        setSelectedCountry(country);

        if (allowedCountries.includes(country)) {
          getSubdivisionOptions(country, language);
        } else {
          setSubdivisionList([]);
        }

        setValue("destination", "");
        destinationRef.current?.clearValue();

        setValue("subdivision", "");
        subdivRef.current?.clearValue();
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, bankList, allowedCountries]);

  useEffect(() => {
    if (lang) {
      setValue("country", selectedCountry);

      if (allowedCountries.includes(selectedCountry)) {
        getSubdivisionOptions(selectedCountry, lang.language);
      }

      fetchAdditionalFieldsAppriza(selectedCountry);
    }
  }, [selectedCountry, allowedCountries, lang]);

  useEffect(() => {
    if (isEmpty(phoneCountryCodeSelect)) {
      setPhoneCountryCodeSelect(countriesWithoutUSAOptions.find((c) => c.value === selectedCountry)?.shortCode);
    }
  }, [countriesWithoutUSAOptions, incomingCountry]);

  const phoneMinLenght = useMemo(() => {
    const minLengthPhone = CountryCodeList.find((c) => c.countryCode === selectedCountry)?.phoneMinLength;

    return minLengthPhone;
  }, [phoneCountryCodeSelect]);

  const zipCodeMinLenght = useMemo(() => {
    const minLength = CountryCodeList.find((c) => c.countryCode === incomingCountry)?.zipCodeLength || 5;

    return minLength;
  }, [phoneCountryCodeSelect]);

  useEffect(() => {
    const bankName = currentPaymentDestination?.destination;
    const destinationSelected = bankList.find((dest) => dest.destination === bankName);
    const AccountTypeList: AccountTypes[] = [];
    if (destinationSelected) {
      destinationSelected?.destinationExpressions?.map((item) => {
        AccountTypeList.push({
          label: language === "es" ? item.labelSP : item.labelEN,
          value: item.accountType,
          key: item.accountType,
        });
      });
    }
    setAccountTypeList(AccountTypeList);
  }, [bankList, language]);

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} loading={loading}>
        <FormProvider {...form}>
          <form onSubmit={processSubmit}>
            {isBankTransfer && (
              <>
                <Container>
                  <Row>
                    <Column span={12} as={Header}>
                      <Title
                        tagName="h1"
                        text={t("Beneficiaries.CreateBeneficiary.Title")}
                        color="black"
                        size={6}
                        align="left"
                      />

                      <Text color="black" size={1} align="left">
                        {t("Beneficiaries.CreateBeneficiary.Subtitle")}
                      </Text>
                    </Column>
                  </Row>
                </Container>
                <FlexContainer p="20px">
                  <BeneficiaryAccount
                    country={incomingCountry}
                    countrySubdivisions={subdivisionList}
                    AccountTypeList={AccountType}
                    showOnNextButton
                    showCountry
                    showAccountHeading
                    onAdditionalFields={(additionalFields) => setAdditionalFields(additionalFields)}
                    onBankList={(bankList) => bankList && setBankList(bankList)}
                    onNext={() => showFullForm()}
                    onLoading={(loading) => {
                      setBankAccountStatus(loading ? "loading" : "idle");
                    }}
                  />
                </FlexContainer>
              </>
            )}

            {(fullForm || !isBankTransfer) && (
              <Container id="general-data-form" className="form-extra">
                <Row>
                  <Column span={12} px={3} justifyContent="flexStart" alignItems="center" as={SectionStep}>
                    {isBankTransfer && <IconFont name="step-two" size="xxlarge" color="primary" gradient />}
                    <h2>{t("Beneficiaries.CreateBeneficiary.PersonalInfoTitle")}</h2>
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <FormInput
                      textLabel={t("Beneficiaries.CreateBeneficiary.Form.FirstName")}
                      label="firstName"
                      rules={{
                        required: t("Forms.required"),
                        validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
                      }}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <FormInput
                      textLabel={t("Beneficiaries.CreateBeneficiary.Form.MiddleName")}
                      label="middleName"
                      rules={{
                        pattern: {
                          value: allowedCompoundNames,
                          message: t("Forms.inputAlphabets"),
                        },
                      }}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <FormInput
                      textLabel={t("Beneficiaries.CreateBeneficiary.Form.LastName")}
                      label="lastNames"
                      rules={{
                        required: t("Forms.required"),
                        validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
                      }}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <FormInput
                      textLabel={t("Beneficiaries.CreateBeneficiary.Form.Alias")}
                      label="alias"
                      rules={{}}
                      infoText={t("Beneficiaries.CreateBeneficiary.Form.AliasHint")}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <InputDateMask
                      textLabel={t("Beneficiaries.CreateBeneficiary.Form.DateOfBirth")}
                      label="dateOfBirth"
                      placeholderInput="MM/DD/YYYY"
                      size="xxxlarge"
                      handleChange={handleDobChange}
                      rules={{
                        required: t("Forms.required"),
                        pattern: {
                          value: allowedDate,
                          message: t("Forms.person.invalidDate"),
                        },
                        validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
                      }}
                      register={register}
                      errors={errors}
                      mb="20px"
                      full
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <FormInput
                      textLabel={t("Beneficiaries.CreateBeneficiary.Form.Address1")}
                      label="address1"
                      rules={{
                        required: t("Forms.required"),
                        validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
                      }}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <FormInput
                      textLabel={t("Beneficiaries.CreateBeneficiary.Form.Address2")}
                      label="address2"
                      rules={{}}
                    />
                  </Column>
                </Row>

                {isCashPickup &&
                  (incomingCountry ? (
                    <>
                      <FlexContainer>
                        <Text size={0} color="grey" align="left" weight={600} margin="10px 10px 0 10px">
                          {t("Beneficiaries.CreateBeneficiary.Form.Country")}
                        </Text>
                      </FlexContainer>
                      <FlexContainer p="1rem">
                        <FlexItem w="auto">{incomingCountryIcon && <CountryIcon src={incomingCountryIcon} />}</FlexItem>
                        <Text size={1} weight={400} color="grey" margin="8px 0" align="left">
                          {countriesWithoutUSAOptions.find((c) => c.value === incomingCountry)?.label}
                        </Text>
                      </FlexContainer>
                    </>
                  ) : (
                    <Row>
                      <Column span={12} px={3}>
                        <Controller
                          control={control}
                          name="country"
                          rules={{ required: t("Forms.required") }}
                          render={({ field: { onBlur, onChange, value } }) => (
                            <>
                              <SearchSelect
                                labelProps={{ color: "grey", size: 0 }}
                                errors={errors}
                                label={t("Beneficiaries.CreateBeneficiary.Form.Country")}
                                options={countriesWithoutUSAOptions}
                                name="country"
                                placeholder=""
                                onBlur={onBlur}
                                onChange={(sel) => onChange(sel?.value || "")}
                                value={countriesWithoutUSAOptions.find((c) => c.value === value)}
                              />
                            </>
                          )}
                        />
                      </Column>
                    </Row>
                  ))}

                <Row>
                  <Column span={12} px={3}>
                    <Controller
                      control={control}
                      name="subdivision"
                      rules={{ required: t("Forms.required") }}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <>
                          <SearchSelect
                            labelProps={{ color: "grey", size: 0 }}
                            errors={errors}
                            selectRef={subdivRef}
                            label={t("Beneficiaries.CreateBeneficiary.Form.State")}
                            options={subdivisionList}
                            name="subdivision"
                            placeholder=""
                            onBlur={onBlur}
                            onChange={(sel) => onChange(sel?.value || "")}
                            value={subdivisionList.find((s) => s.value === value)}
                          />
                        </>
                      )}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <Controller
                      control={control}
                      name="city"
                      rules={{ required: t("Forms.required") }}
                      render={({ field: { onBlur, onChange, value } }) => (
                        <SearchSelect
                          labelProps={{ color: "grey", size: 0 }}
                          errors={errors}
                          selectRef={cityRef}
                          label={t("Beneficiaries.CreateBeneficiary.Form.City")}
                          name="city"
                          placeholder=""
                          options={citiesList}
                          onBlur={onBlur}
                          onChange={(sel) => onChange(sel?.value || "")}
                        />
                      )}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <FormInput
                      textLabel={t("Beneficiaries.CreateBeneficiary.Form.PostalCode")}
                      maxLength={zipCodeMinLenght}
                      label="postalCode"
                      rules={{
                        required: t("Forms.required"),
                        minLength: {
                          value: zipCodeMinLenght,
                          message: t("Beneficiaries.CreateBeneficiary.Form.PostalCodeMinLength", {
                            minLength: zipCodeMinLenght,
                          }),
                        },
                        validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
                      }}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <FlagPhoneNumberInput
                      labelText={t("Beneficiaries.CreateBeneficiary.Form.PhoneNumber")}
                      label="PhoneNumber"
                      rules={{
                        required: t("Forms.required"),
                        validate: (value: string) => (value.trim() === "" ? t("Forms.required") : true),
                        maxLength: {
                          value: CountryCodeList.find((c) => c.countryCode === selectedCountry)?.phoneMaxLength || 10,
                          message: t("Beneficiaries.CreateBeneficiary.Form.MaxLengthPhoneNumber"),
                        },
                        minLength: {
                          value: phoneMinLenght || 10,
                          message: t("Beneficiaries.CreateBeneficiary.Form.MinLengthPhoneNumber", {
                            minLength: phoneMinLenght || 10,
                          }),
                        },
                      }}
                      register={register}
                      errors={errors}
                      countries={countriesWithoutUSAOptions.map((c) => c.shortCode as CountryCode)}
                      countryDefault={
                        countriesWithoutUSAOptions.find((c) => c.value === selectedCountry)?.shortCode as CountryCode
                      }
                      setCountryCode={(code: string) => setPhoneCountryCodeSelect(code)}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column span={12} px={3}>
                    <FormInput
                      textLabel={t("Beneficiaries.CreateBeneficiary.Form.Email")}
                      typeInput="email"
                      label="email"
                      rules={{
                        required: t("Forms.required"),
                        pattern: {
                          value: allowedEmail,
                          message: t("Beneficiaries.CreateBeneficiary.Validation.InvalidEmail"),
                        },
                        validate: (value) => (value.trim() === "" ? t("Forms.required") : true),
                      }}
                    />
                  </Column>
                </Row>

                {beneficiaryAdditionalFields &&
                  beneficiaryAdditionalFields.map((field) => (
                    <Row key={field.fieldName}>
                      <Column span={12} px={3}>
                        <DynamicInput {...field} />
                      </Column>
                    </Row>
                  ))}

                {additionalFieldsAppriza &&
                  additionalFieldsAppriza.map((field) => (
                    <Row key={field.fieldName}>
                      <Column span={12} px={3}>
                        <DynamicInput {...field} />
                      </Column>
                    </Row>
                  ))}

                <Row>
                  <Column span={12}>
                    <ButtonSection>
                      <Button
                        text={t("Beneficiaries.CreateBeneficiary.Form.Submit")}
                        variant={isFormValid ? "primary" : "default"}
                        sizeText="large"
                        sizeButton="medium"
                        disabled={!isFormValid}
                      />
                    </ButtonSection>
                  </Column>
                </Row>
              </Container>
            )}
          </form>
        </FormProvider>
      </Layout>
    </ThemeProvider>
  );
}
