export const KYC_STARTED = "started";
export const KYC_EXPIRED = "expired";
export const KYC_ABANDONED = "abandoned";
export const KYC_APPROVED = "approved";
export const KYC_IN_REVIEW = "inReview";
export const KYC_DECLINED = "declined";
export const KYC_SUBMITTED = "submitted";
export const KYC_NOT_STARTED = "notStarted";
export const KYC_RESUBMISSION = "resubmission";
export const KYC_RESUBMISSION_REQUESTED = "resubmission_requested";
