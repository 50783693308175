import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { FormProvider, Controller, useForm } from "react-hook-form";

// own states
import { AppDispatch, State } from "../../Redux/Store";
import { PaymentAchSlice } from "../../Redux/PaymentACH/PaymentACH.slice";

// Own components
import Button from "../../Components/Button";
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Title from "../../Components/Title";
import Text from "../../Components/Text";
import { FormInput } from "../../Components/Input";
import { SearchSelect } from "../../Components/SearchSelect/SearchSelect.component";

import { AlignItems, JustifyContent } from "../../Types/Column";
import { tPaymentACH } from "../../Models/Payments";
import { useNavigate } from "react-router";
import CurrencyService from "../../Services/CurrencyService";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import {
  allowsNumbersAccountNumber,
  allowsNumbersRoutingNumber,
} from "../../Constants/Regexp";
import { FlexContainer } from "../../Components/Flex/Flex.styled";

// Options taken by React Select
type SelectOption = {
  label: string;
  value: string;
};

const BANK_ACCOUNT_TYPES = [
  {
    value: "Checking account",
    label: "Checking account",
  },
  {
    value: "Savings account",
    label: "Savings account",
  },
];

const PaymentACH = () => {
  const [tSendMoney] = useTranslation("sendMoney");
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // Form stuff
  const form = useForm<tPaymentACH>({ mode: "onChange" });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid: isFormValid },
  } = form;
  const { currentQuotation, currentDeliveryMethodQuote, currentPaymentMethod } =
    useSelector(QuotationSelector).data;

  async function processSubmit(data: tPaymentACH) {
    dispatch(PaymentAchSlice.actions.setAchInformation(data));
    navigate("/review-transaction");
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/payments" textLink={t("buttons.back")}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(processSubmit)}>
            <Container>
              <Row>
                <Column span={12} px={2}>
                  <Title
                    tagName="h1"
                    text={t("PaymentACH.title")}
                    color="black"
                    size={6}
                    align="left"
                  />
                </Column>
                <Column span={6} xs={12} px={2}>
                  <FlexContainer w="100%" justify="space-between">
                    <FlexContainer alignItems="center">
                      <Text color="black" size={1} align="left" weight={500}>
                        {t("PaymentACH.subtitle")}
                      </Text>
                    </FlexContainer>
                    <FlexContainer flex="1 1 auto" alignItems="center">
                      <Text color="black" size={3} align="right" weight={600}>
                        {`$ ${CurrencyService.getUSDAmountWithFees(
                          currentQuotation?.amount?.toString() || "0",
                          (currentDeliveryMethodQuote?.fee || 0) +
                            (currentPaymentMethod?.paymentMethodFee || 0)
                        )} USD`}
                      </Text>
                    </FlexContainer>
                  </FlexContainer>
                </Column>
                <Column span={12} px={2}>
                  <Text color="grey" size="default" align="left" weight={400}>
                    {t("PaymentACH.description")}
                  </Text>
                </Column>
                <Column span={12} px={2}>
                  <Text color="black" size={2} align="left" weight={600}>
                    {t("PaymentACH.formTitle")}
                  </Text>
                </Column>
                <Column span={8} xs={12} pt={1} pb={2}>
                  <Row>
                    <Column span={12} px={2} pt={2}>
                      <FormInput
                        textLabel={t("PaymentACH.labelHolderName")}
                        label="holderName"
                        rules={{
                          required: t("Forms.required"),
                          validate: (value: string) =>
                            value.trim() === "" ? t("Forms.required") : true,
                        }}
                      />
                    </Column>
                    <Column span={12} px={2}>
                      <FormInput
                        textLabel={t("PaymentACH.labelBankName")}
                        label="bankName"
                        rules={{
                          required: t("Forms.required"),
                          validate: (value: string) =>
                            value.trim() === "" ? t("Forms.required") : true,
                        }}
                      />
                    </Column>
                    <Column span={12} pb={6} mx={2}>
                      <Controller
                        control={control}
                        name="bankAccountType"
                        rules={{ required: t("Forms.required") }}
                        render={({ field: { onBlur, onChange, value } }) => (
                          <SearchSelect
                            labelProps={{ color: "grey", size: 0 }}
                            errors={errors}
                            label={t("PaymentACH.labelBankAccountType")}
                            options={BANK_ACCOUNT_TYPES}
                            name="bankAccountType"
                            placeholder=""
                            search={false}
                            onBlur={onBlur}
                            onChange={(sel) =>
                              onChange((sel as SelectOption)?.value || "")
                            }
                            value={BANK_ACCOUNT_TYPES.find(
                              (c) => c.value === value
                            )}
                          />
                        )}
                      />
                    </Column>
                    <Column span={12} px={2}>
                      <FormInput
                        typeInput="number"
                        inputMode="numeric"
                        textLabel={t("PaymentACH.labelAccountNumber")}
                        maxLength={17}
                        minLength={8}
                        label="accountNumber"
                        rules={{
                          required: t("Forms.required"),
                          maxLength: {
                            value: 17,
                            message: t("Payments.Form.maxCharactersAccount"),
                          },
                          minLength: {
                            value: 8,
                            message: t("Payments.Form.minCharactersAccount"),
                          },
                          pattern: {
                            value: allowsNumbersAccountNumber,
                            message: t("Forms.inputNumeric"),
                          },
                          validate: (value: string) =>
                            value.trim() === "" ? t("Forms.required") : true,
                        }}
                      />
                    </Column>
                    <Column span={12} px={2}>
                      <FormInput
                        typeInput="number"
                        inputMode="numeric"
                        textLabel={t("PaymentACH.labelRouting")}
                        label="labelRouting"
                        maxLength={9}
                        rules={{
                          required: t("Forms.required"),
                          maxLength: {
                            value: 9,
                            message: t("Payments.Form.maxCharactersRouting"),
                          },
                          minLength: {
                            value: 9,
                            message: t("Payments.Form.minCharactersRouting"),
                          },
                          pattern: {
                            value: allowsNumbersRoutingNumber,
                            message: t("Forms.inputNumeric"),
                          },
                          validate: (value: string) =>
                            value.trim() === "" ? t("Forms.required") : true,
                        }}
                      />
                    </Column>
                    <Column
                      span={12}
                      mb={6}
                      px={1}
                      pt={6}
                      justifyContent={JustifyContent.center}
                    >
                      <Button
                        type="submit"
                        variant={isFormValid ? "primary" : "default"}
                        sizeButton="large"
                        sizeText="large"
                        sizeIcon="large"
                        text={tSendMoney("Next")}
                        colorIcon="white"
                        disabled={!isFormValid}
                      />
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Container>
          </form>
        </FormProvider>
      </Layout>
    </ThemeProvider>
  );
};

export default PaymentACH;
