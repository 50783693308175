import styled from "styled-components";

export const BanUserModalUserPhone = styled.a`
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 12px;
  box-sizing: border-box;
  min-width: 110px;
  padding: 0.7rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.Background_03};
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 1rem 0;

  &:hover {
    opacity: 0.9;
  }
`;
