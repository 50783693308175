import { createContext } from "react";
import { Feature, FeatureType } from "../../Modules/Common/domain/Feature";

export const FeatureContext = createContext<{
  features: {
    newQuoterFeature?: boolean;
    squarePaymentMethodFeature?: boolean;
    paypalPaymentMethodFeature?: boolean;
    venmoPaymentMethodFeature?: boolean;
    rewardsFeature?: boolean;
    rewardsPopupFeature?: boolean;
    authorizePaymentMethodFeature?: boolean;
  };
  getAppFeatures: (opts: { type?: FeatureType; reload?: boolean }) => Promise<Feature[]>;
  getUserFeature: (userId: string, featureCode: string, setUserFeature?: (active: boolean) => void) => Promise<boolean>;
  getAllUserFeatures: () => Promise<void>;
} | null>(null);
