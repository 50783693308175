import { BeneficiaryCreated } from "../domain/BeneficiaryCreated";
import { BeneficiaryForm } from "../domain/BeneficiaryForm";
import { BeneficiaryRepository } from "../domain/BeneficiaryRepository";

export const createBeneficiary =
  (beneficiaryRepository: BeneficiaryRepository) =>
  async (
    beneficiaryData: BeneficiaryForm,
    language: string
  ): Promise<BeneficiaryCreated> =>
    beneficiaryRepository.createBeneficiary(beneficiaryData, language);
