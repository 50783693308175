// Own redux and models
import { ZelleTransactionModalProps } from "../../../Models/ModalInterface";

// Own components
import Modal from "../Modal.component";
import ZelleTransaction from "../../ZelleTransaction";

// Own styles
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";

export const ZelleTransactionModal = (props: ZelleTransactionModalProps) => {
  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <ZelleTransaction transactionList={props?.transactionList} />
    </Modal>
  );
};
