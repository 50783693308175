import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import { tokenSelector } from "../Redux/Token/Token.slice";
import { TOKEN_KEY, TOKEN_TTL } from "../Constants/Token";
import { storeLogin } from "./Session";
import { AppDispatch } from "../Redux/Store";
import { jwtDecode } from "jwt-decode";

// This hook will tell you whether the current user is logged in, by doing two things:
// 1. Check if a token exists in sessionStorage
// 2. Get the timestamp stored in Redux and check if it's not older than the TTL
export function useIsSessionAlive() {
  const cookies = new Cookies();
  const token = cookies.get(TOKEN_KEY);
  const stateToken = useSelector(tokenSelector);
  const dispatch = useDispatch<AppDispatch>();

  if (!token) {
    return false;
  }

  if (token !== stateToken.value) {
    const decode = JSON.stringify(jwtDecode(token));
    const userId =
      JSON.parse(decode)[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ];
    storeLogin(dispatch, token, userId);

    return true;
  } else {
    // Both these values are in ms, hence why we divide by 1000 later
    const now = Date.now();
    const tokenTime = new Date(stateToken.timestamp).valueOf();

    if (stateToken.timestamp > 0) {
      // How long the token has been alive
      const tokenAge = (now - tokenTime) / 1000;

      // True if token is still alive
      return tokenAge < TOKEN_TTL;
    }
    return false;
  }
}
