import { Destination } from "../../Common/domain/Destination";
import { PaymentMethods } from "../../Common/domain/PaymentMethods";

export type QuoteDeliveryMethodCode = "D2B" | "CPU";

export type QuoteDeliveryMethod = {
  deliveryMethodId: number;
  deliveryMethodCode: QuoteDeliveryMethodCode;
  fee: number;
  fx: number;
  amountToReceive: number;
  paymentMethods: Array<QuotePaymentMethod>;
  paymentDestinations: Array<QuotePaymentMethodDestination>;
};

export type QuotePaymentMethodDestination = Omit<Destination, "code">;

export type QuotePaymentMethod = {
  default: boolean;
  paymentOrigin: PaymentMethods;
  paymentMethodFee: number;
  totalCost: number;
  discount?: number | null;
};

export const getQuoteDeliveryMethod = (
  deliveryMethods: QuoteDeliveryMethod[],
  currentMethod: QuoteDeliveryMethodCode
) => {
  return deliveryMethods.find((deliveryMethod) => deliveryMethod.deliveryMethodCode === currentMethod);
};
