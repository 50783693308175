import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useForm } from "react-hook-form";

// own states
import { State } from "../../Redux/Store";

// Own components
import Button from "../../Components/Button";
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Title from "../../Components/Title";
import Text from "../../Components/Text";

import { AlignItems, JustifyContent } from "../../Types/Column";

import {
  ContainerCard,
  IconSendolaPay,
  PaymentSendolaPayLogo,
} from "./Payments.styled";

import PaymentSendolaPaySrc from "../../Assets/Img/sendola-pay-logo-dark.svg";
import CheckboxInput from "../../Components/Checkbox";
import Link from "../../Components/Link";
import { useSendolaPayPaymentOrder } from "../../Hooks/useSendolaPayPaymentOrder";
import { useNavigate } from "react-router-dom";
import IconFont from "../../Components/IconFont";

const PaymentSendolaPay = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const navigate = useNavigate();

  const { isLoading, senderPrivacypolicy, personId } =
    useSendolaPayPaymentOrder();

  // Form stuff
  const {
    handleSubmit,
    formState: { errors, isValid: isFormValid },
    register,
  } = useForm({ mode: "onBlur" });

  async function processSubmit(data: any) {
    senderPrivacypolicy();
    navigate("/payment-sendola-pay-agreement");
  }

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(processSubmit)}>
        <Layout
          path="/payments"
          textLink={t("buttons.back")}
          loading={isLoading}
        >
          <Container>
            <Row>
              <Column span={12} justifyContent={JustifyContent.center}>
                <ContainerCard>
                  <Column
                    span={12}
                    xs={12}
                    px={3}
                    mb={6}
                    alignItems={AlignItems.center}
                    justifyContent={JustifyContent.spaceBetween}
                  >
                    <Title
                      tagName="h1"
                      text={"Sendola Pay"}
                      color="black"
                      size={4}
                      align="left"
                    />
                    <PaymentSendolaPayLogo src={PaymentSendolaPaySrc} />
                  </Column>
                  <Column
                    span={12}
                    px={3}
                    justifyContent={JustifyContent.center}
                  >
                    <IconSendolaPay>
                      <IconFont name="sendola-pay" size="xxxlarge" gradient />
                    </IconSendolaPay>
                  </Column>
                  <Column span={6} xs={12} pt={1} pb={2}>
                    <Row>
                      <Column span={12} px={2} py={1}>
                        <Text size={1} weight={400} align="left">
                          {t("Payments.sendolaPay.sendolaPayAgreementText1")}
                        </Text>
                      </Column>
                      <Column span={12} px={2} py={1}>
                        <Text size={1} weight={400} align="left">
                          {t("Payments.sendolaPay.sendolaPayAgreementText2")}
                        </Text>
                      </Column>
                      <Column span={12} px={2} py={1}>
                        <Text size={1} weight={400} align="left">
                          {t("Payments.sendolaPay.sendolaPayAgreementText3")}
                        </Text>
                      </Column>
                      <Column span={12} px={2} py={1}>
                        <CheckboxInput
                          id="check-e-signature-agreement"
                          variant="checkbox"
                          label="checkESignatureAgreement"
                          register={register}
                          errors={errors}
                          rules={{
                            required: t("Forms.required"),
                          }}
                        >
                          <Text size={1} margin="0" align="left">
                            {t("Payments.sendolaPay.sendolaAceptAgreement")}
                            <Link href={"/GlobalPrivacyPolicy"} target="blank">
                              {t("Payments.sendolaPay.sendolaAceptAgreement2")}
                            </Link>
                          </Text>
                        </CheckboxInput>
                      </Column>
                      <Column span={12} mb={6} px={1} pt={6}>
                        <Button
                          type="submit"
                          variant={isFormValid ? "primary" : "default"}
                          sizeButton="large"
                          sizeText="large"
                          sizeIcon="large"
                          iconButton="chevronRight"
                          text={t("Payments.sendolaPay.buttonContinue")}
                          colorIcon="white"
                          disabled={!isFormValid}
                        />
                      </Column>
                    </Row>
                  </Column>
                </ContainerCard>
              </Column>
            </Row>
          </Container>
        </Layout>
      </form>
    </ThemeProvider>
  );
};

export default PaymentSendolaPay;
