import theme from "styled-theming";
import { theme as config } from "./Theme";

export const avatarBackgroundColor = theme("currentTheme", {
  default: config.Platinum,
  martori: config.Platinum,
});

export const avatarTextColor = theme("currentTheme", {
  default: config.Black,
  martori: config.Black,
});
