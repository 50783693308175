import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { ConfigParameter } from "../../Modules/Common/domain/GlobalConfig";

const initialState: ConfigParameter = {
  customerId: null,
  customerName: null,
  invitationCode: null,
  gpTier: null,
  oneTier: null,
};

export const globalConfigSlice = createSlice({
  name: "globalConfig",
  initialState,
  reducers: {
    setConfig(state, { payload }) {
      return {
        ...state,
        customerId: payload?.customerId,
        customerName: payload?.customerName,
        invitationCode: payload?.invitationCode,
        gpTier: payload?.gpTier,
        oneTier: payload?.oneTier,
      };
    },
    clearConfig() {
      return { ...initialState };
    },
  },
});

export const globalConfigSelector = (state: State) => state.globalConfig;
