import styled from "styled-components";
import { theme } from "styled-tools";

import { ModalWrap } from "../Modal.styled";

export const StepLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 7px 9px;
  background-color: ${theme("Grey_Light")};
`;

export const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  color: ${theme("White")};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  border-radius: 50%;
  background: ${theme("VenmoGradient")};
`;

export const VenmoConfirmationWrap = styled(ModalWrap)`
  border-radius: 16px;
  border: 2px solid ${theme("VenmoColor")};
`;

export const RowIntructions = styled.div`
  background-color: ${theme("Background_01")};
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid ${theme("Primary_18")};
  padding: 12px 16px;
  margin: 24px 0;

  .text-strong {
    color: ${theme("ZelleColor")};
  }
`;

export const RowSteps = styled.div`
  margin: 16px 0;
`;

export const TextCopy = styled.div`
  display: flex;

  p {
    border-bottom: 1px solid ${theme("Primary_600")};

    &:hover {
      color: ${theme("ZelleColor")};
      border-bottom: none;
    }
  }
`;

export const ArrowBack = styled.div`
  position: absolute;
  top: 24px;
  z-index: 5;
`;
