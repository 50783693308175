import styled from "styled-components";
import mediaQuery, { Screen } from "../../../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const LinkedAccountCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px;
  background: ${theme("Background_01")};
  box-shadow: 0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15);
  justify-self: center;
  margin-bottom: 16px;

  &:hover {
    cursor: pointer;
  }

  span {
    font-weight: 600;
  }
`;

export const LinkedAccountRadioCardStyled = styled(LinkedAccountCardStyled)`
  &:hover {
    cursor: initial;
  }
`;

export const LinkedAccountEmptyImg = styled.div`
  background-color: ${theme("Image_Empty_bg")};
  height: 50px;
  width: 50px;
  position: relative;
  border-radius: 6px;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    color: ${theme("Image_Empty_Color")};
    transform: translate(-50%, -50%);
  }
`;

export const LinkedAccountContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & div:first-child {
    flex-grow: 1;
  }

  p > span {
    margin-right: 1.3em;
  }
`;

export const LinkedAccountCardAmount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinkedAccountContentArrow = styled.div`
  padding-right: 1.3em;
`;

export const LinkedAccountCardRadioStyled = styled.div`
  input {
    ::after {
      display: none;
    }

    :hover {
      outline: 2px solid ${theme("RADIO_SHADOW")};
      box-shadow: 0 0 0 4px ${theme("Background_01")};

      ::after {
        display: none;
      }
    }

    :checked,
    :focus {
      border-color: ${theme("Primary_500")};
      outline: 2px solid ${theme("RADIO_SHADOW")};
      box-shadow: inset 0 0 0 5px ${theme("Background_01")};

      ::after {
        display: none;
      }

      :hover {
        outline: 2px solid ${theme("RADIO_SHADOW")};
      }
    }
  }
`;
