import React, { PropsWithChildren } from "react";
import { useFirstErrorAutofocus } from "../../Hooks/useFirstErrorAutofocus";

interface FormProps extends PropsWithChildren {
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
}

export const Form = ({ children, onSubmit }: FormProps) => {
  useFirstErrorAutofocus();
  return (
    <form style={{ width: "100%", maxWidth: "393px", margin: "auto" }} onSubmit={onSubmit}>
      {children}
    </form>
  );
};
