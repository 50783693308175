import styled from "styled-components";
import mediaQuery, { mediaQueryMax, Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";
import searchIcon from "../../Assets/Icons/search.svg";
import { quoterCardFeeLabels } from "../../Contexts/ThemeProvider/Texts";
import { headerBackgroundColor } from "../../Contexts/ThemeProvider/Layout";

export const TransactionsContainer = styled.div`
  box-sizing: border-box;
  padding: 0 16px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const ItemsContainerPaginator = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;

export const ItemsContainer = styled(ItemsContainerPaginator)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 25px;
  ${mediaQuery[Screen.desktop]} {
    display: none;
  }
`;

export const ContentColumn = styled.div`
  margin: 64px 136px;

  @media screen and (${mediaQueryMax[Screen.md]}) {
    margin: 0 30px;
  }
`;

export const RecentTransactions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 30px 0;
  align-items: center;
`;

export const PaginationSection = styled.div`
  ${mediaQuery[Screen.mobile]} {
    display: none;
  }

  .page-item a.page-link:hover {
    background-color: ${headerBackgroundColor};
    color: ${theme("Background_01")};
  }

  .page-item.active .page-link {
    font-weight: 700;
    color: ${theme("Background_01")};
    background-color: ${quoterCardFeeLabels};
    border: ${quoterCardFeeLabels};
  }

  .page-item.disabled .page-link {
    color: ${theme("Background_04")};
    pointer-events: none;
    cursor: auto;
  }

  .page-item .page-link {
    color: ${headerBackgroundColor};
  }
`;

export const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const TransferMonthTitle = styled.p`
  width: 100%;
  max-width: 390px;
  text-align: left;
  margin: 20px 0 0 0;
`;
