const sizes = {
  12: "4.5rem", // 72px
  11: "4rem", // 64px
  10: "3.5rem", // 56px
  9: "3rem", // 48px
  8: "2.75rem", // 44px
  7: "2.5rem", // 40px
  6: "2.25rem", // 36px
  5: "2rem", // 32px
  4: "1.75rem", // 28px
  3: "1.5rem", // 24px
  2: "1.25rem", // 20px
  1.5: "1.125rem", // 18px
  1: "1rem", // 16px
  0.5: "0.875rem", // 14px
  0.3: "0.75rem", // 12px
  0.2: "0.525rem",
  0.1: "0.625rem",
  0: "1.125rem",
  default: "0.75rem", // 12px
};

export type SizesKey = keyof typeof sizes;

export const handleSizeType = (size?: SizesKey) => {
  return size || size === 0 ? sizes[size] : sizes["default"];
};
