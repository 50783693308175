import React, { useEffect, useState } from "react";
import { Item, Props } from "../../Models/selectInterface";
import Icon from "../Icon";
import isEmpty from "lodash.isempty";

import {
  ErrorContainer,
  ErrorMessage,
  Label,
  ListItem,
  Search,
  SectionIcon,
  SectionSelectList,
  SectionText,
  SelectHeader,
  SelectList,
  SelectStyled,
} from "./Select.styled";
import { useTranslation, withTranslation } from "react-i18next";
import { IconTypeKey } from "../../Models/IconInterface";

const Select = (props: Props) => {
  const {
    options,
    messagge,
    placeholder,
    size,
    getOptionSelected,
    errors,
    nameInput,
    valueState,
  } = props;

  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(
    valueState
  );
  const [selectedOptionIcon, setSelectedOptionIcon] = useState<
    IconTypeKey | ""
  >("");
  const [OptionValue, setOptionValue] = useState(options);
  const [currentTarget, setCurrentTarget] = useState<Element | null>();

  useEffect(() => {
    if (isOpen && currentTarget) {
      const searchInput = currentTarget.querySelector(
        ".searchInput"
      ) as HTMLElement;

      if (searchInput) {
        searchInput.focus();
      }
    }

    if (isEmpty(selectedOption) && valueState) {
      const valor = OptionValue.filter((item) => item.value === valueState);
      setSelectedOption(valor[0].label);
    }
  }, [isOpen, currentTarget, valueState]);

  const toggling = (event: React.MouseEvent) => {
    const { currentTarget } = event;
    setCurrentTarget(currentTarget?.closest(".containerSelected"));

    setIsOpen(!isOpen);
  };

  /**
   * Method to change state when selecting an option
   *
   * @param value string
   * @returns
   */
  const onOptionClicked = (value: Item) => () => {
    setSelectedOption(value.label);
    setSelectedOptionIcon(value.icon ? value.icon : "");
    getOptionSelected(value.value);
    setIsOpen(false);
  };

  const SearchOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = String(event.target.value).toUpperCase();
    const optionsList = [...OptionValue];
    optionsList.forEach((item: Item) => {
      const label = item.label.toUpperCase();
      if (label.includes(searchText)) {
        item.visible = true;
      } else {
        item.visible = false;
      }
    });
    setOptionValue(optionsList);
  };

  const clearInput = () => {
    setSelectedOption(null);
    const optionsList = [...OptionValue];
    optionsList.forEach((item: Item) => {
      item.visible = true;
    });
    setOptionValue(optionsList);
  };

  return (
    <SelectStyled $size={size} {...props} className="Container">
      {messagge && <Label>{messagge}</Label>}

      <SelectHeader
        className="containerSelected"
        $error={errors ? errors[nameInput] : null}
        {...props}
      >
        {!isOpen ? (
          <>
            <SectionText onClick={toggling}>
              {selectedOptionIcon && (
                <Icon
                  icon={selectedOptionIcon}
                  color="black"
                  size="small"
                  fillIcon={false}
                />
              )}
              {selectedOption || placeholder}
            </SectionText>

            {isEmpty(selectedOption) ? (
              <SectionIcon onClick={toggling}>
                <Icon
                  icon="chevronDown"
                  color="black"
                  size="small"
                  fillIcon={false}
                />
              </SectionIcon>
            ) : (
              <SectionIcon onClick={clearInput}>
                <Icon
                  icon="circleX"
                  color="black"
                  size="small"
                  fillIcon={false}
                />
              </SectionIcon>
            )}
          </>
        ) : (
          <Search
            className="searchInput"
            type="text"
            placeholder={t("global.search")}
            onChange={(e) => SearchOption(e)}
          />
        )}
      </SelectHeader>
      {isOpen && (
        <SectionSelectList>
          <SelectList $size={size}>
            {OptionValue.map(
              (option: Item) =>
                option.visible && (
                  <ListItem
                    onClick={onOptionClicked(option)}
                    key={Math.random()}
                    className={
                      selectedOption === option.label ? "selected" : ""
                    }
                    {...props}
                  >
                    {option.icon && (
                      <Icon
                        icon={option.icon}
                        color="black"
                        size="small"
                        fillIcon={false}
                      />
                    )}
                    <span>{option.label}</span>
                  </ListItem>
                )
            )}
          </SelectList>
        </SectionSelectList>
      )}
      {errors && errors[nameInput] && (
        <ErrorContainer>
          <Icon
            icon="alertTriangle"
            color="error"
            size="small"
            fillIcon={false}
          />
          <ErrorMessage>{errors[nameInput].message}</ErrorMessage>
        </ErrorContainer>
      )}
    </SelectStyled>
  );
};

export default withTranslation("ns")(Select);
