import styled from "styled-components";
import { FlexContainer } from "../Flex/Flex.styled";
import { theme } from "styled-tools";

export const CouponBarContainer = styled(FlexContainer)`
  justify-content: start;
  padding: 4.12px;
  gap: 6px;
`;

export const CouponInputStyled = styled.div<{ $isError: string }>`
  width: 100%;

  input {
    border: 1px solid
      ${(props) => (props.$isError === "4001" || props.$isError === "4002" ? theme("Primary_20") : theme("Primary_18"))};
    border-radius: 4px;
    box-sizing: border-box;
    color: ${theme("Primary_03_100")};
    font-size: 12px;
    font-weight: 600;
    padding: 4px 8px;
    text-transform: uppercase;
    width: 100%;
  }
`;

export const ButtonStyled = styled.button`
  background-color: ${theme("Primary_600")};
  border-radius: 4px;
  color: ${theme("Background_01")};
  cursor: pointer;
  font-size: 10px;
  height: 26px;
  padding: 4px 8px;
  width: 54px;

  &:disabled {
    background: ${theme("Primary_18")};
    color: ${theme("Background_01")};
    border: 1px solid ${theme("Primary_18")};
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const ContainerText = styled.div`
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  padding: 0px 3px;
  color: ${theme("Content_UX_02")};
  margin-top: -15px;
  width: 100%;

  &.hidden {
    display: none;
  }
  * {
    color: ${theme("Content_UX_02")};
  }
`;

export const Span = styled.span<{ $error?: boolean }>`
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: ${(props) => (props.$error ? props.theme.Error : props.theme.Background_02)};
  width: 100%;
  flex-grow: 1;
  text-align: left;
`;
Span.displayName = "InputSpan";
