import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { PaymentOrderState } from "../../Modules/PaymentOrders/domain/PaymentOrderState";

const initialState: PaymentOrderState = {
  loading: false,
  error: null,
  data: undefined,
};

export const paymentOrderSlice = createSlice({
  name: "paymentOrder",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
      return state;
    },
    setPaymentOrderCreated(state, action) {
      state.data = action.payload;
      return state;
    },
  },
});

export const paymentOrderSelector = (state: State) => state.paymentOrder;
