import { useMemo, useState } from "react";
import { Step } from "../Models/stepsInterface";
import { useTranslation } from "react-i18next";

export const useSendMoneyStepper = (props?: { initialActiveStep: number }) => {
  const { initialActiveStep } = props || { initialActiveStep: 1 };

  const [tSendMoney] = useTranslation("sendMoney");
  const steps = useMemo(() => {
    const sendMoneySteps: Step[] = [
      { step: 1, label: tSendMoney("steps.quote") },
      { step: 2, label: tSendMoney("steps.recipients") },
      { step: 3, label: tSendMoney("steps.payment") },
      { step: 4, label: tSendMoney("steps.summary") },
      { step: 5, label: tSendMoney("steps.finish") },
    ];

    return sendMoneySteps;
  }, [tSendMoney]);

  const [activeStep, setActiveStep] = useState(initialActiveStep);

  return { steps, activeStep, setActiveStep };
};
