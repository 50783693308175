import styled, { css } from "styled-components";
import { theme, ifProp, prop } from "styled-tools";

import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { SizesKey } from "../../Utilities/Sizes";
import { setTextSize } from "../Text/functions/setTextSize";
import {
  inputActiveColor,
  inputBackgroundColor,
  inputPlaceholderColor,
} from "../../Contexts/ThemeProvider/Input";

export const FlagPhoneNumberContainer = styled.div<{
  $error: boolean;
  mb?: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${prop("mb")};

  input {
    border: 1px solid
      ${ifProp("$error", theme("Error"), theme("Background_03"))};
    border-radius: 12px;
    padding: 13px 15px;
    margin-left: 10px;

    &:focus-visible {
      outline-color: ${theme("Background_03")};
    }
  }
`;

export const FlagInputContainer = styled.div`
  display: flex;

  input {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

export const FlagDropdown = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;

  .dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 13px 15px;
    background-color: ${theme("Background_01")};
    border: 1px solid ${theme("Background_03")};
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease;
    border-radius: 12px;
    margin-right: 8px;
    margin-top: 4px;
  }

  .dropdown-option-label {
    flex-grow: 1;
    font-weight: bold;
  }

  .dropdown-caret {
    display: inline-block;
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: ${theme("Background_03")} transparent transparent transparent;
    border: 1px solid ${theme("Background_03")};
    border-radius: 12px;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    padding: 0 0 12px 0;
    background-color: ${theme("Background_01")};
    border: 1px solid ${theme("Background_03")};
    border-top: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    list-style: none;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 12px;
    font-size: 16px;
    font-size: 500;
  }

  .dropdown-menu.open {
    opacity: 1;
    visibility: visible;
  }

  .dropdown-menu li {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .dropdown-menu li:hover {
    background-color: ${theme("Background_03")};
  }

  .dropdown-menu li .dropdown-option-image {
    margin-right: 12px;
  }

  .dropdown-menu li .dropdown-option-label {
    flex-grow: 1;
    font-weight: bold;
  }

  /* Centering the dropdown */
  .dropdown.open .dropdown-menu {
    transform: translateY(0);
  }

  /* Adjust dropdown width */
  .dropdown.open .dropdown-toggle {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
  }

  .dropdown.open .dropdown-menu {
    border-radius: 0 0 4px 4px;
    border-top: none;
  }
`;

export const FlagImgDropdown = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  img {
    display: inline;
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: center;
    margin: auto;
  }
`;

export const LabelPhone = styled.label`
  color: ${theme("Content_UX_02")};
  display: flex;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  width: 100%;

  ${mediaQuery[Screen.xs]} {
    font-size: 16px;
  }
`;

export const ErrorContainer = styled.div`
  color: ${theme("Error")};
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 0 8px;
`;

export const ErrorMessage = styled.div`
  font-size: 14px;
  margin-left: 8px;
  font-weight: 500;
  line-height: 21px;
`;
