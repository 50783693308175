import styled, { css } from "styled-components";
import { prop, switchProp, theme } from "styled-tools";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const StepContainer = styled.div<{ width?: string; padding?: string }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: ${prop("width", "100%")};
  gap: 8px;
  margin-right: auto;
  margin-left: auto;
  padding: ${prop("padding", "0 22px")};
`;

export const StepWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  max-width: 157px;
  flex: 1 1 auto;
  gap: 8px;
`;

export const StepBar = styled.div<{ status: "current" | "done" | "default" }>`
  width: 100%;
  height: 4px;
  ${switchProp("status", {
    current: css`
      background-color: ${theme("Green_05")};
      filter: brightness(0.5);
    `,
    done: css`
      background-color: ${theme("Green_05")};
    `,
    default: css`
      background-color: ${theme("Background_05")};
    `,
  })};
`;

export const StepsLabelContainer = styled.div`
  width: 100%;
`;

export const StepFinishIconContainer = styled.div`
  margin: auto;

  span {
    font-size: 18px;
  }
`;

export const StepLabel = styled.p`
  text-align: center;
  font-size: 0.7rem;
  margin-top: 0;

  ${mediaQuery[Screen.desktop]} {
    font-size: 1.1rem;
  }
`;
