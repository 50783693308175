import { createSlice } from "@reduxjs/toolkit";
import { getStatusPersonKYC } from "./PersonsKYC.actions";
import { State } from "../Store";
import { PersonState } from "../../Modules/Person/domain/Person";

const initialState: PersonState = {
  loading: false,
  error: null,
  data: {
    isLocked: false,
    personVerifyKYC: undefined,
    personVeriff: undefined,
    currentPerson: undefined,
  },
};

export const PersonsKYCSlice = createSlice({
  name: "PersonsKYC",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
      return state;
    },
    blockUser(state, action) {
      if (state.data) {
        state.data.isLocked = true;
      }
    },
    unBlockUser(state, action) {
      if (state.data) {
        state.data.isLocked = false;
      }
    },
    setPersonVeriff(state, action) {
      state.data = {
        ...(state.data || {}),
        personVeriff: action.payload,
      };
      state.error = null;
    },
    setCurrentPerson(state, action) {
      state.data = {
        ...(state.data || {}),
        currentPerson: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line
      .addCase(getStatusPersonKYC.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      // eslint-disable-next-line
      .addCase(getStatusPersonKYC.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.error = null;
      })
      // eslint-disable-next-line
      .addCase(getStatusPersonKYC.rejected, (state: any, action: any) => {
        state.loading = false;
        state.data = initialState.data;
        state.error = action.payload;
      });
  },
});

export default PersonsKYCSlice.reducer;

export const PersonsKYCSelector = (state: State) => state.PersonsKYC;
