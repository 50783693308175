import { createSlice } from "@reduxjs/toolkit";
import { TokenValidationAction } from "./TokenValidation.actions";
import { State } from "../Store";

export interface TokenValidationState {
  loading: boolean;
  error: unknown | null;
  data: {
    message?: string;
  };
}

const initialState: TokenValidationState = {
  loading: false,
  error: null,
  data: {
    message: undefined,
  },
};

export const TokenValidationSlice = createSlice({
  name: "TokenValidation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line
      .addCase(TokenValidationAction.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      // eslint-disable-next-line
      .addCase(TokenValidationAction.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.data = action.payload;
      })
      // eslint-disable-next-line
      .addCase(TokenValidationAction.rejected, (state: any, action: any) => {
        state.loading = false;
        state.data = {};
        state.error = action.payload.response;
      });
  },
});

export default TokenValidationSlice.reducer;

export const TokenValidationSelector = (state: State) => state.TokenValidation;
