export const CountryCodeList = [
  {
    country: "US",
    countryCode: "USA",
    phoneMaxLength: 10,
    phoneMinLength: 10,
    zipCodeLength: 5,
  },
  {
    country: "MX",
    countryCode: "MEX",
    phoneMaxLength: 10,
    phoneMinLength: 10,
    zipCodeLength: 5,
  },
  {
    country: "AR",
    countryCode: "ARG",
    phoneMaxLength: 10,
    phoneMinLength: 10,
    zipCodeLength: 5,
  },
  {
    country: "CL",
    countryCode: "CHL",
    phoneMaxLength: 9,
    phoneMinLength: 8,
    zipCodeLength: 5,
  },
  {
    country: "CO",
    countryCode: "COL",
    phoneMaxLength: 10,
    phoneMinLength: 10,
    zipCodeLength: 6,
  },
  {
    country: "EC",
    countryCode: "ECU",
    phoneMaxLength: 10,
    phoneMinLength: 7,
    zipCodeLength: 6,
  },
  {
    country: "BR",
    countryCode: "BRA",
    phoneMaxLength: 10,
    phoneMinLength: 9,
    zipCodeLength: 5,
  },
  {
    country: "BO",
    countryCode: "BOL",
    phoneMaxLength: 8,
    phoneMinLength: 8,
    zipCodeLength: 5,
  },
  {
    country: "PE",
    countryCode: "PER",
    phoneMaxLength: 8,
    phoneMinLength: 6,
    zipCodeLength: 5,
  },
  {
    country: "UY",
    countryCode: "URY",
    phoneMaxLength: 8,
    phoneMinLength: 8,
    zipCodeLength: 5,
  },
  {
    country: "GT",
    phoneMaxLength: 8,
    phoneMinLength: 8,
    zipCodeLength: 5,
    countryCode: "GTM",
  },
  {
    country: "SL",
    countryCode: "SLV",
    phoneMaxLength: 8,
    phoneMinLength: 7,
    zipCodeLength: 5,
  },
  {
    country: "HN",
    countryCode: "HND",
    phoneMaxLength: 8,
    phoneMinLength: 8,
    zipCodeLength: 5,
  },
  {
    country: "CA",
    countryCode: "CAN",
    phoneMaxLength: 10,
    phoneMinLength: 10,
    zipCodeLength: 6,
  },
  {
    country: "NI",
    countryCode: "NIC",
    phoneMaxLength: 8,
    phoneMinLength: 8,
    zipCodeLength: 5,
  },
  {
    country: "PH",
    countryCode: "PHL",
    phoneMaxLength: 9,
    phoneMinLength: 7,
    zipCodeLength: 5,
  },
  {
    country: "IN",
    countryCode: "IND",
    phoneMaxLength: 10,
    phoneMinLength: 10,
    zipCodeLength: 6,
  },
  {
    country: "VN",
    countryCode: "VNM",
    phoneMaxLength: 11,
    phoneMinLength: 10,
    zipCodeLength: 6,
  },
  {
    country: "PA",
    countryCode: "PAN",
    phoneMaxLength: 8,
    phoneMinLength: 8,
    zipCodeLength: 4,
  },
];
