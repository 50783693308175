import { createAsyncThunk } from "@reduxjs/toolkit";
import { createConfigApiApp } from "../../Services/ApiApp";
import { GET_USER_FEATURE } from "../../Constants/UserFeature";
import {
  getRequestFeature,
  UserFeaturesResponse,
} from "../../Models/UserFeature";
import { ApiRequestError } from "../../Types/ApiRequestError";

const client = createConfigApiApp();

/**
 * Method to get user feature
 */
export const GetUserFeatureAction = createAsyncThunk(
  GET_USER_FEATURE,
  async (payload: getRequestFeature, { rejectWithValue }) => {
    try {
      const response = await client.get<UserFeaturesResponse>(
        `/userfeatures/${payload.userId}/${payload.featureCode}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error as ApiRequestError);
    }
  }
);
