import { useTranslation } from "react-i18next";
import { BeneficiaryModalProps } from "../../../Models/ModalInterface";
import Modal from "../Modal.component";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import { RecipientExchangeRates } from "../../../Modules/Common/domain/ExchangeRates";
import { GradientCard } from "../../Card/Card.styled";
import Button from "../../Button";
import { maskInterbankKey } from "../../../Utilities/String";
import IconFont from "../../IconFont";
import { useSelector } from "react-redux";
import { BeneficiarySelector } from "../../../Redux/Beneficiary/Beneficiary.slice";
import { useModal } from "../../../Hooks/useModal";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { RecentlyUsedTag } from "./SelectBeneficiaryAccountModal.styled";

export const SelectBeneficiaryAccountModal = (props: BeneficiaryModalProps) => {
  const {
    data: { beneficiarySelected },
  } = useSelector(BeneficiarySelector);
  const { t } = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const { modal, showModal } = useModal();
  const { countryDestination } = useSelector(QuotationSelector).data;

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer justify="center" m="0">
        <Text size={1} weight={500} margin={0}>
          {props.title}
        </Text>
      </FlexContainer>
      <FlexContainer justify="start" gap="6px" m="0 0 12px 0">
        <FlexContainer w="fit-content">
          <img
            width={24}
            src={
              RecipientExchangeRates.find(
                (exchange) => exchange.countryCode === props.recipient?.country
              )?.icon
            }
          />
        </FlexContainer>
        <FlexContainer direction="column" flex="1 1 auto">
          <Text align="left" size={1} weight={500} lineHeight="24px" margin={0}>
            {props.recipient?.fullName}
          </Text>
          <Text
            align="left"
            size={0.5}
            weight={400}
            lineHeight="21px"
            color="grey"
            margin={0}
          >
            {props.recipient?.alias}
          </Text>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer m="0 0 24px 0">
        <Text size={0.5} weight={600} lineHeight="21px" margin={0}>
          {tSendMoney("SelectRecipientAccount")}
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" gap="8px">
        {props.recipient?.account?.map((account, index) => (
          <Button
            key={index}
            variant="transparent"
            padding="0px"
            sizeButton="full"
            onClick={() => {
              props.handleConfirm && props.handleConfirm(account);
              props.handleClose(true);
            }}
          >
            <GradientCard
              width="100%"
              padding="11px 15px"
              borderColor="muted"
              active={beneficiarySelected?.accountSelected?.id === account.id}
            >
              <FlexContainer
                justify="space-between"
                alignItems="center"
                flex="1 1 auto"
                gap="12px"
              >
                <FlexContainer w="fit-content">
                  <img
                    width={42}
                    src={
                      RecipientExchangeRates.find(
                        (exchange) => exchange.countryCode === account.country
                      )?.icon
                    }
                  />
                </FlexContainer>
                <FlexContainer direction="column">
                  <Text size={0.5} weight={500} lineHeight="24px" margin={0}>
                    {account.bankName}
                  </Text>
                  <Text
                    size={0.5}
                    weight={400}
                    lineHeight="24px"
                    color="grey"
                    margin={0}
                  >
                    {maskInterbankKey(account.accountNumber)}
                  </Text>
                </FlexContainer>
                {index === 0 && (
                  <RecentlyUsedTag>
                    <Text
                      weight={500}
                      size={1}
                      lineHeight="15px"
                      color="white"
                      margin={0}
                    >
                      {t("Beneficiaries.RecentlyUsed")}
                    </Text>
                  </RecentlyUsedTag>
                )}
                <IconFont name="chevron-right" color="black" size="small" />
              </FlexContainer>
            </GradientCard>
          </Button>
        ))}
        <FlexContainer w="100%" m="16px 8px 0 0" justify="end">
          <Button
            variant="transparent"
            sizeButton="fit"
            padding="0px"
            onClick={() => {
              showModal({
                modalType: "addBeneficiaryAccountModal",
                recipient: props.recipient,
                country: countryDestination,
                handleConfirm: async (accountSelected) => {
                  props.handleConfirm && props.handleConfirm(accountSelected);
                  props.handleClose(true);
                },
              });
            }}
          >
            <Text
              color="solid_light"
              size={0.5}
              lineHeight="21px"
              weight={500}
              margin={0}
            >{`+ ${t("Beneficiaries.ButtonNewAccount")}`}</Text>
          </Button>
        </FlexContainer>
      </FlexContainer>
      {modal}
    </Modal>
  );
};
