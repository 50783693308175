import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppDispatch, State } from "../../../../Redux/Store";
import { Column, Container, Row } from "../../../../Components/Grid";
import { FlexContainer, FlexItem } from "../../../../Components/Flex/Flex.styled";
import { AlignItems } from "../../../../Types/Column";
import { AccountDisplayName, ActionButtons } from "./AccountCard.styled";
import Text from "../../../../Components/Text";
import Link from "../../../../Components/Link";
import { CardButton } from "../../../../Components/Card/Card.styled";
import { AccountCardProps } from "../../../../Modules/Beneficiary/domain/BeneficiaryCardProps";
import { BeneficiarySlice } from "../../../../Redux/Beneficiary";

const AccountCard = ({ withEditButtons, onClick, onAddButtonClick, accountsList, ...account }: AccountCardProps) => {
  const navigate = useNavigate();
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();

  const handleEditBeneficiary = async (bank?: string, id?: string, country?: string) => {
    dispatch(
      BeneficiarySlice.actions.setBankSelected({
        bankName: bank,
        idBankName: id,
        country,
      })
    );
    navigate("/add-accounts", {
      state: { viewAccount: "editAccount" },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Row>
          <Column span={12} mb={2} alignItems={AlignItems.center} as={CardButton}>
            <FlexContainer w="100%" p="16px" gap="5%" alignItems="center">
              <FlexItem h="100%" w="64%" direction="column" justify="center" flexWrap>
                <AccountDisplayName>
                  <Text size={0} weight={500} margin="0" align="left">
                    {accountsList && accountsList[0].bankName}
                  </Text>
                </AccountDisplayName>
              </FlexItem>
              {withEditButtons && (
                <FlexItem w="auto" alignSelf="center" justify="end" direction="column" as={ActionButtons}>
                  <Link
                    href="#"
                    variant="primary"
                    onClick={() =>
                      handleEditBeneficiary(
                        accountsList && accountsList[0].bankName,
                        accountsList && accountsList[0].destinationId,
                        accountsList?.[0]?.country
                      )
                    }
                  >
                    {t("global.edit")}
                  </Link>
                </FlexItem>
              )}
            </FlexContainer>
          </Column>
        </Row>
      </Container>
    </ThemeProvider>
  );
};

export default AccountCard;
