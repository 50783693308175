import { differenceInYears, isDate, isPast, isValid, parse } from "date-fns";
import { allowedOneNumber } from "../Constants/Regexp";

/**
 * Parses a string in `dd/MM/yyyy` format and returns the resulting Date object
 * @param v {string} The date string
 * @returns Date
 */
export function parseDoBString(v: string) {
  return parse(v, "dd/MM/yyyy", new Date());
}

/**
 * Takes a date string in `dd/MM/yyyy` format, parses it, and returns whether the string represents
 * a valid date
 * @param v {string} The date string
 * @returns Boolean
 */
export function isDateString(v: string) {
  const d = parseDoBString(v);
  return isValid(d) && isDate(d);
}

/**
 * Takes a date string in `dd/MM/yyyy` format, parses it, and returns whether the string represents
 * a date in the past
 * @param v {string} The date string
 * @returns Boolean
 */
export function isPastDate(v: string) {
  const d = parseDoBString(v);
  return isValid(d) && isDate(d) && isPast(d);
}

/**
 * Takes a date string in `dd/MM/yyyy` format, parses it, and returns whether the string represents
 * a date at least 18 years in the past
 * @param v {string} The date string
 * @returns Boolean
 */
export function isLegalAge(v: string) {
  const d = parseDoBString(v);
  return differenceInYears(new Date(), d) >= 18;
}

export const formatDateEng = (val: string) => {
  const result = [];

  if (val && val.length) {
    for (let i = 0; i < val.length; i++) {
      const current = val[i];

      if (i === 2 && current !== "/") {
        result.push("/");
      } else if (i === 5 && current !== "/") {
        result.push("/");
      }

      if ((allowedOneNumber.test(current) && i < 10) || i === 2 || i === 5) {
        result.push(current);
      }
    }
  }

  return result.join("");
};
