import styled from "styled-components";

export const AccountDisplayName = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

export const ActionButtons = styled.div`
  text-align: right;
  margin-left: auto;
`;
