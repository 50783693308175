import { useEffect, useRef, useState } from "react";
import { Feature } from "../Modules/Common/domain/Feature";
import isEmpty from "lodash.isempty";
import { useModal } from "./useModal";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const useWalkthrough = (opts?: { onEndWalkthrough?: () => void }) => {
  const { modal, showModal, setOpenModal } = useModal();
  const [featureList, setFeatureList] = useState<Feature[]>([]);

  const preventDefault = useRef((e: TouchEvent) => {
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  }).current;

  const startWalkthrough = (features: Feature[]) => {
    setFeatureList(features);
    document.body.style.overflow = "hidden";
    window.addEventListener("touchmove", preventDefault, { passive: false });
    showModal({
      modalType: "walkthrough",
      popUps: features.map((feat) => ({
        id: feat.value,
        messageEn: feat.descriptionEn,
        messageEs: feat.descriptionEs,
        isNew: true,
        element: feat.elementId
          ? document.getElementById(feat.elementId)
          : document.getElementById(`${feat.type}-${feat.value}`),
        onClose: (id) => {
          const currentDate = new Date();
          cookies.set(`${feat.value}DateClicked`, currentDate);
          setFeatureList((current) =>
            current.filter((feat) => feat.value !== id)
          );
        },
      })),
    });
  };

  const endWalkthrough = () => {
    document.body.style.overflow = "auto";
    window.removeEventListener("touchmove", preventDefault);
    opts?.onEndWalkthrough && opts.onEndWalkthrough();
  };

  useEffect(() => {
    if (isEmpty(featureList)) {
      setOpenModal(false);
      endWalkthrough();
    }
  }, [featureList]);

  return {
    modal,
    startWalkthrough,
    endWalkthrough,
  };
};
