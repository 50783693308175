import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Select, {
  StylesConfig,
  components,
  OptionProps,
  ControlProps,
  PlaceholderProps,
  MenuProps,
  GroupBase,
  SingleValue,
} from "react-select";
import type {} from "react-select/base";
import isEmpty from "lodash.isempty";

// Theme
import theme from "../../Contexts/ThemeProvider";

import {
  CustomControlContainer,
  CustomOptionContainer,
  CustomSelectLinkButton,
  CustomSelectLinkButtonContainer,
  CustomSelectPlaceholder,
} from "./AuthorizeForm.styled";
import { AuthorizeAccountsSelect } from "../../Modules/PaymentOrders/domain/Authorize";
import IconFont from "../IconFont";

declare module "react-select/base" {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    toggleMenuIsOpen?: () => void;
    hasOptions?: boolean;
  }
}

type CustomOptionProps<T = string, extend = { data?: string }> = {
  label: string;
  value: T;
  image?: string;
} & extend & AuthorizeAccountsSelect;

const selectStyles: StylesConfig<CustomOptionProps> = {
  container: (styles, state) => ({
    ...styles,
    textAlign: "left",
    color: theme.Background_02,
  }),
  control: (styles, state) => ({
    ...styles,
    fontSize: '14px',
    textAlign: "left",
    borderRadius: "12px",
    padding: "10px 5px 10px 15px",
    background: theme.Primary_01_50,
    borderColor:
      state.menuIsOpen || state.isFocused
        ? theme.Background_03
        : theme.Background_03,
    "&:hover": {
      borderColor: theme.Background_03,
    },
    boxShadow: "none",
    color: theme.Background_02,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    opacity: state.isDisabled ? "0.6" : "1",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? styles.color : theme.Neutral_900,
  }),
  indicatorSeparator: (styles) => ({
    display: "none",
  }),
  input: (styles, state) => ({
    ...styles,
    color: theme.Background_02,
  }),
  menu: (styles, state) => ({
    ...styles,
    marginTop: "-9px",
    textAlign: "left",
    border: `1px solid ${theme.Background_03}`,
    borderTop: "none",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    padding: "10px 5px",
    background: theme.Primary_01_50,
    boxShadow: "none",
    color: theme.Background_02,
    zIndex: 999
  }),
  menuList: (styles) => ({
    ...styles,
    marginTop: "-5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    zIndex: 999
  }),
  option: (styles, state) => ({
    ...styles,
    fontSize: "14px",
    color: state.isSelected ? theme.Primary_01_500 : styles.color,
    borderTop: `0.5px solid ${theme.Primary_01_700}`,
    background:
      state.isFocused || state.isSelected
        ? theme.Primary_01_50
        : styles.background,
    fontWeight: state.isSelected ? "bold" : styles.fontWeight,
  }),
};

const CustomOption = ({
  children,
  ...props
}: OptionProps<AuthorizeAccountsSelect>) => {
  const cardNumberLastFour = props.data.cardNumber.slice(-4);
  const cardholderFormatted = props.data.label.split(' ')[0];

  return (
    <components.Option {...props}>
      <CustomOptionContainer>
        <div className="option-acccount-type">
          { isEmpty(props.data.accountType) ? 
            <IconFont name="building-bank" size="default" />
            : <img src={props.data.accountType} alt={props.data.accountType} />
          }
          
        </div>
        <span>{cardholderFormatted}</span>
        <b>{`*${cardNumberLastFour}`}</b>
      </CustomOptionContainer>
    </components.Option>
  );
};

const CustomPlaceholder = ({
  children,
  ...props
}: PlaceholderProps<CustomOptionProps>) => {
  return (
    <components.Placeholder {...props}>
      <CustomSelectPlaceholder
        hasOptions={props.selectProps.hasOptions}
      >
        {children}
      </CustomSelectPlaceholder>
    </components.Placeholder>
  );
};

const CustomControl = ({
  children,
  ...props
}: ControlProps<CustomOptionProps>) => {
  const data = props.getValue();

  if (data.length === 0) {
    return <components.Control {...props}>{children}</components.Control>;
  }
  const cardNumberLastFour = data[0].cardNumber.slice(-4);
  const cardholderFormatted = data[0].label.split(' ')[0];
  const indicatorChild = React.Children.toArray(children)[1];

  return (
    <components.Control {...props}>
      <CustomOptionContainer>
        <div className="option-acccount-type">
          { isEmpty(data[0].accountType) ? 
            <IconFont name="building-bank" size="default" />
            : <img src={data[0].accountType} alt={data[0].accountType} />
          }
        </div>
        <CustomControlContainer>
          <div className="control-label">  
            <p>{cardholderFormatted}</p>
            <b>{`*${cardNumberLastFour}`}</b>
          </div>
          {indicatorChild}
        </CustomControlContainer>
      </CustomOptionContainer>
    </components.Control>
  );
};

const CustomMenu = ({ children, ...props }: MenuProps<CustomOptionProps>) => {
  const { t } = useTranslation("global");

  return (
    <components.Menu {...props}>
      {children}
      <CustomSelectLinkButtonContainer>
        <CustomSelectLinkButton onClick={() => {
          props.clearValue();

          if (props.selectProps.toggleMenuIsOpen) {
            props.selectProps.toggleMenuIsOpen();
          }
        }}>
          {t("Authorize.labels.addNewCard")}
        </CustomSelectLinkButton>
      </CustomSelectLinkButtonContainer>
    </components.Menu>
  );
};

const AuthorizeAccountSelect = ({
  onAddNewCard,
  onSelect,
  options = []
}: {
  onSelect: (value: AuthorizeAccountsSelect) => void;
  onAddNewCard?: () => void;
  options: AuthorizeAccountsSelect[]
}) => {
  const { t } = useTranslation("global");
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <Select<CustomOptionProps>
      isDisabled={options.length === 0}
			isSearchable={false}
      isClearable
      menuIsOpen={menuIsOpen}
      onMenuClose={() => setMenuIsOpen(false)}
      onMenuOpen={() => setMenuIsOpen(!menuIsOpen)}
      hasOptions={options.length > 0}
      toggleMenuIsOpen={() => {
        if (onAddNewCard) {
          onAddNewCard();
        }
        setMenuIsOpen(!menuIsOpen);
      }}
      placeholder={
        options.length === 0 ? t("Authorize.labels.addNewCard") : t("Authorize.labels.selectCard")
      }
      onChange={(value) => onSelect(value as AuthorizeAccountsSelect)}
      components={{
        Option: CustomOption,
        Control: CustomControl,
        Placeholder: CustomPlaceholder,
        Menu: CustomMenu,
      }}
      options={options}
      styles={selectStyles}
    />
  );
};

export default AuthorizeAccountSelect;
