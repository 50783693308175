import styled from "styled-components";
import { theme } from "styled-tools";

import mediaQuery, { Screen } from "../../../../../Utilities/MediaQuery";
import PaymentSendolaPayConfirmationSrc from "../../../../../Assets/Img/sendola-pay-confirmation.svg";

export const CheckInstructionsLogoStyled = styled.div`
  display: flex;
  width: 110px;
  height: 120px;
  background-image: url(${PaymentSendolaPayConfirmationSrc});
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;

  div {
    width: 100px;
    height: 100px;
    margin-top: 50px;
  }
`;
