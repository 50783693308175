import styled from "styled-components";
import { ifProp, prop } from "styled-tools";

export const Container = styled.section<{
  margin?: string;
  maxWidth?: string;
}>`
  display: grid;
  grid-template-rows: auto;
  padding: 0;
  width: 100%;
  max-width: ${prop("maxWidth", "1500px")};
  margin: ${prop("margin", "unset")};
`;

Container.displayName = "Container";
