import { useTranslation } from "react-i18next";

import Modal from "../Modal.component";
import Text from "../../Text";
import Button from "../../Button";
import { VelocityLimitModalProps } from "../../../Models/ModalInterface";
import Icon from "../../Icon";

export const VelocityLimitSendolaPayModal = ({
  limitType = "day",
  ...props
}: VelocityLimitModalProps) => {
  const { t } = useTranslation("modals");
  const message =
    limitType === "day"
      ? t("velocityLimits.SendolaPay.messageDaily")
      : t("velocityLimits.SendolaPay.messageMonthly");

  return (
    <Modal {...props}>
      <Icon icon="sendolaPayLogo" size="xxlarge" color="transparent" />
      <Text size={3} weight={700}>
        {limitType === "day"
          ? t("velocityLimits.SendolaPay.titleDaily")
          : t("velocityLimits.SendolaPay.titleMonthly")}
      </Text>
      <Text size={1}>{props.message || message}</Text>
      <Button
        variant={"primary"}
        sizeButton="large"
        sizeText="large"
        text={t("velocityLimits.next")}
        colorIcon="white"
        sizeIcon="large"
        onClick={() => props.handleClose()}
      />
    </Modal>
  );
};
