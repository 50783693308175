import { Props } from "../../Models/cardInterface";
import { CardStyled } from "./Card.styled";

const Card = (props: Props) => {
  const { children, type } = props;
  return (
    <CardStyled $type={type} {...props}>
      {children}
    </CardStyled>
  );
};

export default Card;
