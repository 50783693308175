import theme from "styled-theming";

import { theme as config } from "./Theme";

// Home
const cardInformationButtonBackgroundColor = theme("currentTheme", {
  default: config.Primary_02,
  martori: config.MartoriPrimary_900,
});

const cardInformationBackgroundColor = theme("currentTheme", {
  default: config.Content_State_UX_Disabled,
  martori: config.Content_State_UX_Disabled,
});

const cardInformationFontColor = theme("currentTheme", {
  default: config.Content_UX_03,
  martori: config.MartoriWhite,
});

const cardInformationButtonIconBackgroundColor = theme("currentTheme", {
  default: config.Primary_01,
  martori: config.MartoriPrimary_01,
});

const cardInformationItemBorderColor = theme("currentTheme", {
  default: config.Background_03,
  martori: config.Background_03,
});

const cardInformationItemBorderBgColor = theme("currentTheme", {
  default: config.Background_01,
  martori: config.Background_01,
});

const iconInfoColor = theme("currentTheme", {
  default: config.Primary_01,
  martori: config.MartoriPrimary_01,
});

const iconInfoBorderColor = theme("currentTheme", {
  default: `linear-gradient(
    280deg,
    ${config.Primary_03} 0%,
    ${config.Primary_01} 100%
  ) 0 10%;
`,
  martori: `linear-gradient(
    280deg,
    ${config.MartoriGradient_01} 0%,
    ${config.MartoriGradient_02} 100%
  ) 0 10%;
`,
});

const mobileIconInfoBorderColor = theme("currentTheme", {
  default: config.Content_UX_02,
  martori: config.Content_UX_02,
});

const dividerColor = theme("currentTheme", {
  default: config.Primary_03,
  martori: config.MartoriPrimary_01,
});

const textColors = theme.variants("currentTheme", "color", {
  white: {
    default: config.Background_01,
    martori: config.MartoriWhite,
  },
  black: {
    default: config.Background_02,
    martori: config.Background_02,
  },
  gray: {
    default: config.Content_UX_02,
    martori: config.Content_UX_02,
  },
  black_highlight: {
    default: config.Content_UX_01,
    martori: config.Content_UX_01,
  },
  primary: {
    default: config.Primary_01,
    martori: config.MartoriPrimary_01,
  },
  warning: {
    default: config.Primary_08,
    martori: config.Primary_08,
  },
  error: {
    default: config.Red_08,
    martori: config.Red_08,
  },
  transparent: {
    default: config.Transparent,
    martori: config.Transparent,
  },
});

const headerGradientBase = theme("currentTheme", {
  default: config.Primary_03,
  martori: config.MartoriPrimary_900,
});

const paymentMethodInstructionCard = theme("currentTheme", {
  default: config.Primary_01_50,
  martori: config.MartoriPrimary_01_50,
});

const horizontalLinearGradient = theme("currentTheme", {
  default: `linear-gradient(
      90deg,
      ${config.Primary_03} 0%,
      ${config.Primary_01} 100%
    ) 0 10%;
  `,
  martori: `linear-gradient(
      90deg,
      ${config.MartoriGradient_01} 0%,
      ${config.MartoriGradient_02} 100%
    ) 0 10%;
  `,
});

const TransactionDetailCardColor = theme("currentTheme", {
  default: config.Background_10,
  martori: config.MartoriBackground_10,
});

const TransactionNewBackgroundColor = theme("currentTheme", {
  default: config.Background_10,
  martori: config.MartoriBackground_10,
});

export {
  cardInformationBackgroundColor,
  cardInformationButtonBackgroundColor,
  cardInformationFontColor,
  cardInformationButtonIconBackgroundColor,
  cardInformationItemBorderColor,
  cardInformationItemBorderBgColor,
  iconInfoBorderColor,
  iconInfoColor,
  mobileIconInfoBorderColor,
  textColors,
  dividerColor,
  headerGradientBase,
  paymentMethodInstructionCard,
  horizontalLinearGradient,
  TransactionDetailCardColor,
  TransactionNewBackgroundColor,
};
