import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { GeolocationState } from "../../Modules/Common/domain/LocationPosition";

const initialState: GeolocationState = {
  latitude: null,
  longitude: null,
  state: null,
  country: null,
};

export const GeolocationSlice = createSlice({
  name: "Geolocation",
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    setLatLng(state, { payload }) {
      return {
        ...state,
        latitude: payload.latitude,
        longitude: payload.longitude,
        state: payload.state,
        country: payload.country,
      };
    },
  },
});

export const GeoLocationSelector = (state: State) => state.Geolocation;
