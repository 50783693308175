import { CountryRepository } from "../domain/Countries";

export const getCountry =
  (citiesRepository: CountryRepository) =>
  async (lang: string, sponsorBank?: string) =>
    citiesRepository.getCountries(lang, sponsorBank);

export const getAllCountries =
  (countryRepository: CountryRepository) => async (lang: string) =>
    countryRepository.getAllCountries(lang);
