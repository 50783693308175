import styled from "styled-components";
import { theme } from "styled-tools";

export const RowLeft = styled.div`
  text-align: left;
`;

export const Checkbox = styled.input`
  accent-color: ${theme("Content_UX_02")};
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const Text = styled.div`
  font-family: "Poppins";
  color: ${theme("Background_02")};
  font-size: 16px;
  text-align: left;
  align-self: center;
`;

export const Label = styled.label`
  display: contents;
  width: 100%;
`;

export const ErrorContainer = styled.div`
  font-family: "Poppins";
  color: ${theme("Error")};
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
`;

export const ErrorMessage = styled.span`
  font-size: 16px;
  margin-left: 8px;
`;
