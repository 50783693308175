import ResponsivePagination, {
  ResponsivePaginationProps,
} from "react-responsive-pagination";
import { PaginationSection } from "./Pagination.styled";

export const Pagination = (props: ResponsivePaginationProps) => {
  return (
    <PaginationSection>
      <ResponsivePagination {...props} />
    </PaginationSection>
  );
};
