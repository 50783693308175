import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

// own states
import { State } from "../../../Redux/Store";

// Own components
import Button from "../../../Components/Button";
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Text from "../../../Components/Text";
import { AlignItems, JustifyContent } from "../../../Types/Column";
import { ContainerCard } from "../Payments.styled";

import {
  SendolaPayConfimration,
  SendolaPayChainIcon,
} from "./SendolaPay.styled";

import CancelIcon from "../../../Assets/Icons/cancel.svg";
import ChainIcon from "../../../Assets/Icons/chain.svg";
import { Spinner } from "../../ReviewTransaction/components/Spinner/Spinner.styled";
import { useSendolaPayPaymentOrder } from "../../../Hooks/useSendolaPayPaymentOrder";
import { SendolaPayOrderSelector } from "../../../Redux/PaymentOrder/SendolaPayOrder.slice";
import isEmpty from "lodash.isempty";
import { useNavigate } from "react-router-dom";
import IconShape from "../../../Components/IconShape";

const SendolaPayConfirmation = () => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const theme = useSelector((state: State) => state.theme);
  const { credentialsSendola } = useSelector(SendolaPayOrderSelector).data;
  const [credentials, setCredentials] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { senderCredentialsSendola, personId } = useSendolaPayPaymentOrder();

  useEffect(() => {
    if (!credentials) {
      setLoading(true);
      validateSendolaCredentials();
    }
  }, [personId, credentials]);

  const validateSendolaCredentials = async () => {
    if (!isEmpty(credentialsSendola) && personId) {
      setCredentials(true);
      const response = await senderCredentialsSendola(
        personId,
        credentialsSendola
      );

      if (response) {
        setLoading(false);
        setError(response.isError);
      }
    }
  };

  const handleButtonContinue = () => {
    if (error) {
      navigate("/payment-sendola-pay-agreement");
    } else {
      navigate("/payment-sendola-pay-balance");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/payments" textLink={t("buttons.back")}>
        <Container>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center} my={4}>
              <ContainerCard>
                <Row>
                  <Column
                    span={12}
                    xs={12}
                    px={3}
                    mb={6}
                    alignItems={AlignItems.center}
                    justifyContent={JustifyContent.center}
                  >
                    {!error ? (
                      <IconShape
                        icon="circle-exclamation"
                        iconSize="xlarge"
                        border="rounded"
                        width={75}
                        height={75}
                      />
                    ) : (
                      <IconShape
                        icon="circle-check"
                        iconSize="xlarge"
                        border="rounded"
                        width={75}
                        height={75}
                      />
                    )}
                  </Column>
                  <Column span={12} px={3}>
                    <Text color="black" size={3} align="center" weight={600}>
                      {t(
                        loading
                          ? "Payments.sendolaPay.sendolaPayLoading"
                          : error
                          ? "Payments.sendolaPay.sendolaPayError"
                          : "Payments.sendolaPay.sendolaPayConfirmation"
                      )}
                    </Text>
                  </Column>
                  <Column
                    span={12}
                    xs={12}
                    pt={1}
                    pb={2}
                    justifyContent={JustifyContent.center}
                  >
                    <SendolaPayConfimration>
                      {loading ? (
                        <Spinner />
                      ) : error ? (
                        <SendolaPayChainIcon src={CancelIcon} />
                      ) : (
                        <SendolaPayChainIcon src={ChainIcon} />
                      )}
                    </SendolaPayConfimration>
                  </Column>
                  {!loading && (
                    <Column
                      span={12}
                      xs={12}
                      pt={1}
                      pb={2}
                      justifyContent={JustifyContent.center}
                    >
                      <Button
                        type="submit"
                        variant="primary"
                        sizeButton="large"
                        sizeText="large"
                        sizeIcon="large"
                        text={t("SendolaPayPayment.modalButtonText")}
                        colorIcon="white"
                        onClick={handleButtonContinue}
                      />
                    </Column>
                  )}
                </Row>
              </ContainerCard>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default SendolaPayConfirmation;
