import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FAQsFiles } from "../../Constants/FAQsFiles";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../Redux/Translate";

const FAQs = () => {
  const location = useLocation();
  const { pathname } = location;
  const FAQsFilesUrl = FAQsFiles.filter((item) => item.path === pathname);
  const lang = useSelector(selectorLanguage);

  useEffect(() => {
    if (lang) {
      if (lang.language === "es") {
        window.location.href = FAQsFilesUrl[0].urlEs;
      }
      if (lang.language === "en") {
        window.location.href = FAQsFilesUrl[0].urlEn;
      }
    }
  }, [lang]);
  return null;
};
export default FAQs;
