import { GeocodeService } from "../Services/geocode/GeocodeService";

export const useGeoLocation = () => {
  const geocodeService = new GeocodeService();

  const cityExists = async (
    country: string,
    subdivision: string,
    citySub: string
  ) => {
    return await geocodeService.getCity(country, subdivision, citySub);
  };

  return {
    cityExists,
  };
};
