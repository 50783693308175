import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  HeaderStyled,
  Logo,
  LanguageSection,
  MenuContainer,
  MenuContent,
  MenuButtons,
  Options,
  Element,
  FlagIcon,
  DesktopActionButtons,
} from "./Header.styled";

// Own actions

// own states
import { State, AppDispatch } from "../../Redux/Store";

// Own Components
import Button from "../Button";
import Text from "../Text";
import Icon from "../Icon";
import Link from "../Link";
import MXIcon from "../../Assets/Flags/MXIcon.png";
import USIcon from "../../Assets/Flags/eu.png";

// Own services
import { useIsSessionAlive } from "../../Services/SessionTimeout";
import { doLogout } from "../../Services/Session";
import { fromAuthApp } from "../../Utilities/RedirectFromOrigin";

// Own constants
import { ThemeContext } from "../../Contexts/ThemeContext/ThemeContext";
import { FlexContainer } from "../Flex/Flex.styled";
import { PersonsKYCSelector } from "../../Redux/PersonsKYC";
import { PlatformAppURL } from "../../Constants/ExternalServices";
import IconFont from "../IconFont";
import { getSlicedString } from "../../Utilities/String";
import { LanguageContext } from "../../Contexts/Language/LanguageContext";

export default function Header({ showContent = true }: { showContent?: boolean }) {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const MenuRef = useRef<HTMLDivElement>(null);
  const LanguageRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const lang = useSelector((state: State) => state.language);
  const person = useSelector(PersonsKYCSelector).data?.personVeriff;
  const [showMenuUser, setShowMenuUser] = useState(false);
  const signInUrl = fromAuthApp("sign-in");
  const signUpUrl = fromAuthApp("sign-up");
  const logoutUrl = fromAuthApp("logout");
  const {
    theme: { images },
  } = useContext(ThemeContext);
  const languageContext = useContext(LanguageContext);

  const changeLanguageFunc = (language: string) => {
    setShowOptions(false);
    languageContext?.updateLanguage(language);
  };

  const showMenuContainer = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const HandleLogout = () => {
    localStorage.clear();
    doLogout(dispatch);
    window.location.href = logoutUrl;
  };

  const handleShowMenuUser = () => {
    setShowOptions(false);
    setShowMenuUser(!showMenuUser);
  };

  const isLoggedIn = useIsSessionAlive();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (MenuRef.current && !MenuRef.current?.contains(event.target as Node)) {
        setShowMenuUser(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [MenuRef]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (LanguageRef.current && !LanguageRef.current?.contains(event.target as Node)) {
        setShowOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [LanguageRef]);

  return (
    <HeaderStyled className="header">
      {showContent && (
        <>
          <FlexContainer w="auto" flex="1 1 0px" ref={LanguageRef}>
            <LanguageSection onClick={() => setShowOptions(!showOptions)}>
              <FlagIcon
                src={lang.language === "es" ? MXIcon : USIcon}
                alt={`${lang.language === "es" ? "Mexico flag" : "USA flag"}`}
              />
              <Text size={0.5} color="white" weight={500} margin="0 5px 0 10px">
                {lang.language && lang.language.toString().toUpperCase()}
              </Text>
              <Icon icon={showOptions ? "chevronUp" : "chevronDown"} color="white" size="medium" />
            </LanguageSection>
            <Options $show={showOptions}>
              <Element onClick={() => changeLanguageFunc("es")}>
                <FlagIcon src={MXIcon} alt="Mexico flag" />
                Español
              </Element>
              <hr />
              <Element onClick={() => changeLanguageFunc("en")}>
                <FlagIcon src={USIcon} alt="USA flag" />
                English
              </Element>
            </Options>
          </FlexContainer>
          <Logo>
            <Link href={isLoggedIn ? "/dashboard" : "/"}>
              <img src={images["logo"]} alt="Sendola Global Payments" />
            </Link>
          </Logo>
          {isLoggedIn ? (
            <FlexContainer w="auto" flex="1 1 0px" justify="end" ref={MenuRef}>
              <Button variant="transparent" sizeButton="fit" padding="0 16px 0 0" onClick={handleShowMenuUser}>
                <FlexContainer justify="space-between" gap="5px">
                  <Text color="white" size={0.5} weight={500}>
                    {person?.name && person.name.length > 9 ? getSlicedString(person.name, 6) : person?.name}
                  </Text>
                  <Icon icon={showMenuUser ? "chevronUp" : "chevronDown"} color="white" size="medium" />
                </FlexContainer>
              </Button>
              <Options $show={showMenuUser} minWidth="264px" ltr>
                <Element onClick={() => (window.location.href = `${PlatformAppURL}/account-details`)}>
                  <IconFont name="pencil" color="white" size="medium" />
                  <Text size={1} weight={500} color="white">
                    {t("header.ActionButtonAccount")}
                  </Text>
                </Element>
                <hr />
                <Element onClick={HandleLogout}>
                  <Icon icon="logout" color="white" size="medium" />
                  <Text size={1} weight={500} color="white">
                    {t("header.ActionBtnLogout")}
                  </Text>
                </Element>
              </Options>
            </FlexContainer>
          ) : (
            <DesktopActionButtons>
              <a href={signInUrl}>
                <Button variant="secondary" sizeText="small" text={t("header.ActionBtnOutline")} />
              </a>
              <a href={signUpUrl}>
                <Button variant="primary" sizeText="small" text={t("header.ActionButtonDefault")} />
              </a>
            </DesktopActionButtons>
          )}

          {!isLoggedIn && (
            <MenuContainer>
              <Icon icon={"menu"} color="white" size="medium" fillIcon={false} onClick={() => showMenuContainer()} />
              <MenuContent className={showMobileMenu ? "show" : ""}>
                <MenuButtons>
                  <a href={signInUrl}>
                    <Button variant="secondary" sizeText="medium" text={t("header.ActionBtnOutline")} />
                  </a>
                  <a href={signUpUrl}>
                    <Button variant="primary" sizeText="medium" text={t("header.ActionButtonDefault")} />
                  </a>
                </MenuButtons>
              </MenuContent>
            </MenuContainer>
          )}
        </>
      )}
    </HeaderStyled>
  );
}
