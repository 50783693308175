import styled from "styled-components";
import { ifProp, prop } from "styled-tools";

import vector from "../../Assets/Img/bgVector.png";
import { imgTypes } from "../../Utilities/Icons";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { ImageTypeKey } from "../../Models/IconInterface";

export const CardImageStyled = styled.div<{
  $image: ImageTypeKey;
  bgImage?: string;
}>`
  width: 419px;
  height: 460px;
  display: flex;
  background: url(${ifProp(
    "bgImage",
    prop("bgImage"),
    imgTypes[`${prop("$image")}` as ImageTypeKey]
  )});
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: bottom, left;
  mask-image: url(${vector});
  mask-repeat: no-repeat;
  mask-size: 100%;
  flex-direction: column;
  box-shadow: inset 50px 0px 44px 9px rgba(0, 0, 0, 0.84);
  position: relative;

  ${mediaQuery[Screen.sm]} {
    width: 250px;
    height: 290px;
    mask-size: 250px 290px;
  }

  ${mediaQuery[Screen.xs]} {
    width: 250px;
    height: 290px;
    mask-size: 250px 290px;
  }

  p {
    text-align: left;
    font-family: "Poppins";
    position: absolute;
    bottom: 0;
    padding: 40px;
    width: 50%;
  }
`;
