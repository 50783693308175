export interface Codes {
  code: string;
  msg: string;
  value: string;
}

export interface VelocityItem {
  code: string;
}

export const hasLimit = (velocity: VelocityItem[], code: string): boolean =>
  velocity.some((item) => item.code === code);

export const shouldExclude = (item: VelocityItem, conditions: { [key: string]: boolean }): boolean => {
  return Object.keys(conditions).some((key) => conditions[key] && item.code === key);
};

export const filterVelocitiesToShow = (velocities: Codes[]) => {
  const hasMonthlyKYC1Limit = hasLimit(velocities, "VL018");
  const hasDailyDestinationLimit = hasLimit(velocities, "VL009");
  const hasDailyLimit = hasLimit(velocities, "VL003");
  const hasDailyCountrySubdivisionLimit = hasLimit(velocities, "VL013");
  const hasPerTransactionAmountLimit = hasLimit(velocities, "VL005");

  return velocities.filter((item, index, self) => {
    const isFirstOccurrence = self.findIndex((value) => value.code === item.code) === index;
    const isNotExcludedCode = item.code !== "VL007";

    const excludeConditions = {
      VL008: hasPerTransactionAmountLimit,
      VL017: hasMonthlyKYC1Limit,
      VL003: hasDailyDestinationLimit,
      VL010: hasDailyDestinationLimit,
      VL004: hasDailyLimit,
      VL014: hasDailyCountrySubdivisionLimit,
    };

    return isFirstOccurrence && isNotExcludedCode && !shouldExclude(item, excludeConditions);
  });
};
