import styled, { css } from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import PlusIcon from "../../Assets/Icons/plus.svg";

import { switchProp, theme } from "styled-tools";
import {
  quoterCardFeeCaption,
  quoterCardLabels,
} from "../../Contexts/ThemeProvider/Texts";

export const CardMoney = styled.div`
  padding: 40px 48px;
  box-shadow: 0px 4px 20px 0px ${(props) => props.theme.Content_UX_02}30;
  max-width: 512px;

  ${mediaQuery[Screen.mobile]} {
    padding: 16px;
  }
`;

export const ExchangeRate = styled.div`
  box-sizing: border-box;
  color: ${quoterCardLabels};
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  padding: 0 2px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  div:nth-child(1) {
    text-align: left;
  }
  div:nth-child(2) {
    text-align: right;
  }
`;

export const SelectBankTitle = styled.h4`
  color: ${quoterCardLabels};
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
`;

export const TransferFeeTitle = styled.h4`
  background-image: url("${PlusIcon}");
  background-position: 30% center;
  background-repeat: no-repeat;
  color: ${quoterCardFeeCaption};
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  margin-bottom: 0;
  background-position: center left;
  text-align: right;

  ${mediaQuery[Screen.desktop]} {
    margin-top: 0;
  }
`;

export const TransferFeeAmount = styled.div`
  color: ${quoterCardLabels};
  text-align: right;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
`;

export const PaymentAmountStyled = styled.div`
  display: flex;
  width: 100%;
  margin: 24px 0;
`;

export const TransferFeesStyled = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;

  ${mediaQuery[Screen.desktop]} {
    flex-wrap: nowrap;
  }
`;

export const PaymentAmountLabel = styled.div`
  text-align: center;
  height: 57px;
  flex: 1 0 0;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ${mediaQuery[Screen.mobile]} {
    text-align: left;
  }
`;

export const PaymentAmountSubLabel = styled.div`
  display: flex;
  color: ${quoterCardLabels};
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: center;

  ${mediaQuery[Screen.mobile]} {
    justify-content: left;
  }
`;

export const PaymentAmountAmount = styled.div`
  display: flex;
  justify-content: center;
  align-items: top;
  gap: 4px;
  align-self: stretch;
  color: ${quoterCardLabels};
  text-align: right;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ButtonSectionMobile = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0px;
  width: 100%;

  ${mediaQuery[Screen.desktop]} {
    display: none;
    padding: 0px 76px;
  }

  button {
    margin: 0;
    width: 100%;
    max-width: 270px;
  }
`;

export const ButtonSectionCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 40px;
  align-items: center;
  button {
    width: 300px;
  }

  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;

export const ButtonSection = styled(ButtonSectionCard)<{ $status?: string }>`
  ${mediaQuery[Screen.mobile]} {
    display: flex;
    margin: 24px 0;
  }

  button {
    max-width: 350px;
    width: 100%;
    height: 56px;
    border: 0px;

    ${switchProp(
      "$status",
      {
        approved: css`
          background: linear-gradient(
            180deg,
            ${theme("Primary_03")} 0%,
            ${theme("Primary_01")} 100%
          );
        `,
        decline: css`
          background: ${theme("Content_UX_02")};
        `,
        review: css`
          background: ${theme("Content_UX_02")};
        `,
      },
      css`
        background: linear-gradient(
          180deg,
          ${theme("Primary_03")} 0%,
          ${theme("Primary_01")} 100%
        );
      `
    )}
  }
`;

export const ErrorVelocityLimitsStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: ${theme("Primary_10")};
  padding: 10px;
  border-radius: 12px;
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
`;

export const IconVelocityLimits = styled.div`
  display: flex;
  flex-direction: column;
  width: 30px;
  align-items: start;
`;

export const TextVelocityLimits = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  text-align: left;
  padding-left: 5px;
  font-size: 0.75rem;
  line-height: 1.125rem;

  label {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
  }
`;
