import styled from "styled-components";
import { theme } from "styled-tools";

export const AlerBoxContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: ${theme("Primary_10")};
  padding: 10px;
  border-radius: 12px;
  font-size: 14px;
  width: 100%;
`;

export const AlerBoxIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30px;
  align-items: start;
`;

export const AlertBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  text-align: left;
  padding-left: 5px;
  font-size: 0.75rem;
  line-height: 1.125rem;

  label {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
  }
`;
