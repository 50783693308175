import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

// Own states
import { State } from "../../Redux/Store";

// Own components
import Layout from "../../Components/Layout";
import { Column, Container, Row } from "../../Components/Grid";
import Title from "../../Components/Title";
import Text from "../../Components/Text";
import { JustifyContent } from "../../Types/Column";

// Own styles
import { ContentColumn } from "./Disclosures.styled";
import LinksList from "../../Components/LinksList";

const Disclosures = () => {
  const theme = useSelector((state: State) => state.theme);
  const [t] = useTranslation("global");

  const LegalLinks = [
    {
      name: t("Disclosures.links.TermsAndConditions"),
      url: "/",
    },
    {
      name: t("Disclosures.links.FeeSchedule"),
      url: "/",
    },
    {
      name: t("Disclosures.links.TransactionLimits"),
      url: "/",
    },
    {
      name: t("Disclosures.links.ESignatureAgreement"),
      url: "/",
    },
    {
      name: t("Disclosures.links.PrivacyPolicy"),
      url: "/",
    },
    {
      name: t("Disclosures.links.ConsumerandCardholderAgreement"),
      url: "/",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/" textLink={t("buttons.back")}>
        <Container>
          <Row>
            <Column as={ContentColumn} span={12}>
              <Row>
                <Column span={6} xs={12} md={12}>
                  <Title
                    size={5}
                    text={t("Disclosures.title")}
                    color="black"
                    align="left"
                    tagName="h1"
                  />
                </Column>
              </Row>
              <Row>
                <Column span={5} xs={12} md={12}>
                  <Text size={2} color="grey" align="justify">
                    {t("Disclosures.subtitle")}
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column span={5} xs={12} md={12}>
                  <LinksList data={LegalLinks} />
                </Column>
              </Row>
              <Row>
                <Column
                  span={5}
                  xs={12}
                  md={12}
                  justifyContent={JustifyContent.center}
                >
                  <Text size={1} color="grey" align="center">
                    {t("Disclosures.textFooter")}
                  </Text>
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default Disclosures;
