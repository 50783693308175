import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { VenmoInstructionsModalProps } from "../../../Models/ModalInterface";
import { FlexContainer } from "../../Flex/Flex.styled";

import Text from "../../Text";
import Link from "../../Link";
import Modal from "../Modal.component";

import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import VenmoLogo from "../../../Assets/Img/venmo-wide-logo.png";
import { waLinkEn, waLinkEs } from "../../../Constants/Zelle";
import { selectorLanguage } from "../../../Redux/Translate";
import { VenmoColorSpan } from "../../Text/Text.styled";

import { StepNumber } from "./VenmoModal.styled";

export const VenmoInstructionsModal = (props: VenmoInstructionsModalProps) => {
  const [t] = useTranslation("global");
  const { language } = useSelector(selectorLanguage);

  const zelleSupportWA = language === "en" ? waLinkEn : waLinkEs;

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer justify="center" alignItems="center" gap="4px" m="0 0 20px 0">
        <Text display="inline" align="left" size={1} weight={600}>
          {t("Venmo.instructions.title1")}
          &nbsp;
          <img src={VenmoLogo} width="96px" height="18px" style={{ display: "inline", margin: "0 5px -5px 4px" }} />
          {t("Venmo.instructions.title2")}
        </Text>
      </FlexContainer>
      <FlexContainer direction="column" gap="12px" p="0">
        <FlexContainer alignItems="center" gap="10px">
          <StepNumber>1</StepNumber>
          <FlexContainer direction="column">
            <Text align="left" size={0.5} color="black" weight={400} lineHeight="16px">
              <VenmoColorSpan dangerouslySetInnerHTML={{ __html: t("Venmo.instructions.step1.description") }} />
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems="center" gap="10px">
          <StepNumber>2</StepNumber>
          <FlexContainer direction="column">
            <Text align="left" size={0.5} color="black" weight={400} lineHeight="16px">
              <VenmoColorSpan dangerouslySetInnerHTML={{ __html: t("Venmo.instructions.step2.description") }} />
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer alignItems="center" gap="10px">
          <StepNumber>3</StepNumber>
          <FlexContainer direction="column">
            <Text align="left" size={0.5} color="black" weight={400} lineHeight="16px">
              <VenmoColorSpan dangerouslySetInnerHTML={{ __html: t("Venmo.instructions.step3.description") }} />
            </Text>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer justify="center" alignItems="baseline" gap="4px" m="24px 0">
          <Text color="black" size={0.5} weight={400}>
            {t("Venmo.instructions.helpAdvice")}
          </Text>
          <Link
            href="#"
            size={0.5}
            underline
            onClick={() => {
              window.location.href = zelleSupportWA;
            }}
          >
            <Text color="black" size={0.5} weight={600}>
              {t("global.contactSupport")}
            </Text>
          </Link>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
