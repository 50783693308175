import { useEffect, useMemo, useState } from "react";
import Text from "../../../../Components/Text";
import { useTranslation } from "react-i18next";
import { CountryCodeList } from "../../../../Modules/Common/domain/PhoneCountryCode";
import { useFormContext } from "react-hook-form";
import FlagPhoneNumberInput from "../../../../Components/FlagPhoneNumberInput";
import { useCountry } from "../../../../Hooks/useCountry";
import { CountryCode } from "libphonenumber-js/core";
import { FormInput } from "../../../../Components/Input";
import { allowedEmail, allowedNumbers } from "../../../../Constants/Regexp";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../../Redux/Translate";
import { iso31661Alpha3ToAlpha2 } from "iso-3166";

interface ContactFormProps {
  countrySelected?: string | null;
}

export const ContactForm = ({ countrySelected }: ContactFormProps) => {
  const { t } = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const [phoneCountryCode, setPhoneCountryCode] = useState<string | null | undefined>(
    countrySelected && iso31661Alpha3ToAlpha2[countrySelected]
  );
  const [phoneCountryCodeSelect, setPhoneCountryCodeSelect] = useState<string>();

  const {
    formState: { errors },
    setValue,
    register,
  } = useFormContext();
  const { allCountriesSelectOption, getAllCountriesList } = useCountry();

  const phoneMaxLenght = useMemo(() => {
    const maxLengthPhone = CountryCodeList.find((c) => c.countryCode === phoneCountryCode)?.phoneMaxLength;

    return maxLengthPhone;
  }, [phoneCountryCode]);

  const phoneMinLenght = useMemo(() => {
    const minLengthPhone = CountryCodeList.find((c) => c.countryCode === phoneCountryCode)?.phoneMinLength;

    return minLengthPhone;
  }, [phoneCountryCode]);

  useEffect(() => {
    if (phoneCountryCodeSelect) {
      setValue("phoneCountryCode", phoneCountryCodeSelect);
    }
  }, [phoneCountryCodeSelect]);

  useEffect(() => {
    getAllCountriesList(language);
  }, [language]);

  return (
    <>
      <Text size={2} lineHeight="24px" weight={600} align="left" margin="0 0 25px 2px">
        {t("Beneficiaries.ContactTitle")}
      </Text>
      <FormInput
        placeholderInput={t("Beneficiaries.CreateBeneficiary.Form.Email")}
        typeInput="email"
        label="email"
        rules={{
          pattern: {
            value: allowedEmail,
            message: t("Beneficiaries.CreateBeneficiary.Validation.InvalidEmail"),
          },
        }}
        mb="25px"
        isError={Boolean(errors?.["email"])}
      />
      <FlagPhoneNumberInput
        labelText={t("Beneficiaries.CreateBeneficiary.Form.PhoneNumber")}
        label="phoneNumber"
        rules={{
          required: t("Forms.required"),
          validate: (value: string) => (value.trim() === "" ? t("Forms.required") : true),
          maxLength: {
            value: phoneMaxLenght || 10,
            message: t("Beneficiaries.CreateBeneficiary.Form.MaxLengthPhoneNumber"),
          },
          minLength: {
            value: phoneMinLenght || 10,
            message: t("Beneficiaries.CreateBeneficiary.Form.MinLengthPhoneNumber", {
              minLength: phoneMinLenght || 10,
            }),
          },
          pattern: {
            value: allowedNumbers,
            message: t("Forms.inputNumeric"),
          },
        }}
        register={register}
        errors={errors}
        countries={allCountriesSelectOption.map((c) => c.shortCode as CountryCode)}
        countryDefault={allCountriesSelectOption.find((c) => c.value === countrySelected)?.shortCode as CountryCode}
        setCountryCode={setPhoneCountryCodeSelect}
        onCountryChange={(_, countryCodeAlpha3) => {
          setPhoneCountryCode(countryCodeAlpha3);
        }}
        mb="25px"
      />
    </>
  );
};
