import { useTranslation } from "react-i18next";

import Modal from "../Modal.component";
import Text from "../../Text";
import Button from "../../Button";
import {
  CodeKeys,
  VelocityLimitModalProps,
} from "../../../Models/ModalInterface";
import IconFont from "../../IconFont";
import { MarginIcon } from "../Modal.styled";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../Redux/Translate";

export const VelocityLimitModal = ({
  limitType = "day",
  codesMessages,
  pageName,
  ...props
}: VelocityLimitModalProps) => {
  const { t } = useTranslation("modals");
  const [errorsTimeout, setErrorsTimeout] = useState<{ [key: string]: string }>(
    {}
  );
  const lang = useSelector(selectorLanguage);

  const messages: CodeKeys = useMemo(
    () => ({
      VL001: t(
        pageName === "verify"
          ? "velocityLimits.messagetxnDailyVerify"
          : "velocityLimits.messagetxnDaily"
      ),
      VL002: t(
        pageName === "verify"
          ? "velocityLimits.messageTxnMonthlyVerify"
          : "velocityLimits.messageTxnMonthly"
      ),
      VL003: t(
        pageName === "verify"
          ? "velocityLimits.messageAmountDailyVerify"
          : "velocityLimits.messageAmountDaily"
      ),
      VL004: t(
        pageName === "verify"
          ? "velocityLimits.messageAmountMonthlyVerify"
          : "velocityLimits.messageAmountMonthly"
      ),
      VL007: t("velocityLimits.messageMinAmountDestination"),
      VL008: t("velocityLimits.messageMaxAmountDestination"),
      VL009: t("velocityLimits.messageAmountDestinationDaily"),
      VL010: t("velocityLimits.messageAmountDestinationMonthly"),
      VL013: t("velocityLimits.messageTxs"),
      VL014: t("velocityLimits.messageAmountTxs"),
      VL015: t("velocityLimits.messageCountrySubdivisionDaily", {
        limit: codesMessages?.find((item) => item.code === "VL015")?.value,
      }),
      VL016: t("velocityLimits.messageCountrySubdivisionMonthly", {
        limit: codesMessages?.find((item) => item.code === "VL016")?.value,
      }),
      VF005: t("velocityLimits.messageTxnDuplicated", {
        amount: props.amount,
        beneficiaryName: props.beneficiaryName,
        time: errorsTimeout["VF005"],
      }),
    }),
    [errorsTimeout, codesMessages, lang]
  );

  useEffect(() => {
    if (codesMessages) {
      for (const item of codesMessages) {
        if (item.code === "VF005") {
          setErrorsTimeout((current) => ({
            ...current,
            [item.code]: item.msg,
          }));
        }
      }
    }
  }, [codesMessages]);

  return (
    <Modal {...props}>
      <MarginIcon>
        <IconFont name="alert" size="xlarge" color="error" />
      </MarginIcon>
      {codesMessages &&
        codesMessages.map((item, index) => (
          <Text key={index} size={1} margin="0 0 20px 0">
            {messages[item.code]}
          </Text>
        ))}
      <Button
        variant={"primary"}
        sizeButton="large"
        sizeText="large"
        text={t("velocityLimits.next")}
        colorIcon="white"
        sizeIcon="large"
        onClick={() => props.handleClose()}
      />
    </Modal>
  );
};
