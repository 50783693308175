import { LetterAvatarStyled } from "./Avatar.styled";

interface LetterAvatarProps {
  label: string;
  letters?: number;
}

export const LetterAvatar = ({ label, letters = 1 }: LetterAvatarProps) => {
  return (
    <LetterAvatarStyled>
      {Array.from(Array(letters), (_v, index) =>
        label.charAt(index).toUpperCase()
      )}
    </LetterAvatarStyled>
  );
};
