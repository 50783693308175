import { createClient } from "../../../Services/ApiClient";
import { createConfigApiApp } from "../../../Services/ApiApp";
import { Coupon, CouponGroup, VerifyCouponResponse } from "../domain/Coupon";
import { CouponRepository } from "../domain/CouponRepository";

const client = createClient();
const clientApp = createConfigApiApp();

export const createApiCouponRepository = (): CouponRepository => {
  return {
    getCoupons,
    verify,
  };
};

const getCoupons: CouponRepository["getCoupons"] = async () => {
  const response = await client.get<Coupon[]>("Rewards/user-coupons");

  const sortedCoupons = response.data.sort(
    (a, b) => new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime()
  );

  const couponsGrouped = sortedCoupons.reduce((groups, coupon) => {
    const couponGroup = groups.find((group) => group.code === coupon.code);
    if (couponGroup) {
      couponGroup.qty = couponGroup.qty + 1;
    } else {
      groups.push({
        code: coupon.code,
        description: coupon.description,
        qty: 1,
      });
    }

    return groups;
  }, [] as CouponGroup[]);

  return { coupons: response.data, couponsGrouped };
};

const verify: CouponRepository["verify"] = async (payload) => {
  const res = await clientApp.post<VerifyCouponResponse>("rewards/coupons/verify", payload);

  return res.data;
};
