import { PropsWithChildren, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../../Components/Layout";
import theme from "../ThemeProvider";
import { Container } from "../../Components/Grid";
import { FlexContainer } from "../../Components/Flex/Flex.styled";

const GoogleMapsLoader = ({ children }: PropsWithChildren) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCgmTcXyQ_9Dh20i2D-BOJW2Bv3SddUjnM&callback=initMap&v=weekly`;
      script.async = true;
      script.onload = () => {
        setIsLoaded(true);
      };
      document.body.appendChild(script);
    } else {
      setIsLoaded(true);
    }
  }, []);

  if (!isLoaded)
    return (
      <ThemeProvider theme={theme}>
        <Layout loading>
          <FlexContainer h="100dvh"></FlexContainer>
        </Layout>
      </ThemeProvider>
    );

  return <>{children}</>;
};

export default GoogleMapsLoader;
