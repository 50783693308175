import styled from "styled-components";
import { textComponentSolidlight } from "../../../../Contexts/ThemeProvider/Texts";

export const ContainerTitleAccount = styled.div`
  width: 100%;
  border-left: 8px solid ${textComponentSolidlight};
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const bankCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  position: relative;
`;

export const FormSectionBank = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8px 20px 8px;

  input {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 15px;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
`;

export const TitleSectionBank = styled(FormSectionBank)`
  padding: 0px 8px 16px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    cursor: pointer;
  }
`;
