import theme from "styled-theming";

import { theme as config } from "./Theme";

const textComponentSolidlight = theme("currentTheme", {
  default: config.Primary_01,
  martori: config.MartoriPrimary_01,
});

const quoterCardLabels = theme("currentTheme", {
  default: config.Content_UX_01,
  martori: config.Content_UX_01,
});

const quoterCardFeeLabels = theme("currentTheme", {
  default: config.Primary_01,
  martori: config.MartoriPrimary_01,
});

const quoterCardFeeCaption = theme("currentTheme", {
  default: config.Content_UX_02,
  martori: config.Content_UX_02,
});

const switchCaption = theme("currentTheme", {
  default: config.Primary_01,
  martori: config.MartoriPrimary_01,
});

export {
  switchCaption,
  quoterCardLabels,
  quoterCardFeeLabels,
  quoterCardFeeCaption,
  textComponentSolidlight,
};
