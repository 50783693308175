import { ReactNode } from "react";

import { Column, Row } from "../../../../Components/Grid";
import Text from "../../../../Components/Text";

import {
  LinkedAccountCardStyled,
  LinkedAccountContent,
  LinkedAccountContentArrow,
} from "./PaymentLinkedAccountCard.styled";
import IconFont from "../../../../Components/IconFont";

type Props = {
  title: string;
  subtitle: string | ReactNode;
  imgSrc?: string;
  extra?: ReactNode;
};

const PaymentLinkedAccountCard = ({
  title,
  subtitle,
  imgSrc,
  extra,
}: Props) => (
  <Column span={12} as={LinkedAccountCardStyled}>
    <Row>
      <Column span={12} p={2} pl={3}>
        <LinkedAccountContent>
          <div>
            <Text
              color="black"
              size={1}
              align="left"
              weight={600}
              margin="5px 0"
            >
              {title}
            </Text>
            <Text
              color="grey"
              size={1}
              align="left"
              weight={400}
              margin="5px 0"
            >
              {subtitle}
            </Text>
            {extra}
          </div>
          <LinkedAccountContentArrow>
            <IconFont name="chevron-right" />
          </LinkedAccountContentArrow>
        </LinkedAccountContent>
      </Column>
    </Row>
  </Column>
);

export default PaymentLinkedAccountCard;
