import { useTranslation } from "react-i18next";
import theme from "../../../Contexts/ThemeProvider";
import { GradientCard } from "../../Card/Card.styled";
import { FlexContainer } from "../../Flex/Flex.styled";
import Switch from "../../Switch/Switch.component";
import Text from "../../Text";
import { useEffect } from "react";
import { QuoteChannel } from "../../../Modules/Quote/domain/QuoteSelected";
import { formatNumber } from "../../../Utilities/NumberUtils";

interface ModalSwitchProps {
  lessFeesData: { fx: number; fee: number; currency: string };
  bestFXData: { fx: number; fee: number; currency: string };
  onChange?: (value: QuoteChannel) => void;
}

export const ChannelSwitch = ({ lessFeesData, bestFXData, onChange }: ModalSwitchProps) => {
  const [t] = useTranslation("sendMoney");

  useEffect(() => {
    onChange && onChange(QuoteChannel.bestFx);
  }, []);

  return (
    <GradientCard width="100%" borderColor="primaryMagenta" padding="8px 10px">
      <FlexContainer alignItems="center" justify="center" gap="8px">
        <FlexContainer w="auto" direction="column" alignSelf="start">
          <Text align="left" size={0.5} weight={600} lineHeight="17.5px">
            {t("Models.LessFees.title")}
          </Text>
          <Text align="left" size={0.5} weight={600} lineHeight="17.5px" color="primaryBlue400">
            $1 USD = {formatNumber(lessFeesData.fx)} {lessFeesData.currency}
          </Text>
          <FlexContainer w="auto" alignItems="center" gap="6px">
            &bull;
            <Text align="left" size={0.1} weight={500} lineHeight="12.5px" transform="lowercase">
              ${lessFeesData.fee} {t("TransferFees")}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer w="auto" justify="center">
          <Switch
            value={true}
            valueOff={QuoteChannel.lessFee}
            valueOn={QuoteChannel.bestFx}
            colorOff={theme.Primary_Blue_400}
            colorOn={theme.Primary_03}
            onChange={(value) => onChange && onChange(value as QuoteChannel)}
          />
        </FlexContainer>
        <FlexContainer w="auto" direction="column" alignSelf="start">
          <Text align="left" size={0.5} weight={600} lineHeight="17.5px">
            {t("Models.BestFX.title")}
          </Text>
          <Text align="left" size={0.5} weight={600} lineHeight="17.5px" color="magenta">
            $1 USD = {formatNumber(bestFXData.fx)} {lessFeesData.currency}
          </Text>
          <FlexContainer w="auto" alignItems="center" gap="6px">
            &bull;
            <Text align="left" size={0.1} weight={500} lineHeight="12.5px" transform="lowercase">
              ${bestFXData.fee} {t("TransferFees")}
            </Text>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </GradientCard>
  );
};
