import { ToastProps } from "../../Models/Notifications";
import Icon from "../Icon";
import Title from "../Title";
import { ToastContainer } from "./ToastNotifications.styled";

const ToastNotifications = ({
  show,
  status,
  message,
  closeNotification,
}: ToastProps) => {
  return (
    <ToastContainer $status={status} hidden={!show}>
      {status !== "success" && (
        <Icon
          icon="alertTriangle"
          color={status === "error" ? "error" : "warning"}
          size="medium"
          fillIcon={false}
        />
      )}
      <Title
        color={status === "error" ? "error" : "black"}
        size={0.5}
        tagName="h1"
        text={message}
        align="left"
        weight={400}
      ></Title>
      <Icon icon="iconX" color="black" onClick={closeNotification} />
    </ToastContainer>
  );
};

export default ToastNotifications;
