import { useStatus } from "./useStatus";
import { Errors } from "../Modules/Common/domain/Errors";
import { AuthorizeApiPaymentOrderRepository } from "../Modules/PaymentOrders/infrastructure/ApiPaymentWithAuthorizeRepository";
import {
  authorizeGetCardsByPersonId,
	authorizeCreateOrder
} from "../Modules/PaymentOrders/application/createPaymentOrder";
import { AuthorizeCreateOrder } from "../Modules/PaymentOrders/domain/Authorize";
import { useState } from "react";

export const useAuthorizePaymentOrder = () => {
  const { isLoading, setStatus, error, setError } = useStatus();
  const [isOrderLoading, setIsOrderLoading] = useState(false);

  const getAuthorizeCardsPayment = async (personId: string) => {
    setStatus("loading");
    try {
			const response = await authorizeGetCardsByPersonId(
				AuthorizeApiPaymentOrderRepository()
			)(personId);

      setStatus("idle");
      
      return response;
    } catch (error) {
      setStatus("error");
      if (error && (error as Errors).errors) {
        setError(error as Errors);
      }
    }
  };

  const setAuthorizeCreateOrder = async (order: AuthorizeCreateOrder) => {
    setIsOrderLoading(true);
    setError(null);

    try {
      const response = await authorizeCreateOrder(
        AuthorizeApiPaymentOrderRepository()
      )(order);

      setStatus("idle");

      return response;
    } catch (error) {
      setStatus("error");
      if (error && (error as Errors).errors) {
        setError(error as Errors);
      }
    } finally {
      setIsOrderLoading(false);
    }
  };

  return {
    error,
    isLoading,
    isOrderLoading,
    getAuthorizeCardsPayment,
    setAuthorizeCreateOrder,
  };
};
