// Own Components
import Text from "../Text";

// Own styles
import { LoaderContainer, LoaderStyled, Percent } from "./Loader.styled";

import { LoaderProps } from "../../Models/Loader";

const Loader = (props: LoaderProps) => {
  const { percentText, message } = props;
  return (
    <LoaderContainer full={props.full}>
      <LoaderStyled />
      {percentText && (
        <Percent $msg={message ? true : false}>{percentText}</Percent>
      )}
      {message && (
        <Text size={2} weight={700}>
          {message}
        </Text>
      )}
    </LoaderContainer>
  );
};

export default Loader;
