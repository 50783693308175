export const randomString = (length = 10) =>
  Array(length)
    .fill(0)
    .map(() => Math.random().toString(36).charAt(2))
    .join("");

export function maskUsernameInEmail(email: string): string {
  const parts = email.split("@");
  const username = parts[0];
  if (username.length > 1) {
    const domain = parts[1];
    if (username.length > 2) {
      const hiddenUsername = "*".repeat(username.length - 2);
      return username.slice(0, 2) + hiddenUsername + "@" + domain;
    } else {
      const hiddenUsername = "*".repeat(username.length - 1);
      return username.slice(0, 1) + hiddenUsername + "@" + domain;
    }
  } else {
    return email;
  }
}

export function maskPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.length < 4) return phoneNumber;
  const visibleDigits = phoneNumber.slice(-4);
  const hiddenQty = phoneNumber.length - 4;
  const hiddenDigits = "*".repeat(hiddenQty);

  return hiddenDigits + visibleDigits;
}

export function maskInterbankKey(number: string): string {
  if (number) {
    const lastDigits = number.slice(-4);
    const asterisks = "*".repeat(lastDigits.length);

    return asterisks + lastDigits;
  }
  return "";
}

export function parseDate(date: string): string {
  const parts = date.split("-");

  if (parts.length !== 3) {
    throw new Error("Incorrect date format. It should be dd-mm-yyyy");
  }

  const month = parts[0];
  const day = parts[1];
  const year = parts[2];

  if (isNaN(parseInt(day)) || isNaN(parseInt(month)) || isNaN(parseInt(year))) {
    throw new Error("Incorrect date format. Components should be numbers");
  }

  const convertedDate = `${year}-${month}-${day}`;
  return convertedDate;
}

export function formatDate(inputDate: string, lang: string): string {
  const months: {
    [x: string]: string[];
  } = {
    en: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    es: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
  };

  const date = new Date(inputDate);

  const day = date.getDate();
  const month = months[lang][date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);
  const hour = date.getHours() % 12 || 12;
  const minute = date.getMinutes();
  const ampm = date.getHours() >= 12 ? "pm" : "am";

  return `${day} / ${month} / ${year} ${hour}:${minute
    .toString()
    .padStart(2, "0")} ${ampm}`;
}

export function CustomFormatDate(inputDate: string, lang: string): string {
  const months: {
    [x: string]: string[];
  } = {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    es: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
  };

  const formattedDate = new Date(
    parseInt(inputDate.split("-")[1]),
    parseInt(inputDate.split("-")[0]) - 1,
    1
  );

  const date = new Date(formattedDate);
  const month = months[lang][date.getMonth()];
  const year = date.getFullYear().toString();

  return `${month} ${year}`;
}

export function getSlicedString(str: string, numChars: number): string {
  return `${str.substring(0, numChars)}${str.length > numChars ? "..." : ""}`;
}
