import { useCallback, useEffect, useState } from "react";
import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOptions,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnLoad,
} from "react-plaid-link";

import Button from "../../../../Components/Button";
import {
  PlaidAccountsUpdateRequest,
  PlaidTokenExchangeRequest,
} from "../../../../Modules/PaymentOrders/domain/Plaid";
import usePlaidPaymentOrder from "../../../../Hooks/usePlaidPaymentOrder";
import { ButtonVariant } from "../../../../Models/buttonInterface";
import { IconTypeKey } from "../../../../Models/IconInterface";

type Props = {
  requiredUpdate?: boolean;
  updateData?: PlaidAccountsUpdateRequest;
  btnText: string;
  btnVariant?: ButtonVariant;
  btnIcon?: IconTypeKey;
  onCompleted: (requestId: string) => void;
};

const PlaidLink = ({
  requiredUpdate,
  updateData,
  btnText,
  btnVariant = "primary",
  btnIcon,
  onCompleted,
}: Props) => {
  const [token, setToken] = useState<string | null>(null);
  const [metadata, setMetada] = useState({} as PlaidTokenExchangeRequest);
  const { 
    plaidCreateToken,
    plaidExchangeToken,
    plaidUpdateAccount,
    errorModal 
  } = usePlaidPaymentOrder();

  useEffect(() => {
    createLinkToken();
  }, []);

  useEffect(() => {
    if (Object.keys(metadata).length > 0) {
      exchangeToken(metadata);
    }
  }, [metadata]);

  const createLinkToken = async () => {
    let response = null;

    try {
      if (requiredUpdate && updateData) {
        response = await plaidUpdateAccount(updateData);
      } else {
        response = await plaidCreateToken();
      }

      if (response) {
        setToken(response?.linkToken);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exchangeToken = async (body: PlaidTokenExchangeRequest) => {
    const response = await plaidExchangeToken({
      publicToken: body.publicToken,
      institutionId: body.institutionId,
    });

    if (response) {
      onCompleted(response.accountId);
    }
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      // send public_token to your server
      // https://plaid.com/docs/api/tokens/#token-exchange-flow
      if (!requiredUpdate) {
        setMetada({
          publicToken,
          institutionId: metadata.institution?.institution_id || "",
          accounts: metadata.accounts,
        });
      } else {
        onCompleted("");
      }
    },
    []
  );

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  }, []);

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log(error, metadata);
  }, []);

  const onLoad = useCallback<PlaidLinkOnLoad>(() => {
    console.log("load =======>");
  }, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onEvent,
    onExit,
    onLoad,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <>
      <Button
        disabled={!ready}
        type="submit"
        variant={btnVariant}
        sizeButton="large"
        sizeText="medium"
        sizeIcon="medium"
        text={btnText}
        colorIcon="white"
        {...(btnIcon ? { iconButton: "arrowRight" } : {})}
        onClick={() => open()}
      />
      {errorModal}
    </>
  );
};

export default PlaidLink;
