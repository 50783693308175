/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from "react-i18next";
import { SelectOption } from "../Models/Select";
import { useGeoLocation } from "./useGeoLocation";
import { FieldValues } from "react-hook-form";
import { useDebounce } from "./useDebounce";
import { isValidAccountWithMessage } from "../Modules/Beneficiary/domain/Beneficiary";
import { Destination } from "../Modules/Common/domain/Destination";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../Redux/Translate";
import { useCallback } from "react";

export const useBeneficiaryValidations = () => {
  const [t] = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const { cityExists } = useGeoLocation();

  const validateCity = useDebounce(
    async (
      city: string,
      {
        country,
        subdivision,
        accountStateISOCode,
        subdivisionList,
      }: FieldValues & { subdivisionList?: SelectOption[] }
    ) => {
      const subdivisionFound = subdivisionList?.find(
        (s) => s.value === (subdivision || accountStateISOCode)
      );
      const countryCode = country || subdivisionFound?.value.split("-")[0];

      if (countryCode && subdivisionFound) {
        const cities = await cityExists(
          country,
          country === "MEX" ? subdivisionFound?.value : subdivisionFound?.label,
          city
        );

        return cities?.includes(city) || t("Forms.cityNotValid");
      }
      return [];
    },
    200
  );

  const validateBankAccount = useCallback(
    (
      value: string,
      formValues: FieldValues & { bankList: Array<SelectOption & Destination> }
    ) => {
      const { country, destination, bankList, accountTypeId } = formValues;
      const destinationSelected = bankList.find(
        (dest) => dest.id === destination
      );
      const validationObj =
        destinationSelected?.destinationExpressions?.find(
          (expr) => expr.accountType === accountTypeId
        ) || destinationSelected?.destinationExpressions[0];
      if (validationObj?.regexExpression) {
        const regexp = new RegExp(validationObj?.regexExpression);

        const validationMsg = {
          en: validationObj.messageEN!,
          es: validationObj.messageSP!,
        }[language as string];

        return (
          regexp.test(value) ||
          validationMsg ||
          (country === "MEX"
            ? t("Beneficiaries.CreateBeneficiary.Validation.AccountMX")
            : t("Beneficiaries.CreateBeneficiary.Validation.InvalidAccount"))
        );
      }

      return country === "MEX" && !destination
        ? t("Beneficiaries.CreateBeneficiary.Validation.InvalidBank")
        : isValidAccountWithMessage(value, country) ||
            t("Beneficiaries.CreateBeneficiary.Validation.AccountMX");
    },
    [language]
  );

  const validateBankAccountEditBeneficiary = useCallback(
    (
      value: string,
      formValues: FieldValues & { bankList: Array<SelectOption & Destination> },
      fieldsPrefix: string,
      bankSelected?: string
    ) => {
      const { country, bankList, accounts } = formValues;
      const accountSelect = accounts[fieldsPrefix.split(".")[1]];
      const destinationSelected = bankList.find(
        (dest) => dest.id === bankSelected
      );

      const validationObj = destinationSelected?.destinationExpressions?.find(
        (expr) => expr.accountType === accountSelect.accountTypeId
      );
      if (validationObj?.regexExpression) {
        const regexp = new RegExp(validationObj?.regexExpression);

        const validationMsg = {
          en: validationObj.messageEN!,
          es: validationObj.messageSP!,
        }[language as string];

        return (
          regexp.test(value) ||
          validationMsg ||
          (country === "MEX"
            ? t("Beneficiaries.CreateBeneficiary.Validation.AccountMX")
            : t("Beneficiaries.CreateBeneficiary.Validation.InvalidAccount"))
        );
      }

      return country === "MEX" && !accountSelect.destination
        ? t("Beneficiaries.CreateBeneficiary.Validation.InvalidBank")
        : isValidAccountWithMessage(value, country) ||
            t("Beneficiaries.CreateBeneficiary.Validation.AccountMX");
    },
    [language]
  );

  return {
    validateCity,
    validateBankAccount,
    validateBankAccountEditBeneficiary,
  };
};
