import { CardImgProps } from "../../Models/cardInterface";
import { CardImageStyled } from "./CardImg.styled";

const CardImage = (props: CardImgProps) => {
  const { image, children } = props;
  return (
    <CardImageStyled $image={image} {...props}>
      {children}
    </CardImageStyled>
  );
};

export default CardImage;
