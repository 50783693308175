import { useState } from "react";
import { SelectOption } from "../Models/Select";
import { getCities } from "../Modules/Common/application/getCities";
import { Errors } from "../Modules/Common/domain/Errors";
import { createApiCityRepository } from "../Modules/Common/infraestructure/ApiCityRepository";
import { useStatus } from "./useStatus";

export const useCity = () => {
  const { status, isLoading, setStatus, setError } = useStatus();
  const [citiesList, setCitiesList] = useState<SelectOption[]>([]);

  const getSubdivisionCities = async (subdivisionCode: string) => {
    setStatus("loading");
    try {
      const cities = await getCities(createApiCityRepository())(
        subdivisionCode
      );

      setStatus("idle");
      return cities;
    } catch (error) {
      setStatus("error");
      setError(error as Errors);
    }
  };

  const getSubdivisionCitiesOptions = async (subdivisionCode: string) => {
    const cities = await getSubdivisionCities(subdivisionCode);

    const options =
      cities?.map((city) => ({
        label: city.cityName,
        value: city.cityName,
        key: city.cityName,
      })) || [];

    setCitiesList(options);

    return options;
  };

  return {
    citiesList,
    status,
    isLoading,
    getSubdivisionCities,
    getSubdivisionCitiesOptions,
  };
};
