import styled, { css } from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import {
  footerBackgroundColor,
  footerLogo,
  footerRibbonBackgroundColor,
} from "../../Contexts/ThemeProvider/Layout";
import { textComponentSolidlight } from "../../Contexts/ThemeProvider/Texts";

export const Container = styled.div`
  background-color: ${footerBackgroundColor};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 64px 0;
  max-width: 1500px;
  margin: 0 auto;

  ${mediaQuery[Screen.xs]} {
    flex-direction: column;
  }
`;

export const Linear = styled.div`
  background: ${footerRibbonBackgroundColor};
  height: 45px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  ${mediaQuery[Screen.xs]} {
    flex-direction: column;
  }
`;

export const Logocontent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;

  img {
    ${footerLogo}
  }

  ${mediaQuery[Screen.mobile]} {
    flex-direction: column;
    margin-top: 100px;
    justify-content: center;
  }
`;

export const LogoSendolaPay = styled.div`
  padding-right: 30px;

  img {
    width: 112px;
    height: 70px;
  }

  ${mediaQuery[Screen.mobile]} {
    margin-bottom: 30px;
    padding-right: 0;
  }
`;

export const DisclosureContainer = styled.div`
  padding: 0 30px;

  a {
    color: ${textComponentSolidlight};
  }

  ${mediaQuery[Screen.mobile]} {
    padding: 0 30px 40px 30px;
  }
`;

const stylesList = css`
  padding: 0;
  margin: 0 24px;
  list-style: none;

  li {
    margin: 15px;
    margin-right: 60px;
    font-weight: 500;
    line-height: normal;

    a {
      font-size: 18px;
      font-weight: 500;
    }

    ${mediaQuery[Screen.xs]} {
      margin: 20px;
      text-align: left;
    }
  }
`;

export const ListMobile = styled.ul`
  ${stylesList}
  display: none;

  ${mediaQuery[Screen.mobile]} {
    display: block;
  }
`;

export const List = styled.ul`
  display: block;
  ${stylesList}

  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;

export const CompanyLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${mediaQuery[Screen.xs]} {
    order: 1;
  }
`;

export const SendolaLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    width: 140px;

    ${mediaQuery[Screen.xs]} {
      font-size: 12px;
    }
  }

  ${mediaQuery[Screen.xs]} {
    order: 2;
  }
`;
