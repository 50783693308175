import { BeneficiariesPaginated } from "../domain/Beneficiary";
import { BeneficiaryRepository } from "../domain/BeneficiaryRepository";
import {
  // Beneficiary,
  BeneficiaryEdit,
  BeneficiaryUpdate,
} from "../domain/Beneficiary";
import { SelectOption } from "../../../Models/Select";
import { Errors } from "../../Common/domain/Errors";

export const getBeneficiaries =
  (beneficiaryRepository: BeneficiaryRepository) =>
  async (params: {
    page: number;
    perPage: number;
    country?: string;
    destination?: string;
    search?: string;
    language?: string;
  }): Promise<BeneficiariesPaginated> =>
    beneficiaryRepository.getBeneficiaries(params);

export const getBeneficiariesRecent =
  (beneficiaryRepository: BeneficiaryRepository) =>
  async (params: {
    page: number;
    perPage: number;
    country?: string;
    destination?: string;
    search?: string;
    language?: string;
  }): Promise<BeneficiariesPaginated> =>
    beneficiaryRepository.getBeneficiariesRecent(params);

export const getBeneficiaryId =
  (beneficiaryRepository: BeneficiaryRepository) =>
  async (data: string): Promise<BeneficiaryEdit> =>
    beneficiaryRepository.getBeneficiaryId(data);

export const updateBeneficiary =
  (beneficiaryRepository: BeneficiaryRepository) =>
  async (data: BeneficiaryUpdate): Promise<BeneficiaryEdit | Errors> =>
    beneficiaryRepository.updateBeneficiary(data);

export const deleteBeneficiary =
  (beneficiaryRepository: BeneficiaryRepository) =>
  async (beneficiaryId: string): Promise<BeneficiaryEdit> =>
    beneficiaryRepository.deleteBeneficiary(beneficiaryId);

export const deleteAccount =
  (beneficiaryRepository: BeneficiaryRepository) =>
  async (beneficiaryId: string, accountId: string): Promise<BeneficiaryEdit> =>
    beneficiaryRepository.deleteAccount(beneficiaryId, accountId);
