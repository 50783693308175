import { TOKEN_KEY } from "../Constants/Token";
import { LANG_KEY } from "../Constants/Language";
import Cookies from "universal-cookie";
import { AxiosProvider } from "./http/Axios.provider";
import { ApiRequestError } from "../Types/ApiRequestError";
import { CreateAxiosDefaults } from "axios";
import store from "../Redux/Store";

const apiKey = import.meta.env.VITE_API_KEY;
const prefixUrl = import.meta.env.VITE_GLOBAL_TRANSFER_URL;
const HttpClient = AxiosProvider;

function getToken() {
  const cookies = new Cookies();
  return sessionStorage.getItem(TOKEN_KEY) || cookies.get(TOKEN_KEY);
}

export function createClient(config?: CreateAxiosDefaults) {
  const language = sessionStorage.getItem(LANG_KEY) || "en";

  const client = new HttpClient({
    config: config || {
      baseURL: prefixUrl,
      headers: {
        languageCode: language,
        "x-api-key": apiKey,
      },
    },
    hooks: {
      request: (config) => {
        const token = getToken();

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },
      response: {
        onRejected: (error) => {
          const apiErrorData = error.response?.data as ApiRequestError;
          const errorObj = {
            status: error.response?.status,
            statusText: error.response?.statusText,
            ...apiErrorData,
          };

          if (
            (error.response?.data as { code: string; message: string })
              ?.code === "AUTH001"
          ) {
            store.dispatch({ type: "PersonsKYC/blockUser" });
          } else if (error.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
          } else {
            store.dispatch({ type: "PersonsKYC/unBlockUser" });
          }

          // TODO: Customize error handling allowing more types of errors and actions

          throw errorObj;
        },
      },
    },
  });

  return client;
}
