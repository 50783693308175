import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { State } from "../../../Redux/Store";
import Layout from "../../../Components/Layout";
import { ProgressSteps } from "../../../Components/Steps";
import { Column, Container, Row } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Text from "../../../Components/Text";
import { useSendMoneyStepper } from "../../../Hooks/useSendMoneyStepper";
import { ThemeProvider } from "styled-components";
import { PaymentMethodsContainer } from "../Payments.styled";
import { CheckInstructionsCard } from "../Components/CheckInstructions/CheckInstructionsCard";
import PaymentSendolaCardLogoSrc from "../../../Assets/Img/sendola-pay-logo.png";
import IconFont from "../../../Components/IconFont";
import { JustifyContent } from "../../../Types/Column";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router-dom";
import { useSendolaPayPaymentOrder } from "../../../Hooks/useSendolaPayPaymentOrder";

export const SendolaPayInstructions = () => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const navigate = useNavigate();

  const theme = useSelector((state: State) => state.theme);
  const { steps, activeStep } = useSendMoneyStepper({ initialActiveStep: 3 });
  const { senderPrivacypolicy } = useSendolaPayPaymentOrder();

  const nextStep = async () => {
    const response = await senderPrivacypolicy();
    if (response && !response.isError) {
      navigate("/payment-sendola-pay-balance");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/payments" textLink={t("buttons.back")}>
        <ProgressSteps steps={steps} activeStep={activeStep} />
        <Container>
          <PaymentMethodsContainer>
            <Row>
              <Column
                span={12}
                justifyContent={JustifyContent.center}
                mb={3}
                mt={3}
              >
                <img src={PaymentSendolaCardLogoSrc} />
              </Column>
              <Column span={12} px={3} mb={4}>
                <Title
                  tagName="h1"
                  text={t("Payments.sendolaPay.instructionsTitle")}
                  color="black"
                  size={3}
                  align="left"
                />
              </Column>
              <Column
                span={12}
                px={3}
                mb={5}
                justifyContent={JustifyContent.center}
              >
                <CheckInstructionsCard
                  title={t("Payments.howItWorks")}
                  body={
                    <>
                      <Text align="left" size={1} margin="24px 0 24px 0">
                        {t("Payments.sendolaPay.helpText1")}
                      </Text>
                      <Text align="left" size={1} margin="24px 0 24px 0">
                        {t("Payments.sendolaPay.helpText2")}
                      </Text>
                    </>
                  }
                  imageSrcLeft={PaymentSendolaCardLogoSrc}
                  iconRight={
                    <IconFont
                      name="user-rounded"
                      size="xxlarge"
                      color="secondary"
                    />
                  }
                />
              </Column>
              <Column span={12} mb={6} px={3}>
                <Button
                  variant="primary"
                  sizeButton="xlarge"
                  sizeText="large"
                  sizeIcon="large"
                  text={tSendMoney("sendNow")}
                  iconButton="chevronRight"
                  colorIcon="white"
                  onClick={() => nextStep()}
                />
              </Column>
            </Row>
          </PaymentMethodsContainer>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};
