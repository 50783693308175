import React, { useEffect, useMemo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { useBlocker, useLocation, useNavigate } from "react-router-dom";
import { isNil } from "lodash";

// Own components
import Button from "../../Components/Button";
import { Column, Container, Row } from "../../Components/Grid";
import Layout from "../../Components/Layout";
import Text from "../../Components/Text";
import IconFont from "../../Components/IconFont";

import { AppDispatch, State } from "../../Redux/Store";

import { ButtonContainer } from "../ReviewTransaction/ReviewTransaction.styled";

import { SummaryContainer } from "./TransactionCompleted.styled";

import { paymentOrderSelector, paymentOrderSlice } from "../../Redux/PaymentOrder/PaymentOrder.slice";
import { useFeature } from "../../Hooks/useFeature";

import { imgTypes } from "../../Utilities/Icons";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import { rails } from "../../Constants/Rails";
import { usePaymentOrder } from "../../Hooks/usePaymentOrders";
import { TransferOrdersSelector } from "../../Redux/TransferOrders";
import { BeneficiarySlice } from "../../Redux/Beneficiary";
import { FlexContainer } from "../../Components/Flex/Flex.styled";
import { useModal } from "../../Hooks/useModal";
import { PaymentOrderStatus } from "../../Modules/PaymentOrders/domain/PaymentOrder";
import { QuoteSummary } from "../../Components/QuoterCardAdvanced/QuoteSummary/QuoteSummary.component";
import {
  ActionsIcons,
  ContainerMTCN,
  CopyCOntainer,
  LabelCard,
  TextCopy,
} from "../Transactions/Components/TransactionHistoryDetailsPage.styled";
import { CopyTextToClipboard } from "../../Utilities/CopyTextToClipboard";
import IconShape from "../../Components/IconShape";
import Link from "../../Components/Link";
import { ReferralBanner } from "../../Components/ReferralBanner/ReferralBanner.component";
import { format } from "date-fns";
import getDateFnsLocale from "../../Utilities/dateFnsLocale";
import { AlerBoxContainer } from "../../Components/AlertBox/AlertBox.styled";
import { useTimer } from "../../Hooks/useTimer";

const TransactionCompletedPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useSelector((state: State) => state.theme);
  const paymentOrder = useSelector(paymentOrderSelector).data;
  const unitellerUrl = import.meta.env.VITE_UNITELLER_URL;
  const { currentPaymentDestination, currentPaymentMethod, currentDeliveryMethodQuote } =
    useSelector(QuotationSelector).data;
  const { transactionDetails: transactionDetailsById } = useSelector(TransferOrdersSelector).data;
  const { rewardsFeature } = useFeature();

  const { t, i18n } = useTranslation("global");
  const [copiedText, setCopiedText] = useState<boolean>(false);

  const { isLoading: paymentOrderIsLoading, getTransactionDetail } = usePaymentOrder();

  const { modal: modalZelle, showModal: showModalZelle } = useModal();
  const { modal: modalCPU, showModal: showModalCPU } = useModal();
  const { minutes, seconds } = useTimer({
    target: paymentOrder?.orderedAt ? new Date(paymentOrder.orderedAt) : undefined,
  });

  const railName = useMemo(() => {
    return currentPaymentDestination?.rail ? rails[currentPaymentDestination?.rail as keyof typeof rails] : undefined;
  }, [currentPaymentDestination?.rail]);

  const isBankTransfer = paymentOrder?.deliveryMethod === "D2B";

  const isUnitellerTransaction =
    currentPaymentDestination?.rail === "UNT" || paymentOrder?.rail === "UNT" || paymentOrder?.rail === "Uniteller";

  const isZelleTransaction =
    paymentOrder?.payment?.productType.toLowerCase() === "zelle" ||
    currentPaymentMethod?.paymentOrigin.toLowerCase() === "zelle";

  const isVenmoTransaction =
    paymentOrder?.payment?.productType.toLowerCase() === "venmo" ||
    currentPaymentMethod?.paymentOrigin.toLowerCase() === "venmo";

  const isACHTransaction =
    paymentOrder?.payment?.productType.toLowerCase() === "ACH" ||
    currentPaymentMethod?.paymentOrigin === "Sendola Card" ||
    currentPaymentMethod?.paymentOrigin === "Sendola Plaid" ||
    currentPaymentMethod?.paymentOrigin === "Sendola ACH";

  async function handleFinishButton(event: React.MouseEvent<HTMLAnchorElement>) {
    if (!isBankTransfer) {
      openCPUInstructions();
    } else {
      dispatch(BeneficiarySlice.actions.resetState());
      return navigate("/dashboard");
    }
  }

  const openCPUInstructions = () => {
    showModalCPU({
      modalType: "cpuInstructionsModal",
      rail: railName,
      mtcn: paymentOrder?.mtcn,
      bankName: currentPaymentDestination?.destination,
      status: paymentOrder?.lastStatus,
      handleConfirm: () => {
        dispatch(BeneficiarySlice.actions.resetState());
        navigate("/dashboard");
      },
    });
  };

  const openZelleInstructions = () => {
    if (paymentOrder) {
      showModalZelle({
        modalType: "zelleInstructionsModal",
        finish: true,
        reference: paymentOrder.mtcn,
        amount: paymentOrder.payment.amount
          ? Number(paymentOrder.payment.amount)
          : Number(paymentOrder.instruction.origination.amount) +
            Number(paymentOrder.instruction.quote.fee) +
            Number(paymentOrder.instruction.quote.paymentMethodFee),
      });
    }
  };

  const copyTextMtcn = () => {
    setCopiedText(true);
    CopyTextToClipboard(paymentOrder?.mtcn);
    setTimeout(() => {
      setCopiedText(false);
    }, 1000);
  };

  useEffect(() => {
    if (isNil(paymentOrder) && search) {
      const urlSearchParams = new URLSearchParams(location.search);
      const paymentOrderId = urlSearchParams.get("paymentorderId");

      if (paymentOrderId) {
        getTransactionDetail(paymentOrderId);
      } else {
        return navigate("/dashboard");
      }
    } else if (isNil(paymentOrder)) {
      return navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (transactionDetailsById) {
      const { receipt, receiver, instruction } = transactionDetailsById;
      dispatch(
        paymentOrderSlice.actions.setPaymentOrderCreated({
          ...transactionDetailsById,
          mtcn: transactionDetailsById.mtcn,
          mtn: transactionDetailsById.mtn,
          orderedAt: transactionDetailsById.orderedAt,
          deliveryMethod: instruction.quote.type,
          receipt,
          receiver: {
            firstName: receiver.firstName,
          },
          instruction: {
            destination: {
              country: instruction.destination.country,
              currency: instruction.destination.currency,
            },
            origination: {
              amount: instruction.origination.amount,
            },
            quote: {
              fee: currentDeliveryMethodQuote?.fee || 0,
              paymentMethodFee: currentPaymentMethod?.paymentMethodFee || 0,
              fx: instruction.quote.fx,
            },
          },
        })
      );
    }
  }, [transactionDetailsById]);

  useBlocker(({ nextLocation }) => nextLocation.pathname !== "/dashboard");

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={paymentOrderIsLoading}>
        <Container maxWidth="442px">
          <FlexContainer direction="column" justify="center" p="13px" gap="16px">
            {paymentOrder?.lastStatus !== PaymentOrderStatus.funds ? (
              <FlexContainer gap="12px">
                <FlexContainer w="auto" flex="1 1 auto">
                  <IconShape
                    icon="sending-plane"
                    iconSize="large"
                    bgColor="primary"
                    border="rounded"
                    width={52}
                    height={52}
                  />
                </FlexContainer>
                <FlexContainer direction="column">
                  <Text size={2} weight={600} lineHeight="30px" align="left">
                    {t("TransactionCompleted.SectionTitle")}
                  </Text>
                </FlexContainer>
              </FlexContainer>
            ) : (
              <FlexContainer gap="12px">
                <FlexContainer w="auto" flex="1 1 auto">
                  <IconShape
                    icon="sand-clock"
                    iconSize="large"
                    bgColor="primary"
                    border="rounded"
                    width={52}
                    height={52}
                  />
                </FlexContainer>
                <FlexContainer direction="column">
                  <Text size={2} weight={600} lineHeight="30px" align="left">
                    {t("TransactionCompleted.PendingTitle")}
                  </Text>
                  <Text size={1} weight={400} lineHeight="24px" align="left">
                    {t("TransactionCompleted.PendingDescription")}
                  </Text>
                </FlexContainer>
              </FlexContainer>
            )}

            <FlexContainer justify="center">
              <ContainerMTCN>
                <Text size={0.5} align="center" color="black" weight={500} margin="16px 0 16px 16px">
                  {isZelleTransaction || isVenmoTransaction || isACHTransaction
                    ? t("TransactionCompleted.MTCLikeReference")
                    : "MTCN"}
                </Text>
                <LabelCard>
                  <Text size={0.5} color="black" margin="0 8px 0 0">
                    {paymentOrder?.mtcn}
                  </Text>
                  <ActionsIcons onClick={() => copyTextMtcn()}>
                    <IconFont name="copy" size="small" color="primary" />
                    <Text align="center" color="solid_light" weight={600} margin="16px 0">
                      {t("Transactions.Copy")}
                    </Text>
                  </ActionsIcons>
                </LabelCard>
                {copiedText && (
                  <CopyCOntainer>
                    <TextCopy>
                      <IconShape
                        icon="circle-check"
                        iconSize="small"
                        border="rounded"
                        bgColor="black"
                        height={16}
                        width={16}
                      />
                      <span>{t("Transactions.Copied")}</span>
                    </TextCopy>
                  </CopyCOntainer>
                )}
              </ContainerMTCN>
            </FlexContainer>

            {(isZelleTransaction || isVenmoTransaction) && (
              <AlerBoxContainer>
                <FlexContainer justify="center" alignItems="center" gap="8px">
                  <IconFont name="clock" color="warningStrong" />
                  <Text align="left" size={0.5} color="warningStrong" weight={500}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: isZelleTransaction
                          ? t("Zelle.expirationTimer", { time: `${minutes}:${seconds}` })
                          : t("Venmo.expirationTimer", { time: `${minutes}:${seconds}` }),
                      }}
                    />
                  </Text>
                </FlexContainer>
              </AlerBoxContainer>
            )}
            <SummaryContainer>
              <FlexContainer justify="space-between" m="0 0 16px 0">
                <Text size={0.5} weight={500} lineHeight="21px">
                  {t("TransactionCompleted.TransactionDetails.transactionDate")}
                </Text>
                <Text size={0.5} weight={500} lineHeight="21px">
                  {paymentOrder &&
                    `${format(new Date(paymentOrder.orderedAt), "MMM dd, yyyy", {
                      locale: getDateFnsLocale(i18n.language),
                    })} ${format(new Date(paymentOrder.orderedAt), "hh:mm aaaa", {
                      locale: getDateFnsLocale(i18n.language),
                    })}`}
                </Text>
              </FlexContainer>
              <QuoteSummary paymentOrderCreated={paymentOrder} />
              {paymentOrder?.receipt && (
                <FlexContainer m="12px 0 0 0" justify="end">
                  <Link href={paymentOrder?.receipt || ""}>
                    <FlexContainer gap="12px">
                      <Text size={1} weight={600} lineHeight="24px" color="solid_2">
                        {t("Transactions.DownloadPrereceipt")}
                      </Text>
                      <IconFont name="download" size="medium" color="primary" />
                    </FlexContainer>
                  </Link>
                </FlexContainer>
              )}
            </SummaryContainer>

            {rewardsFeature && <ReferralBanner />}

            <Row>
              <Column span={12} xs={12} px={2}>
                <ButtonContainer>
                  <Button
                    variant="primary"
                    sizeButton="full"
                    sizeText="large"
                    text={t("TransactionCompleted.ActionButton")}
                    onClick={handleFinishButton}
                  />
                </ButtonContainer>
              </Column>
            </Row>
            {isUnitellerTransaction && (
              <FlexContainer direction="column" alignItems="center" justify="center" gap="24px">
                <img src={imgTypes["logoUniteller"]} width={83} alt="logo-header" />
                <FlexContainer justify="center" alignItems="center" gap="4px">
                  <IconFont name="circle-exclamation" size="default" color="error" />
                  <Text size="default">
                    <Link
                      href={`${unitellerUrl}`}
                      target={"_blank"}
                      rel="noopener noreferrer"
                      color="error"
                      size="default"
                      underline
                    >
                      {t("ReviewTransaction.disclaimerUniteller")}
                    </Link>
                    {t("ReviewTransaction.disclaimerUniteller2")}
                  </Text>
                </FlexContainer>
              </FlexContainer>
            )}
          </FlexContainer>
        </Container>
        {modalZelle}
        {modalCPU}
      </Layout>
    </ThemeProvider>
  );
};

export default withTranslation("ns")(TransactionCompletedPage);
