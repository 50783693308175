import { allowedCountriesForPayment } from "../../Modules/Common/domain/Countries";
import { iso31661, iso31662 } from "iso-3166";

export class GeocodeService {
  private geocoder: google.maps.Geocoder | null = null;
  private decodedComponents?: google.maps.GeocoderAddressComponent[] | null =
    null;

  constructor() {
    if (window.google) {
      this.initializeGeolocation();
    } else {
      window.addEventListener("load", this.initializeGeolocation);
    }
  }

  initializeGeolocation = () => {
    if (window.google) {
      this.geocoder = new google.maps.Geocoder();
    } else {
      console.error("Google Maps API not loaded");
    }
  };

  public async getCity(country: string, subdivision: string, citySub: string) {
    let cities: string[] = [];
    const alpha2Country = iso31661.find(
      (isoObj) => isoObj.alpha3 === country
    )?.alpha2;
    const inversedSubdivision = iso31662.find(
      (isoObj) =>
        isoObj.parent === alpha2Country &&
        (isoObj.code.includes(subdivision.split("-")[1]) ||
          isoObj.name.includes(subdivision))
    );
    if (!inversedSubdivision || !this.geocoder) return null;
    const decoded = await this.geocoder.geocode({
      componentRestrictions: {
        country: alpha2Country,
        administrativeArea: inversedSubdivision.name,
        locality: citySub,
      },
    });

    if (decoded) {
      const addressComponents = decoded?.results
        .filter((result) =>
          result.address_components.find(
            (component) =>
              component.types.includes("administrative_area_level_1") &&
              component.long_name === inversedSubdivision.name
          )
        )
        ?.flatMap((result) => result.address_components);

      cities = addressComponents
        ?.filter((component) => component?.types?.includes("locality"))
        .map((component) => component.long_name);
    }

    return cities;
  }

  private async getLocationComponents(location: { lat: number; lng: number }) {
    const decodedLocation = await this.geocoder?.geocode({
      location,
    });

    const addressComponents = decodedLocation?.results?.flatMap(
      (result) => result.address_components
    );

    this.decodedComponents = addressComponents;
    return addressComponents;
  }

  public async getStateFromŁocation({
    location,
    isoFormat,
  }: {
    location?: { lat: number; lng: number };
    isoFormat?: boolean;
  } = {}) {
    let state: string | null = null;
    const decodedComponents = location
      ? await this.getLocationComponents(location)
      : this.decodedComponents;
    const countryCode = await this.getCountryFromLocation();

    if (decodedComponents) {
      const stateComponent =
        decodedComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        ) || null;

      if (isoFormat && stateComponent?.long_name) {
        const isoState = iso31662.find(
          (isoObj) =>
            isoObj.parent === countryCode &&
            (isoObj.name.includes(stateComponent.long_name) ||
              stateComponent.long_name.includes(isoObj.name))
        );
        state = isoState?.code || stateComponent.short_name;
      } else {
        state = stateComponent?.short_name || null;
      }
    }

    return state;
  }

  public async getCountryFromLocation({
    location,
    isoFormat,
  }: {
    location?: { lat: number; lng: number };
    isoFormat?: boolean;
  } = {}) {
    let country: string | null = null;
    const decodedComponents = location
      ? await this.getLocationComponents(location)
      : this.decodedComponents;

    if (decodedComponents) {
      const countryComponent = decodedComponents.find((component) =>
        component.types.includes("country")
      );

      if (
        isoFormat &&
        (countryComponent?.short_name || countryComponent?.long_name)
      ) {
        const isoCountry = iso31661.find(
          (isoObj) =>
            isoObj.alpha2 === countryComponent.short_name ||
            isoObj.name === countryComponent.long_name
        );

        country = isoCountry?.alpha3 || countryComponent?.short_name;
      } else {
        country = countryComponent?.short_name || null;
      }
    }

    return country;
  }

  public async locationIsAllowed(location: { lat: number; lng: number }) {
    await this.getLocationComponents(location);
    const currentCountry = await this.getCountryFromLocation();

    return currentCountry
      ? allowedCountriesForPayment.includes(currentCountry)
      : false;
  }
}
