import {
  ContainerInput,
  ContainerText,
  InputStyled,
  Text,
  Span,
} from "./InputDateMask.styled";
import Icon from "../Icon";
import { tInputMaskProps } from "../../Models/InputMask";
import { ContainerLeft } from "../Input/Input.styled";

const InputDateMask = (props: tInputMaskProps) => {
  const {
    textLabel,
    label,
    register,
    rules,
    errors,
    handleChange = () => {
      /* do nothing by default */
    },
    placeholderInput,
    size,
    mb,
    full,
  } = props;

  return (
    <ContainerInput mb={mb}>
      <ContainerText>
        <ContainerLeft>
          <Text>{textLabel}</Text>
        </ContainerLeft>
      </ContainerText>
      <InputStyled
        type="text"
        placeholder={placeholderInput}
        $size={size}
        $full={full}
        {...register(label, {
          ...rules,
          onChange: handleChange,
        })}
      />
      {errors[label] && (
        <ContainerText>
          <Icon
            icon={"alertTriangle"}
            color={"error"}
            size="small"
            fillIcon={false}
          />
          <Span>{errors[label].message}</Span>
        </ContainerText>
      )}
    </ContainerInput>
  );
};

export default InputDateMask;
