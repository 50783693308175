import styled from "styled-components";
import { theme } from "styled-tools";

export const FloatingButton = styled.button<{ $position: string }>`
  background: ${theme("Gradient_12")};
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0px 4px 24px 0px ${theme("Shadow_01")};
  position: fixed;
  border: none;
  cursor: pointer;
  z-index: 5;
  color: white;
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-duration: 1.2s;

  @keyframes blink {
    0% {
      transform: scale(1);
      opacity: 0.9;
    }
    50% {
      transform: scale(1.15);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.9;
    }
  }

  &:hover {
    opacity: 90%;
  }

  ${(props) => {
    switch (props.$position) {
      case "TopLeft":
        return `
          top: 180px;
          left: 20px;
        `;
      case "TopRight":
        return `
          top: 180px;
          right: 20px;
        `;
      case "MiddleLeft":
        return `
          top: calc(55% + 60px);
          left: 20px;
        `;
      case "MiddleRight":
        return `
          top: calc(55% + 60px);
          right: 20px;
        `;
      case "BottomLeft":
        return `
          bottom: 20px;
          left: 20px;
        `;
      case "BottomRight":
        return `
          bottom: 25px;
          right: 20px;
        `;
      default:
        return `
          bottom: 25px;
          right: 20px;
        `;
    }
  }};
`;
