import { useTranslation } from "react-i18next";
import Button from "../../Button";
import { GradientCard } from "../../Card/Card.styled";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";

interface AddRecipientCardButtonProps {
  onClick: () => void;
}

export const AddRecipientCardButton = ({
  onClick,
}: AddRecipientCardButtonProps) => {
  const [t] = useTranslation("global");
  return (
    <Button
      variant="transparent"
      padding="0px"
      color="primary"
      onClick={() => onClick && onClick()}
    >
      <GradientCard
        width="254px"
        height="53px"
        padding="6px 10px"
        borderColor="transparent"
      >
        <FlexContainer justify="start" alignItems="center" w="254px">
          <Text
            align="left"
            size={0.5}
            weight={500}
            lineHeight="21px"
            color="solid_light"
            margin={0}
          >
            + {t("Beneficiaries.CreateNewRecipient")}
          </Text>
        </FlexContainer>
      </GradientCard>
    </Button>
  );
};
