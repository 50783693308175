import styled, { css } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

import { TextColors } from "../../../Models/TextInterface";
import {
  TransactionDetailCardColor,
  textColors,
} from "../../../Contexts/ThemeProvider/Pages";
import {
  quoterCardFeeLabels,
  switchCaption,
} from "../../../Contexts/ThemeProvider/Texts";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { tTableInfoColumn } from "../../../Models/TableInfo";
import { handleColor } from "../../../Utilities/Icons";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;
  width: 100%;

  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

export const IconCountry = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

export const Amount = styled.span`
  font-size: 12px;
  font-weight: 400;
  font-family: Poppins;
  line-height: 16px;
  color: ${theme("Content_UX_04")};
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
`;

export const IconSection = styled.div`
  background: linear-gradient(
    180deg,
    ${theme("Primary_03")} 0%,
    ${theme("Primary_01")} 100%
  );
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 16px;
  width: 24px;
  margin: 0 0 12px 0;

  div {
    height: 24px;
    width: 24px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

export const OptionsSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin-top: 12px;

  cursor: pointer;
  a {
    padding: 0 0px 24px 24px;
  }

  div,
  span {
    margin-left: 10px;
  }
`;

export const DownloadReceipt = styled.a`
  padding: 0;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
`;

export const LinkText = styled.span<{
  color: TextColors;
}>`
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
  color: ${textColors};
`;

export const IconArrowSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 40px;
`;

export const SwitchStyled = styled.div`
  border-top: 1px solid ${theme("Content_UX_05")};
  width: 100%;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  border-bottom: 1px solid ${theme("Content_UX_05")};
`;

export const SwitchBtn = styled.button<{ $active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme("Content_UX_02")};
  background: none;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
  cursor: pointer;
  gap: 8px;

  span {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: ${theme("Primary_11")};
    ${(props) =>
      props.$active
        ? css`
            color: ${switchCaption};
            border-bottom: 2px solid ${quoterCardFeeLabels};
          `
        : css`
            color: ${theme("Primary_11")};
          `};

    div {
      margin-left: 4px;
    }
  }
`;

export const ContainerMTCN = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px;
  background: ${TransactionDetailCardColor};
  margin: 12px 0 0 0;
  align-items: center;
  width: 100%;
  max-width: 380px;
  gap: 8px;
`;

export const LabelCard = styled.div`
  border: 1px solid ${theme("Background_01")};
  background: ${theme("Background_01")};
  color: ${theme("Content_UX_01")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
  height: 29px;
  border-radius: 4px;
  margin-right: 16px;
  min-width: 235px;
  justify-content: space-between;

  p:first-child {
    letter-spacing: 1px;
    padding-left: 8px;
  }

  svg {
    cursor: pointer;
  }
`;

export const ActionsIcons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  gap: 4px;
  max-width: 60px;
  align-items: center;
  margin: 0 8px;

  p {
    text-decoration: underline;
  }
`;

export const DetailCard = styled.div`
  display: flex;
  width: 100%;
  max-width: 380px;
  flex-direction: column;
  border-radius: 8px;
  background: ${theme("Background_06")};
  margin: 12px 0 24px 0;
  padding: 0 15px;
  div {
    font-size: 14px;
  }
`;

export const CopyCOntainer = styled.div`
  width: 100%;
  height: 29px;
  border-radius: 4px;
  margin-right: 16px;
  display: flex;
  z-index: 1;
  position: absolute;
  justify-content: right;
  max-width: 300px;
`;

export const TextCopy = styled.div`
  display: flex;
  width: 93px;
  color: ${theme("Primary_01_50")};
  padding: 2px 11px 2px 10px;
  height: 28px;
  border-radius: 4px;
  border: 0.5px solid ${theme("Background_03")};
  opacity: 0.75;
  background: ${theme("Background_11")};
  box-shadow: 0px 4px 4px 0px rgba(${theme("RGB_Background_01")}, 0.25);
  align-items: center;
  justify-content: center;
  gap: 4px;
  transition: opacity 0.3s linear;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 345px;
  flex-direction: row;
  padding: 0px 24px 24px;

  div:first-child {
    padding-right: 10px;
  }
`;

export const MessageContainerError = styled.div`
  display: flex;
  width: 100%;
  max-width: 345px;
  flex-direction: row;
  padding: 0px 16px 24px;
  margin-top: 10px;
  div:first-child {
    padding-right: 10px;
  }
`;

export const MessageContainerTransaction = styled.div`
  display: flex;
  width: 100%;
  max-width: 345px;
  flex-direction: row;
  padding: 0px 24px 12px;

  div:first-child {
    padding-right: 10px;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonDetail = styled.button`
  width: 250px;

  ${mediaQuery[Screen.mobile]} {
    width: 80%;
  }
`;

export const StatusText = styled.p<{ status: string }>`
  font-size: 14px;
  font-weight: 400;
  margin: "0 0 4px 0";
  text-align: left;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  margin: 0 0 16px 0;
  justify-content: center;
  align-items: center;
  color: ${theme("Primary_20")};
  span {
    margin-left: 10px;
    color: ${(props) =>
      props.status.toLowerCase() === "paid" ||
      props.status.toLowerCase() === "declined"
        ? theme("Primary_12")
        : props.status.toLowerCase() === "rejected" ||
          props.status.toLowerCase() === "canceled"
        ? theme("Primary_20")
        : props.status.toLowerCase() === "pending" ||
          props.status.toLowerCase() === "verified" ||
          props.status.toLowerCase() === "accepted" ||
          props.status.toLowerCase() === "created" ||
          props.status.toLowerCase() === "funds" ||
          props.status.toLowerCase() === "reviewing" ||
          props.status.toLowerCase() === "available for payment"
        ? theme("Primary_21")
        : theme("Content_UX_04")};
  }
`;

export const IconStatus = styled.img`
  display: flex;
  height: 24px;
  width: 24px;
`;

export const TableInfoStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: "12px 24px";
`;

export const TableRow = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
`;

export const TableColumn = styled.div<tTableInfoColumn>`
  flex: 1;
  flex-grow: "1";
  padding: ${ifProp("padding", prop("padding"), "0px")};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  text-align: ${(props) => props.textAlign || "right"};
  font-weight: ${(props) => props.fontWeight || 400};
  color: ${(props) =>
    props.color ? handleColor(props.color) : props.theme.Content_UX_01};
`;

export const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${theme("Background_03")};
  margin: 12px 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  span {
    font-size: 18px;
  }
`;

export const HintTooltip = styled.div`
  position: absolute;
  left: 50px;
  width: 60%;
  font-family: Poppins;
  font-size: 14px;
  background: ${theme("Background_12")};
  box-shadow: 0px 0px 24px 0px #00000026;
  border-radius: 10px;
  padding: 10px;
  z-index: 10;
  margin-top: 20px;
  color: ${theme("Background_01")};
`;
