import styled from "styled-components";
import { FlexContainer, FlexItem } from "../../Components/Flex/Flex.styled";
import Card from "../../Components/Card";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const ConfirmationHeaderContainer = styled(FlexContainer)`
  max-width: 1061px;
  padding: 0 20px;
  flex-wrap: wrap;
  align-self: start;
  gap: 20px;
  height: 100%;
  align-items: center;

  ${mediaQuery[Screen.desktop]} {
    flex-wrap: nowrap;
    padding: 0 155px;
  }
`;
ConfirmationHeaderContainer.displayName = "ConfirmationHeaderContainer";

export const ConfirmationContainer = styled(FlexContainer)`
  flex-direction: column;
  padding: 0 20px;
  max-width: 1061px;
  align-self: start;
  margin-bottom: 102px;

  ${mediaQuery[Screen.desktop]} {
    padding: 0 155px;
  }
`;
ConfirmationContainer.displayName = "ConfirmationContainer";

export const ConfirmationHeaderTitle = styled(FlexItem)`
  width: fit-content;
  align-self: center;

  ${mediaQuery[Screen.desktop]} {
    order: 0;
  }
`;
export const ConfirmationHeaderIcon = styled(FlexItem)`
  flex-grow: 0;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;

  ${mediaQuery[Screen.md]} {
    margin-right: 0;
    margin-left: 0;
    order: 1;
  }

  ${mediaQuery[Screen.desktop]} {
    order: 1;
  }
`;

export const RecipientInfoCard = styled(Card)`
  max-width: 436px;
`;
RecipientInfoCard.displayName = "RecipientInfoCard";
