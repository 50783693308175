import { useTranslation } from "react-i18next";
import {
  ContainerSwitch,
  SwitchBtn,
  Tab,
  TabsStyled,
  TextNew,
  TextNewContainer,
} from "./Tabs.styled";
import { PropsTab, iTabInfo } from "../../Models/TabInfo";
import { useWalkthrough } from "../../Hooks/useWalkthrough";
import { useEffect, useMemo } from "react";
import isEmpty from "lodash.isempty";
import { useSelector } from "react-redux";
import { FeaturesSelector } from "../../Redux/Features/Features.slice";

const Tabs = ({ data, changeTab, onTabsReload }: PropsTab) => {
  const { t } = useTranslation("global");
  const { appFeatures } = useSelector(FeaturesSelector);
  const { modal, startWalkthrough } = useWalkthrough({
    onEndWalkthrough: onTabsReload,
  });
  const tabsWithWalkthrough = useMemo(
    () => data.filter((tab) => tab.showDescriptionPopup),
    [data]
  );
  const showWalkthrough = useMemo(
    () => !isEmpty(tabsWithWalkthrough),
    [tabsWithWalkthrough]
  );

  useEffect(() => {
    if (showWalkthrough && !isEmpty(appFeatures)) {
      const featuresWithWalkthrough = appFeatures.filter((feat) =>
        tabsWithWalkthrough.map((tab) => tab.value).includes(feat.value)
      );
      startWalkthrough(featuresWithWalkthrough);
    }
  }, [tabsWithWalkthrough]);

  return (
    <TabsStyled $showPopup={showWalkthrough}>
      {data.map((item: iTabInfo, index: number) => (
        <Tab key={index} id={item.elementId} active={item.isActive}>
          {item.isNew && (
            <TextNewContainer>
              <div>
                <TextNew>{t("Dashboard.TabNew")}</TextNew>
              </div>
            </TextNewContainer>
          )}

          <ContainerSwitch>
            <SwitchBtn
              $active={item.isActive}
              onClick={() => changeTab(item.value)}
            >
              <span>{item.label}</span>
            </SwitchBtn>
          </ContainerSwitch>
        </Tab>
      ))}
      {modal}
    </TabsStyled>
  );
};

export default Tabs;
