import { PropsWithChildren } from "react";
import { FlexContainer } from "../Flex/Flex.styled";
import Icon from "../Icon";
import { Span } from "../Input/Input.styled";

export const ErrorMessage = ({ children }: PropsWithChildren) => {
  return (
    <FlexContainer p="5px 3px">
      <Icon icon="alertTriangle" color="error" size="small" />
      <Span $error>{children}</Span>
    </FlexContainer>
  );
};
