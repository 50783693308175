import { useState } from "react";
import {
  Frames,
  CardNumber,
  ExpiryDate,
  Cvv,
  FrameCardValidationChangedEvent,
  FrameCardTokenizedEvent,
} from "frames-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

// Own Components
import Button from "../../../../Components/Button";
import Title from "../../../../Components/Title";
import Text from "../../../../Components/Text";
import { Column, Row } from "../../../../Components/Grid";
import Input from "../../../../Components/Input";

// Own Styles
import { FormContainer, FormLabel, FormInput } from "./CheckoutForm.styled";

import type { CheckoutFormProps } from "../../../../Types/CheckoutForm";
import { checkoutPublicKey } from "../../../../Constants/ConfigureApi";

const CheckoutForm = ({ makePayment }: CheckoutFormProps) => {
  const [t] = useTranslation("global");
  const [isValid, setIsValid] = useState(false);
  const [cardholder, setCardholder] = useState({
    name: "",
  });

  const {
    register,
    control,
    formState: { isValid: cardHolderValid, errors },
  } = useForm({ mode: "onChange" });

  const handleCardTokenized = (e: FrameCardTokenizedEvent) => {
    if (makePayment && e?.token) {
      makePayment({ token: e.token });
    }
  };

  const handleCardValidation = (event: FrameCardValidationChangedEvent) => {
    setIsValid(event.isValid);
  };

  const handleCardHolder = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardholder({ name: event.target.value });
  };

  return (
    <Row>
      <Column span={12}>
        <FormContainer>
          <Title
            size={4}
            tagName="h4"
            color="black"
            text={t("CheckoutPayment.checkoutFormTitle")}
            align="left"
          />
          <Frames
            config={{
              debug: true,
              publicKey: checkoutPublicKey,
              cardholder: {
                name: cardholder.name,
              },
              localization: {
                cardNumberPlaceholder: " ",
                expiryMonthPlaceholder: "MM",
                expiryYearPlaceholder: "YY",
                cvvPlaceholder: " ",
              },
              style: {
                base: {
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#384055",
                },
                valid: {
                  color: "#384055",
                },
                invalid: {
                  color: "#94000D",
                },
              },
            }}
            ready={() => console.log("ready!!!!")}
            cardValidationChanged={handleCardValidation}
            cardTokenized={handleCardTokenized}
          >
            <Row>
              <Column span={12}>
                <FormLabel>
                  <Text size={1} align="left">
                    {t("CheckoutPayment.checkoutFormLabelCard")}
                  </Text>
                </FormLabel>
                <CardNumber />
              </Column>
              <Column span={12}>
                <FormLabel>
                  <Text size={1} align="left">
                    {t("CheckoutPayment.checkoutFormLabelCardholder")}
                  </Text>
                </FormLabel>
                <Input
                  as={FormInput}
                  label="CardHolder"
                  textLabel=""
                  typeInput="text"
                  register={register}
                  control={control}
                  rules={{
                    required: t("Forms.required"),
                  }}
                  validate={true}
                  isError={!!errors?.CardHolder}
                  onInputChange={handleCardHolder}
                />
              </Column>
            </Row>
            <Row>
              <Column span={6} xs={12} mr={2}>
                <FormLabel>
                  <Text size={1} align="left">
                    {t("CheckoutPayment.checkoutFormLabelExpirationDate")}
                  </Text>
                </FormLabel>
                <ExpiryDate />
              </Column>
              <Column span={6} xs={12}>
                <FormLabel>
                  <Text size={1} align="left">
                    CVV
                  </Text>
                </FormLabel>
                <Cvv />
              </Column>
            </Row>
            <Row>
              <Column span={12} pt={4}>
                <Button
                  variant={!isValid || !cardHolderValid ? "default" : "primary"}
                  onClick={() => Frames.submitCard()}
                  text={t("CheckoutPayment.checkoutFormButton")}
                  disabled={!isValid}
                  sizeText="medium"
                  sizeButton="xxlarge"
                />
              </Column>
            </Row>
          </Frames>
        </FormContainer>
      </Column>
    </Row>
  );
};

export default CheckoutForm;
