import { Link } from "react-router-dom";

// Own components
import Icon from "../Icon";

// Own styles
import { List, Item } from "./LinksList.styled";

// Own models
import { dataProps, EnumDataItem } from "../../Models/LinksList";

const LinksList = (props: dataProps) => {
  return (
    <List>
      {props.data.map((elem: EnumDataItem, index) => {
        return (
          <Link to={elem.url} key={index}>
            {elem.icon && <Icon icon={elem.icon} color="black" size="medium" />}
            <Item>
              {elem.name}
              <Icon icon="chevronRight" color="black" size="medium" />
            </Item>
          </Link>
        );
      })}
    </List>
  );
};

export default LinksList;
