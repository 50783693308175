import styled from "styled-components";
import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { theme } from "styled-tools";
import { iconShapeWihGradient } from "../../../Contexts/ThemeProvider/IconFont";
import { headerBackgroundColor } from "../../../Contexts/ThemeProvider/Layout";
import { quoterCardFeeLabels } from "../../../Contexts/ThemeProvider/Texts";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 130px;

  ${mediaQuery[Screen.lg]} {
    margin: 0 80px;
  }

  ${mediaQuery[Screen.mobile]} {
    margin: 10px 24px;
  }
`;

export const SubtitleBeneficiary = styled.p`
  font-size: 24px;
  font-weight: 400;
  color: ${theme("Content_UX_02")};
  text-align: left;

  ${mediaQuery[Screen.mobile]} {
    font-size: 18px;
  }
`;

export const BeneficiariesCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 40px 130px;
  justify-content: start;

  ${mediaQuery[Screen.mobile]} {
    display: flex;
    margin: 24px;
    flex-direction: column;
  }

  ${mediaQuery[Screen.lg]} {
    margin: 0 80px 24px 80px;
  }
`;

export const CreateBeneficiary = styled.div`
  display: flex;
  flex-direction: row;
  width: 410px;
  padding: 10px 0;
  border-radius: 20px;
  background: ${theme("Background_01")};
  box-shadow: 0px 4px 20px 0px rgba(${theme("RGBA_01")}, 0.15);
  justify-content: center;
  align-items: center;

  ${mediaQuery[Screen.mobile]} {
    width: 100%;
    padding: 10px 0;
  }
`;

export const BeneficiaryIcon = styled.div`
  background: ${iconShapeWihGradient};
  border-radius: 50%;
  height: 106px;
  position: relative;
  width: 106px;

  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const PaginationSection = styled.div`
  .page-item a.page-link:hover {
    background-color: ${headerBackgroundColor};
    color: ${theme("Background_01")};
  }

  .page-item.active .page-link {
    font-weight: 700;
    color: ${theme("Background_01")};
    background-color: ${quoterCardFeeLabels};
    border: ${quoterCardFeeLabels};
  }

  .page-item.disabled .page-link {
    color: ${theme("Background_04")};
    pointer-events: none;
    cursor: auto;
  }

  .page-item .page-link {
    color: ${headerBackgroundColor};
  }
`;
