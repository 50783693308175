import Modal from "../Modal.component";
import { ModalProps } from "../../../Models/ModalInterface";
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FlexContainer } from "../../Flex/Flex.styled";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectorLanguage } from "../../../Redux/Translate";
import { useCountry } from "../../../Hooks/useCountry";
import { SearchSelect } from "../../SearchSelect/SearchSelect.component";
import { SelectOption } from "../../../Models/Select";
import { useTranslation } from "react-i18next";
import { DateInput } from "../../DateInput/DateInput.component";
import Button from "../../Button";
import IDAlert from "../../../Assets/Img/IDAlert.png";
import Text from "../../Text";
import { FormInput } from "../../Input";
import { usePerson } from "../../../Hooks/usePerson";
import { getYear } from "date-fns";
import { CreateIDDocumentPayload } from "../../../Modules/Person/domain/Person";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";

export const IdDocumentModal = (props: ModalProps) => {
  const [t] = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const navigate = useNavigate();
  const form = useForm();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;
  const { allCountriesSelectOption, getAllCountriesList } = useCountry();
  const { isLoading, updateIdentificationDocument } = usePerson();
  const idTypeSelectOptions = useMemo(
    () =>
      [
        {
          label: t("Person.IdDocument.Passport"),
          value: "passport",
        },
        {
          label: t("Person.IdDocument.IdCard"),
          value: "id_card",
        },
        {
          label: t("Person.IdDocument.DriversLicense"),
          value: "drivers_license",
        },
      ] as SelectOption[],
    []
  );

  const handleSubmitDocument = handleSubmit(async (data) => {
    const dateComponents = data.expirationDate.split("/");
    const updateResponse = await updateIdentificationDocument({
      ...(data as CreateIDDocumentPayload),
      expirationDate: `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`,
      emisionDate: `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`,
    });

    if (updateResponse?.id && props.handleConfirm) {
      props.handleConfirm(updateResponse);
      props.handleClose(true);
    } else {
      props.handleClose(false);
    }
  });

  useEffect(() => {
    if (language) {
      getAllCountriesList(language);
    }
  }, [language]);

  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <FlexContainer direction="column" gap="0.75rem">
        {isLoading && <Loader full />}
        <FlexContainer justify="center" m="-25px 0 14px 0">
          <img src={IDAlert} width="auto" height="117px" />
        </FlexContainer>
        <FlexContainer justify="center">
          <Text size={1} weight={600} align="center" margin={0}>
            {t("Person.IdDocument.AlertTitle")}
          </Text>
        </FlexContainer>
        <FlexContainer direction="column" gap="0.75rem">
          <Text size={0.5} weight={400} align="left" margin={0}>
            {t("Person.IdDocument.AlertDescription")}
          </Text>
        </FlexContainer>
        <FormProvider {...form}>
          <form onSubmit={handleSubmitDocument} style={{ width: "100%" }}>
            <FlexContainer direction="column" gap="0.75rem">
              <Controller
                control={control}
                name="issuingCountry"
                rules={{ required: t("Forms.required") }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <SearchSelect
                    labelProps={{ color: "grey", size: 0 }}
                    errors={errors}
                    placeholderInput={t(
                      "Beneficiaries.CreateBeneficiary.Form.Country"
                    )}
                    options={allCountriesSelectOption}
                    name="issuingCountry"
                    showAvatar
                    showAvatarOnValue
                    onBlur={onBlur}
                    onChange={(sel) =>
                      onChange((sel as SelectOption)?.value || "")
                    }
                    value={allCountriesSelectOption.find(
                      (c) => c.value === value
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="idType"
                rules={{ required: t("Forms.required") }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <SearchSelect
                    labelProps={{ color: "grey", size: 0 }}
                    errors={errors}
                    placeholderInput={t("Person.IdDocument.IdType")}
                    options={idTypeSelectOptions}
                    name="idType"
                    onBlur={onBlur}
                    onChange={(sel) =>
                      onChange((sel as SelectOption)?.value || "")
                    }
                    value={idTypeSelectOptions.find((c) => c.value === value)}
                  />
                )}
              />
              <FormInput
                placeholderInput={t("Person.IdDocument.IdNumber")}
                label="idNumber"
                rules={{ required: t("Forms.required") }}
              />
              <Controller
                control={control}
                name="expirationDate"
                rules={{ required: t("Forms.required") }}
                render={({ field: { value, onChange } }) => (
                  <DateInput
                    label={t("Person.IdDocument.ExpirationDate")}
                    name="expirationDate"
                    value={value}
                    errors={errors}
                    maxYearAllowed={getYear(new Date()) + 10}
                    onChange={onChange}
                  />
                )}
              />

              <FlexContainer m="0.875rem 0 0.875rem 0" p="0 28px">
                <Button
                  type="submit"
                  variant="primary"
                  sizeButton="full"
                  text={t("global.continue")}
                  sizeText="medium"
                  disabled={isLoading}
                />
              </FlexContainer>
              <FlexContainer m="0 0 0.875rem 0" p="0 28px">
                <Button
                  type="button"
                  variant="outline"
                  sizeButton="full"
                  text={t("global.contactSupport")}
                  sizeText="medium"
                  disabled={isLoading}
                  onClick={() => navigate("/contact-us")}
                />
              </FlexContainer>
            </FlexContainer>
          </form>
        </FormProvider>
      </FlexContainer>
    </Modal>
  );
};
