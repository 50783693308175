import { createAsyncThunk } from "@reduxjs/toolkit";
import { createClient } from "../../Services/ApiClient";

import {
  personCardAPIUrl,
  transferOrdersAPIUrl,
} from "../../Constants/ConfigureApi";

import type { ApiRequestError } from "../../Types/ApiRequestError";

import type {
  PaymentPersonSendola,
  PaymentPersonSendolaBalance,
} from "../../Types/Payments";
import {
  VerifyTransferOrderSuccess,
  VerifyTransferOrders,
} from "../../Types/TransferOrders";

const client = createClient();

export const getAchAccount = createAsyncThunk(
  "PaymentACH/getAchAccount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.get(`${personCardAPIUrl}/ach-account`);
      return (await response.data) as PaymentPersonSendola;
      // eslint-disable-next-line
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);

export const getAchBalance = createAsyncThunk<
  PaymentPersonSendolaBalance,
  string,
  { rejectValue: ApiRequestError }
>("PaymentACH/getAchBalance", async (personId, { rejectWithValue }) => {
  try {
    const response = await client.get(`${personCardAPIUrl}/ach-balance`, {
      headers: {
        personId: personId,
      },
    });
    return (await response.data) as PaymentPersonSendolaBalance;
    // eslint-disable-next-line
  } catch (e: any) {
    return rejectWithValue(e);
  }
});

export const achTransferOrder = createAsyncThunk<
  VerifyTransferOrderSuccess,
  VerifyTransferOrders,
  { rejectValue: ApiRequestError }
>("PaymentACH/achTransferOrder", async (payload, { rejectWithValue }) => {
  try {
    const response = await client.post(
      `${transferOrdersAPIUrl}/transfer-orders-ach`,
      payload
    );
    return response.data as VerifyTransferOrderSuccess;
    // eslint-disable-next-line
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
