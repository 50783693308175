import { useTranslation } from "react-i18next";
import Modal from "../Modal.component";
import Text from "../../Text";
import Button from "../../Button";
import { ModalProps } from "../../../Models/ModalInterface";
import IconShape from "../../IconShape";

export const UnlinkAccountSuccessModal = (props: ModalProps) => {
  const [t] = useTranslation("global");

  return (
    <Modal {...props}>
      <IconShape
        border="rounded"
        icon="circle-check"
        iconColor="white"
        iconSize="medium"
        bgColor="primary"
        width={50}
        height={50}
      />
      <Text size={3} weight={700}>
        {props.title || ""}
      </Text>
      {props.children}
      <Button
        variant={"primary"}
        sizeButton="large"
        sizeText="large"
        text={t("Payments.LinkedAccounts.btnNext")}
        colorIcon="white"
        sizeIcon="large"
        onClick={() =>
          props.handleConfirm ? props.handleConfirm() : props.handleClose()
        }
      />
    </Modal>
  );
};
