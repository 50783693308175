import styled from "styled-components";
import {
  tagBackground,
  tagColor,
} from "../../../Contexts/ThemeProvider/Layout";

export const RecentlyUsedTag = styled.div`
  box-sizing: border-box;
  border-radius: 16px;
  padding: 2px 8px;
  text-align: center;
  background-color: ${tagBackground};
  min-width: 87px;
  width: fit-content;

  p {
    color: ${tagColor};
    font-size: 10px;
  }
`;
