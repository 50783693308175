import styled, { keyframes } from "styled-components";
import { theme } from "styled-tools";

const SpinnerKeyframes = keyframes`
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
`;

export const Spinner = styled.div`
  width: 75px;
  height: 75px;
  border: 5px solid rgba(${theme("RGB_Background_01")}, 0.3);
  border-left: 5px solid ${theme("Content_UX_03")};
  border-radius: 50%;
  animation: ${SpinnerKeyframes} 0.5s linear 0s infinite;
  margin-bottom: 50px;
`;
