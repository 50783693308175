import styled from "styled-components";
import { FlexContainer } from "../../Components/Flex/Flex.styled";

export const StepIcon = styled.img`
  margin-right: 0.3rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const ButtonSection = styled(FlexContainer)`
  flex-direction: column;
  padding: 24px 40px;
  width: 100%;
  align-items: center;
`;
