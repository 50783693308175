import { createClient } from "../../../Services/ApiClient";

import { PaymentOrderRepository } from "../domain/PaymentOrderRepository";
import {
  PaymentOrder,
  PaymentOrderDetailResponse,
  PaymentOrderSenderEmail,
  PaymentOrderVerifications,
  PaymentTransactionHistory,
  PaymentTransactions,
} from "../domain/PaymentOrder";
import { PaymentOrderCreated, PaymentOrderPayload, PaymentOrderTracking } from "../domain/PaymentOrderCreated";
import { VerifyPaymentOrder, VerifyPaymentOrderAPIResponse } from "../domain/VerifyPaymentOrder";

const client = createClient();
const paymentOrderPrefixService = "PaymentOrders";

export const createApiPaymentOrderRepository = (): PaymentOrderRepository => {
  return {
    getPaymentOrders,
    createPaymentOrder,
    getPaymentOrder,
    verifyPaymentOrder,
    getVelocityTxn,
    getVelocityAmountTxn,
    getVelocityTsxSendolaPay,
    getVelocityTsxDestination,
    termsConditionsUniteller,
    saveSenderEmail,
    getPaymentOrdersTransaction,
    getPaymentOrderTracking,
  };
};

const getPaymentOrders = async () => {
  const response = await client.get<PaymentOrder[]>(`${paymentOrderPrefixService}`);
  return response.data;
};

const createPaymentOrder = async (payload: PaymentOrderPayload) => {
  const response = await client.post<PaymentOrderCreated>(`${paymentOrderPrefixService}`, payload);

  return response.data;
};

const getPaymentOrder = async (id: string) => {
  const response = await client.get<PaymentOrderDetailResponse>(`${paymentOrderPrefixService}/${id}`);

  return response.data;
};

const getPaymentOrderTracking = async (id: string) => {
  const response = await client.post<PaymentOrderTracking[]>(`${paymentOrderPrefixService}/status-history/${id}`, {});

  return response.data;
};

const verifyPaymentOrder = async (payload: VerifyPaymentOrder) => {
  const response = await client.post<VerifyPaymentOrderAPIResponse>(`${paymentOrderPrefixService}/verify`, payload);

  return response.data;
};

const getVelocityTxn = async () => {
  const response = await client.get<any>(`${paymentOrderPrefixService}/VelocityTXN`);

  return response.data;
};

const getVelocityAmountTxn = async (amount: number) => {
  const response = await client.get<undefined>(`${paymentOrderPrefixService}/VelocityAmountTXN/${amount}`);

  return response.data;
};

const getVelocityTsxSendolaPay = async () => {
  const response = await client.get<any>(`${paymentOrderPrefixService}/sendolapaytxn`);

  return response.data;
};

const getVelocityTsxDestination = async (amount: number, destinationId: string | null | undefined) => {
  const response = await client.get<any>(`${paymentOrderPrefixService}/velocitylimits/${amount}/${destinationId}`);

  return response.data;
};

const termsConditionsUniteller = async () => {
  const response = await client.get<PaymentOrderVerifications>(`/verifications`, {});

  return response.data;
};

const saveSenderEmail = async (email: string, personId?: string) => {
  const response = await client.post<PaymentOrderSenderEmail>("/Sender/save-email", { email, id: personId });

  return response.data;
};

const getPaymentOrdersTransaction = async (params: PaymentTransactions) => {
  const response = await client.get<PaymentTransactionHistory>(`${paymentOrderPrefixService}/Transactions`, {
    params,
  });
  return response.data;
};
