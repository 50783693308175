import { HttpHookConfig, HttpProvider } from "../../Models/HttpProvider";
import axios, {
  AxiosDefaults,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from "axios";

export class AxiosProvider
  implements HttpProvider<AxiosInstance, AxiosRequestConfig>
{
  instance: AxiosInstance;

  constructor({
    config,
    hooks,
  }: {
    config?: CreateAxiosDefaults;
    hooks?: HttpHookConfig<
      InternalAxiosRequestConfig,
      AxiosResponse,
      AxiosError
    >;
  }) {
    this.instance = axios.create(config);

    if (hooks && hooks.request) {
      this.instance.interceptors.request.use(hooks.request);
    }

    if (hooks && hooks.response) {
      this.instance.interceptors.response.use(
        hooks.response.onFulfilled || ((response) => response),
        hooks.response.onRejected ||
          ((error) => {
            throw error;
          })
      );
    }
  }

  extend<T = AxiosDefaults>(defaults: T) {
    this.instance.defaults = {
      ...this.instance.defaults,
      ...defaults,
    };

    return this.instance;
  }

  request<T = unknown>(config: AxiosRequestConfig<unknown>) {
    return this.instance<T>(config);
  }

  get<T = unknown>(
    url: string,
    config?: AxiosRequestConfig<unknown> | undefined
  ) {
    return this.instance.get<T>(url, config);
  }

  post<T = unknown>(
    url: string,
    body: unknown,
    config?: AxiosRequestConfig<unknown> | undefined
  ) {
    return this.instance.post<T>(url, body, config);
  }
  put<T = unknown>(
    url: string,
    body: unknown,
    config?: AxiosRequestConfig<unknown> | undefined
  ) {
    return this.instance.put<T>(url, body, config);
  }

  patch<T = unknown>(url: string, body: unknown) {
    return this.instance.patch<T>(url, body);
  }
  delete<T = unknown>(
    url: string,
    config?: AxiosRequestConfig<unknown> | undefined
  ) {
    return this.instance.delete<T>(url, config);
  }
}
