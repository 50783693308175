import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

// own states
import { State } from "../../../Redux/Store";

// Own components
import Button from "../../../Components/Button";
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Text from "../../../Components/Text";

import { AlignItems, JustifyContent } from "../../../Types/Column";

import { ContainerCard, PaymentSendolaPayLogo } from "../Payments.styled";

import PaymentSendolaPaySrc from "../../../Assets/Img/sendola-pay-logo.svg";
import Icon from "../../../Components/Icon";
import { useSendolaPayPaymentOrder } from "../../../Hooks/useSendolaPayPaymentOrder";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { useNavigate } from "react-router-dom";
import { SendolaPayTransactionDetail } from "../../../Modules/PaymentOrders/domain/SendolaPay";

const SendolaPayBalance = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const [balance, setBalance] = useState(false);
  const navigate = useNavigate();
  const [negativeBalance, setNegativeBalance] =
    useState<SendolaPayTransactionDetail>();
  const { isLoading, getTransactionDetail, personId } =
    useSendolaPayPaymentOrder();
  const { currentQuotation, currentDeliveryMethodQuote, currentPaymentMethod } =
    useSelector(QuotationSelector).data;

  useEffect(() => {
    if (!balance) {
      getSendolaPayBalance();
    }
  }, [personId]);

  const getSendolaPayBalance = async () => {
    if (currentQuotation?.amount && personId) {
      setBalance(true);
      const totalWithFees =
        currentQuotation.amount +
        (currentDeliveryMethodQuote?.fee || 0) +
        (currentPaymentMethod?.paymentMethodFee || 0);
      const amount = {
        totalTransactionAmount: totalWithFees,
      };

      const response = await getTransactionDetail(personId, amount);
      if (response) {
        setNegativeBalance(response);
      }
    }
  };

  async function processSubmit() {
    if (negativeBalance?.isTotalTransactionAmountPositive) {
      navigate("/review-transaction");
    } else {
      navigate("/payments");
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/payments" textLink={t("buttons.back")} loading={isLoading}>
        <Container>
          <Row>
            <Column span={12} justifyContent={JustifyContent.center}>
              <ContainerCard>
                <Row>
                  <Column
                    span={12}
                    xs={12}
                    px={3}
                    mb={6}
                    alignItems={AlignItems.center}
                    justifyContent={JustifyContent.spaceBetween}
                  >
                    <Title
                      tagName="h1"
                      text={"Sendola Pay"}
                      color="black"
                      size={5}
                      align="left"
                    />
                    <PaymentSendolaPayLogo src={PaymentSendolaPaySrc} />
                  </Column>
                  <Column span={12} px={3}>
                    <Text color="black" size={3} align="left" weight={600}>
                      {t("SendolaPayPayment.subtitle")}
                    </Text>
                  </Column>
                  <Column span={12} xs={12} pt={1} pb={2}>
                    <Row>
                      <Column span={12} xs={12} px={3}>
                        <Row>
                          <Column
                            span={6}
                            xs={8}
                            sm={8}
                            alignItems={AlignItems.center}
                          >
                            <Text
                              color="black"
                              size={0}
                              align="left"
                              weight={500}
                            >
                              {t("SendolaPayPayment.balanceTile")}
                            </Text>
                          </Column>
                          <Column span={6} xs={4}>
                            <Text
                              color="black"
                              size={0}
                              align="right"
                              weight={500}
                            >
                              {negativeBalance
                                ? negativeBalance?.currentBalanceString
                                : "$0.00"}
                            </Text>
                          </Column>
                        </Row>
                        <Row>
                          <Column
                            span={6}
                            xs={8}
                            sm={8}
                            alignItems={AlignItems.center}
                          >
                            <Row>
                              <Column span={12}>
                                <Text
                                  color="black"
                                  size={0}
                                  align="left"
                                  weight={500}
                                  margin="15px 0 0 0"
                                >
                                  {t("SendolaPayPayment.amountLabel")}
                                </Text>
                              </Column>
                              <Column span={12}>
                                <Text
                                  color="black"
                                  size={1}
                                  align="left"
                                  weight={500}
                                  margin="2px 0 15px 0"
                                >
                                  {t("SendolaPayPayment.amountHelper")}
                                </Text>
                              </Column>
                            </Row>
                          </Column>
                          <Column span={6} xs={4}>
                            <Text
                              color="black"
                              size={0}
                              align="right"
                              weight={500}
                            >
                              {negativeBalance
                                ? negativeBalance?.totalTransactionAmountString
                                : "$0.00"}
                            </Text>
                          </Column>
                        </Row>
                        <Row>
                          <Column
                            span={6}
                            xs={8}
                            sm={8}
                            alignItems={AlignItems.center}
                          >
                            <Row>
                              <Column span={12}>
                                <Text
                                  color={
                                    negativeBalance &&
                                    !negativeBalance?.isTotalTransactionAmountPositive
                                      ? "error"
                                      : "black"
                                  }
                                  size={0}
                                  align="left"
                                  weight={600}
                                  margin="15px 0 0 0"
                                >
                                  {t("SendolaPayPayment.newBalance")}
                                </Text>
                              </Column>
                            </Row>
                          </Column>
                          <Column span={6} xs={4}>
                            <Text
                              color={
                                negativeBalance &&
                                !negativeBalance?.isTotalTransactionAmountPositive
                                  ? "error"
                                  : "black"
                              }
                              size={0}
                              align="right"
                              weight={600}
                            >
                              {negativeBalance
                                ? negativeBalance?.newBalanceString
                                : "$0.00"}
                            </Text>
                          </Column>
                        </Row>
                        {negativeBalance &&
                          !negativeBalance?.isTotalTransactionAmountPositive && (
                            <Row>
                              <Column
                                span={12}
                                alignItems={AlignItems.center}
                                justifyContent={JustifyContent.center}
                                my={3}
                              >
                                <Icon
                                  icon="alertTriangle"
                                  color="error"
                                  size="large"
                                />
                              </Column>
                              <Column
                                span={12}
                                alignItems={AlignItems.center}
                                justifyContent={JustifyContent.center}
                              >
                                <Text
                                  color="error"
                                  size={0}
                                  align="center"
                                  weight={700}
                                >
                                  {t("SendolaPayPayment.negativeBalance")}
                                </Text>
                              </Column>
                            </Row>
                          )}
                      </Column>
                      <Column span={12} mb={6} px={1} pt={6}>
                        <Button
                          type="submit"
                          variant="primary"
                          sizeButton="large"
                          sizeText="large"
                          sizeIcon="large"
                          text={t(
                            !negativeBalance?.isTotalTransactionAmountPositive
                              ? "SendolaPayPayment.buttonBack"
                              : "SendolaPayPayment.buttonText"
                          )}
                          colorIcon="white"
                          onClick={processSubmit}
                        />
                      </Column>
                    </Row>
                  </Column>
                </Row>
              </ContainerCard>
            </Column>
          </Row>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default SendolaPayBalance;
