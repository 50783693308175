import styled, { css, CSSProperties } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import { SizesKey } from "../../Utilities/Sizes";
import { setTextSize } from "../Text/functions/setTextSize";
import {
  inputActiveColor,
  inputBackgroundColor,
  inputPlaceholderColor,
} from "../../Contexts/ThemeProvider/Input";
import { ButtonSizesKey, handleSizeButton } from "../../Utilities/SizesButtons";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const SectionOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const TextOption = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
`;

export const TextOptionNew = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${theme("Primary_09")};
  align-items: center;
  padding: 5px 10px;
  font-weight: 700;
  color: ${theme("Background_02")};
  margin-left: auto;
`;

export const TextOptionImageAvatar = styled.img<{
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
}>`
  width: ${prop("width", "35px")};
  height: ${prop("height", "35px")};
  border-radius: 50%;
  margin-right: 8px;
`;

export const ContainerSelect = styled.div<{
  $size?: ButtonSizesKey;
  mb?: string;
}>`
  position: relative;
  width: ${(props) =>
    props.$size ? `${handleSizeButton(props.$size)}px` : "100%"};
  margin-bottom: ${ifProp("mb", prop("mb"), "0")};
  text-align: left;

  ${mediaQuery[Screen.mobile]} {
    width: 100%;
  }
`;
