import styled from "styled-components";
import { theme } from "styled-tools";

export const Radio = styled.input`
  display: -webkit-inline-box;
  appearance: none;
  margin: 0;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid ${theme("Background_04")};
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  position: relative;

  ::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    margin-left: -5px;
    top: 50%;
    margin-top: -5px;
  }
  :checked {
    background-color: ${theme("Transparent")};
    ::after {
      background-color: ${theme("Primary_500")};
    }
    :hover {
      border: 1px solid ${theme("Primary_500")};
      ::after {
        background-color: ${theme("Primary_500")};
      }
    }
  }
  :focus {
    outline: 2px solid ${(props) => props.theme.Background_03};
  }
  :hover {
    ::after {
      background-color: ${(props) => props.theme.Background_04};
    }
  }
  :disabled {
    cursor: not-allowed;
    border: 2px solid ${(props) => props.theme.Primary_01};
    background-color: ${(props) => props.theme.Background_04};
    :hover {
      ::after {
        background-color: ${(props) => props.theme.Background_04};
      }
    }
    :checked {
      ::after {
        background-color: ${(props) => props.theme.Primary_01};
      }
      :hover {
        background-color: ${(props) => props.theme.Neutral_900};
        ::after {
          background-color: ${(props) => props.theme.Primary_01};
        }
      }
    }
  }
`;

export const Label = styled.label`
  position: relative;
  min-height: 34px;
  padding-left: 6px;
  font-weight: normal;
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
  display: block;
`;
