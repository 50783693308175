import styled from "styled-components";
// import mediaQuery, { Screen } from "../../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const ReceiptRowStyled = styled.div`
  margin-bottom: 40px;
`;

export const ReceiptInfoRowStyled = styled.div`
  margin-bottom: 60px;
`;
