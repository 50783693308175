import { createClient } from "../../../Services/ApiClient";
import { City } from "../domain/City";
import { CityRepository } from "../domain/CityRepository";

const client = createClient();
const prefixService = "gpcatalogs/cities";

export const createApiCityRepository = (): CityRepository => {
  return { getCitiesBySubdivision };
};

const getCitiesBySubdivision = async (subdivisionCode: string) => {
  const res = await client.get<City[]>(`${prefixService}/${subdivisionCode}`);

  return res.data;
};
