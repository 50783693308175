import { useTranslation } from "react-i18next";
import Modal from "../Modal.component";
import Text from "../../Text";
import Button from "../../Button";
import { ModalProps } from "../../../Models/ModalInterface";
import IconFont from "../../IconFont";
import Loader from "../../Loader";
import { FlexContainer } from "../../Flex/Flex.styled";

export const UnlinkAccountModal = (props: ModalProps) => {
  const { t } = useTranslation("global");

  return (
    <Modal {...props}>
      <IconFont name="alert" size="large" color="error" />
      <Text size={3} weight={700}>
        {props.title || ""}
      </Text>
      <Text size={2} weight={400}>
        {props.message || ""}
      </Text>
      {props.children}
      <FlexContainer gap="5rem">
        <Button
          variant={"primary"}
          sizeButton="large"
          sizeText="large"
          text={t("Payments.LinkedAccounts.modalUnlink.btnAccept")}
          colorIcon="white"
          sizeIcon="large"
          onClick={props.handleConfirm}
        />
        <Button
          variant={"outline"}
          sizeButton="large"
          sizeText="large"
          text={t("Payments.LinkedAccounts.modalUnlink.btnCancel")}
          colorIcon="white"
          sizeIcon="large"
          onClick={() => props.handleClose()}
          color={"Primary_01"}
        />
      </FlexContainer>
    </Modal>
  );
};
