import styled from "styled-components";
import { PropsStyled } from "../../Models/TitleInterface";
import { handleSizeType } from "../../Utilities/Sizes";
import { textComponentSolidlight } from "../../Contexts/ThemeProvider/Texts";

const Header = ({ tagName, ...otherProps }: PropsStyled) => {
  const Tag = tagName ? tagName : "h1";

  return <Tag {...otherProps} />;
};

const SelectColor = (color: string) => {
  switch (color) {
    case "white":
      return (props: PropsStyled) => props.theme.Background_01;
    case "black":
      return (props: PropsStyled) => props.theme.Background_02;
    case "solid":
      return (props: PropsStyled) => props.theme.Primary_04;
    case "solid_light":
      return textComponentSolidlight;
    case "error":
      return (props: { theme: { Error: string } }) => props.theme.Error;
    default:
      return (props: PropsStyled) => props.theme.Background_02;
  }
};

export const TitleStyled = styled(Header)`
  // It can improve with styled tools, for the moment it's fine.
  color: ${(props) =>
    props.color ? SelectColor(props.color) : props.theme.Background_02};
  font-family: "Poppins";
  font-size: ${(props) => (props.size ? handleSizeType(props.size) : "72px")};
  font-weight: ${(props) => props.weight || null};
  text-align: ${(props) => props.align || "center"};
  margin: 0;
`;
