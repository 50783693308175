import styled, { css, CSSProperties } from "styled-components";
import { ifProp, prop } from "styled-tools";

export const SliderWrapper = styled.div<{
  gap?: CSSProperties["gap"];
  overflowSize?: string;
  top?: CSSProperties["top"];
}>`
  box-sizing: border-box;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 16px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  // overflow show slides
  ${ifProp(
    "overflowSize",
    css`
      position: absolute;
      top: ${prop("top", "33px")};
      left: 0;
      width: calc(100% + ${prop("overflowSize")});
    `
  )}

  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    display: inline-flex;
    gap: ${prop("gap", "8px")};
    min-width: 100%;

    * {
      scroll-snap-align: start;
    }
  }
`;
