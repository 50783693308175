import { ReactNode } from "react";
import { CheckInstructionsLogoStyled } from "./CheckInstructionsLogo.styled";

type Props = {
  children: ReactNode;
};

const CheckInstructionsLogo = ({ children }: Props) => (
  <CheckInstructionsLogoStyled>{children}</CheckInstructionsLogoStyled>
);

export default CheckInstructionsLogo;
