export const GET_PERSON_ID = "getStatusPersonKYC";
export const GET_PERSON_ID_PENDING = "getStatusPersonKYC/pending";
export const GET_PERSON_ID_REJECTED = "getStatusPersonKYC/rejected";
export const GET_PERSON_ID_FULFILLED = "getStatusPersonKYC/fulfilled";

export const GET_PERSON_CARD = "getPersonCard";
export const GET_PERSON_CARD_PENDING = "getPersonCard/pending";
export const GET_PERSON_CARD_REJECTED = "getPersonCard/rejected";
export const GET_PERSON_CARD_FULFILLED = "getPersonCard/fulfilled";

export const GET_CURRENCY_WHITHOUT_FEES = "getCurrencyWithoutFees";
export const GET_CURRENCY_WHITHOUT_FEES_PENDING =
  "getCurrencyWithoutFees/pending";
export const GET_CURRENCY_WHITHOUT_FEES_REJECTED =
  "getCurrencyWithoutFees/rejected";
export const GET_CURRENCY_WHITHOUT_FEES_FULFILLED =
  "getCurrencyWithoutFees/fulfilled";
