import styled, { CSSProperties, keyframes } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import { loaderColor } from "../../Contexts/ThemeProvider/Layout";

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderContainer = styled.div<{ full?: boolean }>`
  position: ${ifProp("full", "fixed", "absolute")};
  max-width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
`;

export const LoaderStyled = styled.div<{
  color?: string;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  border?: CSSProperties["border"];
}>`
  width: ${prop("width", "60px")};
  height: ${prop("height", "60px")};
  border: ${prop("border", "0.5em")} solid ${prop("color", theme("Background_02"))};
  border-left-color: ${loaderColor};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const Percent = styled.span<{ $msg: boolean }>`
  position: absolute;
  top: ${(props) => (props.$msg ? "30%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
`;
