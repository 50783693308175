import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";

const initialState = {
  value: "",
  timestamp: 0,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken(state, { payload }) {
      return {
        ...state,
        value: payload,
      };
    },
    initTimestamp(state) {
      return {
        ...state,
        timestamp: Date.now(),
      };
    },
    clearToken() {
      return { ...initialState };
    },
  },
});

export const tokenSelector = (state: State) => state.token;
