import {
  AdditionalField,
  AdditionalFieldsPayload,
} from "../domain/AdditionalFields";
import { DestinationRepository } from "../domain/DestinationRepository";

export const getAdditionalFields =
  (destinationRepository: DestinationRepository) =>
  async (payload: AdditionalFieldsPayload): Promise<AdditionalField[]> =>
    destinationRepository.getAdditionalFields(payload);

export const getFieldsAppriza =
  (destinationRepository: DestinationRepository) =>
  async (countryCode: string, language?: string): Promise<AdditionalField[]> =>
    destinationRepository.getFieldsAppriza(countryCode, language);
