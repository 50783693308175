import Modal from "../Modal.component";
import { ErrorModalProps } from "../../../Models/ModalInterface";
import Text from "../../Text";
import Icon from "../../Icon";
import Button from "../../Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FlexContainer } from "../../Flex/Flex.styled";

export const ErrorModal = (props: ErrorModalProps) => {
  const [tModals] = useTranslation("modals");
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  return (
    <Modal {...props}>
      {props.icon || <Icon icon="alertTriangle" color="error" size="xlarge" />}
      <Text size={3} weight={700}>
        {props.title ? props.title : "Error"}
      </Text>
      {props.errorMessage && (
        <Text size={2} margin="5px 0 5px 0">
          {props.errorMessage}
        </Text>
      )}
      <FlexContainer m="5px 0 5px 0">{props.children}</FlexContainer>
      <FlexContainer direction="column" alignItems="center" justify="center" gap="1rem">
        {!props.hideContactSuppport && (
          <Button
            variant={"primary"}
            sizeButton="large"
            sizeText="large"
            text={t("global.contactSupport")}
            colorIcon="white"
            sizeIcon="large"
            onClick={() => {
              navigate("/contact-us");
            }}
          />
        )}
        <Button
          variant={"outline"}
          sizeButton="large"
          sizeText="large"
          text={tModals("ok")}
          colorIcon="white"
          sizeIcon="large"
          onClick={() => props.handleClose()}
          color={"Primary_01"}
        />
      </FlexContainer>
    </Modal>
  );
};
