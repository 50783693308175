import Icon from "../Icon";
import { FloatingButton } from "./FloatingCouponsButton.styled";

interface FloatingCouponsButtonProps {
  position?: "TopLeft" | "TopRight" | "MiddleLeft" | "MiddleRight" | "BottomLeft" | "BottomRight";
  onClick: () => void;
}

export const FloatingCouponsButton = ({ position = "BottomRight", onClick }: FloatingCouponsButtonProps) => {
  return (
    <FloatingButton onClick={onClick} $position={position}>
      <Icon icon="flameDiscount" size="medium" />
    </FloatingButton>
  );
};
