import styled from "styled-components";
import { FontColor, handleColor, handleSize } from "../../Utilities/Icons";
import { theme } from "styled-tools";
import { IconSize } from "../../Models/IconInterface";

export const IconStyled = styled.div<{
  size: IconSize;
  color: FontColor;
  gradient?: boolean;
  $fill: boolean;
}>`
  width: content-width;
  height: content-height;

  & svg {
    width: ${(props) => handleSize(props.size)};
    height: ${(props) => handleSize(props.size)};

    rect {
      fill: ${(props) => (props.$fill ? theme("Primary_01") : "")};
      stroke: ${(props) =>
        props.$fill
          ? theme("Primary_07")
          : handleColor(props.color)} !important;
    }

    circle {
      fill: ${(props) => (props.color ? handleColor(props.color) : "")};
    }
  }

  & path {
    fill: ${(props) => (props.$fill ? theme("Primary_01") : "")};
    stroke: ${(props) =>
      props.$fill
        ? theme("Background_01")
        : handleColor(props.color)} !important;
  }
`;
