import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";

import { ThemeProvider } from "styled-components";

import Button from "../../Components/Button";
import { Column, Container, Row } from "../../Components/Grid";
import Layout from "../../Components/Layout";
import Title from "../../Components/Title";
import Text from "../../Components/Text";

import type { SelectInstance } from "react-select";
import type { AppDispatch, State } from "../../Redux/Store";
import { QuotationSelector } from "../../Redux/Quotation/Quotation.slice";
import { ButtonSection, Header } from "./AddBeneficiaryAccount.styled";
import { SelectOption } from "../../Models/Select";
import { useBeneficiary } from "../../Hooks/useBeneficiary";
import { Link, useParams } from "react-router-dom";
import { usePaymentOrder } from "../../Hooks/usePaymentOrders";
import { useModal } from "../../Hooks/useModal";
import { BeneficiarySlice } from "../../Redux/Beneficiary";
import { BeneficiarySelector } from "../../Redux/Beneficiary/Beneficiary.slice";
import { GeoLocationContext } from "../../Contexts/GeoLocation";
import { BeneficiaryAccount } from "../Beneficiaries";
import { useBankAccount } from "../../Hooks/useBankAccount";
import { AccountTypes } from "../../Modules/Common/domain/Destination";
import { selectorLanguage } from "../../Redux/Translate";
import isEmpty from "lodash.isempty";
import { allowedCountries } from "../../Modules/Common/domain/Countries";
import { FlexContainer } from "../../Components/Flex/Flex.styled";

export default function AddBeneficiaryAccount() {
  const [tModals] = useTranslation("modals");
  const [t] = useTranslation("global");
  const { language } = useSelector(selectorLanguage);
  const theme = useSelector((state: State) => state.theme);
  const dispatch = useDispatch<AppDispatch>();
  const [AccountType, setAccountTypeList] = useState<AccountTypes[]>([]);

  const form = useForm({ mode: "all" });
  const { handleSubmit, setValue, watch } = form;

  const incomingCountry = useSelector(QuotationSelector).data
    .countryDestination as string;
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;
  const { currentQuotation, currentDeliveryMethodQuote } = useSelector(
    (state: State) => state.quotation.data
  );
  const currentQuoteDestinations =
    currentDeliveryMethodQuote?.paymentDestinations;
  const destinationRef = useRef<SelectInstance<SelectOption> | null>(null);
  const {
    modal: beneficiaryModal,
    showModal: showBeneficiaryModal,
    setOpenModal: setUnitellerDisclaimerModalOpen,
  } = useModal();

  const {
    isLoading: beneficiaryLoading,
    hasError: beneficiaryError,
    beneficiaryAccountCreated,
    addAccount,
  } = useBeneficiary();
  const { beneficiaryId } = useParams();
  const {
    isLoading: verifyLoading,
    verifyErrorModal,
    velocityModal,
    verifyCurrentSendMoneySelection,
    verificationUniteller,
    saveSenderEmailUniteller,
  } = usePaymentOrder();
  const {
    additionalFields,
    bankList,
    isLoading: bankAccountLoading,
    subdivisionList,
    setAdditionalFields,
    setBankList,
    setStatus: setBankAccountStatus,
    setSubdivisionList,
    getSubdivisionOptions,
  } = useBankAccount();
  const isLoading = beneficiaryLoading || verifyLoading || bankAccountLoading;
  const currentBeneficiarySelected =
    useSelector(BeneficiarySelector).data.beneficiarySelected;
  const location = useContext(GeoLocationContext);
  const { currentPaymentDestination } = useSelector(QuotationSelector).data;

  const processSubmit = handleSubmit(async (data) => {
    const rail = currentPaymentDestination?.rail;

    if (rail === "UNT") {
      const responseVerification = await verificationUniteller();
      if (
        responseVerification?.showTermsAndConditions ||
        isEmpty(responseVerification?.senderEmail)
      ) {
        showBeneficiaryModal({
          modalType: "unitellerDisclaimer",
          showEmailInput: true,
          emailDefaultValue: responseVerification?.senderEmail || undefined,
          handleConfirm: async (data) => {
            const { email } = data as {
              checkLegalTerms: boolean;
              email: string;
            };
            if (email) {
              await saveSenderEmailUniteller(email);
            }
            await handleSaveAccount(data);
            setUnitellerDisclaimerModalOpen(false);
          },
        });
      } else {
        await handleSaveAccount(data);
      }
    } else {
      await handleSaveAccount(data);
    }
  });

  const handleSaveAccount = async (data: any) => {
    if (beneficiaryId) {
      const destinationSelected = bankList.find(
        (bank) => bank.id === data.destination
      );
      if (destinationSelected) {
        const accounts = {
          account: {
            accountNumber: data.bankAccount,
            accountTypeId: data.accountTypeId,
            destinationId: destinationSelected.id,
            country: data.country,
            bankName: destinationSelected.destination,
            additionalFieldInfo: additionalFields.filter(
              (field) => field.fieldBelongsTo !== "Transaction"
            ),
            branch: data.branch,
            ifscCode: data.ifscCode,
          },
          beneficiaryId,
        };

        if (data.beneIfscCode) {
          accounts.account.ifscCode = data.beneIfscCode;
        }

        const response = await addAccount(accounts);

        if (response) {
          showBeneficiaryModal({
            modalType: "success",
            message: tModals("beneficiaries.messageSuccess"),
            handleClose: () => {
              dispatch(
                BeneficiarySlice.actions.setBeneficiarySelected({
                  ...beneficiarySelected,
                  accountSelected: {
                    ...response.account,
                    additionalFieldInfo: additionalFields.map((field) => ({
                      fieldBelongsTo: field.fieldBelongsTo,
                      fieldName: field.fieldName,
                      fieldValue: data[field.fieldName],
                    })),
                    country: data.country,
                    rail: destinationSelected?.rail,
                    bankName: data.destination,
                  },
                })
              );
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    if (beneficiaryError) {
      showBeneficiaryModal({
        modalType: "error",
        errorMessage: t("Beneficiaries.AddAccount.errorMessage"),
      });
    }
  }, [beneficiaryError]);

  useEffect(() => {
    if (
      beneficiaryAccountCreated &&
      currentQuotation &&
      currentBeneficiarySelected &&
      currentBeneficiarySelected.accountSelected?.accountNumber
    ) {
      verifyCurrentSendMoneySelection();
    }
  }, [beneficiaryAccountCreated, currentQuotation, currentBeneficiarySelected]);

  useEffect(() => {
    setValue("country", incomingCountry);

    if (allowedCountries.includes(incomingCountry)) {
      getSubdivisionOptions(incomingCountry, language);
    }
  }, [incomingCountry, allowedCountries]);

  useEffect(() => {
    const bankName = currentPaymentDestination?.destination;
    const destinationSelected = bankList.find(
      (dest) => dest.destination === bankName
    );
    const AccountTypeList: AccountTypes[] = [];
    if (destinationSelected) {
      destinationSelected?.destinationExpressions?.map((item) => {
        AccountTypeList.push({
          label: language === "es" ? item.labelSP : item.labelEN,
          value: item.accountType,
          key: item.accountType,
        });
      });
    }
    setAccountTypeList(AccountTypeList);
  }, [bankList, language]);

  return (
    <ThemeProvider theme={theme}>
      <Layout textLink={t("buttons.back")} loading={isLoading}>
        <FormProvider {...form}>
          <form onSubmit={processSubmit}>
            <Container>
              <Row>
                <Column span={12} as={Header}>
                  <Title
                    tagName="h1"
                    text={t("Beneficiaries.AddAccount.title")}
                    color="black"
                    size={6}
                    align="left"
                  />

                  <Text color="black" size={1} align="left">
                    {t("Beneficiaries.AddAccount.subtitle")}
                  </Text>
                </Column>
              </Row>
              <FlexContainer p="0 20px">
                <BeneficiaryAccount
                  country={incomingCountry}
                  countrySubdivisions={subdivisionList}
                  AccountTypeList={AccountType}
                  onBankList={(bankList) => bankList && setBankList(bankList)}
                  onAdditionalFields={(additionalFields) =>
                    setAdditionalFields(additionalFields)
                  }
                  onLoading={(loading) => {
                    setBankAccountStatus(loading ? "loading" : "idle");
                  }}
                />
              </FlexContainer>
              <Row>
                <Column span={12} px={2}>
                  <ButtonSection>
                    <Button
                      type="submit"
                      text={t("Beneficiaries.AddAccount.submit")}
                      variant="primary"
                      sizeText="large"
                      sizeButton="large"
                    />
                  </ButtonSection>
                </Column>
              </Row>
            </Container>
          </form>
          {verifyErrorModal}
          {beneficiaryModal}
          {velocityModal}
        </FormProvider>
      </Layout>
    </ThemeProvider>
  );
}
