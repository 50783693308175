import { createClient } from "../../../Services/ApiClient";
import { PaymentOrderWithSendolaPayRepository } from "../domain/PaymentOrderRepository";
import {
  PrivacyPolicyStatus,
  SenderPrivacyPolicyStatus,
  SendolaCredentials,
  SendolaPayTransaction,
  SendolaPayTransactionDetail,
  ShowSendolaPay,
} from "../domain/SendolaPay";

const client = createClient();
const sendolaPayPrefixService = "Funds";

export const sendolaApiPaymentOrderRepository =
  (): PaymentOrderWithSendolaPayRepository => {
    return {
      getShowSendolaPay,
      getPrivacypolicyStatus,
      senderPrivacypolicyStatus,
      senderCredentialsSendolaOne,
      sendolaPayTransactionDetail,
    };
  };

const getShowSendolaPay = async () => {
  const response = await client.get<ShowSendolaPay>(
    `${sendolaPayPrefixService}/must-show-sendola-pay`
  );

  return response.data;
};

const getPrivacypolicyStatus = async () => {
  const response = await client.get<PrivacyPolicyStatus>(
    `${sendolaPayPrefixService}/sender-privacy-policy-status`
  );
  return response.data;
};

const senderPrivacypolicyStatus = async () => {
  const response = await client.patch<SenderPrivacyPolicyStatus>(
    `${sendolaPayPrefixService}/sender-privacy-policy-status`,
    {}
  );
  return response.data;
};

const senderCredentialsSendolaOne = async (
  personId: string,
  body: SendolaCredentials
) => {
  const response = await client.patch<SenderPrivacyPolicyStatus>(
    `${sendolaPayPrefixService}/sender-credentials`,
    body
  );
  return response.data;
};

const sendolaPayTransactionDetail = async (
  personId: string,
  body: SendolaPayTransaction
) => {
  const response = await client.post<SendolaPayTransactionDetail>(
    `${sendolaPayPrefixService}/transaction-details`,
    body
  );
  return response.data;
};
