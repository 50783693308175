import { Column, Row } from "../../../../Components/Grid";
import Skeleton from "../../../../Components/Skeleton";

import { LinkedAccountCardStyled } from "./PaymentLinkedAccountCard.styled";

const PaymentLinkedAccountCardLoading = () => (
  <Column span={12} as={LinkedAccountCardStyled}>
    <Row>
      <Column span={2} p={2} pb={1}>
        <Skeleton width={50} height={50} />
      </Column>
      <Column span={10} p={2}>
        <Skeleton width={100} height={10} />
        <Skeleton width={200} height={10} />
      </Column>
    </Row>
  </Column>
);

export default PaymentLinkedAccountCardLoading;
