import { PaymentMethods } from "../../Common/domain/PaymentMethods";
import { QuoteDeliveryMethodCode } from "../../Quote/domain/QuoteDeliveryMethod";

export interface Coupon {
  id: string;
  description: string;
  expirationDate: string;
  code: string;
}

export interface CouponGroup extends Omit<Coupon, "id" | "expirationDate"> {
  qty: number;
}

export interface CouponState {
  loading: boolean;
  error: string | null;
  data: {
    coupons: Coupon[];
    couponsGrouped: CouponGroup[];
  };
}

export interface VerifyCouponResponse {
  succeeded: boolean;
  message: string;
  data: {
    amount: number;
    paymentMethod: string;
    corridor: string;
    fee: number;
    paymentMethodFee: number;
    transactionPrefunded: boolean;
    deliveryMethod: QuoteDeliveryMethodCode;
    discount: number;
  };
}

export interface VerifyCouponPayload {
  userId: string;
  couponCode: string;
  amount: number;
  paymentMethod: PaymentMethods;
  corridor: string;
  fee: number;
  paymentMethodFee: number;
  deliveryMethod: QuoteDeliveryMethodCode;
  countryCode: string;
  countrySubdivision: string;
  isFirstTransaction: boolean;
}

export type VerifyCouponPayloadShort = Omit<VerifyCouponPayload, "userId" | "isFirstTransaction">;

export function checkIfCouponApplied(input: VerifyCouponPayloadShort, output: VerifyCouponResponse["data"]) {
  if (output.transactionPrefunded) return true;

  return (
    output.amount !== input.amount || output.fee !== output.fee || output.paymentMethodFee !== output.paymentMethodFee
  );
}

export function getNextCoupon(currentCoupon: CouponGroup, coupons: CouponGroup[]) {
  const currentCouponIdx = coupons.findIndex((coupon) => coupon.code === currentCoupon.code);

  if (currentCouponIdx !== -1) return coupons[currentCouponIdx + 1];

  return;
}
