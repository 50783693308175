import styled, { CSSProperties } from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { ifProp, prop, theme } from "styled-tools";

export const OverLay = styled.div<{ zIndex?: CSSProperties["zIndex"] }>`
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  align-items: center;
  overflow-y: auto;
  z-index: ${prop("zIndex", 5)};
  backdrop-filter: blur(2px);
`;

export const ModalWrap = styled.div<{
  dragClose?: boolean;
  transformY?: string;
  isDragging?: boolean;
  zIndex?: CSSProperties["zIndex"];
}>`
  box-sizing: border-box;
  margin: auto;
  padding: 30px;
  background: ${theme("Background_01")};
  border: 1px solid ${theme("Content_State_UX_Hover")};
  width: 350px;
  border-radius: 45px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${prop("zIndex")};

  ${mediaQuery[Screen.sm]} {
    width: 75%;
    max-width: 374px;
    padding: 25px;
  }

  ${mediaQuery[Screen.xs]} {
    max-width: 374px;
    padding: 20px;
  }

  h1 {
    ${mediaQuery[Screen.xs]} {
      font-size: 32px;
      width: 100%;
    }
  }
`;

export const HeadModal = styled.div`
  width: 100%;
  text-align: right;
  justify-content: end;
  display: flex;
  z-index: 2;
`;

export const MarginIcon = styled.div`
  margin: 0 0 20px 0;
`;

export const BottomModalWrapper = styled(ModalWrap)<{
  transformY?: string;
  isDragging?: boolean;
}>`
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, ${prop("transformY", "0px")});
  width: 100%;
  max-width: 100%;
  margin: 0;
  border-radius: 24px 24px 0 0;
  border: none;
  padding: 24px;
  animation: slide-up ease-in 400ms;
  transition: ${ifProp("isDragging", "none", "transform 0.3s ease")};
  max-height: 100dvh;
  overflow-y: auto;
  background: ${theme("Gradient_14")};
  box-shadow: 0px -20px 24px 0px rgba(${theme("RGBA_01")}, 0.25);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme("Content_UX_01")}71;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  ${mediaQuery[Screen.desktop]} {
    max-width: 480px;
    bottom: unset;
    border-radius: 24px;
    animation: none;
  }

  @keyframes slide-up {
    0% {
      bottom: -100%;
    }
    30% {
      bottom: -70%;
    }
    40% {
      bottom: -60%;
    }
    50% {
      bottom: -50%;
    }
    70% {
      bottom: -30%;
    }
    90% {
      bottom: -10%;
    }
    100% {
      bottom: 0;
    }
  }
`;
