import styled from "styled-components";
import { FlexContainer } from "../../Flex/Flex.styled";
import { theme } from "styled-tools";

export const QuoteSummaryContainer = styled(FlexContainer)`
  hr {
    width: 100%;
    height: 0.5px;
    background-color: ${theme("Black")};
    border: none;
  }

  hr:last-of-type {
    margin: -11px 0 -6px 0;
  }
`;

export const StepLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 4px;
  background-color: ${theme("Grey_Light")};
`;
