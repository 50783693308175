import {
  IconSize,
  IconsFontColors,
  IconsFontType,
  IconsShapeBorder,
} from "../../Models/IconInterface";
import IconFont from "../IconFont";
import IconShapeStyled from "./IconShape.styled";

interface Props {
  border?: IconsShapeBorder;
  icon: IconsFontType;
  iconSize?: IconSize;
  iconColor?: IconsFontColors;
  width?: number;
  height?: number;
  gradient?: boolean;
  bgColor?: IconsFontColors;
}

const IconShape = ({
  border,
  icon,
  iconSize,
  iconColor,
  width,
  height,
  gradient,
  bgColor,
}: Props) => {
  return (
    <IconShapeStyled
      $width={width}
      $height={height}
      border={border ? border : "square"}
      {...(gradient ? { gradient: true } : {})}
      {...(bgColor ? { color: bgColor } : {})}
    >
      <IconFont
        size={iconSize || "default"}
        name={icon}
        color={iconColor ? iconColor : "white"}
      />
    </IconShapeStyled>
  );
};

export default IconShape;
