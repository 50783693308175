import { FontColor } from "../Utilities/Icons";

export enum RowBold {
  LEFT = "left",
  RIGHT = "right",
  ROW = "row",
  NONE = "none",
}

export enum Color {
  WHITE = "white",
  SUCCESS = "success",
  SOLID = "solid",
}

export type tTableInfoData = {
  id?: string;
  type?: string;
  label: string;
  value: string;
  bold?: RowBold;
  color?: Color;
  value2?: string;
  label2?: string;
  withDivider?: boolean;
  withCirclePlus?: boolean;
  padding?: string;
};

export type tTableInfo = {
  data: Array<tTableInfoData>;
  withSpace?: boolean;
};

export type tTableInfoColumn = {
  fontWeight?: number;
  textAlign?: string;
  padding?: string;
  grow?: number;
  color?: FontColor;
};
