import { ReactNode, useMemo, useRef, useState } from "react";
import { get } from "lodash";

// Components
import Icon from "../Icon";
import Text from "../Text";
import IconFont from "../IconFont";
import { FlexContainer } from "../Flex/Flex.styled";

// Models
import { AuthorizeInputProps } from "../../Models/inputInterface";

// styled
import {
  ContainerText,
  Span,
  InputStyled,
  ContainerInput,
  HintTooltip,
  ActionButtonIcon,
  Placeholder,
  InfoIcon,
} from "./AuthorizeForm.styled";

const AuthorizeInput = ({
  actionButtonIcon,
  actionButtonText,
  disabledInput,
  errors,
  feedbackText,
  full,
  infoText,
  isError,
  label,
  labelSize = 0,
  maxLength,
  minLength,
  placeholderInput,
  rules,
  size,
  textLabel,
  typeInput,
  validate = true,
  valueInput,
  defaultValueInput,
  opacity,
  inputMode,
  mb,
  onActionButtonClick,
  onInputChange,
  innerRef,
  ...restProps
}: AuthorizeInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>();
  const [showHintTooltip, setShowHintTooltip] = useState(false);
  const [focusInput, setFocusInput] = useState(false);

  const hasError = isError || (errors && Boolean(get(errors, label)));

  return (
    <FlexContainer
      direction="column"
      m={`0 0 ${mb || "10px"} 0`}
      style={{ position: "relative" }}
    >
      {showHintTooltip && infoText && (
        <HintTooltip
          width={207}
          onMouseEnter={() => setShowHintTooltip(true)}
          onMouseLeave={() => setShowHintTooltip(false)}
          dangerouslySetInnerHTML={{
            __html: infoText,
          }}
        ></HintTooltip>
      )}
      {textLabel && (
        <Text
          size={labelSize}
          weight={700}
          align="left"
          margin="0 0 10px 2px"
          color="grey"
          as={"label"}
        >
          {textLabel}
        </Text>
      )}
      <FlexContainer alignItems="center" style={{ position: "relative" }}>
        <ContainerInput $size={size} error={hasError}>
          {placeholderInput && (
            <Placeholder
              size={1}
              active={
                focusInput ||
                Boolean(valueInput) ||
                Boolean(defaultValueInput) ||
                !!restProps.value
              }
              error={hasError}
              onFocus={() => {
                inputRef.current?.focus();
              }}
              onClick={() => {
                inputRef.current?.focus();
              }}
            >
              {placeholderInput}
            </Placeholder>
          )}
          <InputStyled
            ref={(e: HTMLInputElement) => {
              if (innerRef) {
                innerRef(e);
              }
              inputRef.current = e;
            }}
            value={valueInput}
            defaultValue={defaultValueInput}
            aria-invalid={hasError ? "true" : "false"}
            type={typeInput || "text"}
            disabled={disabledInput}
            $error={hasError}
            $validate={validate}
            $size={size}
            maxLength={maxLength}
            minLength={minLength}
            {...restProps}
            $full={full}
            opacity={opacity}
            inputMode={inputMode}
            autoComplete="new-value"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (restProps && restProps?.onChange) {
                restProps.onChange(e);
              }
              onInputChange && onInputChange(e);
            }}
            onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) => {
              if (typeInput === "number") {
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
              }
            }}
            onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (restProps && restProps?.onBlur) {
                restProps.onBlur(event);
              }
              setFocusInput(false);
            }}
            onFocus={() => setFocusInput(true)}
          />
          {actionButtonIcon && actionButtonText && (
            <ActionButtonIcon
              variant="outline"
              type="button"
              onClick={onActionButtonClick}
            >
              {actionButtonText}
              <IconFont name={actionButtonIcon} />
            </ActionButtonIcon>
          )}
        </ContainerInput>
        {infoText && (
          <InfoIcon
            onMouseEnter={() => setShowHintTooltip(true)}
            onMouseLeave={() => setShowHintTooltip(false)}
          >
            <IconFont name="info" size="medium" color="gray" />
          </InfoIcon>
        )}
      </FlexContainer>
      {feedbackText && (
        <ContainerText>
          <Icon icon={"bank"} color={"success"} size="small" fillIcon={false} />
          <Span>{feedbackText}</Span>
        </ContainerText>
      )}
      {errors && get(errors, label) && (
        <ContainerText>
          <Icon
            icon={"alertTriangle"}
            color={"error"}
            size="small"
            fillIcon={false}
          />
          <Span $error>{get(errors, label)?.message?.toString()}</Span>
        </ContainerText>
      )}
    </FlexContainer>
  );
};

export default AuthorizeInput;
