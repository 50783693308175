import styled from "styled-components";
import { FlexContainer } from "../../Components/Flex/Flex.styled";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { theme } from "styled-tools";

export const StepIcon = styled.img`
  margin-right: 0.3rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px;
`;

export const ButtonSection = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const SectionStep = styled.div`
  span {
    margin-right: 10px;
  }
`;

export const disclaimerUniteller = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 0;

  a {
    font-weight: 800;
    color: ${theme("Red_08")};
    text-decoration: underline;
    text-decoration-color: ${theme("Red_08")};
  }
`;
