import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequestError } from "../../Types/ApiRequestError";
import { createClient } from "../../Services/ApiClient";
import { PersonCard } from "../../Modules/Person/domain/Person";

const client = createClient();

/**
 * Method to consult person information from card
 */
export const getPersonCard = createAsyncThunk<
  PersonCard,
  void,
  { rejectValue: ApiRequestError }
>("getPersonCard", async (_, { rejectWithValue }) => {
  try {
    const response = await client.get<PersonCard>("personsendola/byuserid");
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return rejectWithValue(e);
  }
});
