import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../Redux/Store";
import {
  AccountsUpdate,
  BeneficiaryEdit,
  BeneficiaryInfoUpdate,
  BeneficiaryRecipient,
} from "../Modules/Beneficiary/domain/Beneficiary";
import {
  deleteAccount,
  deleteBeneficiary,
  getBeneficiaries,
  getBeneficiariesRecent,
  getBeneficiaryId,
  updateBeneficiary,
} from "../Modules/Beneficiary/application/getBeneficiaries";
import { createApiBeneficiaryRepository } from "../Modules/Beneficiary/infrastructure/ApiBeneficiaryRepository";
import { Errors } from "../Modules/Common/domain/Errors";
import { useStatus } from "./useStatus";
import { BeneficiaryForm } from "../Modules/Beneficiary/domain/BeneficiaryForm";
import { createBeneficiary } from "../Modules/Beneficiary/application/createBeneficiary";
import { BeneficiarySlice } from "../Redux/Beneficiary";
import { BeneficiaryAccountForm } from "../Modules/Beneficiary/domain/BeneficiaryAccountForm";
import { addBeneficiaryAccount } from "../Modules/Beneficiary/application/addBeneficiaryAccount";
import { BeneficiaryAccountCreated } from "../Modules/Beneficiary/domain/BeneficiaryAccountCreated";
import { formatDateEng } from "../Utilities/Date";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createApiSubdivisionRepository } from "../Modules/Common/infraestructure/ApiSubdivisionRepository";
import { getSubdivisions } from "../Modules/Common/application/getSubdivisions";
import { selectorLanguage } from "../Redux/Translate";

interface HookOptions {
  form?: UseFormReturn<FieldValues>;
}

export const useBeneficiary = (opts?: HookOptions) => {
  const { form } = opts || {};
  const [t] = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [errorsApi, setErrosApi] = useState({} as Errors);
  const [beneficiaryInformationId, setBeneficiaryInformationId] = useState<BeneficiaryEdit>();
  const [prefferedBeneficiaries, setPrefferedBeneficiaries] = useState<BeneficiaryRecipient[]>([]);
  const { status, isLoading, hasError, error, setStatus, setError } = useStatus();
  const [beneficiaryAccountCreated, setBeneficiaryAccountCreated] = useState<BeneficiaryAccountCreated | null>(null);
  const { language } = useSelector(selectorLanguage);
  const [errorMessage, setErrorMessage] = useState("");

  const getBeneficiariesPaginated = async ({
    page,
    perPage = 20,
    country,
    destination,
    search,
    recent,
  }: {
    page: number;
    perPage?: number;
    country?: string;
    destination?: string;
    search?: string;
    recent?: boolean;
  }) => {
    setStatus("loading");

    try {
      const useCase = recent ? getBeneficiariesRecent : getBeneficiaries;
      const response = await useCase(createApiBeneficiaryRepository())({
        page,
        perPage,
        country,
        destination,
        search,
        language,
      });
      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      setErrosApi(error as Errors);
      if ((error as Errors).errors || (error as Errors).error) {
        setError(error as Errors);
      }
    }
  };

  const getPrefferedBeneficiaries = async () => {
    const response = await getBeneficiariesPaginated({
      page: 1,
      perPage: 3,
    });
    if (response) {
      setPrefferedBeneficiaries(response.preferredRecipients || []);
    }
  };

  const createRecipient = useCallback(
    async (data: BeneficiaryForm) => {
      setStatus("loading");
      try {
        const response = await createBeneficiary(createApiBeneficiaryRepository())(data, language);

        setStatus("idle");
        return response;
      } catch (error) {
        setStatus("error");
        setError(error as Errors);
        setErrosApi(error as Errors);
      } finally {
        setStatus("idle");
      }
    },
    [language]
  );

  const addAccount = async (accountData: BeneficiaryAccountForm) => {
    setStatus("loading");
    try {
      const response = await addBeneficiaryAccount(createApiBeneficiaryRepository())(accountData);

      setStatus("idle");

      setBeneficiaryAccountCreated(response);
      return response;
    } catch (error) {
      setStatus("error");
    }
  };

  const getBeneficiaryInformation = async (beneficiaryId: string) => {
    setStatus("loading");
    try {
      const response = await getBeneficiaryId(createApiBeneficiaryRepository())(beneficiaryId);
      dispatch(BeneficiarySlice.actions.setBeneficiaryEdit(response));
      setBeneficiaryInformationId(response);
      return response;
    } catch (error) {
      setErrosApi(error as Errors);
    } finally {
      setStatus("idle");
    }
  };

  const updateBeneficiaryId = async (data: Partial<BeneficiaryEdit>, beneficiaryId: string | undefined) => {
    setStatus("loading");
    try {
      const accountsUpdate: AccountsUpdate[] =
        data.accounts?.map((account) => ({
          ...account,
          accountId: account.id,
        })) || [];

      const infoBeneficiary: Partial<BeneficiaryInfoUpdate> = {
        firstName: data.firstName?.trim() || undefined,
        middleName: data.middleName?.trim() || undefined,
        lastName: data.lastName?.trim() || undefined,
        alias: data.alias?.trim() || undefined,
        dateOfBirth: data.dateOfBirth,
        phoneNumber: data.phoneNumber?.trim() || undefined,
        email: data.email?.trim() || undefined,
        address: data.address?.trim() || undefined,
        addressExtra: data.addressExtra || undefined,
        city: data.city || undefined,
        subnational: data.subnational || undefined,
        country: data.country || undefined,
        postalCode: data.postalCode?.trim() || undefined,
        accountBanks: accountsUpdate,
        additionalField: data.additionalField
          ? {
              branch: data.additionalField.branch,
              additionalFieldInfo: data.additionalField.additionalFieldInfo,
            }
          : undefined,
      };

      const request = {
        body: infoBeneficiary,
        idBeneficiary: beneficiaryId,
      };

      const response = await updateBeneficiary(createApiBeneficiaryRepository())(request);
      dispatch(BeneficiarySlice.actions.setBeneficiaryEdit(response));
      return response;
    } catch (error) {
      setStatus("error");
      setError(error as Errors);
    } finally {
      setStatus("idle");
    }
  };

  const deleteAccountBeneficiary = async (beneficiaryId: string, accountId: string) => {
    setStatus("loading");
    try {
      const response = await deleteAccount(createApiBeneficiaryRepository())(beneficiaryId, accountId);
    } catch (error) {
      setErrosApi(error as Errors);
    } finally {
      setStatus("idle");
    }
  };

  const deleteBeneficiarySelected = async (beneficiaryId: string) => {
    setStatus("loading");
    try {
      const response = await deleteBeneficiary(createApiBeneficiaryRepository())(beneficiaryId);
      dispatch(BeneficiarySlice.actions.setBeneficiaryEdit(response));
    } catch (error) {
      setErrosApi(error as Errors);
    } finally {
      setStatus("idle");
    }
  };

  const getSubdivision = async (countryCode: string, language: string) => {
    setStatus("loading");
    try {
      const response = await getSubdivisions(createApiSubdivisionRepository())(countryCode, language);

      setStatus("idle");
      return response;
    } catch (error) {
      setStatus("error");
      setErrosApi(error as Errors);
    }
  };

  const handleDobChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    const dateFormatted = formatDateEng(val);
    form?.setValue("dateOfBirth", dateFormatted);

    const currentDate = new Date();
    const date = new Date(dateFormatted);

    if (date > currentDate) {
      // TODO:  A setTimeout is added to add the validation errors, the setValue cannot call a callback function to add the errors after creating the component
      setTimeout(() => {
        form?.setError("dateOfBirth", {
          type: "custom",
          message: t("Beneficiaries.CreateBeneficiary.Validation.DateNotInPast"),
        });
      });
    } else {
      const currentAge =
        currentDate.getFullYear() -
        date.getFullYear() -
        (currentDate.getMonth() < date.getMonth() ||
        (currentDate.getMonth() === date.getMonth() && currentDate.getDate() < date.getDate())
          ? 1
          : 0);

      if (currentAge < 18) {
        // TODO:  A setTimeout is added to add the validation errors, the setValue cannot call a callback function to add the errors after creating the component
        setTimeout(() => {
          form?.setError("dateOfBirth", {
            type: "custom",
            message: t("Beneficiaries.CreateBeneficiary.Validation.NotLegalAge"),
          });
        });
      }
    }
  };

  const getMessageError = (beneficiaryError: Errors) => {
    setErrorMessage("beneficiaries.messageSaveError");

    if (
      beneficiaryError.errors?.some(
        (error) => (error as string)?.includes("same bank account") || (error as string)?.includes("already exists")
      )
    ) {
      setErrorMessage("beneficiaries.messageDuplicated");
    }

    if (beneficiaryError.error?.msg) {
      setErrorMessage(beneficiaryError.error?.msg);
    }
  };

  return {
    errorsApi,
    error,
    status,
    isLoading,
    hasError,
    prefferedBeneficiaries,
    beneficiaryAccountCreated,
    beneficiaryInformationId,
    getPrefferedBeneficiaries,
    getBeneficiariesPaginated,
    createRecipient,
    addAccount,
    updateBeneficiaryId,
    getBeneficiaryInformation,
    deleteAccountBeneficiary,
    deleteBeneficiarySelected,
    getSubdivision,
    handleDobChange,
    errorMessage,
    getMessageError,
  };
};
