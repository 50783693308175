import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { 
  FormProvider, useForm, Controller, FieldValues 
} from "react-hook-form";
import { NumberFormatBase } from "react-number-format";

// State
import { AuthorizeInputProps } from "../../Models/inputInterface";

// Components
import { Column, Row } from "../Grid";
import Button from "../Button";
import Text from "../Text";

import AuthorizeInput from "./AuthorizeFormInput.component";
import CheckboxInput from "../Checkbox";
import { AuthorizeFormRow } from "./AuthorizeForm.styled";
import AuthorizeAccountSelect from "./AuthorizeAccountSelect.component";
import { allowedOnlyCharacters } from "../../Constants/Regexp";

// Modules
import {
  AuthorizeAccountsSelect, 
  AuthorizeAuthData, 
  AuthorizeCardData, 
  AuthorizeFormData 
} from "../../Modules/PaymentOrders/domain/Authorize";

const API_LOGIN_ID = import.meta.env.VITE_AUTHORIZE_API_LOGIN_ID;
const PUBLIC_KEY = import.meta.env.VITE_AUTHORIZE_PUBLIC_KEY;
const SDK_SRC = import.meta.env.VITE_AUTHORIZE_SDK_SRC;

declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    // eslint-disable-next-line
    Accept: any;
  }
}
type AuthorizeFormProps = {
  data: AuthorizeAccountsSelect[]
  makePayment: (orderPayload: AuthorizeFormData) => void
  isPaymentCompleted?:  boolean;
}

type AuthorizeErrorCode = {
  [x: string]: {
    field: string;
    message: string;
  } | undefined
}

const AuthorizeForm = ({ data, isPaymentCompleted, makePayment }: AuthorizeFormProps) => {
  const [t] = useTranslation("global");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSelectedAccount, setIsSelectedAccount] = useState(false);

  const authorizeForm = useForm({ mode: "all" });

  const {
    control,
    formState: { errors, isValid },
    clearErrors,
    resetField,
    setValue,
    setFocus,
    setError,
    register,
    handleSubmit
  } = authorizeForm;

  const authorizeErrorCodes = [
    {
      "E_WC_05": {
        field: "cardNumber",
        message: t("Authorize.errors.cardNumber")
      }
    },
    {
      "E_WC_08": {
        field: "expDate",
        message: t("Authorize.errors.expDate")
      }
    },
    {
      "E_WC_06": {
        field: "expDate",
        message: t("Authorize.errors.expDate")
      }
    },
    {
      "E_WC_07": {
        field: "expDate",
        message: t("Authorize.errors.expDate")
      }
    },
    {
      "E_WC_15": {
        field: "cardCode",
        message: t("Authorize.errors.cvv"),
      }
    },
    {
      "E_WC_17": {
        field: "cardHolder",
        message: t("Authorize.errors.cardHolder")
      }
    },
  ];

  const handleSaveAccount = handleSubmit(async (data: FieldValues) => {
    const expDate = data.expDate.split('-');
    const expDateMonth = expDate[0];
    const expDateYear = expDate[1];

    const authData: AuthorizeAuthData = {
      clientKey: PUBLIC_KEY,
      apiLoginID: API_LOGIN_ID,
    };

    const cardData: AuthorizeCardData = {
      fullName: data.cardHolder || null,
      cardNumber: data.cardNumber || null,
      month: expDateMonth,
      year: expDateYear,
      cardCode: data.cardCode || null,
    };

    const secureData = {
      authData,
      cardData,
    };

    if (window?.Accept !== "undefined") {
      // eslint-disable-next-line
      window?.Accept.dispatchData(secureData, (response: any) => {
        if (response.messages.resultCode === "Error") {
          let i = 0;
          while (i < response.messages.message.length) {
            const errorCodes = authorizeErrorCodes.filter(
              (error: AuthorizeErrorCode) => error[response.messages.message[i].code]
            );
            
            if (errorCodes.length > 0) {
              errorCodes.forEach((error: AuthorizeErrorCode) => {
                setError(
                  error[response.messages.message[i].code]?.field || '', 
                  { message: error[response.messages.message[i].code]?.message || '' }
                );
              })
            }
            i = i + 1;
          }
        } else {
          makePayment(data as AuthorizeFormData);
        }
      });
    }
  });

  const getSelectedAccount = (account: AuthorizeAccountsSelect) => {
    if (account) {
      setValue('cardHolder', account.label);
      setValue('cardNumber', account.cardNumber);
      setValue('expDate', account.expDate);
      clearErrors();

      setIsSelectedAccount(true);
    } else {
      setIsSelectedAccount(false);
      resetForm();
    }
  }

  const resetForm = () => {
    resetField('cardHolder', { defaultValue: '' });
    resetField('cardNumber', { defaultValue: '' });
    resetField('expDate', { defaultValue: '' });
    resetField('cardCode', { keepError: true, defaultValue: '' });
    setError('cardHolder', { message: t("Authorize.errors.cardHolder")});
  }

  useEffect(() => {
    const script = document.createElement("script");
    const head = document.getElementsByTagName("head")[0];

    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", SDK_SRC);
    script.async = false;
    script.onload = () => setIsLoaded(true);
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isPaymentCompleted) {
      resetForm();
    }
  }, [isPaymentCompleted]);

  return (
    <FormProvider {...authorizeForm}>
      <form
        id="paymentForm"
        onSubmit={handleSaveAccount}
        style={{ width: "100%" }}
      >
        <Row>
          <Column span={12} mb={2}>
            <AuthorizeAccountSelect
              options={data}
              onSelect={getSelectedAccount}
              onAddNewCard={() => {
                resetForm();
                setFocus('cardHolder');
              }}
            />
          </Column>

          <Column span={12} mb={2} mt={1}>
            <Controller
              name="cardHolder"
              control={control}
              rules={{
                required: t("Authorize.errors.cardHolder"),
                pattern: {
                  value: allowedOnlyCharacters,
                  message: t("Authorize.errors.cardHolderInvaid"),
                },
              }}
              render={({ 
                field: { ref, name, ...rest },
                formState: { errors }
              }) => {
                return (
                  <AuthorizeInput
                    minLength={10}
                    maxLength={30}
                    innerRef={ref}
                    label={name}
                    errors={errors}
                    full
                    placeholderInput={t("Authorize.labels.cardHolder")}
                    {...rest}
                  />
                )
              }}
            />
          </Column>
          <Column span={12} mb={2}>
            <Controller
              name="cardNumber"
              control={control}
              rules={{
                required: t("Authorize.errors.cardNumberEmpty"),
                maxLength: {
                  value: 16,
                  message: t("Authorize.errors.cardNumber"),
                },
                minLength: {
                  value: 13,
                  message: t("Authorize.errors.cardNumber"),
                },
              }}
              render={({ 
                field: { ref, name, onChange, ...rest },
                formState: { errors }
              }) => {
                const authorizeInputProps = {
                  innerRef: ref,
                  errors: errors,
                  label: name,
                  placeholderInput: t("Authorize.labels.cardNumber")
                }

                return (
                  <NumberFormatBase
                    type="text"
                    format={(value) =>
                      value
                        .replace(/\s+/g, "")
                        .replace(/([a-z0-9]{4})/gi, "$1 ")
                        .trim()
                        .toLocaleUpperCase()
                    }
                    removeFormatting={(value) => value.replace(/\s+/gi, "")}
                    isValidInputCharacter={(char) => /^[a-z0-9]$/i.test(char)}
                    getCaretBoundary={(value) =>
                      Array(value.length + 1)
                        .fill(0)
                        .map((v) => true)
                    }
                    onValueChange={(v) => {
                      onChange(v.value)
                    }}
                    onKeyDown={(e) =>
                      !/^(?:[a-z0-9]|Backspace|Delete|Home|End|ArrowLeft|ArrowRight|Shift|CapsLock|Control|NumLock|Tab|Paste|Redo|Undo)$/i.test(
                        e.key
                      ) && e.preventDefault()
                    }
                    getInputRef={ref}
                    {...rest}
                    customInput={AuthorizeCardNumberInput}
                    {...authorizeInputProps}
                  />
                )
              }}
            />
          </Column>
          
          <Column span={12} mb={2}>
            <Row as={AuthorizeFormRow}>
              <Column span={6}>
                <Controller
                  name="expDate"
                  control={control}
                  rules={{
                    required: t("Authorize.errors.expDateEmpty"),
                  }}
                  render={({ 
                    field: { ref, name, onChange, ...rest },
                    formState: { errors }
                  }) => {
                    const authorizeInputProps = {
                      errors: errors,
                      label: name,
                      placeholderInput: t("Authorize.labels.expDate")
                    }
                    return (
                      <AuthorizeCardExpiryInput
                        inputProps={authorizeInputProps}
                        inputRef={ref}
                        onChange={onChange}
                        {...rest}
                      />
                    )
                  }}
                />
              </Column>

              <Column span={6}>
                <Controller
                  name="cardCode"
                  control={control}
                  rules={{
                    required: t("Authorize.errors.cvvEmpty"),
                    minLength: {
                      value: 3,
                      message: t("Authorize.errors.expDateEmpty")
                    }
                  }}
                  render={({ 
                    field: { ref, name, ...rest },
                    formState: { errors }
                  }) => {
                    return (
                      <AuthorizeInput
                        label={name}
                        errors={errors}
                        full
                        typeInput="password"
                        infoText={t("Authorize.cvvInfoText")}
                        placeholderInput={t("Authorize.labels.cvv")}
                        maxLength={4}
                        minLength={3}
                        {...rest}
                      />
                    )
                  }}
                />
              </Column>
            </Row>
          </Column>

          { !isSelectedAccount && (
            <Column span={12} mb={4}>
              <CheckboxInput
                id="save-card"
                variant="checkbox"
                label="saveCard"
                register={register}
                errors={errors}
              >
                <Text color="grey" size={0.5} weight={500} align="left">
                  {t("Authorize.saveCardText")}
                </Text>
              </CheckboxInput>
            </Column>
          )}

          <Column span={12} mb={6}>
            <input type="hidden" name="dataValue" id="dataValue" />
            <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
            <Button
              disabled={!isValid}
              type="submit"
              colorIcon="white"
              sizeIcon="large"
              iconButton="arrowRight"
              text={t('Authorize.payButton')}
              variant="primary"
              sizeText="medium"
              sizeButton="full"
            />
          </Column>
        </Row>
      </form>
    </FormProvider>
  );
};

const AuthorizeCardNumberInput = (props: AuthorizeInputProps) => {
  return (
    <AuthorizeInput
      type="text"
      full
      {...props}
    />
  );
};

const CustomExpiryInput = (props: AuthorizeInputProps) => {
  return (
    <AuthorizeInput
      type="text"
      full
      {...props}
    />
  );
}

const AuthorizeCardExpiryInput = (
  { inputRef, onChange, inputProps, ...rest}: 
  { 
    inputRef: ((el: HTMLInputElement) => void) | React.Ref<HTMLInputElement> | undefined,
    onChange: (value: string) => void, 
    inputProps: AuthorizeInputProps
  }
) => {
  const format = (val: string, pattern?: string) => {
    if (val === '') return '';
    let month = val.substring(0, 2);
    const year = val.substring(2, 4);

    if (month.length === 1 && Number(month[0]) > 1) {
      month = `0${month[0]}`;
    } else if (month.length === 2) {
      // set the lower and upper boundary
      if (Number(month) === 0) {
        month = `01`;
      } else if (Number(month) > 12) {
        month = '12';
      }
    }

    if(pattern) {
      return `${month}-${year}`
    }

    return `${month} / ${year}`;
  };

  return (
    <NumberFormatBase
      getInputRef={inputRef}
      format={format} 
      customInput={CustomExpiryInput}
      onValueChange={(v) => {
        onChange(format(v.value, '-'));
      }}
      {...rest}
      {...inputProps}
    />
  );
}

export default AuthorizeForm;
