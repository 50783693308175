import * as dateFnsLocales from "date-fns/locale";
import { Locale } from "date-fns";

interface Locales {
  [key: string]: Locale;
}

const getDateFnsLocale = (currentLanguage = "en"): Locale => {
  const locales: Locales = {
    en: dateFnsLocales.enUS,
    es: dateFnsLocales.es,
  };

  return locales[currentLanguage];
};

export default getDateFnsLocale;
