import { CountryWithCurrency } from "../../Common/domain/Countries";
import { ExchangeRate } from "../../Common/domain/ExchangeRates";

export const getCountryDestination = (
  country: string,
  contries: ExchangeRate[]
) => {
  return contries.find((exchangeRate) => exchangeRate.countryCode === country);
};

export const getQuoteCountryDestinationCode = (
  currency: string,
  contries: CountryWithCurrency[]
) => {
  return contries.find((country) => country.currency === currency)?.countryCode;
};
