import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { isEmpty } from "lodash";

// own states
import { AppDispatch, State } from "../../../Redux/Store";

import {
  BeneficiariesCard,
  BeneficiaryIcon,
  CreateBeneficiary,
  Header,
  SubtitleBeneficiary,
} from "./BeneficiarySelection.styled";

// Own components
import Layout from "../../../Components/Layout";
import { Column, Container, Row } from "../../../Components/Grid";
import Title from "../../../Components/Title";
import Text from "../../../Components/Text";
import { ProgressSteps } from "../../../Components/Steps";
import IconShape from "../../../Components/IconShape";

import { JustifyContent } from "../../../Types/Column";
import { BeneficiarySlice } from "../../../Redux/Beneficiary";
import { QuotationSelector } from "../../../Redux/Quotation/Quotation.slice";
import { BeneficiarySelector } from "../../../Redux/Beneficiary/Beneficiary.slice";

import { useSendMoneyStepper } from "../../../Hooks/useSendMoneyStepper";
import { usePaymentOrder } from "../../../Hooks/usePaymentOrders";
import {
  Account,
  BeneficiaryRecipient,
} from "../../../Modules/Beneficiary/domain/Beneficiary";

import BeneficiaryCard from "../Components/BeneficiaryCard";
import { GeoLocationContext } from "../../../Contexts/GeoLocation";
import { useBeneficiary } from "../../../Hooks/useBeneficiary";
import { useModal } from "../../../Hooks/useModal";

import "react-responsive-pagination/themes/classic.css";
import React from "react";
import { scrollTopWindow } from "../../../Utilities/ScrollTopWindow";
import { Pagination } from "../../../Components/Pagination/Pagination.component";

const BeneficiarySelection = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    modal: unitellerDisclaimerModal,
    showModal: showUnitellerDisclaimerModal,
    setOpenModal: setUnitellerDisclaimerModalOpen,
  } = useModal();
  const { steps, activeStep } = useSendMoneyStepper({ initialActiveStep: 2 });

  const [preferred, setPreferred] = useState<BeneficiaryRecipient[]>([]);
  const [recipients, setRecipients] = useState<BeneficiaryRecipient[]>([]);
  const location = useContext(GeoLocationContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const {
    isLoading: loadingBeneficiaryVerify,
    verifyErrorModal,
    velocityModal,
    verifyCurrentSendMoneySelection,
    verificationUniteller,
    saveSenderEmailUniteller,
  } = usePaymentOrder();
  const { isLoading: loadingBeneficiary, getBeneficiariesPaginated } =
    useBeneficiary();

  const loading = loadingBeneficiary || loadingBeneficiaryVerify;

  const currentBeneficiarySelected =
    useSelector(BeneficiarySelector).data.beneficiarySelected;
  const {
    countryDestination,
    currentDeliveryMethodQuote,
    currentPaymentDestination,
  } = useSelector(QuotationSelector).data;
  const currentQuoteDestinations =
    currentDeliveryMethodQuote?.paymentDestinations;
  const isCashPickup = currentDeliveryMethodQuote?.deliveryMethodCode === "CPU";

  const fetchBeneficiaries = async (page: number) => {
    const paginationResponse = await getBeneficiariesPaginated({
      country: !isCashPickup ? countryDestination : undefined,
      destination: !isCashPickup ? currentPaymentDestination?.id : undefined,
      page: page,
    });
    if (paginationResponse) {
      let preferredRecipients = paginationResponse.preferredRecipients || [];
      let nonPreferredRecipients = paginationResponse.recipients || [];

      if (isCashPickup) {
        preferredRecipients =
          preferredRecipients?.filter(
            (recipient) => recipient.country === countryDestination
          ) || [];
        nonPreferredRecipients =
          nonPreferredRecipients?.filter(
            (recipient) => recipient.country === countryDestination
          ) || [];
      }

      setPageCount(Math.ceil(paginationResponse.pagination.total / 20));
      setPreferred(preferredRecipients);
      setRecipients(nonPreferredRecipients);
    }
  };

  const currentPageRecipients = React.useMemo(() => {
    const listaCurrentRecipients = recipients;
    if (listaCurrentRecipients?.length === 0) {
      setCurrentPage(1);
    }
    return listaCurrentRecipients;
  }, [recipients, currentPage]);

  const selectBeneficiary = async (
    beneficiary?: BeneficiaryRecipient,
    account?: Account
  ) => {
    const selected = beneficiary
      ? {
          ...beneficiary,
          accountSelected: {
            ...account,
            rail: currentQuoteDestinations?.find(
              (destination) => destination.destination === account?.bankName
            )?.rail,
          },
        }
      : undefined;
    dispatch(BeneficiarySlice.actions.setBeneficiarySelected(selected));
  };

  const handleAddBeneficiaryClick = (beneficiary: BeneficiaryRecipient) => {
    selectBeneficiary(beneficiary);
    navigate(`/beneficiary/${beneficiary.beneficiaryId}/add-account`);
  };

  useEffect(() => {
    (async () => {
      if (
        (!isEmpty(preferred) || !isEmpty(recipients)) &&
        currentBeneficiarySelected
      ) {
        const rail = currentPaymentDestination?.rail;

        if (rail === "UNT") {
          const responseVerification = await verificationUniteller();
          if (
            responseVerification?.showTermsAndConditions ||
            isEmpty(responseVerification?.senderEmail)
          ) {
            showUnitellerDisclaimerModal({
              modalType: "unitellerDisclaimer",
              showEmailInput: true,
              emailDefaultValue: responseVerification?.senderEmail || undefined,
              handleConfirm: async (data) => {
                const { email } = data as {
                  checkLegalTerms: boolean;
                  email: string;
                };
                if (email) {
                  await saveSenderEmailUniteller(email);
                }
                verifyCurrentSendMoneySelection();
                setUnitellerDisclaimerModalOpen(false);
              },
            });
          } else {
            verifyCurrentSendMoneySelection();
          }
        } else {
          verifyCurrentSendMoneySelection();
        }
      }
    })();
  }, [preferred, recipients, currentBeneficiarySelected]);

  useEffect(() => {
    dispatch(BeneficiarySlice.actions.resetState());
    fetchBeneficiaries(currentPage);
  }, [currentPaymentDestination]);

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    scrollTopWindow();
    fetchBeneficiaries(page);
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout path="/dashboard" textLink={t("buttons.back")} loading={loading}>
        <ProgressSteps steps={steps} activeStep={activeStep} />
        <Container>
          <Row>
            <Column span={12} as={Header}>
              <Title
                tagName="h1"
                text={t("Beneficiaries.Beneficiary.Title")}
                color="black"
                size={6}
                align="left"
              />
            </Column>
            <Column span={12} as={Header}>
              <SubtitleBeneficiary>
                {t("Beneficiaries.BeneficiarieSelection.Subtitle")}
              </SubtitleBeneficiary>
            </Column>
            <Column span={12} as={Header} my={3}>
              <CreateBeneficiary as={NavLink} to="/add-beneficiary">
                <Column span={4} ml={2}>
                  <BeneficiaryIcon>
                    <IconShape
                      icon="user-plus"
                      iconSize="xlarge"
                      width={75}
                      height={75}
                      gradient
                      border="rounded"
                    />
                  </BeneficiaryIcon>
                </Column>
                <Column span={8} px={3}>
                  <Text size={2} weight={600} align={"left"}>
                    {t("Beneficiaries.BeneficiarieSelection.CreateBeneficiary")}
                  </Text>
                </Column>
              </CreateBeneficiary>
            </Column>
            <Column span={12} as={Header}>
              <Text color="black" size={3} align="left" weight={600}>
                {t("Beneficiaries.BeneficiarieSelection.PreferredBeneficiary")}
              </Text>
            </Column>
            <Column
              span={12}
              xs={12}
              sm={12}
              md={12}
              as={BeneficiariesCard}
              justifyContent={JustifyContent.center}
            >
              {isEmpty(preferred) && (
                <Text size={3}>
                  {t("Beneficiaries.BeneficiarieSelection.NoPreferredResults")}
                </Text>
              )}
              {preferred?.map((recipient) => (
                <BeneficiaryCard
                  {...{
                    ...recipient,
                    account: !isCashPickup ? recipient.account : [],
                  }}
                  key={recipient.beneficiaryId}
                  Preferred
                  withAddButton={!isCashPickup}
                  withAccountSelect={!isCashPickup}
                  onClick={(beneficiary, account) => {
                    if (beneficiary) {
                      selectBeneficiary(beneficiary, account);
                    }
                  }}
                  onAddButtonClick={() => {
                    handleAddBeneficiaryClick(recipient);
                  }}
                />
              ))}
            </Column>
            {!isEmpty(currentPageRecipients) && (
              <Column span={12} as={Header}>
                <Text color="black" size={3} align="left" weight={600}>
                  {t("Beneficiaries.Beneficiary.AllBeneficiarie")}
                </Text>
              </Column>
            )}
            <Column span={12} xs={12} sm={12} md={12} as={BeneficiariesCard}>
              {currentPageRecipients?.map((recipient) => (
                <BeneficiaryCard
                  {...{
                    ...recipient,
                    account: !isCashPickup ? recipient.account : [],
                  }}
                  key={recipient.beneficiaryId}
                  withAddButton={!isCashPickup}
                  withAccountSelect={!isCashPickup}
                  onClick={(beneficiary, account) => {
                    if (beneficiary) {
                      selectBeneficiary(beneficiary, account);
                    }
                  }}
                  onAddButtonClick={() => {
                    handleAddBeneficiaryClick(recipient);
                  }}
                />
              ))}
            </Column>
            {pageCount > 1 && (
              <Column span={12} my={4}>
                <Pagination
                  current={currentPage}
                  total={pageCount}
                  onPageChange={(page) => handlePageChange(page)}
                />
              </Column>
            )}
          </Row>
        </Container>
        {verifyErrorModal}
        {velocityModal}
        {unitellerDisclaimerModal}
      </Layout>
    </ThemeProvider>
  );
};

export default BeneficiarySelection;
