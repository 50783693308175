// Own redux and models
import { VenmoTransactionModalProps } from "../../../Models/ModalInterface";

// Own components
import Modal from "../Modal.component";
import { VenmoTransaction } from "../../VenmoTransaction";

// Own styles
import { BottomModalWrapper } from "../BottomModal/BottomModal.styled";

export const VenmoTransactionModal = (props: VenmoTransactionModalProps) => {
  return (
    <Modal {...props} hiddenCloseIcon={false} as={BottomModalWrapper}>
      <VenmoTransaction transactionList={props?.transactionList} />
    </Modal>
  );
};