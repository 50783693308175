export const LEGAL_FILES = [
  {
    path: "/GlobalPrivacyPolicy",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/espan%CC%83ol/ES_PrivacyPolicyGPSP.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/ingle%CC%81s/PrivacyPolicyGPSP.pdf",
  },
];

export const FOOTER_LEGAL_FILES = [
  {
    name: "terms_and_conditions",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/Sendola+Terms+of+Service++(8092025).pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/Sendola+Terms+of+Service++(8092025).pdf",
  },
  {
    name: "privacy_policy",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/espan%CC%83ol/ES_PrivacyPolicyGP.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/ingle%CC%81s/PrivacyPolicyGP.pdf",
  },
  {
    name: "faqs",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/espan%CC%83ol/ES_FAQ.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/ingle%CC%81s/FAQ.pdf",
  },
];

export const UNITELLER_LEGAL_FILES = [
  {
    name: "user_agreement",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/espan%CC%83ol/ES_UniTellerUserAgreement.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/ingle%CC%81s/UniTellerUserAgreement.pdf",
  },
  {
    name: "privacy_policy",
    urlEs:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/espan%CC%83ol/ES_UniTellerPrivacyPolicy.pdf",
    urlEn:
      "https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Global/ingle%CC%81s/UniTellerPrivacyPolicy.pdf",
  },
];
