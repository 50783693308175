import { Errors } from "../Modules/Common/domain/Errors";
import { useStatus } from "./useStatus";
import { createApiCountryRepository } from "../Modules/Common/infraestructure/ApiCountryRepository";
import { getAllCountries, getCountry } from "../Modules/Common/application/getCountry";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../Redux/Store";
import { countrySelector, countrySlice } from "../Redux/Country/Country.slice";
import { selectorLanguage } from "../Redux/Translate";
import { useEffect, useMemo, useRef, useState } from "react";
import { SelectOption } from "../Models/Select";
import isEmpty from "lodash.isempty";
import { QuotationSelector } from "../Redux/Quotation/Quotation.slice";
import { PaymentMethod, PaymentMethods } from "../Modules/Common/domain/PaymentMethods";
import { CountryWithCurrency } from "../Modules/Common/domain/Countries";

export const useCountry = () => {
  const { status, isLoading, setStatus, setError } = useStatus();
  const dispatch = useDispatch<AppDispatch>();
  const { language } = useSelector(selectorLanguage);
  const { paymentMethodSelected } = useSelector(QuotationSelector).data;
  const languagePrev = useRef<string>(language);
  const paymentSourcePrev = useRef<PaymentMethods | undefined>();
  const allDestinationCountries = useSelector(countrySelector).countries;
  const [countries, setCountries] = useState<CountryWithCurrency[]>(allDestinationCountries);
  const [allCountries, setAllCountries] = useState<CountryWithCurrency[]>([]);
  const allCountriesSelectOption = useMemo(
    () =>
      allCountries.map((c) => ({
        label: c.countryName,
        value: c.countryCode,
        shortCode: c.alpha2Code,
        image: c.icon,
      })) as (SelectOption & { shortCode: string })[],
    [allCountries]
  );

  const countriesWithoutUSAOptions: (SelectOption & { shortCode: string })[] = allDestinationCountries
    .filter((country) => country.countryCode !== "USA")
    .map((c) => ({
      label: c.countryName,
      value: c.countryCode,
      shortCode: c.alpha2Code, // For the phone number input
      image: c.icon,
    }));

  const getCountryList = async (language: string, sponsorBank?: string) => {
    setStatus("loading");
    try {
      const countriesList = await getCountry(createApiCountryRepository())(language, sponsorBank);
      setCountries(countriesList);
      dispatch(countrySlice.actions.setCountryList(countriesList));
      setStatus("idle");
      return countriesList;
    } catch (error) {
      setStatus("error");
      setError(error as Errors);
    }
  };

  const getAllCountriesList = async (language: string) => {
    setStatus("loading");
    try {
      const countries = await getAllCountries(createApiCountryRepository())(language);

      if (!isEmpty(countries)) {
        setAllCountries(countries);
      } else {
        setAllCountries([]);
      }
      setStatus("idle");
      return countries;
    } catch (error) {
      setStatus("error");
      setError(error as Errors);
    }
  };

  useEffect(() => {
    if ((language && language !== languagePrev.current) || isEmpty(countriesWithoutUSAOptions)) {
      getCountryList(language);
      languagePrev.current = language;
    } else if (paymentMethodSelected && paymentMethodSelected.paymentSource !== paymentSourcePrev.current) {
      const paymentMethod = paymentMethodSelected as PaymentMethod;
      if (paymentMethod.isSendolaPay) {
        getCountryList(language, paymentMethod.sponsorBank);
      } else {
        getCountryList(language);
      }
      paymentSourcePrev.current = paymentMethodSelected?.paymentSource;
    }
  }, [language, paymentMethodSelected?.id]);

  return {
    status,
    isLoading,
    countries,
    countriesWithoutUSAOptions,
    allCountries,
    allCountriesSelectOption,
    getCountryList,
    getAllCountriesList,
  };
};
