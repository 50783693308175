import {
  ButtonContainer,
  IconContainer,
  PopupCard,
  TextContainer,
} from "./InConstructionModal.styled";
import Icon from "../../Icon";
import { Column, Container, Row } from "../../Grid";
import Title from "../../Title";
import Button from "../../Button";
import { useTranslation } from "react-i18next";
import Modal from "../Modal.component";
import { ModalProps } from "../../../Models/ModalInterface";

const InConstructionModal = (props: ModalProps) => {
  const [t] = useTranslation("global");

  return (
    <Modal {...props} as={PopupCard}>
      <Container>
        <Row>
          <Column span={12} xs={12}>
            <IconContainer>
              <Icon icon="alertTriangle" color="error" size="xlarge" />
            </IconContainer>
            <TextContainer>
              <Title
                text={t("PopUp.Title")}
                size={5}
                tagName="h1"
                color="black"
              />
              <Title
                text={t("PopUp.SubTitle")}
                size={3}
                color="black"
                tagName="h5"
              />
            </TextContainer>
            <ButtonContainer>
              <Button
                variant="primary"
                text={t("buttons.close")}
                sizeText="large"
                colorIcon="white"
                sizeIcon="large"
                iconButtonLeft="circleX"
                onClick={() => props.handleClose()}
                sizeButton="large"
              />
            </ButtonContainer>
          </Column>
        </Row>
      </Container>
    </Modal>
  );
};

export default InConstructionModal;
