import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { QuotationState } from "../../Modules/Quote/domain/QuoteState";

const initialState: QuotationState = {
  loading: false,
  error: null,
  data: {
    currentQuotation: undefined,
    countryDestination: undefined,
    currentDeliveryMethodQuote: undefined,
    currentPaymentDestination: undefined,
    currentPaymentMethod: undefined,
    couponToApply: undefined,
    currentQuoteDiscounted: undefined,
    currentChannel: undefined,
  },
};

export const QuotationSlice = createSlice({
  name: "quotation",
  initialState,
  reducers: {
    resetState(state) {
      state = initialState;
      return state;
    },
    setCurrentQuotation(state, action) {
      state.data.currentQuotation = action.payload.currentQuotation;
      state.data.countryDestination = action.payload.countryDestination;
    },
    setCountryDestination(state, action) {
      state.data.countryDestination = action.payload;
    },
    setCurrentDeliveryMethodQuote(state, action) {
      state.data.currentDeliveryMethodQuote = action.payload;
    },
    setCurrentPaymentDestination(state, action) {
      state.data.currentPaymentDestination = action.payload;
    },
    setCurrentPaymentMethod(state, action) {
      state.data.currentPaymentMethod = action.payload;
    },
    setPaymentMethods(state, action) {
      state.data.paymentMethods = action.payload;
    },
    setPaymentMethodSelected(state, action) {
      state.data.paymentMethodSelected = action.payload;
    },
    setCouponToApply(state, action) {
      state.data.couponToApply = action.payload;
    },
    setCurrentQuoteDiscounted(state, action) {
      state.data.currentQuoteDiscounted = action.payload;
    },
    setCurrentChannel(state, action) {
      state.data.currentChannel = action.payload;
    },
    setQuotesPerChannel(state, action) {
      state.data.quotesPerChannel = action.payload;
    },
  },
});

export default QuotationSlice.reducer;
export const QuotationSelector = (state: State) => state.quotation;
