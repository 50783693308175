import { useTranslation } from "react-i18next";
import { FlexContainer } from "../../Flex/Flex.styled";
import Text from "../../Text";
import CurrencyService from "../../../Services/CurrencyService";
import { maskInterbankKey } from "../../../Utilities/String";
import { GradientCard } from "../../Card/Card.styled";
import Button from "../../Button";
import { BeneficiaryRecipient } from "../../../Modules/Beneficiary/domain/Beneficiary";
import { RecipientExchangeRates } from "../../../Modules/Common/domain/ExchangeRates";
import IconFont from "../../IconFont";
import { CSSProperties } from "styled-components";
import { useSelector } from "react-redux";
import { BeneficiarySelector } from "../../../Redux/Beneficiary/Beneficiary.slice";
import { BankSection } from "../QuoterCardAdvanced.styled";

interface RecipientCardProps extends BeneficiaryRecipient {
  id?: string;
  active?: boolean;
  backgroundColor?: CSSProperties["backgroundColor"];
  onClick?: (recipient?: BeneficiaryRecipient) => void;
  onEditClick?: () => void;
}

export const RecipientCard = ({
  id,
  active,
  backgroundColor,
  onClick,
  onEditClick,
  ...recipient
}: RecipientCardProps) => {
  const [t] = useTranslation("global");
  const { beneficiarySelected } = useSelector(BeneficiarySelector).data;
  return (
    <Button
      id={id}
      variant="transparent"
      padding="0px"
      onClick={() => onClick && onClick(recipient)}
    >
      <GradientCard
        width="15.875rem"
        padding="6px 10px"
        active={active}
        borderColor="transparent"
        backgroundColor={backgroundColor}
      >
        <FlexContainer
          justify="space-between"
          alignItems="center"
          w="15.875rem"
        >
          <FlexContainer w="100%" flex="1 1 auto" gap="8px">
            <img
              width={24}
              src={
                RecipientExchangeRates.find(
                  (exchange) => exchange.countryCode === recipient.country
                )?.icon
              }
            />
            <FlexContainer mw="10.5rem" direction="column">
              <Text
                align="left"
                size={0.5}
                lineHeight="21px"
                weight={500}
                margin={0}
                overflow="ellipsis"
              >
                {recipient.fullName}
              </Text>
              {recipient.account?.length > 1 ? (
                <Text
                  align="left"
                  size={0.5}
                  lineHeight="18px"
                  color="grey"
                  weight={500}
                  margin={0}
                >
                  {" "}
                  {t("Beneficiaries.MultipleAccounts")}
                </Text>
              ) : (
                <BankSection>
                  <Text
                    align="left"
                    size={0.5}
                    lineHeight="18px"
                    color="grey"
                    weight={500}
                    margin={"0 4px 0 0"}
                  >
                    {recipient.account?.[0]?.bankName
                      ? recipient.account?.[0]?.bankName.length > 10
                        ? recipient.account?.[0]?.bankName.substring(0, 10) +
                          "..."
                        : recipient.account?.[0]?.bankName
                      : ""}
                  </Text>
                  <Text
                    align="left"
                    size={0.5}
                    lineHeight="18px"
                    color="grey"
                    weight={500}
                    margin={0}
                  >
                    {" "}
                    {maskInterbankKey(recipient.account?.[0]?.accountNumber)}
                  </Text>
                </BankSection>
              )}
            </FlexContainer>
          </FlexContainer>
          <FlexContainer w="content-width" direction="column" alignItems="end">
            <IconFont
              name="pencil"
              color="primaryHighlight"
              size="medium"
              onClick={onEditClick}
            />
          </FlexContainer>
        </FlexContainer>
      </GradientCard>
    </Button>
  );
};
