import { PropsCheck } from "../../Models/checkboxInterface";
import {
  RowLeft,
  Checkbox,
  Text,
  Label,
  ErrorContainer,
  ErrorMessage,
} from "./Checkbox.styled";

import Icon from "../Icon";
import { Container, Row, Column } from "../Grid";

const CheckboxInput = (props: PropsCheck) => {
  const { id, variant, check, children, register, label, errors, rules } =
    props;
  return (
    <Container>
      <Row as={RowLeft}>
        <Column span={1}>
          <Checkbox
            id={id}
            checked={check}
            type={variant}
            {...register(label, rules)}
          />
        </Column>
        <Column span={11}>
          <Label htmlFor={id}>
            <Text>{children}</Text>
          </Label>
        </Column>
      </Row>
      <Row as={RowLeft}>
        <Column span={12}>
          {errors[label] && (
            <ErrorContainer>
              <Icon
                icon="alertTriangle"
                color="error"
                size="small"
                fillIcon={false}
              />
              <ErrorMessage>{errors[label].message}</ErrorMessage>
            </ErrorContainer>
          )}
        </Column>
      </Row>
    </Container>
  );
};

export default CheckboxInput;
