import { css } from "styled-components";
import { Screen } from "../../../Utilities/MediaQuery";
import { PropsText } from "../../../Models/TextInterface";
import { handleSizeType } from "../../../Utilities/Sizes";

export const setTextSize = (screen?: Screen) => (props: PropsText) => {
  const getResponsiveSize = () => {
    const sizeMap = {
      [Screen.xs]: props.xs,
      [Screen.sm]: props.sm,
      [Screen.md]: props.md,
      [Screen.lg]: props.lg,
      [Screen.xl]: props.xl,
      [Screen.xxl]: props.xxl,
      [Screen.mobile]: props.mobile,
      [Screen.desktop]: props.desktop,
      default: props.size,
    };
    return screen ? sizeMap[screen] : sizeMap["default"];
  };

  return css`
    font-size: ${handleSizeType(getResponsiveSize() || props.size)};
  `;
};
