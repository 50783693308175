import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

// own states
import { State } from "../../Redux/Store";

// Own styles / assets
import {
  CurrencySection,
  IconInfo,
  IconsInfoContainer,
  HeadHome,
  TitleSection,
  CardInformationSection,
  IconCardInfo,
  ButtonLink,
  ButtonCardSection,
  TitleFooter,
  ImgCard,
  FooterSection,
  ItemSection,
  LinkFooter,
  ButtonLinkIcon,
} from "./HomePage.styled";

// Own components
import Layout from "../../Components/Layout";
import Text from "../../Components/Text";
import Title from "../../Components/Title";
import { Column, Container, Row } from "../../Components/Grid";
import Link from "../../Components/Link";
import QuoterCard from "../../Components/QuoterCard";
import IconFont from "../../Components/IconFont";

import { AlignItems, JustifyContent } from "../../Types/Column";

import HomePageCards from "../../Assets/Img/HomePageCards.png";
import { useQuote } from "../../Hooks/useQuote";

import { ThemeContext } from "../../Contexts/ThemeContext/ThemeContext";

const HomePage = () => {
  const [t] = useTranslation("global");
  const theme = useSelector((state: State) => state.theme);
  const authUrl = import.meta.env.VITE_AUTHENTICATION_URL;
  const returnUrl = `${import.meta.env.VITE_BASE_URL}`;
  const cardUrl = import.meta.env.VITE_CARD_URL;
  const {
    deliveryMethodsSelectOptions,
    sendToSelectOptions,
    isLoading: loading,
    quoterValue,
    currentPaymentDestination,
    handleQuotationChange,
  } = useQuote();
  const {
    theme: { images, currentTheme },
  } = useContext(ThemeContext);

  const handleSendMoney = () => {
    window.location.replace(`${authUrl}/sign-in?redirect_url=${returnUrl}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout loading={loading} fullWidthContent roundedContent={false}>
        <Row>
          <Column span={12}>
            <HeadHome
              bgImage={images["bgHome"]}
              bgImageResponsive={images["bgHomeMobile"]}
              isDefaultTheme={currentTheme === "default"}
            >
              <Container margin="auto">
                <Row>
                  <Column span={10} xs={12} sm={12} md={12} pt={4} px={3}>
                    <TitleSection>
                      <Title
                        tagName="h1"
                        color="white"
                        size={5}
                        text={t("HomePage.Title")}
                        align="left"
                      />
                      <Text
                        size={0}
                        color="white"
                        align="left"
                        margin="45px 0 "
                      >
                        {t("HomePage.Subtitle")}
                      </Text>
                    </TitleSection>
                  </Column>
                  <Column span={6} xs={12} sm={12} md={12} as={CurrencySection}>
                    <QuoterCard
                      title={t("Dashboard.Title")}
                      textButton={"SendMoney.SubmitLanding"}
                      value={quoterValue.current}
                      maxAmount={currentPaymentDestination?.maximumSendAmount}
                      minAmount={currentPaymentDestination?.minimumSendAmount}
                      deliveryMethodsSelect={deliveryMethodsSelectOptions}
                      sendToOptionsSelect={sendToSelectOptions}
                      handleSubmit={handleSendMoney}
                      onChange={(e) => handleQuotationChange(e)}
                    />
                  </Column>
                </Row>
              </Container>
            </HeadHome>
          </Column>
          <Column span={12} justifyContent={JustifyContent.center} my={6}>
            <IconsInfoContainer>
              <IconInfo>
                <IconFont name="send" size="xxxlarge" gradient />
                <Text size={4}>{t("HomePage.Info.SectionOne")}</Text>
              </IconInfo>
              <IconInfo>
                <IconFont name="shield-check" size="xxxlarge" gradient />
                <Text size={4}>{t("HomePage.Info.SectionTwo")}</Text>
              </IconInfo>
              <IconInfo>
                <IconFont name="credit-card" size="xxlarge" gradient />
                <Text size={4}>{t("HomePage.Info.SectionThree")}</Text>
              </IconInfo>
            </IconsInfoContainer>
          </Column>
          <Column
            span={12}
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.flexStart}
          >
            <CardInformationSection>
              <Row>
                <Column span={8} lg={12} xs={12} sm={12} md={12} mt={6}>
                  <Title
                    color="black"
                    tagName="h1"
                    size={5}
                    text={t("HomePage.CardInfo.Title")}
                    align="left"
                  />
                </Column>
                <Column span={12} hiddenDesktop>
                  <ImgCard src={HomePageCards} alt="Sendola-2023" />
                </Column>
                <Column span={8} lg={10} xs={12} sm={12} md={12}>
                  <IconsInfoContainer>
                    <IconCardInfo>
                      <IconFont name="cash" size="large" color="primary" />
                      <Text size={3}>{t("HomePage.CardInfo.CardCash")}</Text>
                    </IconCardInfo>
                    <IconCardInfo>
                      <IconFont
                        name="shield-check"
                        size="large"
                        color="primary"
                      />
                      <Text size={3}>
                        {t("HomePage.CardInfo.CardShieldCheck")}
                      </Text>
                    </IconCardInfo>
                    <IconCardInfo>
                      <IconFont
                        name="credit-card"
                        size="medium"
                        color="primary"
                      />
                      <Text size={3}>
                        {t("HomePage.CardInfo.CardCreditCard")}
                      </Text>
                    </IconCardInfo>
                  </IconsInfoContainer>
                </Column>
              </Row>
              <Row>
                <Column
                  span={6}
                  lg={5}
                  xs={12}
                  sm={12}
                  md={12}
                  as={ButtonCardSection}
                  mt={4}
                >
                  <ButtonLink>
                    <Link href={`${cardUrl}`}>
                      {t("HomePage.CardInfo.Button")}
                      <ButtonLinkIcon>
                        <IconFont
                          name="diagonal-arrow"
                          size="medium"
                          color="white"
                        />
                      </ButtonLinkIcon>
                      {/* <img src={CardIconBtn} alt="link_card" /> */}
                    </Link>
                  </ButtonLink>
                </Column>
              </Row>
            </CardInformationSection>
          </Column>
          <Column span={12}>
            <TitleFooter>{t("HomePage.Footer.Title")}</TitleFooter>
          </Column>
          <Column span={12} justifyContent={JustifyContent.center}>
            <FooterSection>
              <ItemSection>
                <LinkFooter href="/contact-us">
                  <IconFont name="email" size="default" color="black" />
                  <Text size={1} margin="0 0 0 15px" weight={700} align="left">
                    {t("HomePage.Footer.Email")}
                  </Text>
                </LinkFooter>
              </ItemSection>

              <ItemSection>
                <LinkFooter href="/FAQs" target="_blank">
                  <IconFont name="faqs" size="default" color="black" />
                  <Text size={1} margin="0 0 0 15px" weight={700} align="left">
                    {t("HomePage.Footer.Question")}
                  </Text>
                </LinkFooter>
              </ItemSection>

              <ItemSection>
                <LinkFooter href="tel:8327302683">
                  <IconFont
                    name="phone-assistance"
                    size="default"
                    color="black"
                  />
                  <div>
                    <Text
                      size={1}
                      margin="0 0 0 15px"
                      weight={700}
                      align="left"
                    >
                      {t("HomePage.Footer.HeadSet")}
                    </Text>
                    <Text
                      size={1}
                      margin="0 0 0 15px"
                      weight={700}
                      align="left"
                    >
                      832-730-2683
                    </Text>
                  </div>
                </LinkFooter>
              </ItemSection>
            </FooterSection>
          </Column>
        </Row>
        {/* <ConstructionPopUp handlePopUp={handlePopUp} isPopout={isShowPopup} /> */}
      </Layout>
    </ThemeProvider>
  );
};

export default HomePage;
