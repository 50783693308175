import { createAsyncThunk } from "@reduxjs/toolkit";
import { createClient } from "../../Services/ApiClient";

import { checkoutAPIUrl } from "../../Constants/ConfigureApi";

import type { ApiRequestError } from "../../Types/ApiRequestError";
import type { PaymentCheckoutCustomerCard } from "../../Types/Payments";

const client = createClient();

export const getCustomerCard = createAsyncThunk<
  PaymentCheckoutCustomerCard,
  string,
  { rejectValue: ApiRequestError }
>(
  "PaymentCheckout/getCustomerCard",
  async (customerEmail, { rejectWithValue }) => {
    try {
      const response = await client.get<PaymentCheckoutCustomerCard>(
        `${checkoutAPIUrl}/customer-card-by-email/${customerEmail}`
      );
      return response.data;
      // eslint-disable-next-line
    } catch (e: any) {
      return rejectWithValue(e);
    }
  }
);
