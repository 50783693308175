import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Redux/Store";
import { useTranslation } from "react-i18next";
import { GetLanguageSettingAction } from "../../Redux/GetLanguageSettings/LanguageSettings.actions";
import { GET_LANGUAGE_SETTINGS_FULFILLED, GET_LANGUAGE_SETTINGS_REJECTED } from "../../Constants/Language";
import { LanguageResponseData } from "../../Models/Language";
import { changeLanguage, selectorLanguage } from "../../Redux/Translate";
import { PropsWithChildren, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import { PatchLanguageSettingAction } from "../../Redux/PatchLanguageSettings/PatchLanguageSettings.actions";

export const LanguageProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation("global");
  const { language } = useSelector(selectorLanguage);

  const getLanguageSettings = async () => {
    const response = await dispatch(GetLanguageSettingAction());

    if (response?.type === GET_LANGUAGE_SETTINGS_FULFILLED) {
      const language = (response?.payload as LanguageResponseData)?.language;
      i18n.changeLanguage(language || "en");
      dispatch(changeLanguage(language || "en"));
    } else if (response?.type === GET_LANGUAGE_SETTINGS_REJECTED) {
      i18n.changeLanguage("en");
      dispatch(changeLanguage("en"));
    }
  };

  const updateLanguage = async (language: string) => {
    await dispatch(changeLanguage(language));
    await dispatch(PatchLanguageSettingAction(language));
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    getLanguageSettings();
  }, []);

  return <LanguageContext.Provider value={{ language, updateLanguage }}>{children}</LanguageContext.Provider>;
};
