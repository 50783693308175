import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { CheckInstructionsLogo } from "..";
import { Spinner } from "../../../../ReviewTransaction/components/Spinner/Spinner.styled";

import { CheckInstructionsStyled } from "./CheckInstructionsContainer.styled";
import Button from "../../../../../Components/Button";
import Text from "../../../../../Components/Text";

type Props = {
  children?: ReactNode;
  loading?: boolean;
  title: string;
  description: string;
  icon?: ReactNode;
  btnAction?: () => void;
};

const CheckInstructionsContainer = ({ 
  loading = false, 
  title,
  description,
  icon,
  btnAction,
  children 
}: Props) => {
  const [t] = useTranslation("global");

  return (
    <CheckInstructionsStyled>
      <CheckInstructionsLogo>
        {loading ? <Spinner /> : icon ? icon : null}
      </CheckInstructionsLogo>
      <Text
        color="black"
        size={4}
        align="left"
        weight={600}
        margin="48px 0 24px 0"
      >
        {title}
      </Text>
      <Text color="grey" size={1} align="left" margin="0 0 64px 0">
        {description}
      </Text>
      { children || (
        <Button
          type="submit"
          variant="primary"
          sizeButton="large"
          sizeText="medium"
          sizeIcon="medium"
          text={t("Payments.LinkedAccounts.btnStarted")}
          colorIcon="white"
          iconButton="arrowRight"
          onClick={btnAction}
        />
      )}
    </CheckInstructionsStyled>
  );
};

export default CheckInstructionsContainer;
