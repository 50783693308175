import {
  StepBar,
  StepContainer,
  StepFinishIconContainer,
  StepLabel,
  StepWrapper,
  StepsLabelContainer,
} from "./Steps.styled";
import { StepsProps } from "../../Models/stepsInterface";
import { FlexContainer } from "../Flex/Flex.styled";

import IconShape from "../../Components/IconShape";

export const ProgressSteps = (props: StepsProps) => {
  const { steps, activeStep, padding } = props;

  return (
    <StepContainer padding={padding}>
      {steps.map(({ step, label }, index) => (
        <StepWrapper key={step}>
          <StepBar
            status={
              activeStep === step
                ? "current"
                : activeStep >= step
                ? "done"
                : "default"
            }
          />
          <StepsLabelContainer>
            {index === steps.length - 1 ? (
              <FlexContainer justify="center" alignItems="start">
                <StepFinishIconContainer>
                  <IconShape
                    icon="circle-check"
                    iconSize="default"
                    border="rounded"
                    bgColor="disabled"
                    height={24}
                    width={24}
                    {...(steps.length === activeStep ? { gradient: true } : {})}
                  />
                </StepFinishIconContainer>
              </FlexContainer>
            ) : (
              <StepLabel key={step}>{label}</StepLabel>
            )}
          </StepsLabelContainer>
        </StepWrapper>
      ))}
    </StepContainer>
  );
};
